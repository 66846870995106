import { GridProps, TextGrid, Typography } from '@opswat/react-ui'

interface IProps {
  label: string | React.ReactNode
  value: string | React.ReactNode
  valueProps?: GridProps
  containerProps?: GridProps
}

const TextGridView = ({ label, value, valueProps, containerProps }: IProps) => {
  return (
    <TextGrid
      containerProps={containerProps}
      label={typeof label === 'string' ? <Typography variant="subtitle2">{label}</Typography> : label}
      value={typeof value === 'string' ? <Typography component="div">{value}</Typography> : value}
      labelColProps={{ xs: 4, md: 3 }}
      valueColProps={{ xs: true, ...valueProps }}
    />
  )
}

export default TextGridView
