import React, { useState, useEffect } from 'react'

import _get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import {
  useInterestedProductsQuery,
  useProductInterestedAddMutation,
  useProductInterestedRemoveMutation,
  useSolutionsQuery,
  useLazyProductsQuery
} from 'myopswat-web/src/api/product'
import {
  useEntryIgnoreMutation,
  useEntrySeenMutation,
  useEntryUnseenMutation,
  useLatestNewsQuery,
  useProductSubscriptionEntriesQuery
} from 'myopswat-web/src/api/entry'
import {
  saveProducts,
  addInterestedProducts,
  saveInterestedProducts,
  selectInterestedProducts,
  removeInterestedProducts,
  addSeenEntries,
  removeSeenEntries,
  ignoreEntries,
  selectProductSubscriptionEntries,
  selectLatestNews,
  saveLatestNews,
  saveProductSubscriptionEntries,
  selectProducts
} from 'myopswat-web/src/containers/HomeContainer/homeContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import HomePage from 'myopswat-web/src/pages/HomePage'
import { IProductFilterInput } from 'myopswat-web/src/api/product/types'

const HomeContainer = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const products = useTypedSelector(selectProducts)
  const interestedProducts = useTypedSelector(selectInterestedProducts)
  const latestNews = useTypedSelector(selectLatestNews)
  const productSubscriptionEntries = useTypedSelector(selectProductSubscriptionEntries)

  const [saveItem, setSaveItem] = useState<any>()

  const { enqueueSnackbar } = useSnackbar()

  const [getProducts, { data: productsData, isFetching: isFetchingProducts }] = useLazyProductsQuery()

  const {
    data: interestedProductsData,
    isFetching: isFetchingInterestedProducts,
    refetch: refetchInterestedProducts
  } = useInterestedProductsQuery()

  const [productInterestedAdd, { data: productInterestedAddData, error: productInterestedAddError }] =
    useProductInterestedAddMutation()

  const [productInterestedRemove, { data: productInterestedRemoveData, error: productInterestedRemoveError }] =
    useProductInterestedRemoveMutation()

  const { data: latestNewsData, isFetching: isFetchingLatestNews, refetch: refetchLatestNews } = useLatestNewsQuery()

  const {
    data: relatedProductData,
    isFetching: isFetchingProductSubscriptionEntries,
    refetch: refetchProductSubscriptionEntries
  } = useProductSubscriptionEntriesQuery()

  const [entrySeen, { data: entrySeenData, error: entrySeenError }] = useEntrySeenMutation()

  const [entryUnseen, { data: entryUnseenData, error: entryUnseenError }] = useEntryUnseenMutation()

  const [entryIgnore, { data: entryIgnoreData, error: entryIgnoreError }] = useEntryIgnoreMutation()

  const { data: solutionsData, isFetching: isFetchingSolutions } = useSolutionsQuery()

  const handleProductInterestedAdd = async (data: any) => {
    try {
      setSaveItem(data)
      await productInterestedAdd(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleProductInterestedRemove = async (data: any) => {
    try {
      setSaveItem(data)
      await productInterestedRemove(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntrySeen = async (data: any) => {
    try {
      setSaveItem(data)
      await entrySeen(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntryUnseen = async (data: any) => {
    try {
      setSaveItem(data)
      await entryUnseen(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntryIgnore = async (data: any) => {
    try {
      setSaveItem(data)
      await entryIgnore(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterProducts = (data: IProductFilterInput) => {
    getProducts(data)
  }

  useEffect(() => {
    if (_get(productInterestedAddData, 'success')) {
      enqueueSnackbar(t('makeFavoriteSuccess'), {
        variant: 'success'
      })

      dispatch(addInterestedProducts(saveItem))
    } else if (
      _get(productInterestedAddError, 'errors.0.message') ||
      _get(productInterestedAddError, 'message') ||
      _get(productInterestedAddData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('makeFavoriteFail'), {
        variant: 'error'
      })
    }
  }, [productInterestedAddData, productInterestedAddError])

  useEffect(() => {
    if (_get(productInterestedRemoveData, 'success')) {
      enqueueSnackbar(t('unmakeFavoriteSuccess'), {
        variant: 'success'
      })

      dispatch(removeInterestedProducts(saveItem))
    } else if (
      _get(productInterestedRemoveError, 'errors.0.message') ||
      _get(productInterestedRemoveError, 'message') ||
      _get(productInterestedRemoveData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('unmakeFavoriteFail'), {
        variant: 'error'
      })
    }
  }, [productInterestedRemoveData, productInterestedRemoveError])

  useEffect(() => {
    if (_get(entrySeenData, 'success')) {
      dispatch(addSeenEntries(saveItem))
    } else if (
      _get(entrySeenError, 'errors.0.message') ||
      _get(entrySeenError, 'message') ||
      _get(entrySeenData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('unmakeReadFail'), {
        variant: 'error'
      })
    }
  }, [entrySeenData, entrySeenError])

  useEffect(() => {
    if (_get(entryUnseenData, 'success')) {
      dispatch(removeSeenEntries(saveItem))
    } else if (
      _get(entryUnseenError, 'errors.0.message') ||
      _get(entryUnseenError, 'message') ||
      _get(entryUnseenData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('unmakeReadFail'), {
        variant: 'error'
      })
    }
  }, [entryUnseenData, entryUnseenError])

  useEffect(() => {
    if (_get(entryIgnoreData, 'success')) {
      enqueueSnackbar(t('removeBlogSuccess'), {
        variant: 'success'
      })

      dispatch(ignoreEntries(saveItem))
    } else if (
      _get(entryIgnoreError, 'errors.0.message') ||
      _get(entryIgnoreError, 'message') ||
      _get(entryIgnoreData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('removeBlogFail'), {
        variant: 'error'
      })
    }
  }, [entryIgnoreData, entryIgnoreError])

  useEffect(() => {
    if (productsData) {
      dispatch(saveProducts(productsData))
    }
  }, [isFetchingProducts])

  useEffect(() => {
    if (interestedProductsData) {
      dispatch(saveInterestedProducts(interestedProductsData))
    }
  }, [isFetchingInterestedProducts])

  useEffect(() => {
    if (latestNewsData) {
      dispatch(saveLatestNews(latestNewsData))
    }
  }, [isFetchingLatestNews])

  useEffect(() => {
    if (relatedProductData) {
      dispatch(saveProductSubscriptionEntries(relatedProductData))
    }
  }, [isFetchingProductSubscriptionEntries])

  useEffect(() => {
    handleFilterProducts({
      tags: [],
      solutionIds: [],
      excludedTags: ['utility']
    })
    refetchInterestedProducts()
    refetchLatestNews()
    refetchProductSubscriptionEntries()
  }, [])

  return (
    <HomePage
      productsData={products}
      isFetchingProducts={isFetchingProducts}
      interestedProductsData={interestedProducts}
      isFetchingInterestedProducts={isFetchingInterestedProducts}
      handleProductInterestedAdd={handleProductInterestedAdd}
      handleProductInterestedRemove={handleProductInterestedRemove}
      latestNewsData={latestNews}
      isFetchingLatestNews={isFetchingLatestNews}
      relatedProductData={productSubscriptionEntries}
      isFetchingProductSubscriptionEntries={isFetchingProductSubscriptionEntries}
      handleEntrySeen={handleEntrySeen}
      handleEntryUnseen={handleEntryUnseen}
      handleEntryIgnore={handleEntryIgnore}
      solutionsData={solutionsData}
      isFetchingSolutions={isFetchingSolutions}
      handleFilterProducts={handleFilterProducts}
    />
  )
}

export default HomeContainer
