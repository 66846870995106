import { Box, Typography } from '@opswat/react-ui'

export const EmailSubscriptionContent = ({ title, description }: { title: string; description: string }) => {
  return (
    <>
      <Typography variant="body2">{title}</Typography>
      <Box sx={{ mt: 0.25 }}>
        <Typography variant="subtitle2" color="textSecondary" lineHeight="20px">
          {description}
        </Typography>
      </Box>
    </>
  )
}
