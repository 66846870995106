import React, { useEffect, useRef, useState } from 'react'

import _compact from 'lodash/compact'
import _concat from 'lodash/concat'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _uniq from 'lodash/uniq'

import { CheckDoneIcon, CloseIcon, OptionIcon } from '@opswat/react-icon'
import {
  Box,
  ClickAwayListener,
  Divider,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popper,
  Typography,
  TypographyAnimationFrame,
  TypographyLoading,
  useTheme
} from '@opswat/react-ui'
import { useTranslation } from 'react-i18next'

import DocSearcherSDK from './DocSearcherSDK'
import { useAccountSettingsUpdateMutation, useLazyProfileQuery } from 'myopswat-web/src/api/account'
import { IAccountSettingsUpdateInput } from 'myopswat-web/src/api/account/types'
import { useSnackbar } from 'notistack'
import { useChatCompletionMutation } from 'myopswat-web/src/api/ai'

const AIModal = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { enqueueSnackbar } = useSnackbar()

  const [accountSettingsUpdate, { data: accountSettingsUpdateData, error: accountSettingsUpdateError }] =
    useAccountSettingsUpdateMutation()

  const [getProfile] = useLazyProfileQuery()

  const handleAccountSettingsUpdate = async (data: IAccountSettingsUpdateInput) => {
    try {
      await accountSettingsUpdate(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const [chatCompletion] = useChatCompletionMutation()

  const popperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [clientX, setClientX] = useState<number>(0)
  const [clientY, setClientY] = useState<number>(0)

  const messageQuestionError = 'No suggested questions. Please try another text.'
  const [questionList, setQuestionList] = useState<any[]>([])
  const [isLoadingQuestion, setIsLoadingQuestion] = useState<boolean>(false)
  const [selectQuestion, setSelectQuestion] = useState<any>()
  const [selectQuestionList, setSelectQuestionList] = useState<any>([])

  const messageAnswerError = 'Nothing was found. Please give the system a moment then try again.'
  const [answer, setAnswer] = useState<any>('')
  const [isLoadingAnswer, setIsLoadingAnswer] = useState<boolean>(false)

  const fetchQuestion = async (text: string) => {
    // eslint-disable-next-line no-var
    var result: any = ''

    try {
      await chatCompletion({
        content: text,
        option: 1
      })
        .then((data: any) => {
          result = _get(data, 'data.result.content') || ''
        })
        .catch(() => {
          result = ''
        })

      return result
    } catch (e) {
      return ''
    }
  }

  const fetchQuestionOPSWAT = async (text: string) => {
    // eslint-disable-next-line no-var
    var result: any = ''

    try {
      await chatCompletion({
        content: text,
        option: 2
      })
        .then((data: any) => {
          result = _get(data, 'data.result.content') || ''
        })
        .catch(() => {
          result = ''
        })

      return result
    } catch (e) {
      return ''
    }
  }

  const fetchAnswer = async (question: string) => {
    // eslint-disable-next-line no-var
    var result: any = {}
    const messageAnswerError = 'Nothing was found. Please give the system a moment then try again.'

    try {
      await chatCompletion({
        content: question,
        option: 3
      })
        .then((data: any) => {
          result = _get(data, 'data.result.content') || ''
        })
        .catch(() => {
          result = {
            question: question,
            answer: messageAnswerError
          }
        })

      return result
    } catch (error) {
      result = {
        question: question,
        answer: messageAnswerError
      }
    }

    return result
  }

  const handleQuestion = async (scanText: string, finalText: string) => {
    setIsLoadingQuestion(true)

    try {
      const question1 = await fetchQuestion(scanText)
      const question2 = await fetchQuestion(finalText)
      const question3 = await fetchQuestionOPSWAT(scanText)
      const question4 = await fetchQuestionOPSWAT(finalText)

      const resultQuestionFinal = await _concat(question1, question2, question3, question4)
      const checkLopResult = await _uniq(resultQuestionFinal)
      const checkInvalidResult = await _compact(checkLopResult)
      await setQuestionList(checkInvalidResult)
      await setIsLoadingQuestion(false)
    } catch (error) {
      console.error(error)
      setIsLoadingQuestion(false)
    }
  }

  const handleAnswer = async (question: string) => {
    setIsLoadingAnswer(true)
    const resultAnswerFinal = await fetchAnswer(question)

    await setAnswer(resultAnswerFinal)
    await setIsLoadingAnswer(false)
  }

  const handleClose = () => {
    setOpen(false)
    setClientX(0)
    setClientY(0)

    setQuestionList([])
    setIsLoadingQuestion(false)
    setSelectQuestion(null)
    setSelectQuestionList([])

    setAnswer('')
    setIsLoadingAnswer(false)

    if (window.getSelection()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.getSelection()!.removeAllRanges()
    }
  }

  useEffect(() => {
    if (open === false) {
      DocSearcherSDK.addScanTextListener(document.body, (e: any, docList: any[]) => {
        if (docList && _get(docList, '0') && _get(e, 'x') && _get(e, 'y')) {
          const viewportWidth = window.innerWidth || document.documentElement.clientWidth
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight

          const checkX = e.x > viewportWidth / 2 ? viewportWidth / 1.75 : e.x
          const checkY = e.y > viewportHeight / 2 ? viewportHeight / 3 : e.y + 10

          setClientX(checkX)
          setClientY(checkY)
          setOpen(true)
          handleQuestion(_get(docList, '0', ''), _get(docList, '1', ''))
        }
      })
    }

    return () => {
      DocSearcherSDK.removeScanTextListener(document.body)
    }
  }, [open])

  useEffect(() => {
    if (!_isEmpty(questionList) && !open) {
      setQuestionList([])
      setIsLoadingQuestion(false)
      setSelectQuestion(null)
      setSelectQuestionList([])

      setAnswer('')
      setIsLoadingAnswer(false)
    }
  }, [questionList])

  useEffect(() => {
    if (_get(accountSettingsUpdateData, 'success')) {
      getProfile()
      enqueueSnackbar(t('disabledAISuccess'), {
        variant: 'success'
      })
    } else if (
      _get(accountSettingsUpdateError, 'errors.0.message') ||
      _get(accountSettingsUpdateError, 'message') ||
      _get(accountSettingsUpdateData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('disabledAIFail'), {
        variant: 'error'
      })
    }
  }, [accountSettingsUpdateData, accountSettingsUpdateError])

  return (
    <Popper open={open} ref={popperRef} placement="bottom" style={{ left: clientX, top: clientY, zIndex: 1501 }}>
      <ClickAwayListener onClickAway={() => handleClose()}>
        <Box
          py={1}
          px={2}
          sx={{
            userSelect: 'none',
            width: '500px',
            overflow: 'none',
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.55)',
            border: `1px solid ${theme.palette.divider}`
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>My OPSWAT AI Cyber Adviser</Typography>

            <Box display="flex" alignItems="center" sx={{ mr: -2 }}>
              <Dropdown
                button={
                  <IconButton>
                    <OptionIcon />
                  </IconButton>
                }
                content={
                  <List>
                    <ListItemButton
                      onClick={() => {
                        handleAccountSettingsUpdate({
                          enabledAi: false
                        })
                        handleClose()
                      }}
                    >
                      <Typography variant="subtitle2">Disable My OPSWAT AI Cyber Adviser</Typography>
                    </ListItemButton>
                  </List>
                }
                propsPopper={{ sx: { zIndex: 1501 } }}
              />

              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Divider />

          <List sx={{ maxHeight: '130px', overflow: 'auto' }}>
            {isLoadingQuestion && (
              <Box py={1}>
                <TypographyLoading variant="subtitle2">
                  My OPSWAT AI Cyber Adviser is suggesting questions
                </TypographyLoading>
              </Box>
            )}

            {!isLoadingQuestion && _size(questionList) === 0 && (
              <ListItem disablePadding>
                <ListItemButton disableGutters>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      userSelect: 'none'
                    }}
                  >
                    {messageQuestionError}
                  </Typography>
                </ListItemButton>
              </ListItem>
            )}

            {_map(questionList, (item, idx) => (
              <ListItem disablePadding key={idx.toString()} selected={`${idx + 1}. ${item}` === selectQuestion}>
                <ListItemButton
                  disableGutters
                  onClick={() => {
                    handleAnswer(`${idx + 1}. ${item}`)
                    setSelectQuestion(`${idx + 1}. ${item}`)
                    const selectList = _concat(selectQuestionList, [`${idx + 1}. ${item}`])
                    setSelectQuestionList(selectList)
                  }}
                >
                  {_size(questionList) === 1 && (
                    <TypographyAnimationFrame
                      variant="subtitle1"
                      sx={{
                        userSelect: 'none'
                      }}
                    >
                      {item}
                    </TypographyAnimationFrame>
                  )}

                  {_size(questionList) > 1 && (
                    <TypographyAnimationFrame
                      variant="subtitle1"
                      sx={{
                        userSelect: 'none'
                      }}
                    >{`${idx + 1}. ${item}`}</TypographyAnimationFrame>
                  )}

                  <ListItemIcon>
                    {_includes(selectQuestionList, `${idx + 1}. ${item}`) && (
                      <CheckDoneIcon color={theme.palette.primary.main} />
                    )}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {selectQuestion && !isLoadingAnswer && !_isEmpty(answer) && (
            <>
              <Divider />

              <Box py={1} sx={{ maxHeight: '350px', height: 'auto', overflow: 'auto' }}>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{
                    width: 'auto',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {answer === messageAnswerError || answer === '' ? (
                    <>
                      Nothing was found. Please&nbsp;
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{
                          color: theme.palette.common.white,
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleAnswer(selectQuestion)}
                      >
                        try
                      </Typography>
                      &nbsp;again later.
                    </>
                  ) : (
                    <TypographyAnimationFrame variant="subtitle2">{answer}</TypographyAnimationFrame>
                  )}
                </Typography>
              </Box>
            </>
          )}

          {_size(questionList) > 0 && _size(answer) === 0 && !isLoadingAnswer && (
            <>
              <Divider />
              <Box pt={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Please select question to get information
                </Typography>
              </Box>
            </>
          )}

          {isLoadingAnswer && (
            <>
              <Divider />
              <Box pt={1}>
                <TypographyLoading variant="subtitle2">
                  My OPSWAT AI Cyber Adviser is getting information
                </TypographyLoading>
              </Box>
            </>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}

export default AIModal
