import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    enginesStatus: builder.query<any, string>({
      query: osType => ({
        document: gql`
          query {
            enginesStatus (osType: ${osType}) {
              engineId
              displayName
              latestEngineVersion
              packageType
              releaseVersions {
                releaseDate
                databaseVersions {
                  version
                  createdTime
                }
              }
            }
          }
        `
      })
    }),
    enginePackages: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            enginePackages {
              code
              name
              displayName
              hash
              timestamp
              size
              link
              engines
            }
          }
        `
      })
    })
  })
})

export const { useEnginesStatusQuery, useEnginePackagesQuery, useLazyEnginesStatusQuery, useLazyEnginePackagesQuery } =
  api
