import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  ButtonLoading,
  CheckboxWithLabel,
  Divider,
  Grid,
  TemplateSection,
  TextField,
  Typography
} from '@opswat/react-ui'

import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'

import { useOrganizationUpdateMutation } from 'myopswat-web/src/api/organization'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { OrganizationEditForm } from 'myopswat-web/src/api/organization/types'
import TextGridView from '../TextGridView'
import AddressForm from './AddressForm'
import { basicSchema } from './BasicSchema'

interface IProps {
  organization: any
  countries: any[]
  afterSave: () => void
  onCancel: () => void
}

const BasicEdit = ({ organization, countries, afterSave, onCancel }: IProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateOrganization, { isLoading }] = useOrganizationUpdateMutation()

  const countryMap = useMemo(() => {
    if (_isEmpty(countries)) return {}
    return countries.reduce((obj: any, country: any) => ((obj[country.code] = country), obj), {})
  }, [countries])

  const initValues = useMemo(
    () => ({
      id: organization.id,
      owner: organization.owner || '',
      website: organization.website || '',
      industries: organization.industries.map((industry: number) => industry.toString()),
      billingAddress: organization.billingAddress,
      shippingAddress: organization.shippingAddress,
      note: organization.note || ''
    }),
    [organization]
  )

  const formRef = useForm<OrganizationEditForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(basicSchema)
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues
  } = formRef

  const onSubmit = async (formData: OrganizationEditForm) => {
    try {
      const res = await updateOrganization(formData).unwrap()
      if (res.success) {
        enqueueSnackbar(`You have updated organization's information successfully.`, { variant: 'success' })
        afterSave()
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const copyBillingAddress = (copyFlg: boolean) => {
    if (copyFlg) {
      const billingAddress = _cloneDeep(getValues('billingAddress'))
      // Remove id
      delete billingAddress.id
      const shippingAddress = _merge(getValues('billingAddress'), billingAddress)
      setValue('shippingAddress', shippingAddress)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: onCancel,
                  disabled: isLoading,
                  sx: {
                    mr: 2
                  }
                }}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSubmit(onSubmit, onError),
                  disabled: isLoading
                }}
                isLoading={isLoading}
              >
                Save
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TemplateSection>
        <Grid container sx={{ mt: -4 }}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView label="Name*" value={organization.name} />
              </Grid>
              <Grid item xs={12}>
                <TextGridView
                  label="Owner"
                  value={
                    <TextField
                      placeholder="Name of account owner"
                      required
                      fullWidth
                      error={'owner' in errors}
                      helperText={errors.owner?.message}
                      size="small"
                      {...register('owner')}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextGridView
                  label="Website"
                  value={
                    <TextField
                      fullWidth
                      placeholder="Website address"
                      error={'website' in errors}
                      helperText={errors.website?.message}
                      size="small"
                      {...register('website')}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={10} lg={8}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <AddressForm formRef={formRef} countryMap={countryMap} title="Billing Address" name="billingAddress" />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4} sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 2, sm: 0 } }}>
            <AddressForm formRef={formRef} countryMap={countryMap} title="Shipping Address" name="shippingAddress" />

            <Grid container>
              <Grid item xs={12}>
                <CheckboxWithLabel
                  label="Same as the billing address"
                  checkboxProps={{
                    onChange: (e: any) => copyBillingAddress(e.target.checked)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={10} lg={8}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView
                  containerProps={{
                    sx: {
                      alignItems: 'flex-start'
                    }
                  }}
                  label={<Typography>Note</Typography>}
                  value={
                    <TextField
                      required
                      fullWidth
                      rows={6}
                      multiline
                      error={'note' in errors}
                      helperText={errors.note?.message}
                      size="small"
                      {...register('note')}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TemplateSection>
    </>
  )
}

export default BasicEdit
