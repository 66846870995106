import { FC, useEffect, useMemo } from 'react'

import _format from 'date-fns/format'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { useModal } from '@ebay/nice-modal-react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import {
  CloudIcon,
  DashboardEngineIcon,
  DownloadIcon,
  HomeIcon,
  LicenseV2Icon,
  OrganizationsIcon,
  PoliciesIcon,
  ServiceSupportIcon,
  SolidRightIcon,
  UserGroupIcon
} from '@opswat/react-icon'
import {
  AlertTitle,
  AppMenu,
  Box,
  LeftMenu,
  LeftMenuBanner,
  TemplateDashboard,
  Typography,
  TypographyLink
} from '@opswat/react-ui'

import {
  selectDialogs,
  selectPopupMessage,
  setDialogMessage,
  toggleDialogs
} from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import {
  homePageURL,
  licensedProductsEvaluatedPageURL,
  licensedProductsPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationPageURL,
  productDownloadsPageURL
} from 'myopswat-web/src/routes'

import { DIALOGS } from '@myopswat/common'
import MessageModal from 'myopswat-web/src/components/Dialog/MessageModal'
import { supportServicesPageURL } from 'myopswat-web/src/routes/supportServicesRoutes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import { useAccountSettingsUpdateMutation } from 'myopswat-web/src/api/account'
import BannerHeader from 'myopswat-web/src/components/Banner/BannerHeader'
import { isEnabledBanner } from 'myopswat-web/src/constants'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { enqueueSnackbar } from 'notistack'

import AllForm from './AllForm'
import LogoutLayout from './LogoutLayout'
import RightMenu from './RightMenu'

interface IProps {
  type?: string
}

const LayoutPage: FC<IProps> = ({ type }) => {
  const location = useLocation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const userTypes: string[] = _get(profileData, 'userTypes', [])
  const mdEmailSecurityUrl: string = _get(profileData, 'mdEmailSecurityUrl', '')
  const isChannelPartner = userTypes.includes('CP')
  const message = useTypedSelector(selectPopupMessage)
  const dialogType = useTypedSelector(selectDialogs)

  const dispatch = useAppDispatch()

  const [accountSettingsUpdate] = useAccountSettingsUpdateMutation()

  const messageModal = useModal(MessageModal)

  const handleSelectPage = (data: string) => {
    if (!data) return false
    if (_includes(location.pathname, `${data}`)) return true
    return false
  }

  const arrayData = [
    {
      label: 'Home',
      path: homePageURL,
      icon: <HomeIcon />,
      propListItem: {
        selected: handleSelectPage(homePageURL),
        component: Link,
        to: homePageURL
      }
    },
    {
      label: 'Central Management',
      icon: <DashboardEngineIcon />,
      expandIcon: <SolidRightIcon />,
      propListItem: {
        selected: false,
        component: 'a',
        to: process.env.REACT_APP_OPSWAT_OC_URL,
        target: '_self',
        onClick: () => {
          window.location.href = process.env.REACT_APP_OPSWAT_OC_URL || ''
        }
      }
    },
    {
      label: 'Cloud Services',
      icon: <CloudIcon />,
      expandIcon: <SolidRightIcon />,
      children: [
        {
          label: 'MetaDefender Cloud',
          propListItem: {
            component: Link,
            to: 'https://metadefender.opswat.com/login'
          }
        },
        {
          label: 'MetaDefender IT Access',
          expandIcon: <SolidRightIcon />,
          children: [
            {
              label: 'US Region',
              propListItem: {
                component: Link,
                to: 'https://gears.opswat.com/console/saml/login'
              }
            },
            {
              label: 'EU Region',
              propListItem: {
                component: Link,
                to: 'https://gears-eu.opswat.com/console/saml/login'
              }
            },
            {
              label: 'Other Regions',
              propListItem: {
                component: Link,
                to: 'https://gears.opswat.com/console/saml/login'
              }
            }
          ]
        },
        {
          label: 'Filescan.io',
          propListItem: {
            component: Link,
            to: 'https://filescan.io'
          }
        },
        {
          label: 'MetaDefender Cloud Email Security',
          propListItem: {
            component: Link,
            to: mdEmailSecurityUrl ?? 'https://www.opswat.com/solutions/email-security/cloud-email-gateway'
          }
        }
      ]
    },
    {
      label: 'Product Downloads',
      path: productDownloadsPageURL,
      icon: <DownloadIcon />,
      propListItem: {
        selected: handleSelectPage(productDownloadsPageURL),
        component: Link,
        to: productDownloadsPageURL
      }
    },
    {
      label: 'My Licenses',
      path: licensedProductsPageURL,
      icon: <PoliciesIcon />,
      propListItem: {
        selected: handleSelectPage(licensedProductsPageURL),
        component: Link,
        to: licensedProductsEvaluatedPageURL
      }
    },
    {
      label: 'Activate License',
      icon: <LicenseV2Icon />,
      propListItem: {
        selected: false,
        onClick: () => {
          dispatch(
            toggleDialogs({
              [DIALOGS.ACTIVE_LICENSES]: true
            })
          )
        }
      }
    },
    ...(_get(profileData, 'currentOrganizationId') || _get(profileData, 'usingOrganizationId')
      ? [
          {
            label: 'My Organization',
            path: myOrganizationGeneralInfoPageURL,
            icon: <OrganizationsIcon />,
            propListItem: {
              selected: handleSelectPage(myOrganizationPageURL),
              component: Link,
              to: myOrganizationGeneralInfoPageURL
            }
          }
        ]
      : []),
    {
      label: 'Support',
      path: supportServicesPageURL,
      icon: <ServiceSupportIcon />,
      propListItem: {
        selected: handleSelectPage(supportServicesPageURL),
        component: Link,
        to: supportServicesPageURL
      }
    },
    {
      isHiding: !isChannelPartner,
      label: 'OPSWAT Partner Portal',
      icon: <UserGroupIcon />,
      propListItem: {
        selected: false,
        component: Link,
        to: process.env.REACT_APP_OPSWAT_ALLBOUND_URL
      }
    }
  ]

  const menuList = useMemo(() => _filter(arrayData, item => !item?.isHiding), [arrayData])

  const handleRenderLayout = () => {
    if (isEnabledBanner)
      return (
        <TemplateDashboard
          top={
            <>
              <Box
                sx={{
                  position: 'fixed',
                  zIndex: 1201,
                  top: 0,
                  p: 0,
                  width: '100%'
                }}
              >
                <BannerHeader />
              </Box>

              <AppMenu
                sx={{
                  top: 50
                }}
              >
                <RightMenu />
              </AppMenu>
            </>
          }
          left={<LeftMenuBanner isLogo isIconCollapse menuList={menuList} content={<Outlet />} />}
        />
      )

    return (
      <TemplateDashboard
        top={
          <AppMenu>
            <RightMenu />
          </AppMenu>
        }
        left={<LeftMenu isLogo isIconCollapse menuList={menuList} content={<Outlet />} />}
      />
    )
  }

  const handleRenderFormLayout = () => {
    if (type === 'LOGOUT') {
      return <LogoutLayout />
    } else {
      return <AllForm />
    }
  }

  useEffect(() => {
    if (message) {
      messageModal.show({ message: message })
      dispatch(setDialogMessage(''))
    }
  }, [message])

  useEffect(() => {
    if (
      _get(profileData, 'showWarningSnackbar') &&
      !(
        _get(dialogType, DIALOGS.PERSONAL_INFORMATION, false) ||
        _get(dialogType, DIALOGS_WEB.NPS_NOTIFICATION, false) ||
        _get(dialogType, DIALOGS_WEB.NPS_SURVEY, false)
      )
    ) {
      enqueueSnackbar(
        <>
          <AlertTitle>Engine Update</AlertTitle>
          <Typography variant="body2">Please note Kaspersky will be discontinued as of April 1,2024</Typography>
          <TypographyLink
            variant="body2"
            color="primary"
            href="https://docs.opswat.com/mdcore/knowledge-base/kaspersky-support-for-windows-and-linux-to-be-discontinued"
            target="_blank"
          >
            More details
          </TypographyLink>
        </>,
        {
          variant: 'warning',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          onClose: async () => {
            try {
              const convertTime = _format(new Date(), 'yyyy-MM-dd HH:mm:ssXXX')

              await accountSettingsUpdate({
                showWarningSnackbarAt: convertTime
              })
                .unwrap()
                .then()
                .catch(err => {
                  console.error(err)
                })
            } catch (error) {
              console.error(error)
            }
          },
          SnackbarProps: {
            style: {
              width: '400px'
            }
          }
        }
      )
    }
  }, [_get(profileData, 'showWarningSnackbar')])

  return (
    <>
      {handleRenderLayout()}
      {handleRenderFormLayout()}
    </>
  )
}

export default LayoutPage
