/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _size from 'lodash/size'
import _map from 'lodash/map'

import { useNavigate } from 'react-router-dom'

import {
  Box,
  useTheme,
  Typography,
  BoxImageVideo,
  TypographyLineClamp,
  IconButton,
  Grid,
  Tooltip
} from '@opswat/react-ui'
import { StarIcon, StarYellowIcon } from '@opswat/react-icon'

import { homePageURL } from 'myopswat-web/src/routes'
import defaultImage from 'myopswat-web/src/assets/images/default-bg.png'
interface IProps {
  propsData: any
  propsComponent: object
}

const BoxProductHome: FC<IProps> = ({ propsData, propsComponent }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isInterested = _get(propsData, 'interested', false)

  const handleAddRemoveHome = (data: any) => {
    if (!data) return null

    if (isInterested) {
      const remove = _get(propsComponent, 'handleProductInterestedRemove', (data: any) => {})
      return remove(data)
    } else {
      const add = _get(propsComponent, 'handleProductInterestedAdd', (data: any) => {})
      return add(data)
    }
  }

  return (
    <Box
      p={0.5}
      sx={{
        boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)',
        border: `1px solid ${theme.palette.info.main}`,
        height: '100%',
        transition: 'transform 0.75s',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.info.light
        }
      }}
      onClick={() => navigate(`${homePageURL}/${_get(propsData, 'slug')}`)}
    >
      <Box
        sx={{
          backgroundColor: 'white'
        }}
      >
        <Grid container>
          <Grid item xs sx={{ minHeight: '130px' }}>
            <Box
              px={1}
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              {_map(_get(propsData, 'type'), (item, idx) => (
                <Box
                  sx={{
                    bgcolor: 'inherit',
                    border: `1px solid ${theme.palette.info.main}`,
                    borderRadius: '4px',
                    paddingLeft: '4px',
                    paddingRight: '4px'
                  }}
                  key={idx.toString()}
                  mr={0.5}
                  mt={0.5}
                >
                  <Typography variant="caption">{item}</Typography>
                </Box>
              ))}

              {_map(_get(propsData, 'solutions'), (item, idx) => (
                <Box
                  sx={{
                    bgcolor: 'inherit',
                    border: `1px solid ${theme.palette.info.main}`,
                    borderRadius: '4px',
                    paddingLeft: '4px',
                    paddingRight: '4px'
                  }}
                  key={idx.toString()}
                  mr={0.5}
                  mt={0.5}
                >
                  <Typography variant="caption">{_get(item, 'name')}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs="auto">
            {isInterested ? (
              <Tooltip title="Remove from Tagged Products" arrow>
                <IconButton
                  size="small"
                  onClick={(e: any) => {
                    handleAddRemoveHome(propsData)
                    e.stopPropagation()
                  }}
                >
                  <StarYellowIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add to Tagged Products" arrow>
                <IconButton
                  size="small"
                  onClick={(e: any) => {
                    handleAddRemoveHome(propsData)
                    e.stopPropagation()
                  }}
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Box px={2}>
          <BoxImageVideo
            src={_get(propsData, 'thumbnail') || defaultImage}
            backgroundColor="white"
            ratio={16 / 9}
            fitMedia
          />
        </Box>
      </Box>
      <Box
        p={1}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Box display="flex" justifyContent="center">
          <TypographyLineClamp line={2} variant="h3" textAlign="center" sx={{ minHeight: '48px' }}>
            {_get(propsData, 'name', '...')}
          </TypographyLineClamp>
        </Box>
        <Box display="flex" justifyContent="center">
          <TypographyLineClamp line={2} variant="body2" textAlign="center" sx={{ minHeight: '40px' }}>
            {_get(propsData, 'description', '')
              ? _get(propsData, 'description', '')
                  .replace(/(<([^>]+)>)/gi, '')
                  .replace(/Overview/g, '')
              : ''}
          </TypographyLineClamp>
        </Box>
      </Box>
    </Box>
  )
}

export default BoxProductHome
