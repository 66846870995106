import React, { FC } from 'react'

import { Box, BoxAdvanced, Typography, TypographyLink, useTheme } from '@opswat/react-ui'

const BannerHeader: FC<unknown> = () => {
  const theme = useTheme()

  return (
    <BoxAdvanced
      sx={{
        backgroundColor: '#010006',
        height: '60px',
        maxHeight: '60px',
        overflow: 'auto'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={1.5} display="flex" alignItems="center">
        <img
          src="https://myopswat-assets.s3.us-east-2.amazonaws.com/images/banner/banner.png"
          width="60px"
          height="60px"
          alt="logo"
        />
      </Box>

      <Box>
        <Typography
          sx={{
            color: theme.palette.common.white
          }}
        >
          The Future is Critical
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.common.white
          }}
        >
          You are invited to an exclusive online screening of a film 20 years in the making. Join us as we celebrate
          OPSWAT's past, examine our present, and look towards the future in an unforgettable cinematic
          experience.&nbsp;---&nbsp;
          <TypographyLink href={`${process.env.REACT_APP_OPSWAT_URL}/20`} variant="subtitle2" color="primary">
            Learn more
          </TypographyLink>
        </Typography>
      </Box>
    </BoxAdvanced>
  )
}

export default BannerHeader
