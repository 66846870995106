import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { CheckboxWithLabel } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  label: any
}

const NPSCheckbox: FC<IProps> = ({ label, ...rest }) => {
  return (
    <CheckboxWithLabel
      label={label}
      checkboxProps={{ ...rest, checked: rest.value }}
      restProps={{ sx: { alignItems: 'flex-start' } }}
    />
  )
}

export default NPSCheckbox
