import React, { FC, useState } from 'react'

import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'

import { Grid, ButtonLoading, DialogAdvanced, Box, Typography, Button, Divider } from '@opswat/react-ui'

import imageBg from 'myopswat-web/src/assets/images/feedback-bg.png'
import FeedbackForm from '../Form/FeedbackForm'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { useFeedbackCreateMutation } from 'myopswat-web/src/api/account'
import { IFeedbackInput } from 'myopswat-web/src/api/account/types'
import { DIALOGS, handleBackCM } from '@myopswat/common'
import { dialogURL } from 'myopswat-web/src/routes'

const DialogFeedback: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [feedbackCreate, { isLoading: isLoadingFeedbackCreate }] = useFeedbackCreateMutation()

  const [step, setStep] = useState<'form' | 'notification'>('form')
  const [feedback, setFeedback] = useState<string>('')
  const [recommendProductScore, setRecommendProductScore] = useState<number | null>(null)

  const handleFeedbackCreateUpdate = async (data: IFeedbackInput) => {
    try {
      await feedbackCreate({
        feedback: _get(data, 'feedback', ''),
        recommendProductScore: _get(data, 'recommendProductScore', 1),
        recommendCompanyScore: _get(data, 'recommendCompanyScore', 0)
      })
        .unwrap()
        .then(data => {
          if (data?.success) {
            setStep('notification')
          } else {
            enqueueSnackbar(translate('submitFeedbackFail'), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('submitFeedbackFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setFeedback('')
    setRecommendProductScore(null)

    dispatch(
      toggleDialogs({
        [DIALOGS.FEEDBACK]: false
      })
    )
    setStep('form')

    handleBackCM(dialogURL)
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.FEEDBACK, false)}
      background={imageBg}
      title="Share Your Feedback"
      content={
        <>
          {step === 'form' ? (
            <FeedbackForm
              feedback={feedback}
              setFeedback={setFeedback}
              recommendProductScore={recommendProductScore}
              setRecommendProductScore={setRecommendProductScore}
            />
          ) : (
            <>
              <Divider
                sx={{
                  width: '75%'
                }}
              />

              <Box mr={4} minHeight="400px" display="flex" alignItems="center">
                <Grid container spacing={4}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Typography textAlign="center" variant="h3" fontSize={32}>
                      Thank you
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography textAlign="center" variant="body2">
                      We really appreciate your candid feedback. Your feedback helps us improve our products and
                      services to better meet your needs.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Button sx={{ minWidth: '100px' }} variant="contained" onClick={() => handleClose()}>
                        OK
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      }
      actions={
        <>
          {step === 'form' ? (
            <Grid container>
              <Grid item xs={12} sm={9}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs="auto">
                    <ButtonLoading
                      propsButton={{
                        variant: 'text',
                        color: 'inherit',
                        onClick: () => handleClose()
                      }}
                      propsLoading={{ color: 'inherit' }}
                      isLoading={false}
                    >
                      Cancel
                    </ButtonLoading>
                  </Grid>
                  <Grid item xs="auto">
                    <ButtonLoading
                      propsButton={{
                        variant: 'contained',
                        color: 'primary',
                        disabled: isLoadingFeedbackCreate || !_isNumber(recommendProductScore),
                        onClick: () => {
                          handleFeedbackCreateUpdate({
                            feedback: feedback,
                            recommendProductScore: recommendProductScore
                          })
                        }
                      }}
                      propsLoading={{ color: 'inherit' }}
                      isLoading={isLoadingFeedbackCreate}
                    >
                      Submit
                    </ButtonLoading>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogFeedback
