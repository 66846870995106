import React, { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced, Grid, ButtonLoading } from '@opswat/react-ui'

import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'

import { ILicenseActivateInput } from 'myopswat-web/src/api/product/types'
import LicenseActivateForm from '../Form/LicenseActivateForm'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLazyActivationServerProductsQuery, useLicenseActivateMutation } from 'myopswat-web/src/api/product'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { DIALOGS, handleBackCM } from '@myopswat/common'
import { dialogURL } from 'myopswat-web/src/routes'

const DialogLicenseActivate: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const dialogType = useTypedSelector(selectDialogs)

  const [getActivationServerProducts, { data: activationServerProductsData }] = useLazyActivationServerProductsQuery()

  const [step, setStep] = useState<'activate' | 'download'>('activate')
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const [licenseActivate, { data: licenseActivateData, isLoading: isLoadingLicenseActivate }] =
    useLicenseActivateMutation()

  const handleLicenseActivate = async (data: ILicenseActivateInput) => {
    try {
      await licenseActivate({
        productId: _get(data, 'productId'),
        deployment: _get(data, 'deployment'),
        key: _get(data, 'key'),
        description: _get(data, 'description'),
        quantity: _get(data, 'quantity')
      })
        .unwrap()
        .then(data => {
          if (data?.success) {
            enqueueSnackbar(translate('activeLicensesSuccess'), {
              variant: 'success'
            })
            setStep('download')
          } else {
            enqueueSnackbar(_get(data, 'errors.0.message') || translate('activeLicensesFail'), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('activeLicensesFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setIsSubmit(false)

    dispatch(
      toggleDialogs({
        [DIALOGS.ACTIVE_LICENSES]: false
      })
    )

    handleBackCM(dialogURL)
  }

  useEffect(() => {
    if (_get(dialogType, DIALOGS.ACTIVE_LICENSES, false)) {
      getActivationServerProducts()
    } else {
      setStep('activate')
    }
  }, [dialogType])

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.ACTIVE_LICENSES, false)}
      title="Activate License"
      content={
        <LicenseActivateForm
          data={activationServerProductsData}
          handleLicenseActivate={handleLicenseActivate}
          step={step}
          licenseActivateData={licenseActivateData}
          isSubmit={isSubmit}
          handleClose={handleClose}
          setIsSubmit={setIsSubmit}
        />
      }
      actions={
        step === 'activate' ? (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: () => handleClose()
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: () => setIsSubmit(true),
                  disabled: isLoadingLicenseActivate,
                  fullWidth: true
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={isLoadingLicenseActivate}
              >
                Activate
              </ButtonLoading>
            </Grid>
          </Grid>
        ) : null
      }
      dialogProps={{
        maxWidth: 'xs'
      }}
    />
  )
}

export default DialogLicenseActivate
