import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { IEvaluationRequestResponse, EvaluationRequestInputType, IEvaluationRequestFilterInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    evaluationRequests: builder.query<IEvaluationRequestResponse, IEvaluationRequestFilterInput>({
      query: input => ({
        document: gql`
          query evaluationRequests($filters: EvaluationRequestFiltersInput, $pageInfo: PageInfoType) {
            evaluationRequests(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                status
                evaluationUrl
              }
            }
          }
        `,
        variables: input
      })
    }),
    evaluationRequestCreate: builder.mutation<any, EvaluationRequestInputType>({
      query: ({ productId }) => ({
        document: gql`
          mutation ($input: EvaluationRequestCreateInput!) {
            evaluationRequestCreate(input: $input) {
              errors
              success
              evaluationRequest {
                id
                status
              }
            }
          }
        `,
        variables: {
          input: {
            productId
          }
        }
      })
    })
  })
})
export const { useEvaluationRequestsQuery, useLazyEvaluationRequestsQuery, useEvaluationRequestCreateMutation } = api
