import { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { CheckDisabledIcon, CheckedDisabledIcon } from '@opswat/react-icon'
import {
  AutocompleteMultiple,
  Box,
  CheckboxWithLabel,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  TypographyDivider
} from '@opswat/react-ui'
import { RefCallBack } from 'react-hook-form'
import { EmailSubscriptionContent } from './components'
import { UNSUBSCRIBED_ALL_CONTENT } from './constants'

interface IProps {
  userSettingsData: any
  solutionsData: any
  profileData: any
  emailSubscriptionContent: any
  showEmailSubscriptions: boolean
  isCustomer: boolean
  productOptions: any[]
}

const PreferencesView: FC<IProps> = ({
  userSettingsData,
  solutionsData,
  profileData,
  emailSubscriptionContent,
  showEmailSubscriptions,
  isCustomer,
  productOptions
}) => {
  const [selectedProductsInfo, setSelectedProductsInfo] = useState<string[]>([])
  const sendAllPurchasedProducts = String(_get(userSettingsData, 'subscribedEmail.sendAllPurchasedProducts'))

  useEffect(() => {
    productOptions &&
      setSelectedProductsInfo(
        productOptions
          .filter((option: any) => _get(userSettingsData, `subscribedEmail.selectedProductIds`, []).includes(option.id))
          .map((option: any) => option.name)
      )
  }, [productOptions])

  return (
    <Grid container spacing={6}>
      {showEmailSubscriptions && (
        <Grid item xs={12} md={10} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyDivider label="Email Subscriptions" propsTypo={{ variant: 'body1' }} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}
            >
              {_map(Object.entries(emailSubscriptionContent), ([key, value]: any) => (
                <Grid
                  item
                  key={key}
                  sx={{
                    gridRow: `span ${key === 'productRelease' ? 2 : 1}`,
                    gridColumn: 'span 1'
                  }}
                >
                  <CheckboxWithLabel
                    label={<EmailSubscriptionContent title={value.title!} description={value.description} />}
                    checkboxProps={{
                      disabled: true,
                      checked: _get(userSettingsData, `subscribedEmail.${key}`),
                      icon: <CheckDisabledIcon />,
                      checkedIcon: <CheckedDisabledIcon />
                    }}
                    restProps={{ sx: { alignItems: 'flex-start' } }}
                  />

                  {key === 'productRelease' && _get(userSettingsData, `subscribedEmail.${key}`) && (
                    <Box sx={{ padding: '8px 24px' }}>
                      <RadioGroup sx={{ px: 0.5 }}>
                        <FormControlLabel
                          disabled
                          value={'true'}
                          control={
                            <Radio
                              sx={{
                                padding: '5px'
                              }}
                              size="small"
                              checked={sendAllPurchasedProducts === 'true'}
                            />
                          }
                          label={
                            <Tooltip
                              arrow
                              placement="top"
                              title={!isCustomer && 'Purchase OPSWAT products to enable this option'}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: '#1B273C',
                                    backgroundColor: '#E9EAEB'
                                  }
                                },
                                arrow: {
                                  sx: {
                                    color: '#E9EAEB'
                                  }
                                }
                              }}
                            >
                              <Typography variant="body2" color={'#737da3'}>
                                Only products for which you hold licenses
                              </Typography>
                            </Tooltip>
                          }
                        />
                        <FormControlLabel
                          value={'false'}
                          sx={{ mb: 1 }}
                          control={
                            <Radio
                              disabled
                              sx={{
                                padding: '5px'
                              }}
                              size="small"
                              checked={sendAllPurchasedProducts === 'false'}
                            />
                          }
                          label={
                            <Typography variant="body2" color={'#737da3'}>
                              Customize options
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {sendAllPurchasedProducts === 'false' && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={selectedProductsInfo.join(', ')}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                color: '#1B273C',
                                backgroundColor: '#E9EAEB'
                              }
                            },
                            arrow: {
                              sx: {
                                color: '#E9EAEB'
                              }
                            }
                          }}
                        >
                          <span>
                            <AutocompleteMultiple
                              disabled
                              options={
                                productOptions?.map((option: any) => ({
                                  label: option.name,
                                  value: option.id
                                })) ?? []
                              }
                              value={
                                productOptions
                                  ?.filter((item: any) =>
                                    _get(userSettingsData, `subscribedEmail.selectedProductIds`).includes(item.id)
                                  )
                                  .map((item: any) => ({ label: item.name, value: item.id })) ?? []
                              }
                              name={''}
                              ref={{} as RefCallBack}
                              onChange={() => {
                                return
                              }}
                              onBlur={() => {
                                return
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  )}

                  {key === 'blog' && (
                    <CheckboxWithLabel
                      label={
                        <EmailSubscriptionContent
                          title={UNSUBSCRIBED_ALL_CONTENT.title}
                          description={UNSUBSCRIBED_ALL_CONTENT.description}
                        />
                      }
                      checkboxProps={{
                        disabled: true,
                        checked: _get(userSettingsData, `subscribedEmail.unsubscribedAll`),
                        icon: <CheckDisabledIcon />,
                        checkedIcon: <CheckedDisabledIcon />
                      }}
                      restProps={{ sx: { alignItems: 'flex-start', mt: 2 } }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} md={10} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyDivider label="Interested topic" propsTypo={{ variant: 'body1' }} />
            <Typography variant="caption" color="textSecondary">
              Interested topic are used to provide relevant content of Product Updates on Home page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {_map(solutionsData, (item, idx) => (
                <Grid item xs={12} sm={6} key={idx.toString()}>
                  <CheckboxWithLabel
                    label={<Typography variant="body2">{_get(item, 'name', '--')}</Typography>}
                    checkboxProps={{
                      disabled: true,
                      checked: _get(item, 'subscribed', false),
                      icon: <CheckDisabledIcon />,
                      checkedIcon: <CheckedDisabledIcon />
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10} lg={8}>
        <Grid container>
          <Grid item xs={12}>
            <TypographyDivider
              label={
                <Box display="flex" alignItems="center">
                  My OPSWAT AI Cyber Adviser&ensp;
                  <Box
                    sx={{
                      backgroundColor: '#3D4A68',
                      color: 'white',
                      borderRadius: '4px',
                      px: 1
                    }}
                  >
                    BETA
                  </Box>
                </Box>
              }
              propsTypo={{ variant: 'body1', component: 'div' }}
            />
            <Typography variant="caption" color="textSecondary">
              My OPSWAT AI Cyber Adviser helps you learn more about OPSWAT Products and industry knowledge. This feature
              is available in Home &rsaquo; Product Details page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Switch
              disabled
              checked={!!_get(profileData, 'enabledAi', false)}
              label={
                _get(profileData, 'enabledAi', false)
                  ? 'Enable My OPSWAT AI Cyber Adviser'
                  : 'Disable My OPSWAT AI Cyber Adviser'
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyDivider label="IDaaS Consent" propsTypo={{ variant: 'body1' }} />
            <Typography variant="caption" color="textSecondary">
              By checking the box below, I activate access to the following applications: MetaDefender IT Access, OPSWAT
              Metadefender Cloud, OPSWAT Academy, OPSWAT Allbound, My OPSWAT, OPSWAT Store, Activation Server,
              Metadefender Gateway. By unchecking the box, I deactivate the access.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CheckboxWithLabel
              label={
                <Typography variant="body2">
                  I agree to the OPSWAT Inc.
                  <a href="https://www.opswat.com/legal" target="_blank">
                    Terms of Service and Privacy Policy
                  </a>
                  , unless my organization has a separate written agreement with OPSWAT Inc., in which case those
                  separate terms shall apply
                </Typography>
              }
              checkboxProps={{
                disabled: true,
                checked: !!_get(profileData, 'isIdaasConsent', false),
                icon: <CheckDisabledIcon />,
                checkedIcon: <CheckedDisabledIcon />
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PreferencesView
