import { FC, useEffect } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { Autocomplete, Box, TextField, Typography } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  options: any[]
  defaultValue?: string
  groupByField?: string
}

const NPSDropdown: FC<IProps> = ({ options, groupByField, defaultValue, ...rest }) => {
  useEffect(() => {
    if (defaultValue) rest.onChange(defaultValue)
  }, [])

  return (
    <Autocomplete
      {...rest}
      disableCloseOnSelect
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={(option: any) => option.label || ''}
      groupBy={groupByField ? (option: any) => groupByField && option[`${groupByField}`] : undefined}
      onChange={(_: any, newValue: any) => {
        rest.onChange(newValue)
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          placeholder="Select a Product"
          sx={{ minHeight: '0px' }}
        />
      )}
      renderGroup={(params: any) => {
        return (
          <li key={crypto.randomUUID()}>
            {params.group && (
              <Typography
                sx={{
                  position: 'sticky',
                  top: '-8px',
                  padding: '8px 16px',
                  backgroundColor: 'white',
                  cursor: 'default',
                  color: '#404a67'
                }}
              >
                {`-- ${params.group} --`}
              </Typography>
            )}
            <Box sx={{ fontWeight: params.group ? 400 : 500, paddingLeft: params.group ? 1.5 : 0 }}>
              {params.children}
            </Box>
          </li>
        )
      }}
    />
  )
}

export default NPSDropdown
