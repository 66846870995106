import React, { FC, useState, useEffect } from 'react'

import _get from 'lodash/get'
import _debounce from 'lodash/debounce'

import {
  DropdownFilter,
  Button,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextFieldSearch
} from '@opswat/react-ui'

import _subHours from 'date-fns/subHours'
import _subDays from 'date-fns/subDays'
import _subYears from 'date-fns/subYears'
import _format from 'date-fns/format'

import { SolidDownIcon, FilterIcon } from '@opswat/react-icon'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IProps {
  children?: React.ReactNode
  setDownloadedFilter: (data: any) => void
}

const BoxDownloadedFilter: FC<IProps> = ({ setDownloadedFilter }) => {
  const { t: translate } = useTranslation()

  const defaultValues = {
    q: '',
    time: ''
  }

  const [fromDatetime, setFromTime] = useState<any>('')
  const [toDatetime, setToTime] = useState<any>('')

  const { handleSubmit, control, watch, reset, setValue } = useForm<any>({
    defaultValues: defaultValues
  })

  const onSuccess = (data: any) => {
    const currentDate = new Date()

    if (_get(data, 'time') === '1') {
      const previousDate = _subHours(currentDate, 24)
      const formattedPreviousDate = _format(previousDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'

      setFromTime(formattedPreviousDate)
      setToTime(currentDate)

      setDownloadedFilter({
        q: _get(data, 'q', ''),
        fromDatetime: formattedPreviousDate,
        toDatetime: _format(currentDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'
      })
    } else if (_get(data, 'time') === '2' || _get(data, 'time') === '30') {
      const previousDate = _subDays(currentDate, _get(data, 'time') === '2' ? 7 : 30)
      const formattedPreviousDate = _format(previousDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'

      setFromTime(formattedPreviousDate)
      setToTime(currentDate)

      setDownloadedFilter({
        q: _get(data, 'q', ''),
        fromDatetime: formattedPreviousDate,
        toDatetime: _format(currentDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'
      })
    } else if (_get(data, 'time') === '365') {
      const previousDate = _subYears(currentDate, 1)
      const formattedPreviousDate = _format(previousDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'

      setFromTime(formattedPreviousDate)
      setToTime(currentDate)

      setDownloadedFilter({
        q: _get(data, 'q', ''),
        fromDatetime: formattedPreviousDate,
        toDatetime: _format(currentDate, `yyyy-MM-dd'T'HH:mm:ss`) + 'Z'
      })
    } else {
      setFromTime('')
      setToTime('')

      setDownloadedFilter({
        q: _get(data, 'q', ''),
        fromDatetime: '',
        toDatetime: ''
      })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    const handleSearch = _debounce(() => {
      setDownloadedFilter({
        q: watch('q'),
        fromDatetime: fromDatetime,
        toDatetime: toDatetime
      })
    }, 300)

    handleSearch()
    return () => {
      handleSearch.cancel()
    }
  }, [watch('q')])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterProductName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  setDownloadedFilter({
                    q: '',
                    fromDatetime: fromDatetime,
                    toDatetime: toDatetime
                  })
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Time</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="time"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="1"
                          control={<Radio size="small" />}
                          label={<Typography variant="subtitle2">Last 24 hours</Typography>}
                        />

                        <FormControlLabel
                          value="2"
                          control={<Radio size="small" />}
                          label={<Typography variant="subtitle2">Last 7 days</Typography>}
                        />
                        <FormControlLabel
                          value="30"
                          control={<Radio size="small" />}
                          label={<Typography variant="subtitle2">Last 30 days</Typography>}
                        />
                        <FormControlLabel
                          value="365"
                          control={<Radio size="small" />}
                          label={<Typography variant="subtitle2">Last 12 months</Typography>}
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
              </Grid>
            </>
          }
          onResetFilter={() => {
            setValue('time', '')
          }}
          onResetChange={() => reset()}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BoxDownloadedFilter
