import React, { FC, useState } from 'react'

import _every from 'lodash/every'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _last from 'lodash/last'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { OptionIcon, SolidDownIcon, SolidRightIcon } from '@opswat/react-icon'
import {
  Box,
  Collapse,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableCell,
  TableHead,
  Tooltip,
  Typography,
  TypographyLineClamp,
  TypographyLink
} from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'
import BoxCollapse from 'myopswat-web/src/components/Box/BoxCollapse'
import { homePageURL } from 'myopswat-web/src/routes'

interface IProps {
  data: any
  setOpenDialog: (data: boolean) => void
  setItemDialog: (data: any) => void
  setTypeDialog: (data: 'note' | 'history') => void
  setItemLicenses: (data: any) => void
  setItem: (data: any) => void
  setIsActivate: (data: boolean) => void
  setItemActivate: (data: any) => void
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
  setIsOpenPVersion: (data: boolean) => void
}

const BoxEvaluationsCell: FC<IProps> = ({
  data,
  setOpenDialog,
  setItemDialog,
  setTypeDialog,
  setItemLicenses,
  setItem,
  setIsActivate,
  setItemActivate,
  setCsvFilter,
  setIsOpenPVersion
}) => {
  const [open, setOpen] = useState(true)

  const handleDownloadCSV = (id: string, data: any) => {
    const skusArray =
      _get(data, 'type') === 'sku' ? [_get(data, 'sku')] : _map(_get(data, 'products'), item => _get(item, 'sku'))
    const params = {
      key: _get(data, 'activation_key'),
      productId: id || '',
      skus: skusArray
    }

    setCsvFilter(params)
  }

  const handleCheckAllExpired = (licenses: any) => {
    if (!licenses) return null

    const isExpired = _every(licenses, item => _get(item, 'days') < 0)

    return isExpired
  }

  const handleFilterAllNotExpired = (licenses: any) => {
    if (!licenses) return null

    const allNotExpired = _filter(licenses, item => _get(item, 'days') >= 0)

    return allNotExpired
  }

  const handleCheckStatus = (data: any) => {
    if (!data) return null

    if (_get(data, 'days') >= 7)
      return (
        <Typography variant="body1" color="success.dark" align="center">
          Active
        </Typography>
      )
    if (_get(data, 'days') < 7 && _get(data, 'days') >= 0)
      return (
        <Typography variant="body1" color="error.dark" align="center">
          Expiring
        </Typography>
      )
    if (_get(data, 'days') < 0)
      return (
        <Typography variant="body1" color="error.dark" align="center">
          Expired
        </Typography>
      )

    return null
  }

  const handleCheckAction = (
    item: any,
    data: any,
    licenses: any,
    type: 'parent' | 'children' | 'parentNotChildren'
  ) => {
    if (!data) return null
    if (_get(data, 'days') < 0)
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ width: '40px' }}>
            <Dropdown
              button={
                <IconButton>
                  <OptionIcon />
                </IconButton>
              }
              content={
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setOpenDialog(true)
                        setItemDialog(licenses)
                        setTypeDialog('history')
                        setItemLicenses(data)
                        setItem(item)
                      }}
                    >
                      See full License History
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setOpenDialog(true)
                        setItemDialog(licenses)
                        setTypeDialog('note')
                        setItemLicenses(data)
                        setItem(item)
                      }}
                    >
                      Edit note
                    </ListItemButton>
                  </ListItem>
                  {type === 'parent' && (
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setIsOpenPVersion(true)
                          setItem(item)
                        }}
                      >
                        Download
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              }
            />
          </Box>
        </Box>
      )

    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ width: '40px' }}>
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsActivate(true)
                      setItemActivate({
                        productId: _get(item, 'id'),
                        product: _get(item, 'name'),
                        productSlug: _get(item, 'slug'),
                        key: _get(data, 'activation_key')
                      })
                    }}
                  >
                    Activate License
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setOpenDialog(true)
                      setItemDialog(licenses)
                      setTypeDialog('history')
                      setItemLicenses(data)
                      setItem(item)
                    }}
                  >
                    See full License History
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setOpenDialog(true)
                      setItemDialog(licenses)
                      setTypeDialog('note')
                      setItemLicenses(data)
                      setItem(item)
                    }}
                  >
                    Edit note
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleDownloadCSV(_get(item, 'id', ''), data)}>
                    Download CSV Report
                  </ListItemButton>
                </ListItem>
                {type === 'parent' && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setIsOpenPVersion(true)
                        setItem(item)
                      }}
                    >
                      Download
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            }
          />
        </Box>
      </Box>
    )
  }

  const handleDownloadAction = (item: any) => {
    if (!item) return null
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ width: '40px' }}>
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsOpenPVersion(true)
                      setItem(item)
                    }}
                  >
                    Download
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        </Box>
      </Box>
    )
  }

  const handleStartDate = (date: string) => {
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)
    return (
      <Typography variant="body2" align="center">
        {renderDate}
      </Typography>
    )
  }

  const handleExpireDate = (date: string, days: number) => {
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)

    if (_includes(renderDate, '2100'))
      return (
        <Typography variant="body2" align="center">
          Perpetual
        </Typography>
      )
    return (
      <Typography variant="body2" align="center">
        {renderDate}
        {days > 0 ? ` - ${days} days` : ''}
      </Typography>
    )
  }

  const handleDeployedEvaluations = (deployed: number | string, purchased: number | string) => {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="body2">{deployed}</Typography>
        <Typography>/</Typography>
        <Typography variant="body2">{purchased}</Typography>
      </Box>
    )
  }

  const handleRenderChildren = (
    licensesCount: any,
    item: any,
    licenses: any,
    isExpired: any,
    allNotExpired: any,
    type: 'parent' | 'children' | 'parentNotChildren'
  ) => {
    if (licensesCount === 1)
      return (
        <Box>
          <Table component="div">
            <TableHead component="div">
              <TableCell component="div" sx={{ p: 0.25, width: 400 }}>
                {type === 'parent' ? (
                  <TypographyLink
                    color="primary"
                    sx={{
                      pl: 1.75,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    variant="body2"
                    href={`${homePageURL}/${_get(item, 'slug')}`}
                  >
                    {_get(item, 'name')}
                  </TypographyLink>
                ) : (
                  <Typography variant="body2" sx={{ pl: 1.75 }}>
                    {_get(item, 'name')}
                  </Typography>
                )}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                <Typography variant="body2">{_get(licenses, '0.activation_key')}</Typography>
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                <Typography component="div" variant="body2">
                  {handleCheckStatus(_get(licenses, '0'))}
                </Typography>
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                {handleStartDate(_get(licenses, '0.activated_date'))}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                {handleExpireDate(_get(licenses, '0.expired_date'), _get(licenses, '0.days'))}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                {handleDeployedEvaluations(
                  _get(licenses, '0.deployed_quantity'),
                  _get(licenses, '0.purchased_quantity')
                )}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                <Tooltip title={_get(_last(_get(licenses, '0.notes')), 'content') || ''} arrow>
                  <Box>
                    <TypographyLineClamp variant="body2">
                      {_get(_last(_get(licenses, '0.notes')), 'content') || ''}
                    </TypographyLineClamp>
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                {handleCheckAction(item, _get(licenses, '0'), licenses, type)}
              </TableCell>
            </TableHead>
          </Table>
        </Box>
      )

    if (licensesCount > 1 && isExpired)
      return (
        <Box>
          <Table component="div">
            <TableHead component="div">
              <TableCell component="div" sx={{ p: 0.25, width: 400 }}>
                {type === 'parent' ? (
                  <TypographyLink
                    color="primary"
                    sx={{
                      pl: 1.75,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    variant="body2"
                    href={`${homePageURL}/${_get(item, 'slug')}`}
                  >
                    {_get(item, 'name')}
                  </TypographyLink>
                ) : (
                  <Typography variant="body2" sx={{ pl: 1.75 }}>
                    {_get(item, 'name')}
                  </Typography>
                )}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                <Typography variant="body2">{_get(licenses, `${licensesCount - 1}.activation_key`)}</Typography>
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                <Typography component="div" variant="body2">
                  {handleCheckStatus(_get(licenses, `${licensesCount - 1}`))}
                </Typography>
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                {handleStartDate(_get(licenses, `${licensesCount - 1}.activated_date`))}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                {handleExpireDate(
                  _get(licenses, `${licensesCount - 1}.expired_date`),
                  _get(licenses, `${licensesCount - 1}.days`)
                )}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                {handleDeployedEvaluations(
                  _get(licenses, `${licensesCount - 1}.deployed_quantity`),
                  _get(licenses, `${licensesCount - 1}.purchased_quantity`)
                )}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                <Tooltip title={_get(_last(_get(licenses, `${licensesCount - 1}.notes`)), 'content') || ''} arrow>
                  <Box>
                    <TypographyLineClamp variant="body2">
                      {_get(_last(_get(licenses, `${licensesCount - 1}.notes`)), 'content') || ''}
                    </TypographyLineClamp>
                  </Box>
                </Tooltip>
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                {handleCheckAction(item, _get(licenses, `${licensesCount - 1}`), licenses, type)}
              </TableCell>
            </TableHead>
          </Table>
        </Box>
      )

    if (licensesCount > 1 && !isExpired && _size(allNotExpired) === 1)
      return (
        <Box>
          <Table component="div">
            <TableHead component="div">
              <TableCell component="div" sx={{ p: 0.25, width: 400 }}>
                {type === 'parent' ? (
                  <TypographyLink
                    color="primary"
                    sx={{
                      pl: 1.75,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    variant="body2"
                    href={`${homePageURL}/${_get(item, 'slug')}`}
                  >
                    {_get(item, 'name')}
                  </TypographyLink>
                ) : (
                  <Typography variant="body2" sx={{ pl: 1.75 }}>
                    {_get(item, 'name')}
                  </Typography>
                )}
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                <Typography variant="body2">{_get(allNotExpired, `0.activation_key`)}</Typography>
              </TableCell>
              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                <Typography component="div" variant="body2">
                  {handleCheckStatus(_get(allNotExpired, `0`))}
                </Typography>
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                {handleStartDate(_get(allNotExpired, `0.activated_date`))}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                {handleExpireDate(_get(allNotExpired, `0.expired_date`), _get(allNotExpired, `0.days`))}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                {handleDeployedEvaluations(
                  _get(allNotExpired, `0.deployed_quantity`),
                  _get(allNotExpired, `0.purchased_quantity`)
                )}
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                <Tooltip title={_get(_last(_get(allNotExpired, `0.notes`)), 'content') || ''} arrow>
                  <Box>
                    <TypographyLineClamp variant="body2">
                      {_get(_last(_get(allNotExpired, `0.notes`)), 'content') || ''}
                    </TypographyLineClamp>
                  </Box>
                </Tooltip>
              </TableCell>

              <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                {handleCheckAction(item, _get(allNotExpired, `0`), licenses, type)}
              </TableCell>
            </TableHead>
          </Table>
        </Box>
      )

    return (
      <React.Fragment>
        {type === 'parent' && (
          <>
            <Table component="div">
              <TableHead component="div">
                <TableCell component="div" sx={{ p: 0.25, width: 400 }}>
                  <TypographyLink
                    color="primary"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    variant="body2"
                    href={`${homePageURL}/${_get(item, 'slug')}`}
                  >
                    {_get(item, 'name')}
                  </TypographyLink>
                </TableCell>
              </TableHead>
            </Table>

            {_map(allNotExpired, (itemChild, idx) => (
              <Table component="div" key={idx.toString()}>
                <TableHead component="div">
                  <TableCell component="div" sx={{ p: 0.25, width: 400 }}></TableCell>
                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                    <Typography variant="body2">{_get(itemChild, `activation_key`)}</Typography>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                    <Typography component="div" variant="body2">
                      {handleCheckStatus(itemChild)}
                    </Typography>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                    {handleStartDate(_get(itemChild, `activated_date`))}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                    {handleExpireDate(_get(itemChild, `expired_date`), _get(itemChild, `days`))}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                    {handleDeployedEvaluations(
                      _get(itemChild, `deployed_quantity`),
                      _get(itemChild, `purchased_quantity`)
                    )}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                    <Tooltip title={_get(_last(_get(itemChild, `notes`)), 'content') || ''} arrow>
                      <Box>
                        <TypographyLineClamp variant="body2">
                          {_get(_last(_get(itemChild, `notes`)), 'content') || ''}
                        </TypographyLineClamp>
                      </Box>
                    </Tooltip>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                    {handleCheckAction(item, itemChild, licenses, type)}
                  </TableCell>
                </TableHead>
              </Table>
            ))}
          </>
        )}

        {type === 'children' && (
          <>
            <BoxCollapse
              title={<Typography variant="body2">{_get(item, 'name')}</Typography>}
              content={
                <>
                  {_map(allNotExpired, (itemChild, idx) => (
                    <Table component="div" key={idx.toString()}>
                      <TableHead component="div">
                        <TableCell component="div" sx={{ p: 0.25, width: 400 }}></TableCell>
                        <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                          <Typography variant="body2">{_get(itemChild, `activation_key`)}</Typography>
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                          <Typography component="div" variant="body2">
                            {handleCheckStatus(itemChild)}
                          </Typography>
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                          {handleStartDate(_get(itemChild, `activated_date`))}
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                          {handleExpireDate(_get(itemChild, `expired_date`), _get(itemChild, `days`))}
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                          {handleDeployedEvaluations(
                            _get(itemChild, `deployed_quantity`),
                            _get(itemChild, `purchased_quantity`)
                          )}
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                          <Tooltip title={_get(_last(_get(itemChild, `notes`)), 'content') || ''} arrow>
                            <Box>
                              <TypographyLineClamp variant="body2">
                                {_get(_last(_get(itemChild, `notes`)), 'content') || ''}
                              </TypographyLineClamp>
                            </Box>
                          </Tooltip>
                        </TableCell>

                        <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                          {handleCheckAction(item, itemChild, licenses, type)}
                        </TableCell>
                      </TableHead>
                    </Table>
                  ))}
                </>
              }
            />
          </>
        )}

        {type === 'parentNotChildren' && (
          <>
            {_map(allNotExpired, (itemChild, idx) => (
              <Table component="div" key={idx.toString()}>
                <TableHead component="div">
                  <TableCell component="div" sx={{ p: 0.25, width: 400 }}></TableCell>
                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 200, xl: 350 } }}>
                    <Typography variant="body2">{_get(itemChild, `activation_key`)}</Typography>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                    <Typography component="div" variant="body2">
                      {handleCheckStatus(itemChild)}
                    </Typography>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 120 } }}>
                    {handleStartDate(_get(itemChild, `activated_date`))}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 200 } }}>
                    {handleExpireDate(_get(itemChild, `expired_date`), _get(itemChild, `days`))}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: 100 }}>
                    {handleDeployedEvaluations(
                      _get(itemChild, `deployed_quantity`),
                      _get(itemChild, `purchased_quantity`)
                    )}
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, width: { xs: 100, xl: 150 } }}>
                    <Tooltip title={_get(_last(_get(itemChild, `notes`)), 'content') || ''} arrow>
                      <Box>
                        <TypographyLineClamp variant="body2">
                          {_get(_last(_get(itemChild, `notes`)), 'content') || ''}
                        </TypographyLineClamp>
                      </Box>
                    </Tooltip>
                  </TableCell>

                  <TableCell component="div" sx={{ p: 0.25, minWidth: 40 }}>
                    {handleCheckAction(item, itemChild, licenses, type)}
                  </TableCell>
                </TableHead>
              </Table>
            ))}
          </>
        )}
      </React.Fragment>
    )
  }

  const licenses = _get(data, 'licenses')
  const licensesCount = _size(licenses)
  const isExpired = handleCheckAllExpired(licenses)
  const allNotExpired = handleFilterAllNotExpired(licenses)

  if (!_isEmpty(_get(data, 'children')))
    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mr={0.25}>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => setOpen(!open)}
            >
              {open ? <SolidDownIcon size={16} /> : <SolidRightIcon size={16} />}
            </Box>

            <TypographyLink
              color="primary"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              variant="body2"
              href={`${homePageURL}/${_get(data, 'slug')}`}
            >
              {_get(data, 'name')}
            </TypographyLink>
          </Box>

          {handleDownloadAction(data)}
        </Box>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <BoxEvaluationsCell
            data={_get(data, 'children')}
            setItemDialog={setItemDialog}
            setTypeDialog={setTypeDialog}
            setOpenDialog={setOpenDialog}
            setItem={setItem}
            setItemLicenses={setItemLicenses}
            setIsActivate={setIsActivate}
            setItemActivate={setItemActivate}
            setCsvFilter={setCsvFilter}
            setIsOpenPVersion={setIsOpenPVersion}
          />
        </Collapse>
      </>
    )

  if (_isEmpty(_get(data, 'children')) && _isArray(data))
    return (
      <>
        {_map(data, item => {
          const licenses = _get(item, 'licenses')
          const licensesCount = _size(licenses)
          const isExpired = handleCheckAllExpired(licenses)
          const allNotExpired = handleFilterAllNotExpired(licenses)

          return (
            <React.Fragment key={_get(item, 'id', '').toString()}>
              {handleRenderChildren(licensesCount, item, licenses, isExpired, allNotExpired, 'children')}
            </React.Fragment>
          )
        })}
      </>
    )

  if (
    _isEmpty(_get(data, 'children')) &&
    !_isArray(data) &&
    (licensesCount === 1 ||
      (licensesCount > 1 && isExpired) ||
      (licensesCount > 1 && !isExpired && _size(allNotExpired) === 1))
  )
    return handleRenderChildren(licensesCount, data, licenses, isExpired, allNotExpired, 'parent')

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mr={0.25}>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <SolidDownIcon size={16} /> : <SolidRightIcon size={16} />}
          </Box>

          <TypographyLink
            color="primary"
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            variant="body2"
            href={`${homePageURL}/${_get(data, 'slug')}`}
          >
            {_get(data, 'name')}
          </TypographyLink>
        </Box>

        {handleDownloadAction(data)}
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {handleRenderChildren(licensesCount, data, licenses, isExpired, allNotExpired, 'parentNotChildren')}
      </Collapse>
    </>
  )
}

export default BoxEvaluationsCell
