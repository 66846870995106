import { FC, useState } from 'react'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

import { copyText } from '@opswat/react-core'
import { CopySimpleIcon } from '@opswat/react-icon'
import { Avatar, Box, Button, Dropdown, Grid, Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

interface InformationProps {
  value: string
}

interface Props {
  data: any
}

const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: '#BED5FE',
      width: '58px',
      height: '58px'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}

const InformationCopy: FC<InformationProps> = ({ value }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleReturnCopy = _debounce(() => {
    setIsCopied(false)
  }, 1000)

  const handleCopy = () => {
    copyText(value)
    setIsCopied(true)
    handleReturnCopy()
  }

  return (
    <Grid
      container
      gap={1}
      alignItems="center"
      sx={{
        '&:hover': {
          backgroundColor: '#EFF4FF',
          cursor: 'pointer'
        },
        px: 1,
        py: 0.5
      }}
    >
      <Grid item xs>
        <Tooltip title={value}>
          <Box>
            <TypographyLineClamp line={1} variant="body2">
              {value}
            </TypographyLineClamp>
          </Box>
        </Tooltip>
      </Grid>
      <Tooltip title={isCopied ? 'Copied' : 'Copy'} placement="top" arrow onClick={handleCopy}>
        <Grid item xs="auto">
          <Box sx={{ display: 'flex' }}>
            <CopySimpleIcon />
          </Box>
        </Grid>
      </Tooltip>
    </Grid>
  )
}

const InformationCard: FC<Props> = ({ data }) => {
  return (
    <Dropdown
      hasAction
      position="bottom"
      button={
        <Button fullWidth sx={{ paddingLeft: '0' }}>
          <Grid
            container
            spacing={'10px'}
            sx={{
              display: 'inline-flex',
              flexWrap: 'nowrap',
              flexDirection: {
                xs: 'column',
                lg: 'row'
              },
              alignItems: {
                xs: 'flex-start',
                lg: 'center'
              }
            }}
          >
            <Grid item xs="auto">
              <Avatar {...stringAvatar(_get(data, 'name') || '--')} />
            </Grid>
            <Grid item xs>
              <Typography textAlign="left">{_get(data, 'name') || '--'}</Typography>
              <Typography color="#8E939B" fontSize="12px" fontWeight="400" textAlign="left" lineHeight="20px">
                {_get(data, 'title') || '--'}
              </Typography>
              <TypographyLineClamp
                line={1}
                color="#616875"
                fontSize="12px"
                fontWeight="400"
                textAlign="left"
                lineHeight="20px"
                textTransform="lowercase"
                tooltipValue={_get(data, 'email')}
              >
                {_get(data, 'email') || '--'}
              </TypographyLineClamp>
            </Grid>
          </Grid>
        </Button>
      }
      content={
        <Grid container spacing={2} justifyContent="center" sx={{ p: 2 }}>
          <Grid item xs="auto">
            <Avatar
              {...stringAvatar(_get(data, 'name') || '--')}
              style={{
                width: '80px',
                height: '80px'
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography textAlign="center">{_get(data, 'name') || '--'}</Typography>
            <Typography variant="body2" color="textSecondary" textAlign="center">
              {_get(data, 'title') || '--'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InformationCopy value={_get(data, 'email') || '--'} />
          </Grid>
        </Grid>
      }
      propsPaper={{ sx: { width: '300px' } }}
    />
  )
}

export default InformationCard
