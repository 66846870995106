import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IChatCompletionInputTypeInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    chatCompletion: builder.mutation<any, IChatCompletionInputTypeInput>({
      query: ({ option, content }) => ({
        document: gql`
          mutation chatCompletion($input: ChatCompletionInputType!) {
            chatCompletion(input: $input) {
              success
              errors
              result {
                content
              }
            }
          }
        `,
        variables: {
          input: {
            content: content,
            option: option
          }
        }
      })
    })
  })
})

export const { useChatCompletionMutation } = api
