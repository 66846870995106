import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { TextField } from '@opswat/react-ui'

import { NPS_MAX_LENGTH } from 'myopswat-web/src/components/Dialog/DialogNPSSurvey/interface'

interface IProps extends ControllerRenderProps {
  placeholder?: any
}

const NPSText: FC<IProps> = ({ placeholder, ...rest }: IProps) => {
  return (
    <TextField
      {...rest}
      multiline
      rows={4}
      placeholder={placeholder ?? "To protect your privacy, please don't include any sensitive information."}
      sx={{ '& .MuiOutlinedInput-root': { padding: 1 } }}
      error={rest.value && rest.value.length > NPS_MAX_LENGTH}
      helperText={
        rest.value && rest.value.length > NPS_MAX_LENGTH && `Please enter maximum ${NPS_MAX_LENGTH} characters.`
      }
    />
  )
}

export default NPSText
