import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MessageDialog } from '@opswat/react-ui'

interface IProps {
  message: string
}

const MessageModal = NiceModal.create(({ message }: IProps) => {
  const modal = useModal()
  return (
    <MessageDialog
      maxWidth={'xs'}
      minHeight={300}
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      content={message}
    />
  )
})

export default MessageModal
