import React, { FC, useCallback, useMemo, useState } from 'react'

import _concat from 'lodash/concat'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@opswat/react-icon'
import {
  Box,
  DialogAdvanced,
  Divider,
  Grid,
  Skeleton,
  SwiperSlider,
  TabAdvanced,
  Typography,
  TypographyDivider,
  TypographyLineClamp,
  TypographyLink,
  useTheme
} from '@opswat/react-ui'

import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import { selectProductDetail } from 'myopswat-web/src/containers/MyDetailContainer/myDetailContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'
import DownloadVersionForm from '../Form/DownloadVersionForm'
import DownloadVersionSkeleton from '../Skeleton/DownloadVersionSkeleton'
import {
  PRODUCT_IDS,
  ENDPOINT_SECURITY_SDK_ORDER_ARRAY,
  OPSWAT_NETWALL_ORDER_ARRAY
} from 'myopswat-web/src/constants/product-ids'
import { handleReorderArray } from '@myopswat/common'
import { useLazyCheckDisplayNPSQuery } from 'myopswat-web/src/api/survey'
import { useAppDispatch } from 'myopswat-web/src/store'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'

interface IProps {
  isOpenPVersion: boolean
  setIsOpenPVersion: (data: boolean) => void
  productDetailData: any
  handleDownloadedProductAdd: (data: IDownloadedProductInput) => void
  icapIntegrationsData: any
  isFetchingProductDetail?: boolean
}

interface IBoxModuleUtilityProps {
  propsData: any
  propsComponent: any
}

const BoxModuleUtility: FC<IBoxModuleUtilityProps> = ({ propsData, propsComponent }) => {
  const theme = useTheme()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  const setUtilitiesData = _get(propsComponent, 'setUtilitiesData', (data: any) => {})
  const focusName = _get(propsComponent, 'focusName')
  const focusItem = _get(propsComponent, 'focusItem')

  return (
    <Box
      sx={{
        boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)',
        height: '100%',
        transition: 'transform 0.75s',
        backgroundColor: focusName === _get(propsData, 'name') ? theme.palette.info.light : 'none',
        '&:hover': {
          backgroundColor: theme.palette.info.light
        }
      }}
      onClick={() => {
        if (focusName === _get(propsData, 'name')) {
          setUtilitiesData(focusItem)
        } else {
          setUtilitiesData(propsData)
        }
      }}
    >
      <Box
        p={1}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '40px' }}>
          <TypographyLineClamp line={2} variant="body1" textAlign="center">
            {_get(propsData, 'name', '--')}
          </TypographyLineClamp>
        </Box>
      </Box>
    </Box>
  )
}

const DialogDownloadVersion: FC<IProps> = ({
  isOpenPVersion,
  setIsOpenPVersion,
  productDetailData,
  handleDownloadedProductAdd,
  icapIntegrationsData,
  isFetchingProductDetail
}) => {
  const dispatch = useAppDispatch()

  const [checkDisplayNPS] = useLazyCheckDisplayNPSQuery()
  
  const [utilitiesData, setUtilitiesData] = useState<any>()
  const [hasDownloaded, setHasDownloaded] = useState<boolean>(false)

  const productDetail = useTypedSelector(selectProductDetail)

  const parentId = useMemo(() => {
    // if user click utilities from detail page then click download, productDetail will be parent
    if (_size(productDetail?.children) + _size(productDetail?.utilities) !== 0) return productDetail.id
    // if user click utilities from download dialog, productDetail won't have value, and productDetailData will be parent
    if (utilitiesData) return productDetailData.id
  }, [productDetailData, utilitiesData, productDetail])

  const renderData = utilitiesData || productDetailData

  const renderUtilitiesData = useMemo(() => {
    return _filter(
      _get(productDetailData, 'utilities', []),
      (item: any) => item?.downloadable === true && !_isEmpty(item?.releases) === true
    )
  }, [productDetailData])

  const utilitySortList = useMemo(() => {
    if (productDetailData?.id === PRODUCT_IDS.ENDPOINT_SECURITY_SDK)
      return handleReorderArray(renderUtilitiesData, ENDPOINT_SECURITY_SDK_ORDER_ARRAY)

    if (productDetailData?.id === PRODUCT_IDS.OPSWAT_NETWALL)
      return handleReorderArray(renderUtilitiesData, OPSWAT_NETWALL_ORDER_ARRAY)

    return renderUtilitiesData
  }, [renderUtilitiesData])

  const isShowChildren = !_isEmpty(_get(renderData, 'children', []))

  const convertDefaultData = isShowChildren
    ? _concat(
        [
          {
            id: _get(renderData, 'id', '--'),
            name: _get(renderData, 'name', '--'),
            slug: _get(renderData, 'slug', '--'),
            releaseMetadata: _get(renderData, 'releaseMetadata', []),
            releases: _get(renderData, 'releases', [])
          }
        ],
        _get(renderData, 'children', [])
      )
    : _get(renderData, 'children', [])

  const defaultData = _filter(
    convertDefaultData,
    item => item?.downloadable === true && !_isEmpty(item?.releases) === true
  )

  const tabDefaultArray = _map(defaultData, (item: any) => ({
    label: _get(item, 'name'),
    content: (
      <DownloadVersionForm
        data={item}
        productId={_get(item, 'id')}
        fromParentId={parentId}
        handleDownloadedProductAdd={handleDownloadedProductAdd}
        icapIntegrationsData={icapIntegrationsData}
        setHasDownloaded={setHasDownloaded}
      />
    )
  }))

  const breadcrumbsHomeArray = [
    {
      label: _get(productDetailData, 'name', '--'),
      propsTypos: {
        onClick: () => setUtilitiesData(productDetailData)
      }
    },
    ...((utilitiesData &&
      _get(productDetailData, 'name') !== _get(utilitiesData, 'name') && [
        {
          label: _get(utilitiesData, 'name', '--')
        }
      ]) ||
      [])
  ]

  
  const handleCheckDisplayNPS = useCallback(async () => {
    await checkDisplayNPS({}).then((response: any) => {
      const showDialog = response?.data ?? false
      if (showDialog) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.NPS_SURVEY]: true,
            [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'response-action',
            [DIALOGS_WEB.NPS_NOTIFICATION]: false
          })
        )
      }
    })
  }, [])

  return (
    <DialogAdvanced
      open={isOpenPVersion}
      title={
        <>
          {isFetchingProductDetail ? (
            <Skeleton animation="wave" variant="text" width="250px" height="30px" />
          ) : (
            <TypographyDivider breadcrumbsArray={breadcrumbsHomeArray} isDivider={false} />
          )}
        </>
      }
      iconClose={<CloseIcon />}
      onClose={() => {
        setIsOpenPVersion(false)
        setUtilitiesData(null)
        hasDownloaded && handleCheckDisplayNPS()
        setHasDownloaded(false)
      }}
      content={
        <>
          {isFetchingProductDetail ? (
            <DownloadVersionSkeleton />
          ) : (
            <>
              {_size(defaultData) > 1 ? (
                <TabAdvanced tabs={tabDefaultArray} />
              ) : (
                <>
                  {!_isEmpty(renderData?.releases) ? (
                    <DownloadVersionForm
                      data={renderData}
                      fromParentId={parentId}
                      productId={_get(renderData, 'id')}
                      handleDownloadedProductAdd={handleDownloadedProductAdd}
                      icapIntegrationsData={icapIntegrationsData}
                      setHasDownloaded={setHasDownloaded}
                    />
                  ) : (
                    <>
                      {productDetailData?.id === PRODUCT_IDS.MD_DRIVE ? (
                        <Box sx={{ height: '363px' }}>
                          <Typography variant="body2">
                            Download the {renderUtilitiesData[0]?.name || ''} below and from there you can install and
                            update your {productDetailData.name}.
                          </Typography>
                        </Box>
                      ) : (
                        <TabAdvanced tabs={tabDefaultArray} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      }
      actions={
        <>
          {isFetchingProductDetail ? (
            <Skeleton animation="wave" variant="text" width="100%" height="30px" />
          ) : (
            <Grid container spacing={2}>
              {_size(utilitySortList) === 0 ? null : (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              {_size(utilitySortList) > 0 && (
                <Grid item xs={12}>
                  <Box>
                    <SwiperSlider
                      title="Modules & Utilities"
                      contents={utilitySortList}
                      ContentComponent={BoxModuleUtility}
                      propsStyleSwiper={{
                        slidesPerView: 3,
                        spaceBetween: 5
                      }}
                      propsSwiperSlide={{
                        style: { boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)', scale: '0.95' }
                      }}
                      propsComponent={{
                        setUtilitiesData,
                        focusName: _get(utilitiesData, 'name'),
                        focusItem: productDetailData
                      }}
                      propsLeftButton={{ leftIcon: <ChevronLeftIcon /> }}
                      propsRightButton={{ rightIcon: <ChevronRightIcon /> }}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Contact&nbsp;
                  <TypographyLink
                    variant="subtitle2"
                    href={`${process.env.REACT_APP_OPSWAT_URL}/contact`}
                    target="_blank"
                  >
                    OPSWAT Sales
                  </TypographyLink>
                  &nbsp;to purchase licenses.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      }
      dialogProps={{
        maxWidth: 'md'
      }}
      dialogContentProps={{
        sx: {
          maxHeight: '85vh',
          minHeight: '45vh'
        }
      }}
    />
  )
}

export default DialogDownloadVersion
