import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IReferralSourceSubmitInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    getLeadSources: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            leadSources {
              id
              name
              requiredDetail
            }
          }
        `
      })
    }),
    submitReferralSource: builder.mutation<any, IReferralSourceSubmitInput>({
      query: input => ({
        document: gql`
          mutation ($input: ReferralSourceSubmitInput!) {
            referralSourceSubmit(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    })
  })
})

export const { useLazyGetLeadSourcesQuery, useSubmitReferralSourceMutation } = api
