import { FC } from 'react'
import { Helmet } from 'react-helmet'

import _get from 'lodash/get'

import { PageTitle } from 'myopswat-web/src/routes/handleRoutes'

const HelmetCore: FC<unknown> = () => {
  return (
    <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
      <title itemProp="name" lang="en">
        {_get(PageTitle, `${window.location.pathname}`, 'Home')}
      </title>

      {/* base element */}
      <base target="_blank" href={window.location.href} />

      {/* multiple meta elements */}
      <meta name="description" content="Description" />
      <meta property="og:type" content="article" />

      {/* multiple link elements */}
      <link rel="canonical" href={window.location.href} />

      {/* inline script elements */}
      <script type="application/ld+json">{`
      {
          "@context": "http://schema.org"
      }
  `}</script>
      {/* noscript elements */}
      <noscript>{`
      <link rel="stylesheet" type="text/css" href="foo.css" />
  `}</noscript>
    </Helmet>
  )
}

export default HelmetCore
