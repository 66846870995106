import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import _map from 'lodash/map'

import {
  AnalysisIcon,
  CertificateIcon,
  FolderOpenIcon,
  InformationIcon,
  LifeSaverIcon,
  PackageIcon,
  UserGroupIcon,
  FileTextIcon
} from '@opswat/react-icon'
import { Grid, Typography } from '@opswat/react-ui'

import { reportFalseDetectionSubmitPageURL } from 'myopswat-web/src/routes'

import BoxResource from './BoxResource'

const ResourcesSection = () => {
  const navigate = useNavigate()
  const resourcesInfo = useMemo(() => {
    return [
      {
        icon: <LifeSaverIcon size={16} />,
        label: 'Request Support (original page)',
        link: process.env.REACT_APP_OPSWAT_GO_PORTAL_URL
      },
      {
        icon: <AnalysisIcon size={16} />,
        label: 'OPSWAT System Status',
        link: process.env.REACT_APP_OPSWAT_TRUST_URL
      },
      {
        icon: <FileTextIcon size={16} />,
        label: 'Report False Detection',
        link: `${window.location.origin}${reportFalseDetectionSubmitPageURL}`,
        action: (e: React.MouseEvent<Element, MouseEvent>) => {
          navigate(reportFalseDetectionSubmitPageURL)
          e.preventDefault()
        }
      },
      {
        icon: <CertificateIcon size={16} />,
        label: 'OPSWAT Academy',
        link: process.env.REACT_APP_ACADEMY_URL
      },
      {
        icon: <UserGroupIcon size={16} />,
        label: 'Community Forum',
        link: `${process.env.REACT_APP_OPSWAT_GO_URL}/s`
      },
      {
        icon: <FolderOpenIcon size={16} />,
        label: 'Product Documentation',
        link: process.env.REACT_APP_OPSWAT_DOCS_URL
      },
      {
        icon: <PackageIcon size={16} />,
        label: 'Support Plans',
        link: `${process.env.REACT_APP_OPSWAT_URL}/support#software-support-plans`
      },
      {
        icon: <InformationIcon size={16} />,
        label: 'Help with this page',
        link: `${process.env.REACT_APP_OPSWAT_DOCS_URL}/my/support-services`
      }
    ]
  }, [])

  return (
    <Grid item xs={12} sm={6} md={3.5}>
      <Grid container spacing={'5px'}>
        <Grid item xs={12}>
          <Typography sx={{ py: '8px' }}>Additional Resources</Typography>
        </Grid>

        {_map(resourcesInfo, (item, idx) => (
          <Grid item xs={12} key={idx.toString()} onClick={item.action}>
            <BoxResource {...item} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default ResourcesSection
