import React, { FC, useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'

import { NotificationIcon } from '@opswat/react-icon'
import { Badge, Box, Divider, Dropdown, IconButton, TabAdvanced, Typography, useMediaQuery } from '@opswat/react-ui'

import { useLazyCheckAllNotificationsQuery } from 'myopswat-web/src/api/notification'

import NotificationDirect from './NotificationDirect'
import NotificationProduct from './NotificationProduct'

const CHECK_NOTI_INTERVAL = 30000

enum NotificationType {
  messages = 'messages',
  releases = 'releases'
}

const NotificationRoot: FC<unknown> = () => {
  const isMobile = useMediaQuery('(max-width:1000px)')

  const [openNotificationPopup, setOpenNotificationPopup] = useState<boolean>(false)

  const [checkAllNewNotification, { data: checkAllNewNotificationData }] = useLazyCheckAllNotificationsQuery()

  const hasNewMessageNotifications = useMemo(() => {
    return _get(checkAllNewNotificationData, NotificationType.messages)
  }, [checkAllNewNotificationData])

  const hasNewReleaseNotifications = useMemo(() => {
    return _get(checkAllNewNotificationData, NotificationType.releases)
  }, [checkAllNewNotificationData])

  const hasNewGlobalNotifications = useMemo(() => {
    return hasNewMessageNotifications || hasNewReleaseNotifications
  }, [hasNewMessageNotifications, hasNewReleaseNotifications])

  const AccessibleBadges = () => {
    return (
      <IconButton size="small">
        <Badge variant="dot" color="error" invisible={!hasNewGlobalNotifications}>
          <NotificationIcon />
        </Badge>
      </IconButton>
    )
  }

  const tabArray = [
    {
      label: hasNewReleaseNotifications ? (
        <Badge variant="dot" color="error" invisible={!hasNewMessageNotifications}>
          <Typography sx={{ paddingRight: 'calc(1ch / 2)' }}>Direct</Typography>
        </Badge>
      ) : (
        <Typography>Direct</Typography>
      ),
      content: <NotificationDirect checkNewNotification={checkAllNewNotification} />
    },

    {
      label: hasNewReleaseNotifications ? (
        <Badge variant="dot" color="error" invisible={!hasNewReleaseNotifications}>
          <Typography sx={{ paddingRight: 'calc(1ch / 2)' }}>Product Releases</Typography>
        </Badge>
      ) : (
        <Typography>Product Releases</Typography>
      ),
      content: <NotificationProduct />
    }
  ]

  useEffect(() => {
    const checkNewNotificationInterval = setInterval(checkAllNewNotification, CHECK_NOTI_INTERVAL)
    return () => clearInterval(checkNewNotificationInterval)
  }, [])

  useEffect(() => {
    checkAllNewNotification()
  }, [openNotificationPopup])

  return (
    <Dropdown
      hasAction
      button={<AccessibleBadges />}
      content={
        <Box>
          <Typography sx={{ py: 1, px: 2 }}>Notifications</Typography>
          <Divider />
          <TabAdvanced tabs={tabArray} />
        </Box>
      }
      propsPaper={{
        sx: {
          width: isMobile ? '250px' : '650px'
        }
      }}
      position={isMobile ? 'bottom' : 'bottom-end'}
      onOpenClose={() => {
        setOpenNotificationPopup(!openNotificationPopup)
      }}
    />
  )
}

export default React.memo(NotificationRoot)
