import React, { FC, useEffect, useState } from 'react'

import _debounce from 'lodash/debounce'

import { Grid, TextFieldSearch } from '@opswat/react-ui'
import { useTranslation } from 'react-i18next'

interface IProps {
  children?: React.ReactNode
  setRolesFilter: (data: any) => void
}

const BoxRolesFilter: FC<IProps> = ({ setRolesFilter }) => {
  const { t: translate } = useTranslation()
  const [keyword, setKeyword] = useState<string>('')

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        setRolesFilter({
          q: keyword
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterRoleName') || ''}
          onChange={(e: any) => {
            if (e?.target) {
              setKeyword(e.target.value)
            }
          }}
          onClearText={() => setKeyword('')}
        />
      </Grid>
    </Grid>
  )
}

export default BoxRolesFilter
