import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  OrganizationAcceptInvitationInput,
  OrganizationActiveUsersInput,
  OrganizationChangeUsersRolesInput,
  OrganizationCriticalAlertUserFilterInput,
  OrganizationDeactivateUsersInput,
  OrganizationInviteUserInput,
  OrganizationRemoveUserInput,
  OrganizationUpdateInput,
  OrganizationUserFilterInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    usingOrganization: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            usingOrganization {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              billingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
            }
          }
        `
      })
    }),
    organization: builder.query<any, string>({
      query: organizationId => ({
        document: gql`
          query {
            organization(id: "${organizationId}") {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              industries
              note
              owner
              billingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
              isSuperadmin
            }
          }
        `
      })
    }),
    organizationUsers: builder.query<any, OrganizationUserFilterInput>({
      query: organizationFilters => ({
        document: gql`
          query ($organizationFilters: OrganizationUserFiltersInput) {
            organizationUsers(filters: $organizationFilters) {
              id
              userId
              userSsoId
              fullName
              email
              userEmail
              isActive
              isSuperadmin
              roleIds
              lastLogin
              applicationRoleIds
            }
            applicationRoles {
              id
              name
              ssoId
              permissions
            }
          }
        `,
        variables: {
          organizationFilters: organizationFilters
        }
      })
    }),
    organizationInviteUsers: builder.mutation<any, OrganizationInviteUserInput>({
      query: input => ({
        document: gql`
          mutation organizationInviteUsers($input: InviteUserType!) {
            organizationInviteUsers(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationAcceptInvitation: builder.mutation<any, OrganizationAcceptInvitationInput>({
      query: input => ({
        document: gql`
          mutation organizationAcceptInvitation($input: AcceptInvitationType!) {
            organizationAcceptInvitation(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationRemoveUsers: builder.mutation<any, OrganizationRemoveUserInput>({
      query: input => ({
        document: gql`
          mutation organizationRemoveUsers($input: RemoveUserInput!) {
            organizationRemoveUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationSetSuperAdmin: builder.mutation<any, OrganizationRemoveUserInput>({
      query: input => ({
        document: gql`
          mutation organizationSetSuperAdmin($input: SetSuperAdminInput!) {
            organizationSetSuperAdmin(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationDeactivateUsers: builder.mutation<any, OrganizationDeactivateUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationDeactivateUsers($input: DeactivateUserType!) {
            organizationDeactivateUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationActiveUsers: builder.mutation<any, OrganizationActiveUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationActiveUsers($input: ActiveUserType!) {
            organizationActiveUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationChangeUsersRoles: builder.mutation<any, OrganizationChangeUsersRolesInput>({
      query: input => ({
        document: gql`
          mutation organizationChangeUsersRoles($input: ChangeUserRoleInput!) {
            organizationChangeUsersRoles(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationUpdate: builder.mutation<any, OrganizationUpdateInput>({
      query: input => ({
        document: gql`
          mutation organizationUpdate($input: OrganizationUpdateInput!) {
            organizationUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizations: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            organizations {
              id
              name
            }
          }
        `
      })
    }),
    organizationUpdateUsing: builder.mutation<any, string>({
      query: organizationId => ({
        document: gql`
          mutation {
            organizationUpdateUsing(id: "${organizationId}") {
                success
                errors
            }
          }
        `
      })
    }),
    organizationCriticalAlertUsers: builder.query<any, OrganizationCriticalAlertUserFilterInput>({
      query: filters => ({
        document: gql`
          query ($filters: OrganizationCriticalAlertUserFiltersInput!) {
            organizationCriticalAlertUsers(filters: $filters) {
              id
              name
              email
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    })
  })
})

export const {
  useUsingOrganizationQuery,
  useLazyUsingOrganizationQuery,
  useOrganizationUsersQuery,
  useLazyOrganizationUsersQuery,
  useOrganizationInviteUsersMutation,
  useOrganizationAcceptInvitationMutation,
  useOrganizationRemoveUsersMutation,
  useOrganizationSetSuperAdminMutation,
  useOrganizationDeactivateUsersMutation,
  useOrganizationActiveUsersMutation,
  useOrganizationChangeUsersRolesMutation,
  useOrganizationUpdateMutation,
  useOrganizationQuery,
  useOrganizationsQuery,
  useLazyOrganizationsQuery,
  useOrganizationUpdateUsingMutation,
  useOrganizationCriticalAlertUsersQuery
} = api
