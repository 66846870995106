import { Grid, Skeleton } from '@opswat/react-ui'

const SupportLoading = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
    </Grid>
  )
}

export default SupportLoading
