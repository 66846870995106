import React, { FC, useEffect, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'
import _pickBy from 'lodash/pickBy'
import _has from 'lodash/has'

import { Typography, TextField, Grid, TemplateSection, ButtonLoading, Autocomplete, TextGrid } from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'
import { TimezoneList, getTimeZoneLabel } from '@opswat/react-core'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useLazySysItemRegionsQuery } from 'myopswat-web/src/api/system'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { useLazyProfileQuery, useProfileUpdateMutation } from 'myopswat-web/src/api/account'
import { enqueueSnackbar } from 'notistack'
import { saveSkipProfile, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS, SCHEMA_YUP } from '@myopswat/common'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'

interface IProps {
  sysItemCountriesData: any
}

interface IForm {
  email: string
  firstName: string
  lastName: string
  title: string
  companyName: string
  userTypes: string
  streetAddress1: string
  streetAddress2: string
  city: string
  country: any
  state: any
  phone: string
  postalCode: string
  timezone: any
  note: string
  usingOrganizationId: string
}

const PersonalizeForm: FC<IProps> = ({ sysItemCountriesData }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}

  const [getProfile] = useLazyProfileQuery()
  const [getSysItemRegions, { data: sysItemRegionsData }] = useLazySysItemRegionsQuery()
  const [profileUpdate, { isLoading: isLoadingProfileUpdate }] = useProfileUpdateMutation()

  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return _get(result, 'code')
  }, [sysItemCountriesData])

  const defaultValues = {
    email: _get(profileData, 'email', ''),
    firstName: _get(profileData, 'firstName', ''),
    lastName: _get(profileData, 'lastName', ''),
    title: _get(profileData, 'title', ''),
    companyName: _get(profileData, 'companyName'),
    streetAddress1: _get(profileData, 'address.streetAddress1', ''),
    streetAddress2: _get(profileData, 'address.streetAddress2', ''),
    city: _get(profileData, 'address.city', ''),
    country: country,
    state: _get(profileData, 'address.state'),
    phone: _get(profileData, 'address.phone', ''),
    postalCode: _get(profileData, 'address.postalCode', ''),
    timezone: _get(profileData, 'timezone', ''),
    note: _isEmpty(_get(profileData, 'note')) ? '' : _get(profileData, 'note'),
    usingOrganizationId: _get(profileData, 'usingOrganizationId', '')
  }

  const schema = yup
    .object()
    .shape(
      _merge(
        {},
        ...[
          SCHEMA_YUP.firstName,
          SCHEMA_YUP.lastName,
          SCHEMA_YUP.streetAddress1,
          SCHEMA_YUP.streetAddress2,
          SCHEMA_YUP.citySchema,
          SCHEMA_YUP.title,
          SCHEMA_YUP.companyName,
          SCHEMA_YUP.country,
          SCHEMA_YUP.postalCode,
          SCHEMA_YUP.phone,
          SCHEMA_YUP.timezone,
          SCHEMA_YUP.note
        ]
      )
    )

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = async (data: any) => {
    const commonKeyValues = _pickBy(data, (value, key) => _has(dirtyFields, key))

    if (!_isEmpty(commonKeyValues)) {
      try {
        const response = await profileUpdate(commonKeyValues).unwrap()

        if (response?.success) {
          await enqueueSnackbar(translate('updateProfileSuccess'), {
            variant: 'success'
          })
          await getProfile()
          await dispatch(
            toggleDialogs({
              [DIALOGS.PERSONAL_INFORMATION]: false,
              [DIALOGS_WEB.NPS_SURVEY_HIDDEN]: true
            })
          )
        } else {
          enqueueSnackbar(translate('updateProfileFail'), {
            variant: 'error'
          })
        }
      } catch {
        enqueueSnackbar(translate('updateProfileFail'), {
          variant: 'error'
        })
      }
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    if (watch('country') === 'US') {
      getSysItemRegions(234)
    }
  }, [watch('country')])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OpswatIcon />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h1" paragraph>
          Account Information
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Complete your information to enable full account functions including product management, product evaluations,
          and access to support services.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TemplateSection>
          <TextGrid
            label={<Typography variant="subtitle2">Email</Typography>}
            value={<Typography>{_get(profileData, 'email', '')}</Typography>}
          />

          <TextGrid
            label={<Typography variant="subtitle2">First Name*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                placeholder={_get(profileData, 'firstName', '')}
                error={!!_get(errors, 'firstName', '')}
                helperText={_get(errors, 'firstName.message', '')}
                {...register('firstName')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Last Name*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                placeholder={_get(profileData, 'lastName', '')}
                error={!!_get(errors, 'lastName', '')}
                helperText={_get(errors, 'lastName.message', '')}
                {...register('lastName')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Title*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'title', '')}
                helperText={_get(errors, 'title.message', '')}
                {...register('title')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Company*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'companyName', '')}
                helperText={_get(errors, 'companyName.message', '')}
                {...register('companyName')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Phone Number*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'phone', '')}
                helperText={_get(errors, 'phone.message', '')}
                {...register('phone')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Time Zone*</Typography>}
            value={
              <Controller
                name="timezone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    options={TimezoneList || []}
                    value={value}
                    getOptionLabel={(option: any) => getTimeZoneLabel(option)}
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'timezone', '')}
                        helperText={_get(errors, 'timezone.message', '')}
                      />
                    )}
                  />
                )}
              />
            }
          />
        </TemplateSection>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TemplateSection>
          <TextGrid
            label={<Typography variant="subtitle2">Address1*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'streetAddress1', '')}
                helperText={_get(errors, 'streetAddress1.message', '')}
                {...register('streetAddress1')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Address2</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'streetAddress2', '')}
                helperText={_get(errors, 'streetAddress2.message', '')}
                {...register('streetAddress2')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">City*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'city', '')}
                helperText={_get(errors, 'city.message', '')}
                {...register('city')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">Country*</Typography>}
            value={
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    options={_map(sysItemCountriesData, item => _get(item, 'code')) || []}
                    value={value}
                    getOptionLabel={(option: any) =>
                      _get(
                        _find(sysItemCountriesData, item => _get(item, 'code') === option),
                        'name'
                      ) || ''
                    }
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'country', '')}
                        helperText={_get(errors, 'country.message', '')}
                      />
                    )}
                  />
                )}
              />
            }
          />

          {watch('country') === 'US' && (
            <TextGrid
              label={<Typography variant="subtitle2">State</Typography>}
              value={
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={_map(sysItemRegionsData, item => _get(item, 'name')) || []}
                      value={value}
                      getOptionLabel={(option: any) =>
                        _get(
                          _find(sysItemRegionsData, item => _get(item, 'name') === option),
                          'name'
                        ) || ''
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
                    />
                  )}
                />
              }
            />
          )}

          <TextGrid
            label={<Typography variant="subtitle2">Postal Code*</Typography>}
            value={
              <TextField
                size="small"
                fullWidth
                error={!!_get(errors, 'postalCode', '')}
                helperText={_get(errors, 'postalCode.message', '')}
                {...register('postalCode')}
              />
            }
          />
        </TemplateSection>
      </Grid>

      <Grid item xs={12} sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={true}>
            <Typography variant="body2" color="textSecondary">
              *Information is required to fully enable this account.
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => {
                  dispatch(saveSkipProfile(true))
                  dispatch(
                    toggleDialogs({
                      [DIALOGS.PERSONAL_INFORMATION]: false
                    })
                  )
                },
                fullWidth: true
              }}
              propsLoading={{ color: 'primary' }}
            >
              Skip for now
            </ButtonLoading>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled: isLoadingProfileUpdate || !watch('firstName') || !watch('lastName'),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingProfileUpdate}
            >
              Complete Setup
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PersonalizeForm
