export enum USER_ACCEPT_INVITE_ERRORS {
  DEFAULT = 'AE_00000'
}

export const USER_ACCEPT_INVITE_ERROR_MESSAGES: Record<string, string> = {
  [USER_ACCEPT_INVITE_ERRORS.DEFAULT]:
    'There was an unexpected error while accepting invitation. Please verify with the organization administrator if the invitation is still valid.',
  AE_40403:
    'Unable to accept the invitation. Please make sure that you have the correct invitation URL, or that you didn’t accept the invitation already. If you didn’t accept the invitation and the URL is correct, please request a new invitation.',
  AE_40401:
    'Unable accept the invitation. The user with which you are trying to join doesn’t exist in our database. Please make sure you have an active account with the email that received this invitation.',
  AE_50009: 'Unable accept the invitation. Please make sure you have the correct invitation URL.'
}
