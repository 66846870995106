import { styled } from '@mui/material'
import { formatDatetime } from '@opswat/react-core'
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@opswat/react-ui'
import { Engine } from 'myopswat-web/src/api/engine-status/types'
import { ENGINE_PACKAGE_DATETIME_FORMAT } from '../../contants'
import HashView from './HashView'

const StyledCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '4px',
  fontSize: '12px'
}))

const StyledTypography = styled(Typography)(() => ({
  fontSize: '12px'
}))

interface IProps {
  list: Engine[]
  open: boolean
  afterCopy: () => void
  timezone?: string
}

const EngineView = ({ list, open, afterCopy, timezone }: IProps) => {
  const formatSize = (size: number) => {
    const mB = size / (1024 * 1024)
    if (mB < 100) return `${mB.toFixed(2)} MB`
    return `${(mB / 1024).toFixed(2)} GB`
  }

  return (
    <TableRow>
      <TableCell sx={{ padding: 0 }} colSpan={4}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableContainer sx={{ pl: '16px' }} component={Box}>
            <Table aria-label="engine list">
              <TableHead>
                <TableRow>
                  <StyledCell
                    sx={{
                      minWidth: 198
                    }}
                  >
                    <StyledTypography>Engine Name</StyledTypography>
                  </StyledCell>
                  <StyledCell
                    sx={{
                      minWidth: 90
                    }}
                  >
                    <StyledTypography>Timestamp</StyledTypography>
                  </StyledCell>
                  <StyledCell
                    sx={{
                      minWidth: 200
                    }}
                  >
                    <StyledTypography>Hash</StyledTypography>
                  </StyledCell>
                  <StyledCell
                    sx={{
                      minWidth: 100
                    }}
                  >
                    <StyledTypography sx={{ textAlign: 'right' }}>Package Size</StyledTypography>
                  </StyledCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((engine, idx) => (
                  <TableRow key={`engine-info-${idx}`}>
                    <StyledCell>{engine.display_name}</StyledCell>
                    <StyledCell>
                      {formatDatetime(engine.timestamp, ENGINE_PACKAGE_DATETIME_FORMAT, timezone)}
                    </StyledCell>
                    <StyledCell>
                      <HashView hash={engine.hash} afterCopy={afterCopy} />
                    </StyledCell>
                    <StyledCell sx={{ textAlign: 'right' }}>{formatSize(engine.size)}</StyledCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default EngineView
