import React, { FC } from 'react'

import { Box, useTheme } from '@opswat/react-ui'

interface IProps {
  text: React.ReactNode
  isSelected: boolean
}

const BoxReportType: FC<IProps> = ({ text, isSelected }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        height: '100%',
        minHeight: '70px',
        backgroundColor: isSelected ? theme.palette.primary.main : '#F4F4F5',
        color: isSelected ? 'white' : 'inherit',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      {text}
    </Box>
  )
}

export default BoxReportType
