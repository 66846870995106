import { FC } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

import _map from 'lodash/map'
import _range from 'lodash/range'

import { Grid, Typography, useTheme } from '@opswat/react-ui'

const NPSScore: FC<ControllerRenderProps> = ({ onChange, value }) => {
  const theme = useTheme()

  const RatingStep = (itemValue: any, onChange: any) => {
    const selected = value === itemValue
    return (
      <Grid
        item
        xs={1}
        onClick={() => onChange(selected ? undefined : itemValue)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: 1,
          border: '1px solid #BCBFC3',
          borderRight: '0px',
          backgroundColor: selected ? theme.palette.primary.main : theme.palette.common.white,
          '&:last-child': {
            borderRight: '1px solid #BCBFC3'
          },
          '&:hover': {
            cursor: 'pointer',
            '& .MuiTypography-root': {
              color: selected ? theme.palette.common.white : theme.palette.primary.main,
              transform: selected ? 'none' : 'scale(1.1)',
              transition: 'transform 0.1s ease-in'
            }
          }
        }}
      >
        <Typography variant="body2" color={selected ? theme.palette.common.white : '#485161'}>
          {itemValue}
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      <Grid container columns={11}>
        {_map(_range(0, 11), item => RatingStep(item, onChange))}
      </Grid>
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">Not likely at all</Typography>
        <Typography variant="subtitle2">Extremely likely</Typography>
      </Grid>
    </>
  )
}

export default NPSScore
