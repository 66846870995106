import yup from 'myopswat-web/src/utils/yup'

export const inviteUserSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .myOpswatEmail('Please enter the correct email structure')
    .required('Email cannot be blank'),
  role: yup.string().trim().required('Roles cannot be blank')
})
