import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ButtonLoading, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@opswat/react-ui'
import { useRemoveCriticalAlertUserMutation } from 'myopswat-web/src/api/support'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

interface IProps {
  organization: any
  alertUser: any
}

const HighLightEmail = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 500
}))

const RemoveUserModal = NiceModal.create(({ alertUser, organization }: IProps) => {
  const { t } = useTranslation()
  const modal = useModal()
  const [removeCriticalAlertUser, { isLoading }] = useRemoveCriticalAlertUserMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: {} })
  const onRemove = () => {
    try {
      removeCriticalAlertUser({
        id: alertUser.id,
        orgId: organization.id
      })
        .unwrap()
        .then(res => {
          if (res.success) {
            enqueueSnackbar(t('removeCriticalAlertSuccess'), {
              variant: 'success'
            })
            modal.resolve()
            modal.hide()
          } else {
            showErrorMessage(res.errors)
          }
        })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove()
      }}
      maxWidth={'sm'}
    >
      <DialogTitle component={'h3'}>Remove user from critical alert list</DialogTitle>
      <DialogContent>
        Do you want to remove <HighLightEmail>{alertUser.email}</HighLightEmail> ?
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: () => modal.hide(),
            disabled: isLoading
          }}
        >
          Cancel
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: onRemove,
            disabled: isLoading
          }}
          isLoading={isLoading}
        >
          Yes
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  )
})

export default RemoveUserModal
