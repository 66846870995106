import { FC, useContext } from 'react'

import { ButtonLoading, DialogAdvanced, Typography } from '@opswat/react-ui'

import { DialogNPSSurveyContext } from './interface'

const DialogNPSNotification: FC<any> = () => {
  const { openNotificationDialog, handleOpenSurveyDialog, handleRejectNPSSurvey } = useContext(DialogNPSSurveyContext)

  return (
    <DialogAdvanced
      disableBackdropClick
      open={openNotificationDialog}
      onClose={() => handleRejectNPSSurvey('reject')}
      title={
        <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
          {'Your feedback is important to us!'}
        </Typography>
      }
      content={
        <Typography variant="subtitle2" sx={{ lineHeight: '20px' }}>
          {`We'd love to know your thoughts on our products and services. Share your feedback with us. It's quick!`}
        </Typography>
      }
      actions={
        <>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              onClick: handleOpenSurveyDialog,
              sx: {
                width: '100%',
                textTransform: 'none'
              }
            }}
          >
            {'Share feedback'}
          </ButtonLoading>
          <ButtonLoading
            propsButton={{
              onClick: () => handleRejectNPSSurvey('no_answer'),
              sx: {
                width: '100%',
                textTransform: 'none',
                color: '#1B273C'
              }
            }}
          >
            {'No, thank you'}
          </ButtonLoading>
        </>
      }
      dialogProps={{
        hideBackdrop: true,
        disableScrollLock: true,
        disableEnforceFocus: true, // to allow for interactions in the background
        sx: {
          position: 'sticky', // to allow for interactions in the background
          '& .MuiDialog-container': {
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            position: 'fixed',
            bottom: 0,
            pointerEvents: 'none', // to allow for interactions in the background
            '& .MuiPaper-root': {
              maxWidth: '300px',
              pointerEvents: 'auto'
            }
          }
        }
      }}
      dialogTitleProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 20px',
          '& .MuiIconButton-root': {
            padding: '0px'
          }
        }
      }}
      dialogContentProps={{
        sx: {
          paddingBottom: '0px',
          paddingX: '20px',
          marginBottom: 1
        }
      }}
      dialogActionsProps={{
        sx: {
          paddingX: '20px',
          paddingY: '0px',
          marginY: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }
      }}
    />
  )
}

export default DialogNPSNotification
