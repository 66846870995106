import { FC, useCallback, useMemo, useState } from 'react'

import _get from 'lodash/get'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { CloseIcon } from '@opswat/react-icon'
import { Box, ButtonLoading, DialogAdvanced, Divider, Grid, IconButton, Typography } from '@opswat/react-ui'

import { useDeleteMFTFileMutation } from 'myopswat-web/src/api/analyzer/fileUpload'
import {
  useLazyOrganizationSupportInfoQuery,
  useSupportCaseCreateMutation,
  useSupportCaseUpdateMutation
} from 'myopswat-web/src/api/support'
import { ISupportCaseCreateInput } from 'myopswat-web/src/api/support/types'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import { useLazyCheckDisplayNPSQuery } from 'myopswat-web/src/api/survey'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { CustomStepper } from './components'
import { CaseCancelStep, CaseCreateStep, CaseSkipStep, CaseSuccessStep, CaseUploadStep } from './formSteps'
import { FileItem, FileStatus } from './interface'

interface IProps {
  openDialog: boolean
  productList: any[]
  handleCloseDialog: () => void
}

const DialogSubmitCaseNew: FC<IProps> = ({ openDialog, productList, handleCloseDialog }) => {
  const [activeStep, setActiveStep] = useState<number>(1)
  const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set<number>())

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()

  const [supportCaseCreate, { isLoading: isCreatingCase, data: supportCaseCreateData }] = useSupportCaseCreateMutation()
  const [supportCaseUpdate, { isLoading: isUpdatingCase }] = useSupportCaseUpdateMutation()
  const [getOrganizationSupportInfo] = useLazyOrganizationSupportInfoQuery()
  const [deleteFile] = useDeleteMFTFileMutation()
  const [checkDisplayNPS] = useLazyCheckDisplayNPSQuery()

  const [originalValues, setOriginalValues] = useState<ISupportCaseCreateInput>()

  const defaultValues = useMemo(() => {
    return {
      orgId: _get(profileData, 'currentOrganizationId') ?? '',
      productName: '',
      versionNumber: '',
      licenseKey: '',
      osName: '',
      osVersion: '',
      severity: '',
      subject: '',
      description: '',
      attachments: []
    } as ISupportCaseCreateInput
  }, [profileData])

  const validationSchema = yup.object().shape({
    productName: yup.string().trim().required('Product Name cannot be blank'),
    licenseKey: yup.string().trim().required('License Key cannot be blank'),
    severity: yup.string().trim().required('Severity cannot be blank'),
    subject: yup.string().trim().required('Title cannot be blank'),
    description: yup.string().trim().required('Description cannot be blank')
  })

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<ISupportCaseCreateInput>({
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  const handleCheckDisplayNPS = useCallback(async () => {
    await getOrganizationSupportInfo(_get(profileData, 'currentOrganizationId') ?? '')
      .unwrap()
      .then(async () => {
        const showDialog = await checkDisplayNPS({})
        if (showDialog) {
          dispatch(
            toggleDialogs({
              [DIALOGS_WEB.NPS_SURVEY]: true,
              [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'response-action',
              [DIALOGS_WEB.NPS_NOTIFICATION]: false
            })
          )
        }
      })
  }, [])

  const handleResetForm = useCallback(async () => {
    setActiveStep(1)
    setCompletedSteps(new Set())
    reset()
  }, [])

  const handleSupportCaseCreate = useCallback(
    async (data: ISupportCaseCreateInput) => {
      delete data['attachments']
      try {
        await supportCaseCreate(data)
          .unwrap()
          .then(data => {
            if (data?.success) {
              setActiveStep(prev => prev + 1)
              setCompletedSteps(prev => prev.add(activeStep))
            } else if (data.errors) {
              const error = data.errors[0]
              const errorMsg = error.message
              enqueueSnackbar(errorMsg, {
                variant: 'error'
              })
            }
          })
          .catch(() => {
            enqueueSnackbar(translate('submitCaseFail'), {
              variant: 'error'
            })
          })
      } catch (error) {
        console.error(error)
      }
    },
    [profileData]
  )

  const handleSupportCaseAttachFile = useCallback(
    async (caseId: string) => {
      await supportCaseUpdate({
        caseId,
        files:
          watch('attachments')?.map((item: FileItem) => ({
            name: item.file.name,
            size: `${item.fileSize}`,
            url: `https://uploadfiles.opswat.com/file/${item.fileId}`
          })) ?? []
      })
        .unwrap()
        .then((caseResponse: any) => {
          if (caseResponse.success) {
            setCompletedSteps(prev => prev.add(2))
            setActiveStep(3)
          } else {
            enqueueSnackbar('An error has occured. Please try again and contact support if the error persists.', {
              variant: 'error'
            })
          }
        })
    },
    [watch('attachments')]
  )

  const handlDeleteFile = useCallback(async (fileItem: FileItem) => {
    await deleteFile({ file_id: fileItem.fileId, group_id: fileItem.groupId })
  }, [])

  const handleSkipAttachFile = useCallback(() => {
    setActiveStep(3)
    setCompletedSteps(prev => prev.add(2))

    watch('attachments')
      ?.filter((item: FileItem) => item.status === FileStatus.SUCCESS)
      .forEach((file: FileItem) => {
        handlDeleteFile(file)
      })
  }, [watch('attachments')])

  const handleBackFromSkipAttachFile = useCallback(() => {
    setActiveStep(2)
    setValue(
      'attachments',
      watch('attachments')?.filter((item: FileItem) => item.status !== FileStatus.IN_PROGRESS)
    )
  }, [watch('attachments')])

  const handleBackFromCancelCase = useCallback(() => {
    setOriginalValues(watch())
    setActiveStep(1)
  }, [watch])

  const getStepTitle = useCallback(
    (step: number) => {
      switch (step) {
        case -1:
          return 'Confirm cancellation'
        case -2:
          return 'Skip attaching files'
        case 1:
          return 'Submit A Case'
        case 2:
          return 'Attach Files'
        case 3:
          return `We've received your case #${_get(supportCaseCreateData, 'caseNumber', '')}`
      }
    },
    [supportCaseCreateData]
  )

  const getStepContent = useCallback(
    (step: number) => {
      switch (step) {
        case 1:
          return (
            <CaseCreateStep
              control={control}
              setValue={setValue}
              originalValues={originalValues}
              setOriginalValues={setOriginalValues}
              productList={productList}
            />
          )
        case 2:
          return (
            <CaseUploadStep
              control={control}
              setValue={setValue}
              caseId={_get(supportCaseCreateData, 'caseNumber', '')}
            />
          )
        case 3:
          return <CaseSuccessStep caseId={_get(supportCaseCreateData, 'caseId', '')} />
        case -1:
          return <CaseCancelStep />
        case -2:
          return <CaseSkipStep caseId={_get(supportCaseCreateData, 'caseNumber', '')} />
      }
    },
    [control, supportCaseCreateData, originalValues, productList]
  )

  const getStepCloseAction = useCallback((step: number) => {
    switch (step) {
      case 1: // cancel
        setActiveStep(-1)
        break
      case 2:
      case 3: // finish
        handleCloseDialog()
        handleResetForm()
        break
    }
  }, [])

  const getLeftButtonText = useCallback((step: number) => {
    switch (step) {
      case -1:
      case -2:
        return 'Back'
      case 1:
        return 'Cancel'
      case 2:
        return 'Skip'
    }
  }, [])

  const getLeftButtonAction = useCallback((step: number) => {
    switch (step) {
      case -1: // from 'cancel' back to step 1
        handleBackFromCancelCase()
        break
      case -2: // from 'skip upload' back to step 2
        handleBackFromSkipAttachFile()
        break
      case 1: // cancel
        setActiveStep(-1)
        break
      case 2: // skip upload
        setCompletedSteps(prev => prev.add(2))
        setActiveStep(3)
        break
    }
  }, [])

  const getRightButtonText = useCallback((step: number) => {
    switch (step) {
      case -1:
        return 'Confirm'
      case -2:
        return 'Skip'
      case 1:
        return 'Create Case'
      case 2:
        return 'Attach Files'
      case 3:
        return 'Finish'
    }
  }, [])

  const getRightButtonAction = useCallback(
    (step: number) => {
      switch (step) {
        case -1:
          handleCloseDialog()
          handleResetForm()
          break
        case -2:
          handleSkipAttachFile()
          break
        case 1:
          handleSubmit(handleSupportCaseCreate)()
          break
        case 2:
          handleSupportCaseAttachFile(_get(supportCaseCreateData, 'caseId', ''))
          break
        case 3:
          handleCloseDialog()
          handleResetForm()
          handleCheckDisplayNPS()
          break
      }
    },
    [supportCaseCreateData, profileData]
  )

  const getRightButtonColor = useCallback((step: number) => {
    if (step === -1) {
      return 'error'
    } else {
      return 'primary'
    }
  }, [])

  const getRightButtonIsDisabled = useCallback(
    (step: number) => {
      switch (step) {
        case 1:
          return !isValid || isCreatingCase
        case 2:
          return (
            watch('attachments')?.length === 0 ||
            watch('attachments')?.some((item: FileItem) => item.status !== FileStatus.SUCCESS)
          )
        default:
          return false
      }
    },
    [isValid, watch('attachments')]
  )

  const getRightButtonIsLoading = useCallback(
    (step: number) => {
      switch (step) {
        case 1:
          return isCreatingCase
        case 2:
          return isUpdatingCase
        default:
          return false
      }
    },
    [isCreatingCase, isUpdatingCase]
  )

  return (
    <DialogAdvanced
      open={openDialog}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3">{getStepTitle(activeStep)}</Typography>
          {activeStep > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <CustomStepper steps={3} activeStep={activeStep} completedSteps={completedSteps} />
              <IconButton onClick={() => getStepCloseAction(activeStep)} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      }
      content={getStepContent(activeStep)}
      actions={
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs="auto">
              {getLeftButtonText(activeStep) && (
                <ButtonLoading
                  propsButton={{
                    variant: 'text',
                    color: 'inherit',
                    onClick: () => getLeftButtonAction(activeStep)
                  }}
                  propsLoading={{ color: 'inherit' }}
                  isLoading={false}
                >
                  {getLeftButtonText(activeStep)}
                </ButtonLoading>
              )}
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: getRightButtonColor(activeStep),
                  disabled: getRightButtonIsDisabled(activeStep) || getRightButtonIsLoading(activeStep),
                  onClick: () => getRightButtonAction(activeStep),
                  disableElevation: true
                }}
                isLoading={getRightButtonIsLoading(activeStep)}
                propsLoading={{ color: 'inherit' }}
              >
                {getRightButtonText(activeStep)}
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitleProps={{
        sx: {
          borderBottom: '1px solid #D3D9E6',
          marginInline: '24px',
          paddingY: '20px',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 24px',
          height: '550px'
        }
      }}
    />
  )
}

export default DialogSubmitCaseNew
