import { FC } from 'react'

import { NotAvailableIcon } from '@opswat/react-icon'
import { Box, Table, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@opswat/react-ui'

import _map from 'lodash/map'
import _merge from 'lodash/merge'

interface IProps {
  columns: any
}

const CasesNoData: FC<IProps> = ({ columns }) => {
  const theme = useTheme()

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {_map(columns, (column, columnIdx) => {
                return (
                  <TableCell
                    sx={_merge({ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}` }, column.style)}
                    key={columnIdx.toString()}
                  >
                    <Typography sx={{ ...column.style.sx }}>{column.header}</Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          py: '40px',
          gap: '10px',
          flexDirection: 'column'
        }}
      >
        <NotAvailableIcon size={40} color="#BCBFC3" />
        <Typography component="label">No cases found</Typography>
      </Box>
    </>
  )
}

export default CasesNoData
