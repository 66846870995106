import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    latestNews: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            latestNews {
              id
              url
              seoImage
              title
              postDate
              seen
            }
          }
        `
      })
    }),
    productSubscriptionEntries: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            productSubscriptionEntries {
              id
              url
              title
              tags
              postDate
              seen
              productTags
            }
          }
        `
      })
    }),
    entrySeen: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation entrySeen($input: TargetEntryInput!) {
            entrySeen(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            entryId: id
          }
        }
      })
    }),
    entryUnseen: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation entryUnseen($input: TargetEntryInput!) {
            entryUnseen(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            entryId: id
          }
        }
      })
    }),
    entryIgnore: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation entryIgnore($input: TargetEntryInput!) {
            entryIgnore(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            entryId: id
          }
        }
      })
    }),
    productRelatedEntries: builder.query<any, string>({
      query: productId => ({
        document: gql`
          query productRelatedEntries($productId: UUID!) {
            productRelatedEntries(productId: $productId) {
              id
              url
              title
              postDate
              productTags
              seen
            }
          }
        `,
        variables: {
          productId
        }
      })
    })
  })
})

export const {
  useLatestNewsQuery,
  useProductSubscriptionEntriesQuery,
  useEntrySeenMutation,
  useEntryUnseenMutation,
  useEntryIgnoreMutation,
  useProductRelatedEntriesQuery,
  useLazyProductRelatedEntriesQuery
} = api
