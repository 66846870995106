import { FC } from 'react'

import { Grid, Typography } from '@opswat/react-ui'

const CaseCancelStep: FC<any> = () => {
  return (
    <Grid container rowSpacing={3} sx={{ mt: '12px' }}>
      <Typography variant="body2">
        Are you sure you want to cancel this case? <br /> All previously filled in details will be lost.
      </Typography>
    </Grid>
  )
}

export default CaseCancelStep
