import React, { useEffect } from 'react'

import _includes from 'lodash/includes'

import { useNavigate, useLocation, Outlet } from 'react-router-dom'

import { handleCheckLogin, handleClearCookie } from '@myopswat/common'
import { PageLoading } from '@opswat/react-ui'

import { changePasswordPageURL, homePageURL } from 'myopswat-web/src/routes'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'

export default function SSOContainer() {
  const isToken = handleCheckLogin()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isToken) {
      navigate(homePageURL)
    } else {
      handleClearCookie()
    }
  }, [isToken])

  useEffect(() => {
    if (location.pathname && _includes(location.pathname, 'changePassword')) {
      navigate(`${changePasswordPageURL}${location.search}`)
    }
  }, [])

  if (isToken) return <PageLoading background={imageBg} color="primary" />

  return <Outlet />
}
