import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IEventHistoryInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    eventsHistories: builder.query<any, IEventHistoryInput>({
      query: input => ({
        document: gql`
          query ($filters: OrganizationEventHistoryFiltersInput, $pageInfo: PageInfoType) {
            eventsHistories(filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                user
                type
                impacted
                oldValue
                newValue
                createdAt
              }
              totalCount
            }
          }
        `,
        variables: input
      })
    })
  })
})

export const { useEventsHistoriesQuery } = api
