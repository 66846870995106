export enum INVITE_USER_ERROR_CODES {
  ORGANIZATION_USER_EXIST = 'organization_user_exist',
  ORGANIZATION_USER_NOT_FOUND = 'organization_user_not_found',
  USER_NOT_FOUND = 40401
}

export const INVITE_USER_ERROR_MESSAGES: Record<string | number, string> = {
  [INVITE_USER_ERROR_CODES.ORGANIZATION_USER_EXIST]: 'Existing users detected',
  [INVITE_USER_ERROR_CODES.ORGANIZATION_USER_NOT_FOUND]: 'User has not registered for an OPSWAT account',
  40401:
    'The user <%= email%> has not registered for an OPSWAT account.\nOnly existing users can be invited to organizations.\nVerify the email input or make sure the user you are trying to invite is has an active account.',
  40005: 'We encountered an error sending the invitation. The invitation contains invalid data format.',
  40002: 'We encountered an error sending the invitation. The invitation contains invalid data.',
  40009: 'There was an error inviting <%= email%> to the organization. The assigned role is invalid or does not exist.',
  40006:
    'Error: <%= email%> already has a pending invitation. A new invitation can be sent after the pending invitation expires.',
  40007: 'The user <%= email%> is already invited to this organization.',
  40010: `The user <%= email%> isn't confirmed yet. Only confirmed users can be invited to organizations.`,
  40402: `We encountered an error sending the invitation. We couldn’t find the organization.`,
  50002: `There was an error sending the invitation email to <%= email%>.`,
  50008: `We encountered an error sending the invitation. The user cannot be added to the requested organization.`
}

export const REMOVE_USER_ERROR_MESSAGES: Record<string | number, string> = {
  50007:
    'We encountered an error trying to remove <%= email%>. Please try again and contact support if the error persists.',
  40403: 'Unable to remove the invitation. the invitation has expired or not existing.'
}

export const UPDATE_USER_ERROR_MESSAGES: Record<string | number, string> = {
  40401: 'The user <%= email%> doesn’t exist in our database. Please refresh the page and try again.',
  40005: 'We encountered an error updating the user. Please refresh the page and try again.',
  40009: 'There was an error updating <%= email%>. The assigned role is invalid or does not exist.',
  50010: 'There was an unexpected error while updating the user. Please refresh the page and try again.',
  500014: 'Error when writing user.'
}

export const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'True'
  },
  {
    label: 'Pending',
    value: null
  }
]
