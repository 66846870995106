import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IProps {
  solutionsData: any
  producTypes: any
  isFetchingSolutions: boolean
  handleFilterProducts: (data: any) => void
}

interface ProductFormFilter {
  solutions: any[]
  types: any[]
}

const BoxProductHomeFilter: FC<IProps> = ({
  solutionsData,
  isFetchingSolutions,
  handleFilterProducts,
  producTypes
}) => {
  const { t: translate } = useTranslation()
  const loadingSolutionArray = [1, 2, 3, 4, 5]

  const [keyword, setKeyword] = useState<string>()
  const [solutionsParams, setSolutionsParams] = useState<any[]>([])
  const [typesParams, setTypesParams] = useState<any[]>([])

  const defaultValues = useMemo(() => {
    const solutions = Array(_size(solutionsData)).fill(false)
    const types = Array(_size(producTypes)).fill(false)
    return {
      solutions: solutions,
      types: types
    }
  }, [producTypes, solutionsData])

  const { handleSubmit, control, setValue, watch } = useForm<ProductFormFilter>({
    defaultValues
  })

  const onSuccess = () => {
    handleFilterProducts({
      q: keyword,
      solutionIds: solutionsParams,
      tags: typesParams,
      excludedTags: ['utility']
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleOnChangeSolutions = useCallback((item: any, idx: number, checked: boolean) => {
    setSolutionsParams(prev =>
      checked ? [...prev, item.id] : prev.filter((item: any) => item !== solutionsData[idx].id)
    )
  }, [])

  const handleOnChangeTypes = useCallback((item: any, idx: number, checked: boolean) => {
    setTypesParams(prev => (checked ? [...prev, item] : prev.filter((item: any) => item !== producTypes[idx])))
  }, [])

  const renderSkeletonCheckbox = useMemo(
    () => (
      <Grid item xs={12} key={crypto.randomUUID()}>
        <CheckboxWithLabel
          label="--"
          checkboxProps={{
            checked: false,
            onChange: () => null
          }}
        />
      </Grid>
    ),
    []
  )

  const renderSolutionsCheckbox = useCallback(
    (item: any, idx: any) => (
      <Grid item xs={12} key={idx.toString()}>
        <Controller
          name={`solutions.${idx}`}
          control={control}
          render={() => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
              checkboxProps={{
                checked: solutionsParams.includes(solutionsData[idx].id),
                onChange: (e: any) => handleOnChangeSolutions(item, idx, e.target.checked)
              }}
            />
          )}
        />
      </Grid>
    ),
    [control, solutionsParams, solutionsData]
  )

  const renderProductTypesCheckbox = useCallback(
    (item: any, idx: any) => (
      <Grid item xs={12} key={idx.toString()}>
        <Controller
          name={`types.${idx}`}
          control={control}
          render={() => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item || '--'}</Typography>}
              checkboxProps={{
                checked: typesParams.includes(producTypes[idx]),
                onChange: (e: any) => handleOnChangeTypes(item, idx, e.target.checked)
              }}
            />
          )}
        />
      </Grid>
    ),
    [control, typesParams, producTypes]
  )

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        handleFilterProducts({
          q: keyword,
          solutionIds: solutionsParams,
          tags: typesParams,
          excludedTags: ['utility']
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  useEffect(() => {
    handleSubmit(onSuccess, onFail)()
  }, [])

  useEffect(() => {
    handleSubmit(onSuccess, onFail)()
  }, [watch('solutions'), watch('types')])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterProductName') || ''}
          onChange={(e: any) => {
            if (_get(e, 'target')) {
              setKeyword(e.target.value)
            }
          }}
          onClearText={() => setKeyword('')}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={1}>
              <Grid item xs={12} sm={7}>
                {isFetchingSolutions ? (
                  <Grid container>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Solutions</Typography>
                      </Grid>
                      {_map(loadingSolutionArray, renderSkeletonCheckbox)}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Solutions</Typography>
                      </Grid>
                      {_map(solutionsData, renderSolutionsCheckbox)}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Product Types</Typography>
                  </Grid>
                  {_map(producTypes, renderProductTypesCheckbox)}
                </Grid>
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            setSolutionsParams([])
            setTypesParams([])
          }}
          onResetChange={() => {
            setSolutionsParams(
              watch('solutions')
                .map((checked: any, index: number) => (checked ? solutionsData[index].id : null))
                .filter(item => item)
            )
            setTypesParams(
              watch('types')
                .map((checked: any, index: number) => (checked ? producTypes[index] : null))
                .filter(item => item)
            )
          }}
          onApply={() => {
            setValue(
              'types',
              Array(_size(producTypes))
                .fill(false)
                .map((_, index) => typesParams.includes(producTypes[index]))
            )
            setValue(
              'solutions',
              Array(_size(solutionsData))
                .fill(false)
                .map((_, index) => solutionsParams.includes(solutionsData[index].id))
            )
          }}
          propsPaper={{ sx: { maxWidth: '400px' } }}
        />
      </Grid>
    </Grid>
  )
}

export default BoxProductHomeFilter
