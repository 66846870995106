import { FC, useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { TemplateSection } from '@opswat/react-ui'

import { useApplicationRolesWithPermissionsQuery, useRoleDeleteMutation } from 'myopswat-web/src/api/role'

import BoxRolesFilter from './BoxRolesFilter'
import BoxRolesTable from './BoxRolesTable'

const Roles: FC<unknown> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [rolesFilter, setRolesFilter] = useState<any>({
    q: ''
  })

  const {
    data,
    isFetching: isFetchingRoles,
    refetch: refetchRoles
  } = useApplicationRolesWithPermissionsQuery(rolesFilter)

  const rolesData = useMemo(() => {
    const allPermissions = _get(data, 'allPermissions', [])
    return _map(_get(data, 'roles', []), (role: any) => ({ ...role, allPermissions }))
  }, [data])

  const [roleDelete, { data: roleDeleteData, error: roleDeleteError }] = useRoleDeleteMutation()

  const handleRoleDelete = async (data: string) => {
    try {
      await roleDelete(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (_get(roleDeleteData, 'success')) {
      enqueueSnackbar(t('removeRoleSuccess'), {
        variant: 'success'
      })

      refetchRoles()
    } else if (
      _get(roleDeleteError, 'errors.0.message') ||
      _get(roleDeleteError, 'message') ||
      _get(roleDeleteData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('removeRoleFail'), {
        variant: 'error'
      })
    }
  }, [roleDeleteData, roleDeleteError])

  return (
    <TemplateSection spacing={2}>
      <BoxRolesFilter setRolesFilter={setRolesFilter} />
      <BoxRolesTable rolesData={rolesData} isFetchingRoles={isFetchingRoles} handleRoleDelete={handleRoleDelete} />
    </TemplateSection>
  )
}

export default Roles
