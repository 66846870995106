/* eslint-disable no-var */
import { useEffect, useState } from 'react'

import _get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'
import { useDeploymentIdsReportQuery, useLicenseActivateMutation } from 'myopswat-web/src/api/product'
import { ILicenseActivateInput, ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'
import LicensedProductsPage from 'myopswat-web/src/pages/LicensedProductsPage'

const LicensedProductsContainer = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [csvFilter, setCsvFilter] = useState<ILicenseReportFiltersInput | undefined>()

  const [
    licenseActivate,
    { data: licenseActivateData, isLoading: isLoadingLicenseActivate, error: licenseActivateError }
  ] = useLicenseActivateMutation()

  const {
    data: deploymentIdsReportData,
    refetch: refetchDeploymentIdsReport,
    error: deploymentIdsReportError
  } = useDeploymentIdsReportQuery(csvFilter, {
    skip: !csvFilter
  })

  const handleLicenseActivate = async (data: ILicenseActivateInput) => {
    try {
      await licenseActivate({
        productId: _get(data, 'productId', ''),
        deployment: _get(data, 'deployment'),
        key: _get(data, 'key'),
        description: _get(data, 'description'),
        quantity: _get(data, 'quantity')
      }).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (_get(csvFilter, 'productId')) {
      refetchDeploymentIdsReport()
    }
  }, [csvFilter])

  useEffect(() => {
    if (_get(licenseActivateData, 'success')) {
      enqueueSnackbar(t('activeLicensesSuccess'), {
        variant: 'success'
      })
    } else if (
      _get(licenseActivateError, 'errors.0.message') ||
      _get(licenseActivateError, 'message') ||
      _get(licenseActivateData, 'errors.0.message')
    ) {
      const errMessage = _get(licenseActivateData, 'errors.0.message') || t('activeLicensesFail')
      enqueueSnackbar(errMessage, {
        variant: 'error'
      })
    }
  }, [licenseActivateData, licenseActivateError])

  useEffect(() => {
    if (_get(deploymentIdsReportData, 'content') && _get(csvFilter, 'key', '--')) {
      enqueueSnackbar(t('downloadLicensesCSVSuccess'), {
        variant: 'success'
      })

      handleCreateFile(_get(csvFilter, 'key', '--'), 'csv', _get(deploymentIdsReportData, 'content', ''), {
        type: BLOB_FILE_TYPES.CSV
      })
      setCsvFilter(undefined)
    } else if (
      _get(deploymentIdsReportError, 'errors.0.message') ||
      _get(deploymentIdsReportError, 'message') ||
      _get(deploymentIdsReportData, 'errors.0.message')
    ) {
      const errMessage = _get(deploymentIdsReportData, 'errors.0.message') || t('downloadLicensesCSVFail')
      enqueueSnackbar(errMessage, {
        variant: 'error'
      })
    }
  }, [deploymentIdsReportData, deploymentIdsReportError])

  return (
    <LicensedProductsPage
      isLoadingLicenseActivate={isLoadingLicenseActivate}
      handleLicenseActivate={handleLicenseActivate}
      licenseActivateData={licenseActivateData}
      setCsvFilter={setCsvFilter}
    />
  )
}

export default LicensedProductsContainer
