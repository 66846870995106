import { fetchFilesAPI } from 'myopswat-web/src/api/base-query'

export const api = fetchFilesAPI.injectEndpoints({
  endpoints: builder => ({
    uploadFile: builder.mutation<any, any>({
      query: payload => ({
        url: 'upload/',
        method: 'POST',
        body: payload,
        formData: true
      })
    }),
    uploadMFTFileSingle: builder.mutation<any, any>({
      query: payload => ({
        url: '/mft/file/single/',
        method: 'POST',
        body: payload,
        formData: true
      })
    }),
    deleteMFTFile: builder.mutation<any, any>({
      query: ({ group_id, file_id }: any) => ({
        url: '/mft/file/single/',
        method: 'DELETE',
        headers: {
          'mft-group-id': group_id,
          'mft-file-id': file_id
        }
      })
    })
  })
})

export const { useUploadFileMutation, useUploadMFTFileSingleMutation, useDeleteMFTFileMutation } = api
