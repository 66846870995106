import { FC } from 'react'

import { Grid, Typography } from '@opswat/react-ui'

interface IProps {
  caseId: string
}

const CaseSkipStep: FC<IProps> = ({ caseId }) => {
  return (
    <Grid container rowSpacing={3} sx={{ mt: '12px' }}>
      <Typography variant="body2">
        Case <strong>{`#${caseId}`}</strong> has already been created.
        <br />
        {'Are you sure you want to skip attaching files for this case?'}
      </Typography>
    </Grid>
  )
}

export default CaseSkipStep
