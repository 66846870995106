import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { DATE_FORMATS, handleDownloadFile } from '@myopswat/common'
import { copyText, formatDatetime } from '@opswat/react-core'
import { CheckDoneIcon, CopySimpleIcon, DownloadIcon } from '@opswat/react-icon'
import {
  AlertTitle,
  Autocomplete,
  Box,
  ButtonLoading,
  Card,
  ChipTag,
  Grid,
  List,
  ListItem,
  TextField,
  TextGrid,
  Typography,
  TypographyLink,
  useTheme
} from '@opswat/react-ui'

import _debounce from 'lodash/debounce'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _reverse from 'lodash/reverse'
import _size from 'lodash/size'
import _sortBy from 'lodash/sortBy'

import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import { useTypedSelector } from 'myopswat-web/src/store'
import BoxCollapse from '../Box/BoxCollapse'

interface IProps {
  data: any
  fromParentId?: string
  productId?: string | undefined
  handleDownloadedProductAdd: (data: IDownloadedProductInput, isICAP?: boolean) => void
  icapIntegrationsData: any
  setHasDownloaded: (data: boolean) => void
}

const DownloadVersionForm: FC<IProps> = ({
  data,
  handleDownloadedProductAdd,
  icapIntegrationsData,
  productId,
  fromParentId,
  setHasDownloaded
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}

  const [isWGETCopied, setIsWGETCopied] = useState<boolean>(false)
  const [isCURLCopied, setIsCURLCopied] = useState<boolean>(false)

  const [selectedPlatform, setSelectedPlatform] = useState<any>(null)
  const [selectedAsset, setSelectedAsset] = useState<any>(null)

  const [assetAttributes, setAssetAttributes] = useState<any>([])

  const [versionViewMode, setVersionViewMode] = useState<string>('number')

  const sortAssets = useCallback((assets: any[]) => {
    const versionRegex = /\d+(\.\d+){2,}/
    return _reverse(
      _sortBy(assets, asset => {
        const matches = asset?.version.match(versionRegex)

        return matches && matches.length ? matches[0] : asset.version
      })
    )
  }, [])

  const convertData = useMemo(() => {
    const releases = _get(data, 'releases', [])
    const releaseMap: any = {
      number: {},
      date: {}
    }
    // Map list release and assets to object
    _forEach(releases, (release: any) => {
      const releaseVersion = release.versionSuffix ? `${release.semVer}${release.versionSuffix}` : release.semVer
      const releaseDate = formatDatetime(release.epochTime, 'yyyy-MM-dd', _get(profileData, 'timezone'))
      const sortedAssets = sortAssets(release?.assets || [])
      _forEach(sortedAssets, asset => {
        const platformId = asset.platformId
        const customAsset = {
          ...asset,
          releaseDate: asset.releaseDate || release.epochTime,
          semVer: release.versionSuffix ? `${release.semVer}${release.versionSuffix}` : release.semVer
        }
        if (sortedAssets.length === 1) {
          customAsset['displayName'] = release.semVer
        }

        if (platformId) {
          if (platformId in releaseMap['number']) {
            const currentNumberRelease = _get(releaseMap['number'], `${platformId}`)
            if (releaseVersion in currentNumberRelease) {
              releaseMap['number'][platformId][releaseVersion].assets.push(customAsset)
            } else {
              releaseMap['number'][platformId][releaseVersion] = {
                assets: [customAsset]
              }
            }
          } else {
            releaseMap['number'][platformId] = {
              [releaseVersion]: {
                assets: [customAsset]
              }
            }
          }
          if (platformId in releaseMap['date']) {
            const currentDateRelease = _get(releaseMap['date'], `${platformId}`)
            if (releaseDate in currentDateRelease) {
              if (releaseVersion in currentDateRelease[releaseDate]) {
                currentDateRelease[releaseDate][releaseVersion].assets.push(customAsset)
              } else {
                currentDateRelease[releaseDate][releaseVersion] = { assets: [customAsset] }
              }
            } else {
              releaseMap['date'][platformId][releaseDate] = {
                [releaseVersion]: { assets: [customAsset] }
              }
            }
          } else {
            releaseMap['date'][platformId] = {
              [releaseDate]: {
                [releaseVersion]: { assets: [customAsset] }
              }
            }
          }
        }
      })
    })

    // Sort release date map
    Object.keys(releaseMap['date']).forEach(platformId => {
      const dates = Object.keys(releaseMap['date'][platformId])
      const sortedDates = dates.sort().reverse()
      const newMap: any = {}
      sortedDates.forEach((date: any) => {
        newMap[date] = releaseMap['date'][platformId][date]
      })
      releaseMap['date'][platformId] = newMap
    })

    const results: any[] = []
    const platformList = _get(data, 'releaseMetadata.platforms', [])

    // Map release to Platform
    platformList.forEach((platform: any) => {
      const item: any = { ...platform }
      if (_get(releaseMap['number'], platform.id)) {
        item['releasesByNumber'] = releaseMap['number'][platform.id]
      }
      if (_get(releaseMap['date'], platform.id)) {
        item['releasesByDate'] = releaseMap['date'][platform.id]
      }
      results.push(item)
    })
    return results
  }, [data])

  const platformList = useMemo(() => {
    const result = _map(convertData, (item: any) => ({
      platformId: item.id,
      value: item.name
    }))
    return result || []
  }, [convertData])

  const releaseList = useMemo(() => {
    const result = _find(convertData, item => item?.id === selectedPlatform?.platformId)
    return (versionViewMode === 'number' ? _get(result, 'releasesByNumber') : _get(result, 'releasesByDate')) || []
  }, [convertData, selectedPlatform, versionViewMode])

  const showLatestVersionWarning = useMemo(() => {
    const criteria =
      versionViewMode === 'number'
        ? selectedAsset?.semVer
        : formatDatetime(selectedAsset?.releaseDate, 'yyyy-MM-dd', _get(profileData, 'timezone'))
    const isLatestVersion = _includes(criteria, _keys(releaseList)?.[0])

    return !isLatestVersion
  }, [selectedAsset, versionViewMode, releaseList])

  const containUndownloadableTag = useMemo(() => {
    return Boolean(_find(_get(data, 'tags', []), tag => tag === 'undownloadable'))
  }, [data])

  const handleReturnWGETCopy = _debounce(() => {
    setIsWGETCopied(false)
  }, 1000)

  const handleReturnCURLCopy = _debounce(() => {
    setIsCURLCopied(false)
  }, 1000)

  const handleLinkWGET = (url: any) => {
    const link = `wget ${url} --no-check-certificate`

    copyText(link)
    setIsWGETCopied(true)
    handleReturnWGETCopy()

    enqueueSnackbar(t('copyTextSuccess'), {
      variant: 'success'
    })
  }

  const handleLinkCURL = (url: any) => {
    const link = `curl -O  ${url}`

    copyText(link)
    setIsCURLCopied(true)
    handleReturnCURLCopy()

    enqueueSnackbar(t('copyTextSuccess'), {
      variant: 'success'
    })
  }

  const allIntegrationData = _map(icapIntegrationsData, item => ({
    label: _get(item, 'name', '--'),
    value: _get(item, 'name', '--')
  }))
  const selectedIntegrations = _filter(icapIntegrationsData, (item: any) => _get(item, 'downloaded', false) === true)
  const selectedIntegrationsTags = _map(
    _filter(icapIntegrationsData, (item: any) => _get(item, 'downloaded', false) === true),
    item => _get(item, 'name')
  )

  const defaultValues = {
    allIntegrations: _map(selectedIntegrations, item => ({
      label: _get(item, 'name', '--'),
      value: _get(item, 'name', '--')
    }))
  }

  const { handleSubmit, control } = useForm({
    defaultValues
  })

  const onSuccess = async (value: any) => {
    if (_includes([_get(data, 'id'), productId], PRODUCT_IDS.MD_ICAP)) {
      const otherArray = await _map(
        _filter(_get(value, 'allIntegrations', []), item => _get(item, 'label') === undefined),
        item => item
      )
      const integrationsArray = await _map(
        _filter(_get(value, 'allIntegrations', []), item => _get(item, 'label') !== undefined),
        item => _get(item, 'label')
      )
      const convertIntegrationsArray = await _filter(
        integrationsArray,
        item => !_includes(selectedIntegrationsTags, item)
      )

      handleDownloadedProductAdd(
        {
          productId: _get(data, 'id'),
          os: _get(selectedPlatform, 'value'),
          md5: _get(selectedAsset, `md5`),
          sha1: _get(selectedAsset, `sha1`),
          sha256: _get(selectedAsset, `sha256`),
          downloadLink: _get(selectedAsset, `link`),
          version: _get(selectedAsset, `version`),
          integrations: convertIntegrationsArray,
          otherIntegrations: otherArray,
          fromParentId: fromParentId
        },
        true
      )
    } else {
      handleDownloadedProductAdd({
        productId: _get(data, 'id'),
        os: _get(selectedPlatform, 'value'),
        md5: _get(selectedAsset, `md5`),
        sha1: _get(selectedAsset, `sha1`),
        sha256: _get(selectedAsset, `sha256`),
        downloadLink: _get(selectedAsset, `link`),
        version: _get(selectedAsset, `version`),
        fromParentId: fromParentId
      })
    }

    handleDownloadFile(_get(selectedAsset, `link`, ''))

    setHasDownloaded(true)

    enqueueSnackbar(
      <>
        <AlertTitle>{`Thank you for downloading ${_get(data, 'name', '')}`}</AlertTitle>
        Our team will be in touch with you shortly.
      </>,
      {
        variant: 'info',
        autoHideDuration: null
      }
    )
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    if (!_isEmpty(data) && !_isEmpty(platformList)) {
      setSelectedPlatform(_get(platformList, '0'))
    }
  }, [data])

  useEffect(() => {
    if (!_isEmpty(selectedPlatform) && !_isEmpty(releaseList)) {
      if (versionViewMode === 'number') {
        const firstAsset = _get(releaseList, `${_keys(releaseList)[0]}`)?.assets?.[0]
        setSelectedAsset(firstAsset)
      } else {
        const firstKey = `${_keys(releaseList)[0]}`
        const firstAsset = _get(_get(releaseList, firstKey), `${_keys(releaseList[firstKey])[0]}`)?.assets?.[0]
        setSelectedAsset(firstAsset)
      }
    }
  }, [selectedPlatform, releaseList])

  useEffect(() => {
    setAssetAttributes([
      {
        label: 'Release date',
        value: formatDatetime(
          _get(selectedAsset, 'releaseDate'),
          DATE_FORMATS.DATE,
          _get(profileData, 'timezone') || ''
        )
      }
    ])

    if (productId && !containUndownloadableTag) {
      setAssetAttributes([
        {
          label: 'Release date',
          value: formatDatetime(
            _get(selectedAsset, 'releaseDate'),
            DATE_FORMATS.DATE,
            _get(profileData, 'timezone') || ''
          )
        },
        {
          label: 'MD5',
          value: _get(selectedAsset, `md5`, '--')
        },
        {
          label: 'SHA1',
          value: _get(selectedAsset, `sha1`, '--')
        },
        {
          label: 'SHA256',
          value: _get(selectedAsset, `sha256`, '--')
        }
      ])
    }
  }, [productId, selectedAsset, profileData, containUndownloadableTag])

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={6}>
        <Typography gutterBottom>Platform</Typography>
        <Card variant="outlined">
          <List disablePadding sx={{ height: '120px', overflow: 'auto' }}>
            {_map(platformList, (item, idx) => (
              <ListItem
                key={idx.toString()}
                button
                selected={_get(selectedPlatform, 'platformId') === _get(item, 'platformId')}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                onClick={() => {
                  setSelectedPlatform(item)
                }}
              >
                {_get(item, 'value')}
                {_get(selectedPlatform, 'platformId') === _get(item, 'platformId') && (
                  <CheckDoneIcon color={theme.palette.primary.main} />
                )}
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box sx={{ marginBottom: '0.35em' }}>
          <TypographyLink
            sx={{
              width: '48px',
              display: 'inline-block',
              cursor: versionViewMode === 'number' ? 'default' : 'pointer',
              fontWeight: versionViewMode === 'number' ? 'medium' : 'regular',
              color: versionViewMode === 'number' ? '#1D6BFC' : 'primary',
              '&:hover': {
                textDecoration: 'underline',
                fontWeight: 'medium'
              }
            }}
            onClick={() => {
              setVersionViewMode('number')
            }}
            variant="body2"
          >
            Version
          </TypographyLink>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <TypographyLink
            sx={{
              cursor: versionViewMode === 'date' ? 'default' : 'pointer',
              fontWeight: versionViewMode === 'date' ? 'medium' : 'regular',
              color: versionViewMode === 'date' ? '#1D6BFC' : 'primary',
              '&:hover': {
                textDecoration: 'underline',
                fontWeight: 'medium'
              }
            }}
            onClick={() => {
              setVersionViewMode('date')
            }}
            variant="body2"
          >
            Release date
          </TypographyLink>
        </Box>
        <Card variant="outlined">
          <List disablePadding sx={{ height: '120px', overflow: 'auto' }}>
            {_map(releaseList, (value: any, key: string) => (
              <React.Fragment key={key.toString()}>
                {versionViewMode === 'number' &&
                  (_size(_get(value, 'assets')) > 1 ? (
                    <BoxCollapse
                      title={key || '--'}
                      content={
                        <>
                          {_map(_get(value, 'assets'), (downloadPackage: any, downloadPackageIdx: number) => (
                            <ListItem
                              key={downloadPackageIdx.toString()}
                              button
                              selected={
                                `${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                `${_get(downloadPackage, `displayName`)}${_get(downloadPackage, `md5`)}`
                              }
                              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                              onClick={() => {
                                setSelectedAsset(downloadPackage)
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {_get(downloadPackage, 'displayName') || '--'}
                              </Typography>
                              {`${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                `${_get(downloadPackage, `displayName`)}${_get(downloadPackage, `md5`)}` && (
                                <CheckDoneIcon color={theme.palette.primary.main} />
                              )}
                            </ListItem>
                          ))}
                        </>
                      }
                    />
                  ) : (
                    <ListItem
                      button
                      selected={
                        `${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                        `${_get(value, `assets.0.displayName`)}${_get(value, `assets.0.md5`)}`
                      }
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      onClick={() => {
                        setSelectedAsset(_get(value, 'assets.0'))
                      }}
                    >
                      {_get(value, 'assets.0.semVer')}
                      {`${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                        `${_get(value, `assets.0.displayName`)}${_get(value, `assets.0.md5`)}` && (
                        <CheckDoneIcon color={theme.palette.primary.main} />
                      )}
                    </ListItem>
                  ))}

                {versionViewMode === 'date' && (
                  <BoxCollapse
                    title={key || '--'}
                    content={
                      <>
                        {_map(releaseList[key], (value2: any, key2: string) => (
                          <Box sx={{ marginLeft: 2 }} key={key.toString()}>
                            {_size(_get(value2, 'assets')) > 1 ? (
                              <BoxCollapse
                                title={key2 || '--'}
                                content={
                                  <>
                                    {_map(
                                      _get(value2, 'assets'),
                                      (downloadPackage: any, downloadPackageIdx: number) => (
                                        <ListItem
                                          key={downloadPackageIdx.toString()}
                                          button
                                          selected={
                                            `${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                            `${_get(downloadPackage, `displayName`)}${_get(downloadPackage, `md5`)}`
                                          }
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                          }}
                                          onClick={() => {
                                            setSelectedAsset(downloadPackage)
                                          }}
                                        >
                                          <Typography variant="body2" color="textSecondary">
                                            {_get(downloadPackage, 'displayName') || '--'}
                                          </Typography>
                                          {`${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                            `${_get(downloadPackage, `displayName`)}${_get(
                                              downloadPackage,
                                              `md5`
                                            )}` && <CheckDoneIcon color={theme.palette.primary.main} />}
                                        </ListItem>
                                      )
                                    )}
                                  </>
                                }
                              />
                            ) : (
                              <ListItem
                                button
                                selected={
                                  `${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                  `${_get(value2, `assets.0.displayName`)}${_get(value2, `assets.0.md5`)}`
                                }
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                onClick={() => {
                                  setSelectedAsset(_get(value2, 'assets.0'))
                                }}
                              >
                                {_get(value2, 'assets.0.semVer')}
                                {`${_get(selectedAsset, `displayName`)}${_get(selectedAsset, `md5`)}` ===
                                  `${_get(value2, `assets.0.displayName`)}${_get(value2, `assets.0.md5`)}` && (
                                  <CheckDoneIcon color={theme.palette.primary.main} />
                                )}
                              </ListItem>
                            )}
                          </Box>
                        ))}
                      </>
                    }
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        </Card>
      </Grid>

      {showLatestVersionWarning ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.dark
              }}
            >
              This version is not the latest and might not have newest features.
            </Typography>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box minHeight="16px" />
        </Grid>
      )}

      {_includes([_get(data, 'id'), productId], PRODUCT_IDS.MD_ICAP) && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">Client Integration</Typography>
          <Controller
            name="allIntegrations"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                size="small"
                multiple
                options={allIntegrationData}
                freeSolo
                disableClearable
                value={value}
                getOptionLabel={(option: any) => _get(option, 'label', '--')}
                onChange={(event: any, newValue: any) => {
                  if (_size(selectedIntegrationsTags) <= _size(newValue)) {
                    onChange(newValue)
                  }
                }}
                getOptionDisabled={(option: any) =>
                  _includes(selectedIntegrationsTags, _get(option, 'label', '--')) ||
                  _includes(
                    _map(value, item => _get(item, 'label', '--')),
                    _get(option, 'label', '--')
                  )
                }
                renderTags={(tagValue: any, getTagProps) =>
                  _map(tagValue, (option: any, index: number) => {
                    if (_includes(selectedIntegrationsTags, _get(option, 'label', '--')))
                      return (
                        <ChipTag label={_get(option, 'label', option)} isNotClose={true} {...getTagProps({ index })} />
                      )
                    return <ChipTag label={_get(option, 'label', option)} {...getTagProps({ index })} />
                  })
                }
                renderInput={params => <TextField {...params} size="small" variant="outlined" />}
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {_map(assetAttributes, (item, idx) => (
          <React.Fragment key={idx.toString()}>
            <TextGrid
              label={<Typography variant="body2">{item.label}:</Typography>}
              value={<Typography variant="body1">{item.value}</Typography>}
              labelColProps={{ xs: 12, md: 2 }}
              valueColProps={{ xs: true }}
              boxProps={{
                minHeight: 'auto',
                mb: 1
              }}
            />
          </React.Fragment>
        ))}
      </Grid>

      {!_isEmpty(selectedAsset) && !containUndownloadableTag && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'outlined',
                  color: 'inherit',
                  onClick: () => handleLinkWGET(_get(selectedAsset, `link`, '')),
                  disabled: false,
                  startIcon: <CopySimpleIcon />
                }}
                propsLoading={{ color: 'primary' }}
                isLoading={false}
              >
                {isWGETCopied ? 'Copied' : 'WGET Link'}
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'outlined',
                  color: 'inherit',
                  onClick: () => handleLinkCURL(_get(selectedAsset, `link`, '')),
                  disabled: false,
                  startIcon: <CopySimpleIcon />
                }}
                propsLoading={{ color: 'primary' }}
                isLoading={false}
              >
                {isCURLCopied ? 'Copied' : 'CURL Link'}
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: false,
                  startIcon: <DownloadIcon color="white" />,
                  onClick: () => {
                    handleSubmit(onSuccess, onFail)()
                  },
                  sx: {
                    minWidth: '180px'
                  }
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Download
                {`${_get(selectedAsset, `size`) ? ` - ${_get(selectedAsset, `size`)}` : ''}`}
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default DownloadVersionForm
