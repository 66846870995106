import { KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const fileAPIQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_FILES_API_HOST,
  prepareHeaders: async headers => {
    const accessToken =
      process.env.REACT_APP_TOKEN_STORAGE === KEYS.COOKIE ? getCookie(KEYS.TOKEN_KEY) : getLocalStorage(KEYS.TOKEN_KEY)

    headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  }
})

export const fetchRequestFileAPIQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await fileAPIQuery(args, api, extraOptions)

  return result
}
