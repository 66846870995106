import { useMemo } from 'react'

import {
  CheckIcon,
  CheckedIcon,
  NotificationCautionaryIcon,
  NotificationNegativeIcon,
  NotificationPositiveIcon,
  NotificationInformation
} from '@opswat/react-icon'
import { createTheme } from '@opswat/react-ui'

interface IProps {
  isDarkMode?: boolean
}

export default function useThemeOpswatV3({ isDarkMode = false }: IProps) {
  const opswatTheme = useMemo(
    () =>
      createTheme({
        typography: {
          allVariants: {
            color: '#3D4A68',
            fontFamily: 'Roboto, sans-serif',
            letterSpacing: 0
          },
          h1: {
            fontSize: '17px',
            lineHeight: '32px',
            fontWeight: '500'
          },
          h2: {
            fontSize: '17px',
            lineHeight: '32px',
            fontWeight: '400'
          },
          h3: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500'
          },
          body1: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500'
          },
          body2: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400'
          },
          button: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500'
          },
          subtitle1: {
            fontSize: '13px',
            lineHeight: '16px',
            fontWeight: '500'
          },
          subtitle2: {
            fontSize: '13px',
            lineHeight: '16px',
            fontWeight: '400'
          },
          caption: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400'
          }
        },
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: () => ({
                borderRadius: 0
              })
            }
          },
          MuiTextField: {
            styleOverrides: {
              root: () => ({
                borderColor: '#1D6BFC',
                minHeight: '50px',
                '& label.Mui-focused': {
                  color: '#1D6BFC'
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#1D6BFC'
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#BDBDBD'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1D6BFC',
                    borderWidth: '1px'
                  },
                  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#CB0704',
                    '& .MuiFormHelperText-root': {
                      marginLeft: 0,
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: '400',
                      color: '#CB0704'
                    }
                  }
                },
                input: {
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400'
                },
                placeholder: {
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400'
                },
                '& textarea': {
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400'
                }
              })
            }
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: () => ({
                '&.Mui-error': {
                  marginLeft: 0,
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400',
                  color: '#CB0704'
                }
              })
            }
          },
          MuiTabs: {
            styleOverrides: {
              root: () => ({
                '& .Mui-disabled': {
                  display: 'none'
                },
                '& .Mui-selected': {
                  color: '#1B273C'
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#1B273C'
                }
              })
            }
          },
          MuiButton: {
            styleOverrides: {
              root: () => ({
                minHeight: '32px',
                borderRadius: 0,
                textTransform: 'capitalize',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '500',
                boxShadow: 'none',
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              })
            }
          },
          MuiChip: {
            styleOverrides: {
              root: () => ({
                borderRadius: 0,
                backgroundColor: '#E9EAEB',
                color: '#616875',
                path: {
                  fill: '#616875'
                }
              })
            }
          },
          MuiListItem: {
            styleOverrides: {
              root: () => ({
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              })
            }
          },
          MuiListItemButton: {
            styleOverrides: {
              root: () => ({
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                '& .MuiTouchRipple-root': {
                  display: 'none'
                },
                '&:hover': {
                  backgroundColor: '#E4E9F5'
                }
              })
            }
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: '#111F42',
                color: '#FFFFFF',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '400',
                '& .MuiTypography-root': {
                  marginBottom: 0
                }
              },
              arrow: {
                color: '#111F42'
              }
            }
          },
          MuiCheckbox: {
            defaultProps: {
              icon: <CheckIcon />,
              checkedIcon: <CheckedIcon />
            },
            styleOverrides: {
              root: () => ({
                padding: 0,
                marginTop: '2px',
                marginRight: '8px',
                marginLeft: '8px',
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              })
            }
          },
          MuiLink: {
            defaultProps: {
              underline: 'hover'
            }
          },
          MuiIconButton: {
            styleOverrides: {
              root: () => ({
                '&:hover': {
                  backgroundColor: 'transparent',
                  '& svg': {
                    '&:hover path': {
                      stroke: '#1D6BFC'
                    }
                  }
                },
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              })
            }
          },
          MuiBadge: {
            styleOverrides: {
              colorError: {
                backgroundColor: '#CB0704',
                color: '#fff'
              }
            }
          },
          MuiPaper: {
            styleOverrides: {
              root: () => ({
                '&.MuiAlert-filledError': {
                  backgroundColor: '#FAE6E5'
                }
              })
            }
          },
          MuiAlert: {
            defaultProps: {
              iconMapping: {
                info: <NotificationInformation />,
                success: <NotificationPositiveIcon />,
                error: <NotificationNegativeIcon />,
                warning: <NotificationCautionaryIcon />
              }
            },
            styleOverrides: {
              root: () => ({
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#3D4A68'
              })
            }
          },
          MuiAlertTitle: {
            styleOverrides: {
              root: () => ({
                fontSize: '14px',
                lineHeight: '20px'
              })
            }
          },
          MuiDialog: {
            styleOverrides: {
              root: () => ({
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(27, 39, 60, 0.8)'
                },
                '& .MuiPaper-root': {
                  boxShadow: 'none'
                }
              })
            }
          }
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 700,
            md: 1000,
            lg: 1400,
            xl: 1600
          }
        },
        palette: {
          mode: 'light',
          common: {
            black: '#000',
            white: '#fff'
          },
          primary: {
            light: '#0059C3',
            main: '#1D6BFC',
            dark: '#154FBA'
          },
          info: {
            light: '#E9ECF2',
            main: '#F4F4F5',
            dark: '#A2B2D2'
          },
          success: {
            light: '#04CB04',
            main: '#E5F3E5',
            dark: '#148B14'
          },
          error: {
            light: '#FAE6E5',
            main: '#FB211E',
            dark: '#CB0704'
          },
          warning: {
            light: '#FDE50D',
            main: '#FFF8E7',
            dark: '#FDB00D'
          },
          text: {
            primary: '#3D4A68',
            secondary: '#707EA4',
            disabled: '#A4A8AE'
          },
          background: {
            default: isDarkMode ? '#121212' : '#FFFFFF',
            paper: '#FFFFFF'
          },
          divider: '#E7E9EE'
        },
        shape: {
          borderRadius: 0
        }
      }),
    [isDarkMode]
  )

  return opswatTheme
}
