import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import { Box, Chip, TableAdvanced, TemplateSection, Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useFalseSubmissionsQuery } from 'myopswat-web/src/api/analyzer/falseSubmission'
import {
  FalseSubmission,
  FalseSubmissionCategoryEnum,
  FalseSubmissionStatusEnum,
  IFalseSubmissionFilter,
  IFalseSubmissionFilterInput
} from 'myopswat-web/src/api/analyzer/falseSubmission/types'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'
import { FC, useMemo, useState } from 'react'
import FalseSubmissionFilter from './FalseSubmissionFilter'

const FalseSubmissionHistoryTab: FC<unknown> = () => {
  const [query, setQuery] = useState<IFalseSubmissionFilterInput>({
    filters: {
      categories: [],
      validities: [],
      cxStatuses: [],
      inputTypes: []
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const { data: falseSubmissionData, isFetching: isFetchingFalseSubmissions } = useFalseSubmissionsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const falseSubmissions = useMemo(() => {
    if (falseSubmissionData) {
      return falseSubmissionData.results
    }
    return []
  }, [falseSubmissionData])

  const totalCount = useMemo(() => {
    if (_isEmpty(falseSubmissionData)) {
      return 0
    }
    return falseSubmissionData.totalCount
  }, [falseSubmissionData])

  const columns = useMemo(() => {
    return [
      {
        header: 'File Name / Hash',
        body: (data: any) => (
          <>
            <Typography
              color="primary"
              sx={{
                overflowWrap: 'anywhere'
              }}
              variant="body2"
            >
              {data.inputType === 'file' ? data.filename : data.hash || '--'}
            </Typography>
            {data.inputType === 'file' && (
              <>
                <Chip
                  label="File"
                  color="default"
                  size="small"
                  sx={{ mt: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.08)', color: '#3D4A68', borderRadius: '16px' }}
                />
                <Chip
                  label={humanFileSize(data.size)}
                  color="default"
                  size="small"
                  sx={{
                    mt: 0.5,
                    ml: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    color: '#3D4A68',
                    borderRadius: '16px'
                  }}
                />
              </>
            )}
            {data.inputType === 'hash' && (
              <Chip
                label="Hash"
                variant="outlined"
                color="error"
                size="small"
                sx={{ mt: 0.5, backgroundColor: 'transparent', color: '#FB211E', borderRadius: '16px' }}
              />
            )}
          </>
        ),
        style: { minWidth: 210, width: 210, pr: 2 }
      },
      {
        header: 'Hash',
        body: (data: any) => (
          <>
            {data.inputType === 'file' && (
              <>
                <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
                  MD5: {data.md5}
                </Typography>
                <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
                  SHA1: {data.sha1}
                </Typography>
                <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
                  SHA256: {data.sha256}
                </Typography>
              </>
            )}
            {data.inputType === 'hash' && (
              <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
                {data.hash}
              </Typography>
            )}
          </>
        ),
        style: { minWidth: 360, width: 360, pr: 2 }
      },
      {
        header: 'Category',
        body: (data: FalseSubmission) => (
          <Typography variant="body2">{FalseSubmissionCategoryEnum[data.category]}</Typography>
        ),
        style: { minWidth: 110, width: 110 }
      },
      {
        header: 'Submitted At',
        body: (data: any) => (
          <Typography variant="body2">{formatDatetime(data?.createdAt, DATE_FORMATS.DATE_TIME)}</Typography>
        ),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Products',
        body: (data: any) => (
          <Typography variant="body2">{data?.productNames ? data?.productNames.join(', ') : '--'}</Typography>
        ),
        style: { minWidth: 150, width: 150 }
      },
      {
        header: 'Status',
        body: (data: FalseSubmission) => (
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {FalseSubmissionStatusEnum[data.cxStatus]}
          </Typography>
        ),
        style: { minWidth: 120, width: 120 }
      },
      {
        header: 'Analysis Summary',
        body: (data: FalseSubmission) => (
          <Tooltip title={data.analystNote} arrow>
            <Box>
              <TypographyLineClamp line={3} variant="body2">
                {data.analystNote}
              </TypographyLineClamp>
            </Box>
          </Tooltip>
        ),
        style: { minWidth: 90 }
      }
    ]
  }, [])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery((statePrev: any) => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const handleSearch = (searchData: IFalseSubmissionFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  return (
    <TemplateSection>
      <FalseSubmissionFilter filters={query.filters} onSearch={handleSearch} />
      <TableAdvanced
        columns={columns}
        isLoading={isFetchingFalseSubmissions}
        data={falseSubmissions}
        isPagination
        total={totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}
export default FalseSubmissionHistoryTab
