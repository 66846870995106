import _debounce from 'lodash/debounce'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import {
  FalseSubmissionCategories,
  FalseSubmissionInputTypes,
  FalseSubmissionStatuses,
  IFalseSubmissionFilter
} from 'myopswat-web/src/api/analyzer/falseSubmission/types'
import { useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'

interface FilterSectionProps {
  filters: IFalseSubmissionFilter
  onSearch: (searchData: IFalseSubmissionFilter) => void
}

const FalseSubmissionFilter = ({ filters, onSearch }: FilterSectionProps) => {
  const {
    control,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm<IFalseSubmissionFilter>({
    mode: 'onChange',
    defaultValues: filters
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields])

  const q = useWatch({
    control: control,
    name: 'q'
  })

  const onSuccess = () => {
    const newObj = _omit(getValues(), 'all')

    onSearch({
      ...newObj,
      q: q
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          ...getValues(),
          q: q
        }

        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  const handleSelectCheckbox = (current: string[], value: string, checked: boolean) => {
    let newStatus
    if (checked) {
      newStatus = [...current, value]
    } else {
      newStatus = current.filter(s => s !== value)
    }
    return newStatus
  }

  useEffect(() => {
    if (watch('all') === true) {
      reset()
    }
  }, [watch('all')])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextFieldSearch
              placeholder={'Search by file name or hash...'}
              value={value}
              onChange={e => onChange(e.target.value)}
              onClearText={() => onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Type</Typography>
                      </Grid>
                      {_map(FalseSubmissionInputTypes, (item: any, idx: any) => (
                        <Grid item xs={12} key={idx.toString()}>
                          <Controller
                            name={'inputTypes'}
                            control={control}
                            render={({ field }) => (
                              <CheckboxWithLabel
                                label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                checkboxProps={{
                                  checked: field.value.includes(item.value),
                                  onChange: (e: any) => {
                                    field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                    setValue('all', false)
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </>
                    <>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Status</Typography>
                      </Grid>
                      {_map(FalseSubmissionStatuses, (item: any, idx: any) => (
                        <Grid item xs={12} key={idx.toString()}>
                          <Controller
                            name={'cxStatuses'}
                            control={control}
                            render={({ field }) => (
                              <CheckboxWithLabel
                                label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                checkboxProps={{
                                  checked: field.value.includes(item.value),
                                  onChange: (e: any) => {
                                    field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                    setValue('all', false)
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Category</Typography>
                      </Grid>
                      {_map(FalseSubmissionCategories, (item: any, idx: any) => (
                        <Grid item xs={12} key={idx.toString()}>
                          <Controller
                            name={'categories'}
                            control={control}
                            render={({ field }) => (
                              <CheckboxWithLabel
                                label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                checkboxProps={{
                                  checked: field.value.includes(item.value),
                                  onChange: (e: any) => {
                                    field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                    setValue('all', false)
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
          onResetFilter={() => {
            setValue('all', true)
          }}
          onResetChange={() => console.log()}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
          propsPaper={{ sx: { maxWidth: '400px' } }}
        />
      </Grid>
    </Grid>
  )
}

export default FalseSubmissionFilter
