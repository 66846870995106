import { ChevronDownIcon, ChevronUpIcon } from '@opswat/react-icon'
import { Box, TypographyLink } from '@opswat/react-ui'
import { useMemo } from 'react'

interface IProps {
  open: boolean
  count?: number
  onClick: () => void
}
const CollapseAction = ({ open, count, onClick }: IProps) => {
  const label = useMemo(() => {
    return open ? `Show less` : `Show ${count} more`
  }, [open])

  const Icon = useMemo(() => {
    return open ? ChevronUpIcon : ChevronDownIcon
  }, [open])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={onClick}
    >
      <Icon color="#2672fb" size={32} />
      <TypographyLink
        variant="subtitle2"
        alignSelf="center"
        color="primary"
        sx={{
          textDecoration: 'none'
        }}
      >
        {label}
      </TypographyLink>
    </Box>
  )
}

export default CollapseAction
