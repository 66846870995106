import { handleClearCookie, handleGetParamsURL } from '@myopswat/common'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { useOrganizationAcceptInvitationMutation } from 'myopswat-web/src/api/organization'
import { REDIRECT_MESSAGE_CODE } from 'myopswat-web/src/constants'
import { USER_ACCEPT_INVITE_ERRORS, USER_ACCEPT_INVITE_ERROR_MESSAGES } from 'myopswat-web/src/constants/errors'
import OrganizationInviteAcceptPage from 'myopswat-web/src/pages/OrganizationInviteAcceptPage'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginPageURL, notFoundPageUrl } from 'myopswat-web/src/routes'

const ERROR_CODE_PREFIX = 'AE_'

const OrganizationAcceptInvitationContainer = () => {
  const navigate = useNavigate()

  const searchParams = handleGetParamsURL()
  const invitationId = searchParams?.get('id') || ''

  const [organizationAcceptInvitation] = useOrganizationAcceptInvitationMutation()

  const handleAcceptInvitation = async () => {
    try {
      const res = await organizationAcceptInvitation({
        invitationId: invitationId
      }).unwrap()
      // Force logout user
      handleClearCookie()
      if (res.success) {
        navigate(`${loginPageURL}?code=${REDIRECT_MESSAGE_CODE.ACCEPT_INVITE_SUCCESS}`)
      } else {
        const errorCode = `${ERROR_CODE_PREFIX}${_get(res.errors, '0.code')}`
        const redirectErrorCode = _has(USER_ACCEPT_INVITE_ERROR_MESSAGES, errorCode)
          ? errorCode
          : USER_ACCEPT_INVITE_ERRORS.DEFAULT
        navigate(`${loginPageURL}?code=${redirectErrorCode}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (invitationId) {
      handleAcceptInvitation()
    } else {
      navigate(notFoundPageUrl)
    }
  }, [])

  return <OrganizationInviteAcceptPage />
}

export default OrganizationAcceptInvitationContainer
