export const ONPREMISE = 'on-premise'
export const OEM = 'oem'
export const UTILITY = 'utility'
export const CLOUDBASE = 'cloud-based'
export const EVALUABLE = 'evaluable'
export const UNDOWNLOADABLE = 'undownloadable'

export const ONPREMISE_OEM_TAGS = [ONPREMISE, OEM]
export const ONPREMISE_OEM_CLOUDBASE_TAGS = [ONPREMISE, OEM, CLOUDBASE]
export const ONPREMISE_OEM_UTILITY_TAGS = [ONPREMISE, OEM, UTILITY]
