import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import i18n from './languages/i18n'
import CustomThemeProvider from './providers/CustomThemeProvider'
import reportWebVitals from './reportWebVitals'
import { store } from './store'

declare global {
  interface Window {
    recaptchaOptions: any
    botpress: any
    _mtm: any
    _paq: any
  }
}

// Config to use recaptcha.net instead of www.google.com because it was blocked in China
window.recaptchaOptions = {
  useRecaptchaNet: true
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <CustomThemeProvider>
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  </CustomThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
