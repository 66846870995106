import { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { Box, DialogAdvanced, TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { ILicenseActivateInput, ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'

import { isEnabledPOCFeatures } from 'myopswat-web/src/constants'
import {
  iframeLicensedProductsPageURL,
  licensedProductsCloudBasedPageURL,
  licensedProductsEvaluatedPageURL,
  licensedProductsPurchasedPageURL
} from 'myopswat-web/src/routes'
import CloudBasedProduct from './CloudBasedProduct'
import BoxCommonDialogActivate from './CommonProduct/BoxCommonDialogActivate'
import EvaluatedProduct from './EvaluatedProduct'
import PurchasedProduct from './PurchasedProduct'

interface IProps {
  isLoadingLicenseActivate: boolean
  handleLicenseActivate: (data: ILicenseActivateInput) => void
  licenseActivateData: any
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
}

const LicensedProductsPage: FC<IProps> = ({
  isLoadingLicenseActivate,
  handleLicenseActivate,
  licenseActivateData,
  setCsvFilter
}) => {
  const [step, setStep] = useState<'activate' | 'download'>('activate')

  const [isActivate, setIsActivate] = useState<boolean>(false)
  const [itemActivate, setItemActivate] = useState<any>()

  const handleCheckRouteNotLayout = () => {
    if (_includes(location.pathname, iframeLicensedProductsPageURL)) return true
    return false
  }

  const tabArray = [
    {
      label: 'Evaluation',
      content: (
        <EvaluatedProduct setIsActivate={setIsActivate} setItemActivate={setItemActivate} setCsvFilter={setCsvFilter} />
      ),
      tabPath: licensedProductsEvaluatedPageURL
    },
    {
      label: 'Purchased',
      content: (
        <PurchasedProduct setIsActivate={setIsActivate} setItemActivate={setItemActivate} setCsvFilter={setCsvFilter} />
      ),
      tabPath: licensedProductsPurchasedPageURL
    },
    {
      label: 'Cloud Services',
      content: <CloudBasedProduct />,
      tabPath: licensedProductsCloudBasedPageURL
    }
  ]

  useEffect(() => {
    if (_get(licenseActivateData, 'success')) {
      setStep('download')
    }
  }, [licenseActivateData])

  useEffect(() => {
    if (!isActivate) {
      setStep('activate')
    }
  }, [isActivate])

  const handleRenderLayout = () => {
    if (handleCheckRouteNotLayout() && isEnabledPOCFeatures)
      return (
        <PurchasedProduct setIsActivate={setIsActivate} setItemActivate={setItemActivate} setCsvFilter={setCsvFilter} />
      )

    return <TabAdvanced tabs={tabArray} />
  }

  return (
    <>
      <Box>
        <TypographyDivider label="My Licenses" />
      </Box>

      {handleRenderLayout()}

      <DialogAdvanced
        open={isActivate}
        title="Activate License"
        content={
          <Box sx={{ minHeight: '400px' }}>
            <BoxCommonDialogActivate
              data={itemActivate}
              isLoadingLicenseActivate={isLoadingLicenseActivate}
              handleLicenseActivate={handleLicenseActivate}
              setOpenDialog={setIsActivate}
              step={step}
              licenseActivateData={licenseActivateData}
            />
          </Box>
        }
        dialogProps={{
          maxWidth: 'xs'
        }}
      />
    </>
  )
}

export default LicensedProductsPage
