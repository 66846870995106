import React, { FC, useState, useEffect } from 'react'

import { Box, Typography, Grid, ButtonLoading, CommonOTP, TypographyLink } from '@opswat/react-ui'
import { autoFocusInput } from '@opswat/react-core'

interface IProps {
  isLoadingLogin: boolean
  handleVerifyOTP: (data: string) => void
  setStep: (data: 'otp' | 'code') => void
}

const StepOTP: FC<IProps> = ({ isLoadingLogin, handleVerifyOTP, setStep }) => {
  const [otpString, setOtpString] = useState<string>('')

  const handleSubmit = () => {
    handleVerifyOTP(otpString)
  }

  useEffect(() => {
    autoFocusInput()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        minHeight: '500px',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Multifactor Verification</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Enter the code from the authenticator application</Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={9}>
          <CommonOTP handleSubmit={handleSubmit} getValue={setOtpString} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Can not login? Use&nbsp;
            <TypographyLink variant="subtitle2" onClick={() => setStep('code')}>
              recovery code
            </TypographyLink>
            &nbsp;you previously downloaded, or&nbsp;
            <TypographyLink variant="subtitle2" href="mailto:login-issues@opswat.com" target="_blank">
              email
            </TypographyLink>
            ,&nbsp;
            <TypographyLink
              variant="subtitle2"
              href={`${process.env.REACT_APP_OPSWAT_URL}/support/contact-support`}
              target="_blank"
            >
              phone
            </TypographyLink>
            &nbsp;to us.
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: handleSubmit,
            disabled: isLoadingLogin || !otpString,
            fullWidth: true
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isLoadingLogin}
        >
          Sign In
        </ButtonLoading>
      </Box>
    </Box>
  )
}

export default StepOTP
