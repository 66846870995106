import { formatDatetime } from '@opswat/react-core'
import { Box, Collapse, Typography } from '@opswat/react-ui'
import { DatabaseVersion } from 'myopswat-web/src/api/engine-status/types'
import { useMemo, useState } from 'react'
import CollapseAction from './CollapseAction'

const MAIN_ITEMS_DEFAULT = 3
const DB_VERSION_DATE_FORMAT = 'MM/dd'

interface IProps {
  list: DatabaseVersion[]
}

const DatabaseVersionView = ({ list }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const mainItems = useMemo(() => {
    // View 3 first items
    return list.slice(0, MAIN_ITEMS_DEFAULT)
  }, [list])

  const extendItems = useMemo(() => {
    return list.slice(MAIN_ITEMS_DEFAULT)
  }, [list])

  return (
    <Box>
      {mainItems &&
        mainItems.length > 0 &&
        mainItems.map((dbVersion, idx) => {
          return (
            <Typography variant="subtitle2" key={`engine-status-db-version-main-${idx}`}>
              {dbVersion.version} ({formatDatetime(dbVersion.createdTime, DB_VERSION_DATE_FORMAT)})
            </Typography>
          )
        })}
      {extendItems && extendItems.length > 0 && (
        <>
          {!open && <CollapseAction open={open} onClick={() => setOpen(!open)} count={extendItems.length} />}

          <Collapse in={open} timeout="auto" unmountOnExit>
            {extendItems.map((dbVersion, idx) => {
              return (
                <Typography variant="subtitle2" key={`engine-status-db-version-extend-${idx}`}>
                  {dbVersion.version} ({formatDatetime(dbVersion.createdTime, DB_VERSION_DATE_FORMAT)})
                </Typography>
              )
            })}
          </Collapse>
          {open && <CollapseAction open={open} onClick={() => setOpen(!open)} />}
        </>
      )}
    </Box>
  )
}

export default DatabaseVersionView
