import React, { FC } from 'react'

import { Skeleton, Box, TemplateSection, Card } from '@opswat/react-ui'

const ProductSkeleton: FC<unknown> = () => {
  return (
    <Card variant="outlined">
      <TemplateSection spacing={1}>
        <Skeleton animation="wave" variant="rectangular" width="100%" height="20vh" />

        <Box p={1}>
          <Skeleton animation="wave" variant="text" />
          <Skeleton animation="wave" variant="text" width="85%" />
          <Skeleton animation="wave" variant="text" width="40%" />
        </Box>
      </TemplateSection>
    </Card>
  )
}

export default ProductSkeleton
