import { Autocomplete, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'
import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import { OrganizationEditForm } from 'myopswat-web/src/api/organization/types'
import { useSysItemRegionsQuery } from 'myopswat-web/src/api/system'
import TextGridView from '../TextGridView'

interface IProps {
  formRef: UseFormReturn<OrganizationEditForm>
  name: 'billingAddress' | 'shippingAddress'
  title: string
  countryMap: any
}

const AddressForm = ({ name, title, formRef, countryMap }: IProps) => {
  const {
    register,
    control,
    setValue,
    formState: { errors }
  } = formRef

  const selectedCountry = useWatch({
    control: control,
    name: `${name}.country`
  })

  const selectedCountryId = useMemo(() => {
    if (selectedCountry) return countryMap[selectedCountry].id
    return null
  }, [selectedCountry])

  const hasSateFlg = useMemo(() => {
    return selectedCountry && selectedCountry === 'US'
  }, [selectedCountry])

  const { data: states } = useSysItemRegionsQuery(selectedCountryId, {
    skip: !hasSateFlg,
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {
    if (!hasSateFlg) setValue(`${name}.state`, '')
  }, [hasSateFlg])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1" paragraph>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextGridView
          label="Address 1*"
          value={
            <TextField
              fullWidth
              placeholder="Enter address details"
              error={_has(errors, `${name}.streetAddress1`)}
              helperText={_get(errors, `${name}.streetAddress1.message`)}
              size="small"
              {...register(`${name}.streetAddress1`)}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextGridView
          label="Address 2"
          value={
            <TextField
              fullWidth
              placeholder="Optional"
              error={_has(errors, `${name}.streetAddress2`)}
              helperText={_get(errors, `${name}.streetAddress2.message`)}
              size="small"
              {...register(`${name}.streetAddress2`)}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextGridView
          label="City*"
          value={
            <TextField
              required
              fullWidth
              placeholder="Enter city"
              error={_has(errors, `${name}.city`)}
              helperText={_get(errors, `${name}.city.message`)}
              size="small"
              {...register(`${name}.city`)}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextGridView
          label="Country*"
          value={
            <Controller
              name={`${name}.country`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={Object.keys(countryMap)}
                  autoSelect
                  value={field.value}
                  onChange={(_event: any, newValue: any) => {
                    field.onChange(newValue)
                  }}
                  getOptionLabel={(option: any) => {
                    return _get(countryMap, `${option}.name`, '')
                  }}
                  isOptionEqualToValue={(option: any, value) => option == value}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder="Select your country"
                      error={_has(errors, `${name}.country`)}
                      helperText={_get(errors, `${name}.country.message`)}
                    />
                  )}
                />
              )}
            />
          }
        />
      </Grid>
      {hasSateFlg && (
        <Grid item xs={12}>
          <TextGridView
            label="State"
            value={
              <Controller
                name={`${name}.state`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={states || []}
                    autoSelect
                    value={field.value}
                    onChange={(_event: any, newValue: any) => {
                      if (newValue && typeof newValue !== 'string') {
                        field.onChange(newValue.name)
                      } else {
                        field.onChange('')
                      }
                    }}
                    getOptionLabel={(option: any) => {
                      return typeof option === 'string' ? option : option.name
                    }}
                    isOptionEqualToValue={(option: any, value) => _isEqual(option.name, value)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select your state"
                        size="small"
                        variant="outlined"
                        error={_has(errors, `${name}.state`)}
                        helperText={_get(errors, `${name}.state.message`)}
                      />
                    )}
                  />
                )}
              />
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextGridView
          label="Postal Code*"
          value={
            <TextField
              required
              fullWidth
              placeholder="Enter postal code"
              error={_has(errors, `${name}.postalCode`)}
              helperText={_get(errors, `${name}.postalCode.message`)}
              size="small"
              {...register(`${name}.postalCode`)}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextGridView
          label="Phone Number*"
          value={
            <TextField
              required
              fullWidth
              placeholder="Enter phone number"
              error={_has(errors, `${name}.phone`)}
              helperText={_get(errors, `${name}.phone.message`)}
              size="small"
              {...register(`${name}.phone`)}
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default AddressForm
