import { FC, useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _last from 'lodash/last'
import _map from 'lodash/map'

import { CloseIcon, OptionIcon } from '@opswat/react-icon'
import {
  Box,
  CircularLoading,
  DialogAdvanced,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { useLazyLicenseKeyEnginesQuery } from 'myopswat-web/src/api/product'
import { ILicenseNoteAddInput, ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'
import BoxCollapse from 'myopswat-web/src/components/Box/BoxCollapse'
import { useTypedSelector } from 'myopswat-web/src/store'
import BoxCommonDialogNote from './BoxCommonDialogNote'

interface IProps {
  data?: any
  type: 'evaluations' | 'purchased'
  product: string | undefined
  productId: string | undefined
  productSlug: string | undefined
  isLoadingLicenseNoteAdd: boolean
  handleLicenseNoteAdd: (data: ILicenseNoteAddInput) => void
  setIsActivate: (data: boolean) => void
  setItemActivate: (data: any) => void
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
}

const BoxCommonDialogHistory: FC<IProps> = ({
  data,
  product,
  type,
  productId,
  productSlug,
  isLoadingLicenseNoteAdd,
  handleLicenseNoteAdd,
  setIsActivate,
  setItemActivate,
  setCsvFilter
}) => {
  const theme = useTheme()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}

  const [item, setItem] = useState<any>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [getLicenseKeyEngines, { data: licenseKeyEngineData, isFetching: isFetchingLicenseKeyEngine }] =
    useLazyLicenseKeyEnginesQuery()

  useEffect(() => {
    if (data) {
      const engine_query = data.map((item: any) => {
        return {
          activationKey: item.activation_key,
          activatedDate: item.activated_date,
          productId: productId,
          platform: item.platform,
          enginePackageNames: item.engine_package_names,
          sku: item.sku,
          options: item.options
        }
      })
      getLicenseKeyEngines(engine_query)
    }
  }, [data])

  const licenseKeyEngineMap = useMemo(() => {
    if (licenseKeyEngineData) {
      const results: any = {}
      licenseKeyEngineData.forEach((activationItem: any) => {
        let value: any = []
        if (activationItem.type === 'sku' && activationItem.isAllEngine && activationItem.groupedEngines.length === 1) {
          value.push({
            name: 'All Engines',
            description: 'All Engines', // activationItem.groupedEngines[0].description,
            engines: ''
          })
        } else {
          const engineBundles = activationItem.groupedEngines.map((engineBundle: any) => ({
            name: engineBundle.name,
            description:
              activationItem.type === 'engine_package'
                ? engineBundle.description
                : engineBundle.engines.length
                ? `${engineBundle.engines.length} Engine[s]`
                : 'The license has no description found.',
            engines: engineBundle.engines
          }))
          value = engineBundles
          if (activationItem.addedEngines.length > 0) {
            if (activationItem.type === 'sku' && value.length === 1) {
              value[0].engines = value[0].engines.concat(activationItem.addedEngines)
              value[0].engines = value[0].engines.filter(
                (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.name === value.name)
              )
              value[0].description = `${value[0].engines.length} Engine[s] [Custom]`
            } else {
              const addedEngines = [
                {
                  name: 'Custom Engines',
                  description: 'Custom Engines',
                  engines: activationItem.addedEngines
                }
              ]
              value = value.concat(addedEngines)
            }
          }
          value.forEach((engineBundle: any) => {
            engineBundle.engines = engineBundle.engines.map((engine: any) => engine.name).join('; ')
          })
        }
        results[`${activationItem.activationKey}_${activationItem.platform}_${activationItem.activatedDate}`] = value
      })
      return results
    }
    return {}
  }, [licenseKeyEngineData])

  const handleDownloadCSV = (id: string, data: any) => {
    const skusArray =
      _get(data, 'type') === 'sku' ? [_get(data, 'sku')] : _map(_get(data, 'products'), item => _get(item, 'sku'))
    const params = {
      key: _get(data, 'activation_key'),
      productId: id,
      skus: skusArray
    }

    setCsvFilter(params)
  }

  const handleCheckEvaluationsStatus = (data: any) => {
    if (!data) return null

    if (_get(data, 'days') >= 7) return <Typography color="success.dark">Active</Typography>
    if (_get(data, 'days') < 7 && _get(data, 'days') >= 0) return <Typography color="error.dark">Expiring</Typography>
    if (_get(data, 'days') < 0) return <Typography color="error.dark">Expired</Typography>

    return null
  }

  const handleCheckPurchasedStatus = (data: any) => {
    if (!data) return null

    if (_get(data, 'days') >= 90) return <Typography color="success.dark">Active</Typography>
    if (_get(data, 'days') < 90 && _get(data, 'days') >= 30)
      return <Typography color="warning.dark">Expiring</Typography>
    if (_get(data, 'days') < 30 && _get(data, 'days') >= 0) return <Typography color="error.dark">Expiring</Typography>
    if (_get(data, 'days') < 0) return <Typography color="error.dark">Expired</Typography>

    return null
  }

  const handleCheckAction = (data: any) => {
    if (!data) return null

    if (_get(data, 'days') < 0)
      return (
        <Dropdown
          button={
            <IconButton>
              <OptionIcon />
            </IconButton>
          }
          content={
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ display: 'block' }}
                  onClick={() => {
                    setOpenDialog(true)
                    setItem(data)
                  }}
                >
                  Edit note
                </ListItemButton>
              </ListItem>
            </List>
          }
          propsPopper={{ sx: { zIndex: 1301 } }}
        />
      )

    return (
      <Dropdown
        button={
          <IconButton>
            <OptionIcon />
          </IconButton>
        }
        content={
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ display: 'block' }}
                onClick={() => {
                  setIsActivate(true)
                  setItemActivate({
                    product,
                    productId,
                    productSlug,
                    key: _get(data, 'activation_key')
                  })
                }}
              >
                Activate License
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                sx={{ display: 'block' }}
                onClick={() => {
                  setOpenDialog(true)
                  setItem(data)
                }}
              >
                Edit note
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDownloadCSV(productId || '', data)}>
                Download CSV Report
              </ListItemButton>
            </ListItem>
          </List>
        }
        propsPopper={{ sx: { zIndex: 1301 } }}
      />
    )
  }

  const handleDeployedPurchased = (deployed: number | string, purchased: number | string) => {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="body2" sx={{ minWidth: '70px' }} align="right">
          {deployed}
        </Typography>
        <Typography>/</Typography>
        <Typography variant="body2" sx={{ minWidth: '70px' }} align="left">
          {purchased}
        </Typography>
      </Box>
    )
  }

  const handleExpireDate = (date: string, days: number) => {
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE, _get(profileData, 'timezone') || '')

    if (_includes(renderDate, '2100')) return <Typography variant="body2">Perpetual</Typography>
    return (
      <Typography variant="body2">
        {renderDate}
        {days > 0 ? ` - ${days} days` : ''}
      </Typography>
    )
  }

  if (isFetchingLicenseKeyEngine) {
    return (
      <Box
        sx={{
          minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularLoading type="large" color="primary" />
      </Box>
    )
  }

  return (
    <>
      <TableContainer component={Box}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 165, width: { lg: 320 } }}
              >
                <Typography>License Key</Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0.25,
                  pl: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  minWidth: 200,
                  width: { lg: 530 }
                }}
              >
                <Typography>Description</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 120, width: { lg: 120 } }}
              >
                <Typography align="center">Status</Typography>
              </TableCell>
              <TableCell
                sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 160, width: { lg: 160 } }}
              >
                <Typography>Activate Time</Typography>
              </TableCell>
              <TableCell
                sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 90, width: { lg: 150 } }}
              >
                <Typography align="center">Deployed/Purchased</Typography>
              </TableCell>
              <TableCell sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 66 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_map(data, (item, idx) => (
              <TableRow
                key={idx.toString()}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.grey[50]
                  },
                  '&:last-child td, &:last-child th': {
                    border: 0
                  }
                }}
              >
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 165,
                    width: { lg: 320 }
                  }}
                >
                  <Typography variant="body2">{_get(item, 'activation_key', '--')}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    pr: 0.25,
                    py: 1,
                    pl: 1,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 200,
                    width: { lg: 530 }
                  }}
                >
                  {'activation_key' in item &&
                    'platform' in item &&
                    'activated_date' in item &&
                    `${item['activation_key']}_${item['platform']}_${item['activated_date']}` in licenseKeyEngineMap &&
                    _map(
                      _get(
                        licenseKeyEngineMap,
                        `${item['activation_key']}_${item['platform']}_${item['activated_date']}`
                      ),
                      engineBundle =>
                        engineBundle.engines ? (
                          <BoxCollapse
                            defaultOpen={false}
                            title={
                              <Typography sx={{ width: { lg: 460, md: 300 } }}>{engineBundle.description}</Typography>
                            }
                            boxProps={{ sx: { py: 0.25, gap: 0.5 } }}
                            content={
                              <Box sx={{ pt: 0.25, pb: 1, pl: 2.5 }}>
                                <Typography variant="body2" sx={{ width: { lg: 460, md: 300 } }}>
                                  {engineBundle.engines}
                                </Typography>
                              </Box>
                            }
                          />
                        ) : (
                          <Typography sx={{ width: { lg: 460, md: 300 }, pl: 2.5, py: 0.5 }}>
                            {engineBundle.description}
                          </Typography>
                        )
                    )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 120,
                    width: { lg: 120 }
                  }}
                >
                  {type === 'evaluations' ? handleCheckEvaluationsStatus(item) : handleCheckPurchasedStatus(item)}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 160,
                    width: { lg: 160 }
                  }}
                >
                  {handleExpireDate(_get(item, 'expired_date'), _get(item, 'days'))}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 90,
                    width: { lg: 150 }
                  }}
                >
                  {handleDeployedPurchased(_get(item, 'deployed_quantity'), _get(item, 'purchased_quantity'))}
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 66,
                    textAlign: 'right'
                  }}
                >
                  {handleCheckAction(item)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogAdvanced
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        iconClose={<CloseIcon />}
        title="Note"
        content={
          <BoxCommonDialogNote
            productId={productId}
            licenseKey={_get(data, 'activation_key') || _get(item, 'activation_key')}
            content={_get(_last(_get(item, 'notes')), 'content') || ''}
            isLoadingLicenseNoteAdd={isLoadingLicenseNoteAdd}
            handleLicenseNoteAdd={handleLicenseNoteAdd}
            setOpenDialog={setOpenDialog}
          />
        }
        dialogProps={{
          maxWidth: 'xs'
        }}
      />
    </>
  )
}

export default BoxCommonDialogHistory
