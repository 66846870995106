import { FC, useContext } from 'react'

import _get from 'lodash/get'
import _template from 'lodash/template'

import { Controller, ControllerRenderProps, useWatch } from 'react-hook-form'

import { Grid, Typography } from '@opswat/react-ui'

import { DialogNPSSurveyContext } from '../../Dialog/DialogNPSSurvey/interface'
import { NPSCheckbox, NPSDropdown, NPSIdentity, NPSScore, NPSText } from './components'

interface IProps {
  question: any
}

const NPSFieldController: FC<IProps> = ({ question }) => {
  const { control } = useContext(DialogNPSSurveyContext)

  const questionId = _get(question, 'id', '')
  const questionTitle = _get(question, 'title', '')
  const required = _get(question, 'required', false)
  const maxLength = _get(question, 'maxLength')
  const values = useWatch({
    control
  })

  const getTitle = (title: any) => {
    const relatedTo = _get(question, 'relatedTo')
    if (!relatedTo) {
      return title
    }

    const relatedDefault = _get(question, 'relatedDefault', '')
    let replaceValue = _get(values, relatedTo) || relatedDefault
    if (typeof replaceValue === 'object') {
      replaceValue = replaceValue.label
    }
    const template = _template(title)
    return template({ ['value']: replaceValue })
  }

  const renderFormField = (question: any, field: ControllerRenderProps) => {
    switch (question.type) {
      case 'identity':
        return <NPSIdentity question={question} />
      case 'dropdown':
        return (
          <NPSDropdown
            {...field}
            groupByField="groupName"
            options={question.data}
            defaultValue={question.defaultValue}
          />
        )
      case 'score':
        return <NPSScore {...field} />
      case 'text':
        return <NPSText {...field} placeholder={question.placeholder} />
      case 'checkbox':
        return <NPSCheckbox {...field} label={getTitle(questionTitle)} />
      default:
        return <></>
    }
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      {!['checkbox', 'identity'].includes(question.type) && (
        <Typography component="label">
          {getTitle(questionTitle)}
          <Typography component="span" color="error">
            {required && '*'}
          </Typography>
        </Typography>
      )}
      <Controller
        name={questionId}
        control={control}
        rules={{
          required,
          maxLength
        }}
        render={({ field }) => renderFormField(question, field)}
      />
    </Grid>
  )
}

export default NPSFieldController
