import { TemplateSection } from '@opswat/react-ui'
import _get from 'lodash/get'
import { ILicenseNoteAddInput, ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'
import { FC, useCallback, useEffect, useState } from 'react'

import { useLazyLicensedProductsQuery, useLicenseNoteAddMutation } from 'myopswat-web/src/api/product'
import { useTypedSelector } from 'myopswat-web/src/store'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import BoxEvaluationsFilter from './BoxEvaluationsFilter'
import BoxEvaluationsTable from './BoxEvaluationsTable'

interface IProps {
  setIsActivate: (data: boolean) => void
  setItemActivate: (data: any) => void
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
}

const BoxEvaluations: FC<IProps> = ({ setIsActivate, setItemActivate, setCsvFilter }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [evaluatedFilter, setEvaluatedFilter] = useState<any>()
  const [licensedProductsData, setLicensedProductsData] = useState<any>({})
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const [licenseNoteAdd, { data: licenseNoteAddData, isLoading: isLoadingLicenseNoteAdd, error: licenseNoteAddError }] =
    useLicenseNoteAddMutation()
  const [getLicensedProducts, { isFetching: isFetchingLicensedProducts }] = useLazyLicensedProductsQuery()

  const fetchData = useCallback(async () => {
    try {
      const data = await getLicensedProducts(evaluatedFilter).unwrap()
      setLicensedProductsData(data)
    } catch (error) {
      if (_get(error, 'errors.0.code') !== 'invalid_token' && _get(error, 'errors.0.codeName') !== 'JWT_INVALID') {
        enqueueSnackbar(_get(error, 'errors.0.message'), { variant: 'error' })
      }
    }
  }, [getLicensedProducts, evaluatedFilter])

  const handleLicenseNoteAdd = useCallback(async (data: ILicenseNoteAddInput) => {
    try {
      await licenseNoteAdd({
        licenseKey: _get(data, 'licenseKey', ''),
        productId: _get(data, 'productId', ''),
        content: _get(data, 'content', ''),
        organizationId: _get(profileData, 'currentOrganizationId') || ''
      }).unwrap()
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (_get(licenseNoteAddData, 'success')) {
      enqueueSnackbar(t('updateLicensesNoteSuccess'), {
        variant: 'success'
      })

      fetchData()
    } else if (
      _get(licenseNoteAddError, 'errors.0.message') ||
      _get(licenseNoteAddError, 'message') ||
      _get(licenseNoteAddData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('updateLicensesNoteFail'), {
        variant: 'error'
      })
    }
  }, [licenseNoteAddData, licenseNoteAddError])

  useEffect(() => {
    setEvaluatedFilter({
      q: '',
      licenseType: 'EVALUATED',
      status: 'all'
    })
  }, [])

  useEffect(() => {
    if (evaluatedFilter) {
      fetchData()
    }
  }, [evaluatedFilter, fetchData])

  return (
    <TemplateSection spacing={2}>
      <BoxEvaluationsFilter setEvaluatedFilter={setEvaluatedFilter} />
      <BoxEvaluationsTable
        licensedProductsData={licensedProductsData}
        isFetchingLicensedProducts={isFetchingLicensedProducts}
        isLoadingLicenseNoteAdd={isLoadingLicenseNoteAdd}
        handleLicenseNoteAdd={handleLicenseNoteAdd}
        isSuccessLicenseNoteAdd={_get(licenseNoteAddData, 'success') || false}
        setIsActivate={setIsActivate}
        setItemActivate={setItemActivate}
        setCsvFilter={setCsvFilter}
      />
    </TemplateSection>
  )
}

export default BoxEvaluations
