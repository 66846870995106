import React, { FC } from 'react'

import { TypographyDivider, Box, TemplateSection } from '@opswat/react-ui'

import DownloadableProduct from './DownloadableProduct'

const ProductDownloadsPage: FC<unknown> = () => {
  return (
    <TemplateSection>
      <Box>
        <TypographyDivider label="Product Downloads" />
      </Box>

      <DownloadableProduct />
    </TemplateSection>
  )
}

export default ProductDownloadsPage
