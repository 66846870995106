import { handleGetParamsURL } from '@myopswat/common'
import { PageLoading } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { useEffect } from 'react'

export default function ChangePasswordContainer() {
  const searchParams = handleGetParamsURL()
  const code = searchParams?.get('code') || ''
  const email = searchParams?.get('email') || ''

  useEffect(() => {
    if (code && email) {
      window.location.href = `${process.env.REACT_APP_SSO_WEB_HOST}/changePassword?code=${code}&email=${email}&redirect=${process.env.REACT_APP_SSO_WEB_HOST}`
    }
  }, [])

  return <PageLoading background={imageBg} color="primary" />
}
