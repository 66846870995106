import React, { FC, useEffect } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced } from '@opswat/react-ui'
import PersonalizeForm from '../Form/PersonalizeForm'
import PersonalizeSkeleton from '../Skeleton/PersonalizeSkeleton'
import { selectDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'
import { useLazySysItemCountriesQuery } from 'myopswat-web/src/api/system'
import { DIALOGS } from '@myopswat/common'

const DialogPersonalize: FC<unknown> = () => {
  const dialogType = useTypedSelector(selectDialogs)
  const [getSysItemCountries, { data: sysItemCountriesData, isLoading: isLoadingSysItemCountries }] =
    useLazySysItemCountriesQuery()

  useEffect(() => {
    if (_get(dialogType, DIALOGS.PERSONAL_INFORMATION, false)) {
      getSysItemCountries()
    }
  }, [dialogType])
  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.PERSONAL_INFORMATION, false)}
      content={
        <>
          {isLoadingSysItemCountries ? (
            <PersonalizeSkeleton />
          ) : (
            <PersonalizeForm sysItemCountriesData={sysItemCountriesData} />
          )}
        </>
      }
      dialogProps={{
        maxWidth: 'md'
      }}
    />
  )
}
export default DialogPersonalize
