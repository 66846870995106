import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography, TextField } from '@mui/material'
import { useLazyGetLeadSourcesQuery, useSubmitReferralSourceMutation } from '../../api/referral-source'
import { toggleDialogs } from '../../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch } from '../../store'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _get from 'lodash/get'
import { Autocomplete, Box, ButtonLoading, Chip, IconButton, MenuItem, useTheme } from '@opswat/react-ui'
import { DIALOGS_WEB } from '../../constants/dialogs'
import _map from 'lodash/map'
import { CheckDoneIcon, CloseIcon } from '@opswat/react-icon'

interface IForm {
  leadSources: number[]
  detail?: string
}

const ReferralSourceForm = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const [getLeadSources, { data: leadSourceData }] = useLazyGetLeadSourcesQuery()
  const [referralSourceSubmit, { isLoading: isReferralSourceSubmit }] = useSubmitReferralSourceMutation()
  const [isShowReferralDetail, setIsShowReferralDetail] = useState<boolean>(false)

  const schema = yup.object().shape({
    detail: yup.string().max(500, 'Please enter maximum 500 characters.'),
    leadSources: yup.array().of(yup.number()).required()
  })

  const defaultValues = {
    detail: '',
    leadSources: []
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const watchedFields = watch(['leadSources', 'detail'])

  const isSubmitDisabled = useMemo(() => {
    const [leadSources, detail] = Object.values(watchedFields)
    const requiredDetail = isShowReferralDetail && !detail
    return (Array.isArray(leadSources) && leadSources.length === 0) || requiredDetail
  }, [watchedFields, isShowReferralDetail])

  const onSubmit = async (data: IForm) => {
    try {
      await referralSourceSubmit(data).unwrap()
    } catch (error) {
      console.log(error)
    }
    await dispatch(
      toggleDialogs({
        [DIALOGS_WEB.REFERRAL_SOURCE]: false
      })
    )
  }

  const onFail = (e: any) => {
    console.error(e)
  }
  const requiredDetailSourceIds = (leadSourceData || [])
    .filter((data: any) => data.requiredDetail === true)
    .map((data: any) => data.id)

  useEffect(() => {
    getLeadSources()
  }, [getLeadSources])

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={10}>
        <Grid item xs={12} rowGap={2} sx={{ my: 1.5 }}>
          <Typography paragraph>
            How did you hear about us? Check all that apply
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Controller
        name={'leadSources'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            value={_map(value, val => leadSourceData.find((item: any) => item.id === val)) || []}
            fullWidth
            size="small"
            disabled={false}
            options={_map(leadSourceData, item => item) || []}
            getOptionLabel={option => option.name}
            clearIcon={<CloseIcon size={16} />}
            onChange={(_, newValue: any[]) => {
              const shouldShowDetail = newValue.some((element: any) => requiredDetailSourceIds.includes(element.id))
              setIsShowReferralDetail(shouldShowDetail)
              onChange(newValue.map(item => item.id))
            }}
            isOptionEqualToValue={option => {
              return value.some((item: any) => item === option.id)
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                placeholder={'Please select... '}
                error={!!_get(errors, 'leadSources', '')}
                helperText={_get(errors, 'leadSources.message', '')}
                sx={{
                  minHeight: 'auto',
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: theme.palette.text.secondary
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.text.disabled
                  }
                }}
              />
            )}
            renderTags={(value, getTagProps) => {
              const numTags = value.length
              const limitTags = 3
              return (
                <>
                  {value.slice(0, limitTags).map((option, index) => (
                    <Chip
                      deleteIcon={
                        <IconButton sx={{ padding: 0, margin: '0px !important', marginLeft: '8px !important' }}>
                          <CloseIcon size={16} />
                        </IconButton>
                      }
                      {...getTagProps({ index })}
                      label={
                        <Typography variant="subtitle2" color="#616875">
                          {option.name}
                        </Typography>
                      }
                      sx={{
                        backgroundColor: '#E9EAEB',
                        borderRadius: 0,
                        padding: '4px 4px 4px 8px',
                        height: '24px',
                        opacity: `${1} !important`,
                        '& .MuiChip-label': { padding: 0 }
                      }}
                    />
                  ))}
                  {numTags > limitTags && (
                    <Chip
                      size="small"
                      label={
                        <Typography variant="subtitle2" color="#616875">
                          {`+${numTags - limitTags} more item${numTags - limitTags > 1 ? 's' : ''}`}
                        </Typography>
                      }
                      sx={{
                        backgroundColor: '#E9EAEB',
                        borderRadius: 0,
                        padding: '4px 8px',
                        marginLeft: '4px',
                        height: '24px',
                        opacity: `${1} !important`,
                        '& .MuiChip-label': { padding: 0 }
                      }}
                    />
                  )}
                </>
              )
            }}
            renderOption={(props, option, state) => (
              <MenuItem {...props} value={option.id} sx={{ whiteSpace: 'normal' }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItem: 'center',
                    justifyContent: 'space-between',
                    gap: 1
                  }}
                >
                  {option.name}
                  <Box sx={{ display: state.selected ? 'flex' : 'none' }}>
                    <CheckDoneIcon color={theme.palette.primary.main} />
                  </Box>
                </Box>
              </MenuItem>
            )}
          />
        )}
      />

      <Grid item xs={12} sm={10} sx={{ mt: 2 }}>
        <Typography paragraph>
          Please provide more detail about your choices.
          {isShowReferralDetail && (
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          )}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 20, mt: -1 }}>
        <TextField
          multiline={true}
          size="small"
          fullWidth
          variant="outlined"
          placeholder={'Event name, partner name...'}
          error={!!_get(errors, 'detail', '')}
          helperText={_get(errors, ['detail', 'message'], '')}
          {...register('detail')}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: () => {
              dispatch(
                toggleDialogs({
                  [DIALOGS_WEB.REFERRAL_SOURCE]: false
                })
              )
            }
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isReferralSourceSubmit}
        >
          Skip for now
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: handleSubmit(onSubmit, onFail),
            disabled: isReferralSourceSubmit || isSubmitDisabled
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isReferralSourceSubmit}
        >
          Done
        </ButtonLoading>
      </Grid>
    </Grid>
  )
}

export default ReferralSourceForm
