import { FC } from 'react'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _range from 'lodash/range'
import _size from 'lodash/size'

import { ChevronLeftIcon, ChevronRightIcon } from '@opswat/react-icon'
import {
  Box,
  Grid,
  SwiperSlider,
  TemplateSection,
  Typography,
  TypographyDivider,
  TypographyNoData
} from '@opswat/react-ui'

import BoxBlog from 'myopswat-web/src/components/Box/BoxBlog'
import BlogSkeleton from 'myopswat-web/src/components/Skeleton/BlogSkeleton'
import ProductSkeleton from 'myopswat-web/src/components/Skeleton/ProductSkeleton'
import { CLOUDBASE, OEM, ONPREMISE } from 'myopswat-web/src/constants/product-type'
import { useTypedSelector } from 'myopswat-web/src/store'

import { useProductTypeQuery } from 'myopswat-web/src/api/product'
import BoxProductHome from './BoxProductHome'
import BoxProductHomeFilter from './BoxProductHomeFilter'

interface IProps {
  productsData: any
  isFetchingProducts: boolean
  interestedProductsData: any
  isFetchingInterestedProducts: boolean
  handleProductInterestedAdd: (data: string) => void
  handleProductInterestedRemove: (data: string) => void
  latestNewsData: any
  isFetchingLatestNews: boolean
  relatedProductData: any
  isFetchingProductSubscriptionEntries: boolean
  handleEntrySeen: (data: string) => void
  handleEntryUnseen: (data: string) => void
  handleEntryIgnore: (data: string) => void
  solutionsData: any
  isFetchingSolutions: boolean
  handleFilterProducts: (data: any) => void
}

const HomePage: FC<IProps> = ({
  productsData,
  isFetchingProducts,
  interestedProductsData,
  isFetchingInterestedProducts,
  handleProductInterestedAdd,
  handleProductInterestedRemove,
  latestNewsData,
  isFetchingLatestNews,
  relatedProductData,
  isFetchingProductSubscriptionEntries,
  handleEntrySeen,
  handleEntryUnseen,
  handleEntryIgnore,
  solutionsData,
  isFetchingSolutions,
  handleFilterProducts
}) => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isOEMCustomer = _get(profileData, 'accountInfo.isOemCustomer', false)

  const loadingArray = _range(5)
  const loadingAllProductArray = _range(15)

  const oemProducts = _filter(productsData, item => _includes(_get(item, 'tags'), OEM))
  const cloudProducts = _filter(productsData, item => _includes(_get(item, 'tags'), CLOUDBASE))
  const onPremiseProducts = _filter(productsData, item => _includes(_get(item, 'tags'), ONPREMISE))
  const { data: producTypes } = useProductTypeQuery(undefined, {
    refetchOnMountOrArgChange: false
  })

  return (
    <TemplateSection spacing={4}>
      <Box>
        <TypographyDivider label="Home" />
      </Box>

      <Box>
        <Box mb={2}>
          <Typography>My Tagged Products</Typography>
        </Box>

        {!isFetchingInterestedProducts && _size(interestedProductsData) === 0 && (
          <TypographyNoData>You have not tagged any Product.</TypographyNoData>
        )}

        {isFetchingInterestedProducts ? (
          <>
            <Grid container spacing={2} sx={{ px: 1 }}>
              {_map(loadingArray, (item: any, idx: any) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                  <ProductSkeleton />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ px: 1 }}>
              {_map(_orderBy(interestedProductsData, ['name'], ['asc']), (item: any, idx: any) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                  <BoxProductHome
                    propsData={item}
                    propsComponent={{
                      handleProductInterestedAdd,
                      handleProductInterestedRemove
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>

      <Box>
        {_size(solutionsData) > 0 && (
          <Box mb={2}>
            <BoxProductHomeFilter
              solutionsData={solutionsData}
              producTypes={producTypes}
              isFetchingSolutions={isFetchingSolutions}
              handleFilterProducts={handleFilterProducts}
            />
          </Box>
        )}

        {!isFetchingProducts && _size(productsData) === 0 && (
          <Box>
            <Box mb={2}>
              <Typography>All Products</Typography>
            </Box>
            <TypographyNoData>You have not any Products.</TypographyNoData>
          </Box>
        )}

        {isFetchingProducts ? (
          <Box>
            <Box mb={2}>
              <Typography>All Products</Typography>
            </Box>
            <Grid container spacing={2} sx={{ px: 1 }}>
              {_map(loadingAllProductArray, (item: any, idx: any) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                  <ProductSkeleton />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <TemplateSection spacing={4}>
            {isOEMCustomer && (
              <Box>
                {_size(oemProducts) === 0 ? null : (
                  <Box mb={2}>
                    <Typography>OEM Products</Typography>
                  </Box>
                )}
                <Grid container spacing={2} sx={{ px: 1 }}>
                  {_map(oemProducts, (item: any, idx: any) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                      <BoxProductHome
                        propsData={item}
                        propsComponent={{
                          handleProductInterestedAdd,
                          handleProductInterestedRemove
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <Box>
              {_size(cloudProducts) === 0 ? null : (
                <Box mb={2}>
                  <Typography>Cloud Services</Typography>
                </Box>
              )}
              <Grid container spacing={2} sx={{ px: 1 }}>
                {_map(cloudProducts, (item: any, idx: any) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                    <BoxProductHome
                      propsData={item}
                      propsComponent={{
                        handleProductInterestedAdd,
                        handleProductInterestedRemove
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box>
              {_size(onPremiseProducts) === 0 ? null : (
                <Box mb={2}>
                  <Typography>On-premises Products</Typography>
                </Box>
              )}
              <Grid container spacing={2} sx={{ px: 1 }}>
                {_map(onPremiseProducts, (item: any, idx: any) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
                    <BoxProductHome
                      propsData={item}
                      propsComponent={{
                        handleProductInterestedAdd,
                        handleProductInterestedRemove
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </TemplateSection>
        )}
      </Box>

      <Box>
        <SwiperSlider
          title="Product Updates"
          contents={
            isFetchingProductSubscriptionEntries ? loadingArray : _orderBy(relatedProductData, ['seen'], ['asc'])
          }
          ContentComponent={isFetchingProductSubscriptionEntries ? BlogSkeleton : BoxBlog}
          propsSwiperSlide={{
            style: { boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)', scale: '0.95' }
          }}
          propsComponent={{
            handleEntrySeen,
            handleEntryUnseen,
            handleEntryIgnore
          }}
          propsLeftButton={{ leftIcon: <ChevronLeftIcon /> }}
          propsRightButton={{ rightIcon: <ChevronRightIcon /> }}
        />

        {!isFetchingProductSubscriptionEntries && _size(relatedProductData) === 0 && (
          <TypographyNoData>You have not selected any Interested Topics.</TypographyNoData>
        )}
      </Box>

      <Box>
        <SwiperSlider
          title="Latest News from OPSWAT"
          contents={isFetchingLatestNews ? loadingArray : _orderBy(latestNewsData, ['seen'], ['asc'])}
          ContentComponent={isFetchingLatestNews ? BlogSkeleton : BoxBlog}
          propsSwiperSlide={{
            style: { boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)', scale: '0.95' }
          }}
          propsComponent={{
            handleEntrySeen,
            handleEntryUnseen,
            handleEntryIgnore,
            isNew: true
          }}
          propsLeftButton={{ leftIcon: <ChevronLeftIcon /> }}
          propsRightButton={{ rightIcon: <ChevronRightIcon /> }}
        />
      </Box>
    </TemplateSection>
  )
}

export default HomePage
