import { useMemo, useState } from 'react'

import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _size from 'lodash/size'
import { useSnackbar } from 'notistack'

import { CloseIcon } from '@opswat/react-icon'
import {
  Box,
  CircularLoading,
  DataTable,
  DialogAdvanced,
  Grid,
  IconButton,
  Switch,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import {
  useLazyOrganizationSupportInfoQuery,
  useUpdateOrganizationCaseAccessMutation
} from 'myopswat-web/src/api/support'
import { PAGE_DEFAULT } from 'myopswat-web/src/constants'
import { useTypedSelector } from 'myopswat-web/src/store'
import NoCaseAccessTable from './NoCaseAccessTable'

type IProps = {
  organizationCaseAccessInfo: any
  openDialog: boolean
  handleCloseDialog: () => void
}

const DialogUpdateCaseAccess = ({ organizationCaseAccessInfo, openDialog, handleCloseDialog }: IProps) => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const { enqueueSnackbar } = useSnackbar()

  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [tableData, setTableData] = useState(organizationCaseAccessInfo)

  const [updatingContactId, setUpdatingContactId] = useState<string | null>(null)
  const [updateOrganizationCaseAccess, { isLoading: isUpdating }] = useUpdateOrganizationCaseAccessMutation()
  const [getOrganizationSupportInfo] = useLazyOrganizationSupportInfoQuery()

  const total = _size(organizationCaseAccessInfo)
  const orgId = useMemo(() => _get(profileData, 'currentOrganizationId'), [profileData])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== perPage) page = PAGE_DEFAULT

    setPage(page)
    setPerPage(pageSize)
  }

  const handleUpdateCaseAccess = (data: any) => {
    const contactId = _get(data, 'contactId')
    const newCaseAccess = !_get(data, 'orgCaseAccess')

    setUpdatingContactId(contactId)

    updateOrganizationCaseAccess({
      orgId,
      contactId: contactId,
      access: newCaseAccess
    })
      .unwrap()
      .then(() => {
        setUpdatingContactId(null)

        enqueueSnackbar('Organization case access updated successfully!', {
          variant: 'success'
        })

        setTableData((prev: any) => {
          return prev.map((item: any) => {
            if (_get(item, 'contactId') === contactId) {
              return {
                ...item,
                orgCaseAccess: newCaseAccess
              }
            }
            return item
          })
        })
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })
  }

  const closeDialog = () => {
    handleCloseDialog()

    if (!_isEqual(tableData, organizationCaseAccessInfo)) getOrganizationSupportInfo(orgId!)
  }

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Full Name',
        style: { minWidth: 265, width: 265 }
      },
      {
        accessorKey: 'email',
        header: 'Email',
        style: { minWidth: 265, width: 265 },
        Cell: ({ cell }: { cell: any }) => (
          <TypographyLineClamp line={1} tooltipValue={cell.getValue()}>
            {cell.getValue()}
          </TypographyLineClamp>
        )
      },
      {
        accessorKey: 'orgCaseAccess',
        header: 'Organization Case Access',
        style: { minWidth: 'auto', width: 'auto' },
        size: 150,
        Cell: ({ cell }: { cell: any }) => {
          const rowData = cell.row.original
          return (
            <Grid container alignItems={'center'}>
              <Switch
                checked={cell.getValue()}
                label={''}
                onClick={() => handleUpdateCaseAccess(rowData)}
                color="primary"
                disabled={isUpdating}
              />

              {updatingContactId === rowData.contactId && <CircularLoading />}
            </Grid>
          )
        }
      }
    ]
  }, [organizationCaseAccessInfo, isUpdating, updatingContactId])

  return (
    <DialogAdvanced
      open={openDialog}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3">Configure Organization Case Access</Typography>
          <IconButton onClick={() => closeDialog()} sx={{ padding: 0 }} disabled={isUpdating}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
      content={
        <DataTable
          columns={columns}
          data={tableData.slice(page * perPage, (page + 1) * perPage)}
          isPaganization={true}
          onChangePage={handlePaginationOnChange}
          total={total}
          page={page}
          pageSize={perPage}
          noDataText={<NoCaseAccessTable columns={columns} />}
          noDataTextProps={{ sx: { my: 0, py: 0 } }}
        />
      }
      dialogTitleProps={{
        sx: {
          borderBottom: '1px solid #D3D9E6',
          marginInline: '24px',
          paddingY: '10px ',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 24px',
          height: tableData.slice(page * perPage, (page + 1) * perPage).length < 6 ? 'auto' : '550px'
        }
      }}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            maxWidth: '800px'
          }
        }
      }}
    />
  )
}

export default DialogUpdateCaseAccess
