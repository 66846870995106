import React, { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _debounce from 'lodash/debounce'

import { QRCodeCanvas } from 'qrcode.react'

import {
  Box,
  Typography,
  ButtonLoading,
  Button,
  Grid,
  Card,
  TextField,
  InputAdornment,
  TypographyLink,
  CommonOTP
} from '@opswat/react-ui'
import { copyText } from '@opswat/react-core'
import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'

import { OTPAUTH_ISSUER } from 'myopswat-web/src/constants/mfa'

interface IProps {
  children?: React.ReactNode
  setupMFAData: any
  profile: any
  isLoadingVerifyMFAToken: boolean
  handleVerifyMFAToken: (data: string) => void
}

const StepQR: FC<IProps> = ({ setupMFAData, profile, isLoadingVerifyMFAToken, handleVerifyMFAToken }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [qrCodeText, setQRCodeText] = useState<string>('')
  const [otpString, setOtpString] = useState<string>('')

  const mfaSetupCode = _get(setupMFAData, 'code', '')
  const recoveryCodes = _get(setupMFAData, 'recoveryCodes', '')

  const handleReturnCopy = _debounce(() => {
    setIsCopied(false)
  }, 1000)

  const handleCopy = () => {
    copyText(mfaSetupCode)
    setIsCopied(true)
    handleReturnCopy()
  }

  const handleSubmit = () => {
    handleVerifyMFAToken(otpString)
  }

  useEffect(() => {
    if (profile) {
      setQRCodeText(`otpauth://totp/${_get(profile, 'email')}?secret=${mfaSetupCode}&issuer=${OTPAUTH_ISSUER}`)
    }
  }, [mfaSetupCode, profile])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">Now set up Multi-factor Authentication</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Multi-factor authentication creates strong user verification - thus maintain high-standards of security
              for access to resources.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              sx={{ p: 3, height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 3 }}
            >
              {qrCodeText ? (
                <QRCodeCanvas value={qrCodeText} />
              ) : (
                <img src="" alt="qr-log" width="128px" height="128px" />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ mr: 3 }}>
            <TextField
              variant="outlined"
              size="small"
              value={mfaSetupCode}
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button color={isCopied ? 'inherit' : 'primary'} onClick={handleCopy}>
                      {isCopied ? 'Copied' : 'Copy'}
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            height: 'auto',
            minHeight: '480px',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                1. Download&nbsp;
                <Typography component="span" color="textSecondary">
                  Google Authenticator or another Authenticator
                </Typography>
                &nbsp; app on your mobile device. See Google and Apple App Stores.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                2. Download the&nbsp;
                <TypographyLink
                  color="textSecondary"
                  onClick={() =>
                    handleCreateFile('opswat-recovery-codes', 'txt', recoveryCodes, { type: BLOB_FILE_TYPES.PLAIN })
                  }
                >
                  Recovery Code
                </TypographyLink>
                , store it in a safe place. This is a backup method allowing you to log in, if the app fails (MFA is
                deactivated when using Recovery Codes).
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                3. Scan the QR code with your authenticator app or directly input the provided text code into your
                Authenticator app. After that, please enter the 6-character Authenticator code for verification.
              </Typography>
            </Grid>

            <Grid item xs={12} md={10}>
              <CommonOTP handleSubmit={handleSubmit} getValue={setOtpString} />
            </Grid>
          </Grid>

          <Box>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit,
                disabled: isLoadingVerifyMFAToken,
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingVerifyMFAToken}
            >
              Continue
            </ButtonLoading>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default StepQR
