import { REGEXS } from '@myopswat/common'
import * as yup from 'yup'

declare module 'yup' {
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ''
  > extends yup.Schema<TIn, TContext, TDefault, TFlags> {
    unique(field: string, message: string): ArraySchema<TIn, TContext>
  }
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ''
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    myOpswatEmail(errMessage?: string): StringSchema<TType, TContext>
  }
}

yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    if (!array) return true

    const uniqueData = Array.from(new Set(array.map(row => row[field]?.toLowerCase())))
    const isUnique = array.length === uniqueData.length
    if (isUnique) {
      return true
    }
    const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i])
    if (array[index][field] === '') {
      return true
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message
    })
  })
})

yup.addMethod(yup.string, 'myOpswatEmail', function (message) {
  return this.matches(REGEXS.EMAIL_REGEX, {
    message,
    excludeEmptyString: true
  })
})

export default yup
