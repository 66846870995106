import { useState } from 'react'
import BasicEdit from './BasicEdit'
import BasicView from './BasicView'

interface IProps {
  organization: any
  countries: any[]
  afterSave: () => void
}

const BasicSection = ({ organization, countries, afterSave }: IProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const handleEdit = () => {
    setIsEdit(true)
  }

  const handleAfterSave = () => {
    setIsEdit(false)
    afterSave()
  }

  const handleCancelEdit = () => {
    setIsEdit(false)
  }

  return (
    <>
      {isEdit ? (
        <BasicEdit
          organization={organization}
          countries={countries}
          onCancel={handleCancelEdit}
          afterSave={handleAfterSave}
        />
      ) : (
        <BasicView organization={organization} countries={countries} onEdit={handleEdit} />
      )}
    </>
  )
}
export default BasicSection
