import React, { FC, useState } from 'react'

import _get from 'lodash/get'
import _merge from 'lodash/merge'

import { SCHEMA_YUP, handleForceLogout } from '@myopswat/common'
import { EyeHideIcon, EyeIcon } from '@opswat/react-icon'
import {
  Box,
  ButtonLoading,
  Grid,
  TextField,
  TextGrid,
  TextMeter,
  Typography,
  TypographyDivider
} from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'

import { useAccountChangePasswordMutation } from 'myopswat-web/src/api/account'
import { enqueueSnackbar } from 'notistack'
import { useTypedSelector } from 'myopswat-web/src/store'

interface IForm {
  oldPassword: string
  password: string
  rePassword: string
}

const SecurityPassword: FC<unknown> = () => {
  const { t: translate } = useTranslation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [accountChangePassword, { isLoading: isLoadingChangePassword }] = useAccountChangePasswordMutation()

  const defaultValues = {
    oldPassword: '',
    password: '',
    rePassword: ''
  }

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const handleClickShowOldPassword = () => setShowOldPassword(showOldPassword => !showOldPassword)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const [showRePassword, setShowRePassword] = useState<boolean>(false)
  const handleClickShowRePassword = () => setShowRePassword(showRePassword => !showRePassword)

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const schema = yup.object().shape(_merge({}, ...[SCHEMA_YUP.oldPassword, SCHEMA_YUP.password, SCHEMA_YUP.rePassword]))

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = async (data: any) => {
    const payload = {
      email: _get(profileData, 'email') || '',
      password: data?.oldPassword || '',
      newPassword: data?.password || ''
    }

    const response = await accountChangePassword(payload).unwrap()

    if (response?.success) {
      enqueueSnackbar(translate('changePasswordSuccess'), {
        variant: 'success'
      })
      handleForceLogout()
    } else {
      enqueueSnackbar(_get(response, 'errors.0.message') || translate('changePasswordFail'), {
        variant: 'error'
      })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyDivider label="Password" propsTypo={{ variant: 'body1' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          To reset your password simply enter the current password and then the new password twice.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextGrid
              label={<Typography variant="subtitle1">Current Password</Typography>}
              value={
                <Controller
                  name="oldPassword"
                  control={control}
                  render={(cProps: any) => (
                    <TextField
                      size="small"
                      fullWidth
                      type={showOldPassword ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Box
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                          >
                            {showOldPassword ? <EyeIcon /> : <EyeHideIcon />}
                          </Box>
                        )
                      }}
                      error={!!_get(errors, 'oldPassword', '')}
                      helperText={_get(errors, 'oldPassword.message', '')}
                      required
                      value={cProps.field.value}
                      onChange={(e: any) => cProps.field.onChange(e.target.value)}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                        trigger('oldPassword')
                      }}
                      onBlur={() => {
                        trigger('oldPassword')
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mb={0.5}>
              <TextMeter text={watch('password')} />
            </Box>
            <TextGrid
              label={<Typography variant="subtitle1">New Password</Typography>}
              value={
                <Controller
                  name="password"
                  control={control}
                  render={(cProps: any) => (
                    <TextField
                      size="small"
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Box
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                          >
                            {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                          </Box>
                        )
                      }}
                      error={!!_get(errors, 'password', '')}
                      helperText={_get(errors, 'password.message', '')}
                      required
                      value={cProps.field.value}
                      onChange={(e: any) => cProps.field.onChange(e.target.value)}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                        trigger('password')
                      }}
                      onBlur={() => {
                        trigger('password')
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextGrid
              label={<Typography variant="subtitle1">Confirm Password</Typography>}
              value={
                <Controller
                  name="rePassword"
                  control={control}
                  render={(cProps: any) => (
                    <TextField
                      size="small"
                      fullWidth
                      type={showRePassword ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Box
                            onClick={handleClickShowRePassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                          >
                            {showRePassword ? <EyeIcon /> : <EyeHideIcon />}
                          </Box>
                        )
                      }}
                      error={!!_get(errors, 'rePassword', '')}
                      helperText={_get(errors, 'rePassword.message', '')}
                      required
                      value={cProps.field.value}
                      onChange={(e: any) => cProps.field.onChange(e.target.value)}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                        trigger('rePassword')
                      }}
                      onBlur={() => {
                        trigger('rePassword')
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled:
                  (!watch('oldPassword') && !watch('password') && !watch('rePassword')) || isLoadingChangePassword
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingChangePassword}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SecurityPassword
