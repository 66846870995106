import { CircleWarningIcon } from '@opswat/react-icon'
import { Box, Typography } from '@opswat/react-ui'

interface IProps {
  message?: string | JSX.Element
}

const WarningMessage = ({ message }: IProps) => {
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <Box sx={{ marginTop: '2px', width: '16px', height: '16px' }}>
        <CircleWarningIcon color="#707682" />
      </Box>

      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </Box>
  )
}

export default WarningMessage
