import _map from 'lodash/map'
import _merge from 'lodash/merge'

import { NotAvailableIcon } from '@opswat/react-icon'
import { Box, Table, TableCell, TableContainer, TableHead, Typography, useTheme } from '@opswat/react-ui'

interface IProps {
  columns: any
}

const NoCaseAccessTable = ({ columns }: IProps) => {
  const theme = useTheme()

  const defaultColumnStyle = {
    px: '16px',
    pt: '12px',
    pb: '9.6px'
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            {_map(columns, (column, columnIdx) => {
              return (
                <TableCell
                  sx={_merge(
                    {
                      borderBottom: `1px solid ${theme.palette.divider}`
                    },
                    defaultColumnStyle,
                    column.style
                  )}
                  key={columnIdx.toString()}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold', lineHeight: '1.5rem' }}>
                    {column.header}
                  </Typography>
                </TableCell>
              )
            })}
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          py: '40px',
          gap: '10px',
          flexDirection: 'column'
        }}
      >
        <NotAvailableIcon size={40} color="#BCBFC3" />
        <Typography component="label">There are no appropriate users available to configure</Typography>
      </Box>
    </>
  )
}

export default NoCaseAccessTable
