import React, { FC } from 'react'

import { OpswatIcon } from '@opswat/react-icon'
import { Box, Button, Grid, Typography } from '@opswat/react-ui'
import { handleForceLogout } from '@myopswat/common'

const SessionTimeoutForm: FC<unknown> = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <OpswatIcon />
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>Session Timeout</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          You session has expired due to inactivity. Please sign in to restart a session.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={() => handleForceLogout()}>
            Sign In
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SessionTimeoutForm
