import { FC } from 'react'

import { OpswatIcon } from '@opswat/react-icon'
import { Grid, Typography, TypographyLineClamp, useTheme } from '@opswat/react-ui'
import { useTypedSelector } from 'myopswat-web/src/store'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  orgName: string
}

interface IAboutLabelProps {
  text: string
  marginTop: number
}

const AboutLabel = ({ text, marginTop }: IAboutLabelProps) => {
  return (
    <Typography variant="body2" sx={{ mt: marginTop, color: '#E9EAEB', opacity: 0.5 }}>
      {text}
    </Typography>
  )
}

const AboutProductForm: FC<IProps> = ({ orgName }) => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const theme = useTheme()

  return (
    <Grid container columnSpacing={4} rowSpacing={2}>
      <Grid item xs={3}>
        <OpswatIcon color="white" width="125px" height="18px" />
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="h1"
          sx={{
            color: 'white',
            borderLeft: `4px solid ${theme.palette.success.dark}`,
            pl: 2
          }}
        >
          My OPSWAT
        </Typography>
      </Grid>
      <Grid item xs={3} />
      {orgName && (
        <Grid item xs={3}>
          <AboutLabel text="Organization" marginTop={5} />
        </Grid>
      )}
      {orgName && (
        <Grid item xs={6}>
          <TypographyLineClamp
            line={3}
            sx={{
              color: 'white',
              mt: 5,
              pr: 4
            }}
            tooltipValue={orgName}
            tooltipPlacement="top"
            tooltipProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB',
                  whiteSpace: 'pre-line'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            {orgName}
          </TypographyLineClamp>
        </Grid>
      )}
      <Grid item xs={3} />
      <Grid item xs={3}>
        <AboutLabel text="Platform" marginTop={0} />
      </Grid>
      <Grid item xs={6}>
        <TypographyLineClamp
          line={1}
          sx={{
            color: 'white',
            pr: 4
          }}
        >
          {profileData?.platform}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <AboutLabel text="Central Management" marginTop={0} />
      </Grid>
      <Grid item xs={6}>
        <TypographyLineClamp
          line={1}
          sx={{
            color: 'white',
            pr: 4
          }}
        >
          {profileData?.productManagement}
        </TypographyLineClamp>
      </Grid>
    </Grid>
  )
}

export default AboutProductForm
