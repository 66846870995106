import React, { FC } from 'react'

import { Typography, Grid, TemplateSection, TextGrid, Skeleton } from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'

const PersonalizeSkeleton: FC<unknown> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OpswatIcon />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h1" paragraph>
          Account Information
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Complete your information to enable full account functions including product management, product evaluations,
          and access to support services.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TemplateSection>
          <TextGrid
            label={<Typography variant="subtitle2">Email</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">First Name*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Last Name*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Title</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Phone Number*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Time Zone*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />
        </TemplateSection>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TemplateSection>
          <TextGrid
            label={<Typography variant="subtitle2">Address1*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Address2</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">City*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Country*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />

          <TextGrid
            label={<Typography variant="subtitle2">Postal Code*</Typography>}
            value={<Skeleton animation="wave" variant="text" width="100%" height="35px" />}
          />
        </TemplateSection>
      </Grid>

      <Grid item xs={12} sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={true}>
            <Typography variant="body2" color="textSecondary">
              *Information is required to fully enable this account.
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Skeleton animation="wave" variant="text" width="100%" height="35px" />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Skeleton animation="wave" variant="text" width="100%" height="35px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PersonalizeSkeleton
