import { FC, useMemo, useState } from 'react'

import { Box, ButtonLoading, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'

const TestIframePage: FC<any> = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const optionValues = useMemo(
    () => [
      { name: 'MetaDefender Cloud', url: 'https://metadefender.opswat.com/login' },
      { name: 'MetaDefender IT Access', url: 'https://metaaccess-testing.opswat.com/dashboard/overview' },
      { name: 'Filescan.io', url: 'https://filescan.io' },
      { name: 'Central Management', url: process.env.REACT_APP_OPSWAT_OC_URL ?? '' }
    ],
    []
  )

  return (
    <TemplateSection spacing={2}>
      <Box>
        <TypographyDivider label="Test Iframe Page" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {optionValues.map((item, index) => (
            <ButtonLoading
              key={item.name}
              propsButton={{
                color: index === selectedIndex ? 'primary' : 'inherit',
                disableElevation: true,
                style: {
                  textTransform: 'none'
                },
                onClick: () => setSelectedIndex(index)
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              {item.name}
            </ButtonLoading>
          ))}
        </Grid>
        <Grid item xs={12}>
          <iframe
            title={optionValues.at(selectedIndex)?.name}
            src={optionValues.at(selectedIndex)?.url}
            style={{ border: 'none', width: '100%', height: '75vh' }}
          />
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default TestIframePage
