import { FC, useEffect, useMemo } from 'react'

import _get from 'lodash/get'

import { Box } from '@opswat/react-ui'

import { useUserSettingsQuery } from 'myopswat-web/src/api/account'
import { useLazyEmailSubscriptionProductsQuery, useSolutionsQuery } from 'myopswat-web/src/api/product'
import { selectEditMode } from 'myopswat-web/src/containers/MyInformationContainer/myInformationContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'

import PreferencesEdit from './PreferencesEdit'
import PreferencesLoading from './PreferencesLoading'
import PreferencesView from './PreferencesView'

const Preferences: FC<unknown> = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const editMode = useTypedSelector(selectEditMode)

  const { data: userSettingsData, isFetching: isFetchingUserSettings } = useUserSettingsQuery()
  const { data: solutionsData, isFetching: isFetchingSolutions } = useSolutionsQuery()
  const [loadProducts, { data: productOptions }] = useLazyEmailSubscriptionProductsQuery()

  const emailSubscriptionContent = useMemo(() => {
    return {
      update: {
        title: 'OPSWAT Updates',
        description: 'News about Webinars, Events, Resources, and Solutions (4-6 emails per month)'
      },
      newsletter: {
        title: _get(profileData, 'accountInfo.isOemCustomer', false)
          ? 'Company Activity Newsletter/OEM Newsletter'
          : 'Company Activity Newsletter',
        description: 'Summary of Recent Company Activities - 1 email per Month'
      },
      blog: {
        title: 'Cybersecurity Blog',
        description: 'Product Updates, Technology Tips, and Company News - 3-5 Weekly emails'
      },
      productRelease: {
        title: 'Product Releases',
        description: 'Email notifications whenever OPSWAT releases a new version of a downloadable product.'
      }
    }
  }, [profileData])

  const showEmailSubscriptions = useMemo(() => {
    return _get(userSettingsData, 'isShowEmailSubscriptions', false)
  }, [userSettingsData])

  const isCustomer = useMemo(() => {
    return _get(userSettingsData, 'isCustomer', false)
  }, [userSettingsData])

  useEffect(() => {
    loadProducts()
  }, [])

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {isFetchingUserSettings || isFetchingSolutions ? (
        <PreferencesLoading />
      ) : (
        <>
          {editMode === 'preferences' ? (
            <PreferencesEdit
              userSettingsData={userSettingsData}
              solutionsData={solutionsData}
              emailSubscriptionContent={emailSubscriptionContent}
              profileData={profileData}
              showEmailSubscriptions={showEmailSubscriptions}
              isCustomer={isCustomer}
              productOptions={productOptions}
            />
          ) : (
            <PreferencesView
              userSettingsData={userSettingsData}
              solutionsData={solutionsData}
              emailSubscriptionContent={emailSubscriptionContent}
              profileData={profileData}
              showEmailSubscriptions={showEmailSubscriptions}
              isCustomer={isCustomer}
              productOptions={productOptions}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default Preferences
