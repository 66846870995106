import { copyText } from '@opswat/react-core'
import { CopySimpleIcon } from '@opswat/react-icon'
import { Box } from '@opswat/react-ui'

interface IProps {
  hash: string
  afterCopy?: () => void
}

const HashView = ({ hash, afterCopy }: IProps) => {
  const handleCopy = () => {
    copyText(hash)
    if (afterCopy) {
      afterCopy()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => handleCopy()}
      >
        <CopySimpleIcon />
      </Box>
      {hash}
    </Box>
  )
}
export default HashView
