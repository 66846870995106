import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { TemplateFormLogin, TemplateSection, Box, Typography, Grid, Button } from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'

import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { resendEmailPageURL } from 'myopswat-web/src/routes'

interface IProps {
  children?: React.ReactNode
}

const ConfirmEmailPage: FC<IProps> = () => {
  const navigate = useNavigate()

  return (
    <TemplateFormLogin background={imageBg}>
      <TemplateSection spacing={4}>
        <Box>
          <OpswatIcon />
        </Box>

        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Create your OPSWAT Account</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    My OPSWAT lets you securely manage your OPSWAT products and services remotely, improve productivity
                    flows, and provide better visibility between team members, all in one place.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">Check your email to activate your account</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Thanks for joining My OPSWAT! We've just sent you an email to activate your account and complete the
                    registration process.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    If you haven't received an activation email after ten minutes:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body2">Check your spam or junk folder.</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Check the email address you used register your account.</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Try resending the email.</Typography>
                    </li>
                  </ul>
                </Grid>

                <Grid item xs={12}>
                  <Button color="primary" variant="contained" onClick={() => navigate(resendEmailPageURL)}>
                    Resend
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    For further service and support, reach out to our customer support team at&nbsp;
                    <a target="_blank" href="mailto:opswat-support@opswat.com">
                      opswat-support@opswat.com
                    </a>
                    .
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TemplateSection>
    </TemplateFormLogin>
  )
}

export default ConfirmEmailPage
