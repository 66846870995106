import React, { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import {
  Typography,
  RadioGroup,
  Grid,
  Alert,
  AlertTitle,
  ButtonLoading,
  FormControlLabel,
  Radio,
  Box
} from '@opswat/react-ui'

import { useForm, Controller } from 'react-hook-form'

import { IProfileInput } from 'myopswat-web/src/api/account/types'

interface IProps {
  children?: React.ReactNode
  profile: any
  handleProfileUpdate: (data: IProfileInput) => void
  errorMessage?: string
  isLoadingProfileUpdate?: boolean
  organizationsData: any
}

interface IForm {
  usingOrganizationId: string
}

const DefaultOrganizationForm: FC<IProps> = ({
  isLoadingProfileUpdate,
  profile,
  handleProfileUpdate,
  errorMessage,
  organizationsData
}) => {
  const defaultValues = {
    usingOrganizationId: ''
  }

  const { control, handleSubmit, watch } = useForm<IForm>({
    defaultValues
  })

  const onSuccess = (data: any) => {
    handleProfileUpdate({
      email: _get(profile, 'email', ''),
      firstName: _get(profile, 'firstName', ''),
      lastName: _get(profile, 'lastName', ''),
      usingOrganizationId: _get(data, 'usingOrganizationId', '')
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <Box>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography paragraph>Please select your default Organization before continuing</Typography>
          <Typography variant="subtitle2">
            You currently don't have a default Organization. Please choose one Organization as the default one to land
            in every logging in time.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {errorMessage && (
            <Alert variant="filled" severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="usingOrganizationId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup {...field}>
                {_map(organizationsData, (item, idx) => (
                  <React.Fragment key={idx.toString()}>
                    <FormControlLabel
                      value={_get(item, 'id')}
                      control={<Radio size="small" />}
                      label={<Typography variant="subtitle2">{_get(item, 'name')}</Typography>}
                    />
                  </React.Fragment>
                ))}
              </RadioGroup>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            After click Submit, you still can update it on My Information page.
          </Typography>
        </Grid>
        <Grid item xs="auto" sx={{ mt: 4 }}>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              onClick: handleSubmit(onSuccess, onFail),
              disabled: !watch('usingOrganizationId') || isLoadingProfileUpdate
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoadingProfileUpdate}
          >
            Submit
          </ButtonLoading>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DefaultOrganizationForm
