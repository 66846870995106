import React from 'react'

import _get from 'lodash/get'

import { handleGetErrorMessage } from '@myopswat/common'

import ResendEmailPage from 'myopswat-web/src/pages/ResendEmailPage'
import { useAccountResendMutation } from 'myopswat-web/src/api/account'
import { IAccountResendInput } from 'myopswat-web/src/api/account/types'

export default function ResendEmailContainer() {
  const [accountResend, { data, isLoading, error: accountResendError }] = useAccountResendMutation()

  const handleAccountResend = async (data: IAccountResendInput) => {
    try {
      await accountResend({ email: _get(data, 'email', ''), customHost: _get(data, 'customHost', '') }).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ResendEmailPage
      isSuccess={_get(data, 'success')}
      isLoading={isLoading}
      handleAccountResend={handleAccountResend}
      errorMessage={handleGetErrorMessage(data, accountResendError)}
    />
  )
}
