import React, { FC } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced } from '@opswat/react-ui'

import { IProfileInput } from 'myopswat-web/src/api/account/types'
import DefaultOrganizationForm from '../Form/DefaultOrganizationForm'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLazyProfileQuery, useProfileUpdateMutation } from 'myopswat-web/src/api/account'
import { DIALOGS } from '@myopswat/common'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  profileData: any
  organizationsData: any
}

const DialogDefaultOrganization: FC<IProps> = ({ profileData, organizationsData }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [getProfile] = useLazyProfileQuery()
  const [profileUpdate, { data: profileUpdateData, error: profileUpdateError, isLoading: isLoadingProfileUpdate }] =
    useProfileUpdateMutation()

  const handleProfileUpdate = async (data: IProfileInput) => {
    try {
      await profileUpdate(data)
        .unwrap()
        .then(async () => {
          await enqueueSnackbar(translate('updateProfileSuccess'), {
            variant: 'success'
          })
          await getProfile()

          await dispatch(
            toggleDialogs({
              [DIALOGS.DEFAULT_ORGANIZATION]: false
            })
          )
        })
        .catch(() => {
          enqueueSnackbar(translate('updateProfileFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.DEFAULT_ORGANIZATION, false)}
      content={
        <DefaultOrganizationForm
          profile={profileData}
          isLoadingProfileUpdate={isLoadingProfileUpdate}
          handleProfileUpdate={handleProfileUpdate}
          errorMessage={
            _get(profileUpdateData, 'errors.0.message') ||
            _get(profileUpdateError, 'errors.0.message') ||
            _get(profileUpdateError, 'message')
          }
          organizationsData={organizationsData}
        />
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogDefaultOrganization
