import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@opswat/react-ui'
import React from 'react'

interface IProps {
  isActive: boolean
  onChangeRole: () => void
  onRemoveUser: () => void
  onSetSuperAdmin?: () => void
}

const UserAction = ({ isActive, onChangeRole, onRemoveUser, onSetSuperAdmin }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChangeRole = () => {
    handleClose()
    onChangeRole()
  }

  const handleRemoveUser = () => {
    handleClose()
    onRemoveUser()
  }

  return (
    <>
      <IconButton aria-label="more actions" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClick={event => event.stopPropagation()} onClose={handleClose}>
        {isActive && <MenuItem onClick={handleChangeRole}>Change Role</MenuItem>}

        <MenuItem onClick={handleRemoveUser}>Remove</MenuItem>

        {onSetSuperAdmin && isActive && <MenuItem onClick={onSetSuperAdmin}>Set as Super Admin</MenuItem>}
      </Menu>
    </>
  )
}

export default UserAction
