import { useState } from 'react'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { Box, BoxAdvanced, Button, Divider, Grid, Tooltip, Typography } from '@opswat/react-ui'
import DialogUpdateCaseAccess from './DialogUpdateCaseAccess'

type IProps = {
  organizationCaseAccessInfo: any
  isEditableCaseInfo: boolean
}

const OrganizationCaseAccess = ({ organizationCaseAccessInfo, isEditableCaseInfo }: IProps) => {
  const enableOrgAccessCaseUsers = _filter(organizationCaseAccessInfo, item => _get(item, 'orgCaseAccess'))
  const sizeEnableOrgAccessCaseUsers = _size(enableOrgAccessCaseUsers)

  const [isEditCaseAccess, setIsEditCaseAccess] = useState(false)

  return (
    <>
      <Box>
        <Typography variant="body1" color="#616875">
          Organization Case Access ({sizeEnableOrgAccessCaseUsers})
        </Typography>
      </Box>

      <BoxAdvanced maxHeight="150px">
        <Grid container spacing={1} sx={{ p: 0.25 }}>
          {_map(enableOrgAccessCaseUsers, (item, idx) => (
            <Grid item xs="auto" key={idx.toString()}>
              <Tooltip placement="top" title={_get(item, 'name') || '--'} arrow>
                <Button
                  disableElevation
                  variant="text"
                  sx={{
                    backgroundColor: '#E9EAEB',
                    color: '#485161',
                    fontSize: '13px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    padding: '2px 8px',
                    minHeight: 'auto',
                    '&:hover': {
                      backgroundColor: '#d7d8d9'
                    }
                  }}
                >
                  {_get(item, 'name') || '--'}
                </Button>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </BoxAdvanced>

      {isEditableCaseInfo && (
        <Box sx={{ width: '100%' }} mt={2}>
          <Button
            fullWidth
            disableElevation
            variant={'contained'}
            color={'primary'}
            onClick={() => setIsEditCaseAccess(true)}
            sx={{
              textTransform: 'none'
            }}
          >
            Configure Organization Case Access
          </Button>
        </Box>
      )}

      <DialogUpdateCaseAccess
        organizationCaseAccessInfo={organizationCaseAccessInfo}
        openDialog={isEditCaseAccess}
        handleCloseDialog={() => setIsEditCaseAccess(false)}
      />

      <Box>
        <Divider />
      </Box>
    </>
  )
}

export default OrganizationCaseAccess
