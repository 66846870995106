/** ----------------- PATHNAME -------------------- */
export const generalInformationPath = 'general-information'
export const groupsPath = 'groups'
export const usersPath = 'users'
export const rolesPath = 'application-roles'
export const organizationSecurityPath = 'security'
export const eventHistoryPath = 'event-history'
export const criticalAlertUsers = 'critical-alert-users'

/** ----------------- ROUTE -------------------- */
export const myOrganizationPageURL = '/my-organization'
export const myOrganizationFlexPageURL = '/my-organization/:tabPath'
export const myOrganizationGeneralInfoPageURL = `${myOrganizationPageURL}/${generalInformationPath}`
export const myOrganizationGroupPageURL = `${myOrganizationPageURL}/${groupsPath}`
export const myOrganizationUsersPageURL = `${myOrganizationPageURL}/${usersPath}`
export const myOrganizationRolesPageURL = `${myOrganizationPageURL}/${rolesPath}`
export const myOrganizationSecurityPageURL = `${myOrganizationPageURL}/${organizationSecurityPath}`
export const myOrganizationEventHistoryPageURL = `${myOrganizationPageURL}/${eventHistoryPath}`
export const myOrganizationCriticalAlertUsersPageURL = `${myOrganizationPageURL}/${criticalAlertUsers}`
