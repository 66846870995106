import React, { FC } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'

import { Grid } from '@opswat/react-ui'

import ResourcesSection from './ResourcesSection'
import CasesTable from './table/CasesTable'

interface IProps {
  control: Control
  setValue: UseFormSetValue<any>
  casesInfo: any
}

const CompanyInformation: FC<IProps> = ({ control, setValue, casesInfo }) => {
  return (
    <Grid container spacing={2}>
      <ResourcesSection />
      <Grid item xs={12} sm={6} md={8.5}>
        <CasesTable control={control} setValue={setValue} casesInfo={casesInfo} />
      </Grid>
    </Grid>
  )
}

export default CompanyInformation
