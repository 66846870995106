import { FC, useState } from 'react'

import _get from 'lodash/get'

import { ButtonLoading, DialogAdvanced, Grid, TemplateSection, Typography, TypographyDivider } from '@opswat/react-ui'

import { useNavigate } from 'react-router-dom'

import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'
import { useLazyProfileQuery } from 'myopswat-web/src/api/account'
import { useAccountMfaDisableMutation, useAccountMfaResetMutation } from 'myopswat-web/src/api/mfa'
import { mfaPageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const SecurityMFA: FC<unknown> = () => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [accountMfaReset, { isLoading: isLoadingResetMFA }] = useAccountMfaResetMutation()
  const [accountMfaDisable, { isLoading: isLoadingDisableMFA }] = useAccountMfaDisableMutation()

  const [getProfile] = useLazyProfileQuery()

  const [openDisabledDialog, setOpenDisabledDialog] = useState(false)

  const handleResetMFA = async () => {
    try {
      const response = await accountMfaReset().unwrap()

      if (response?.success) {
        enqueueSnackbar(translate('resetCodeMFASuccess'), {
          variant: 'success'
        })
        handleCreateFile('opswat-recovery-codes', 'txt', _get(response, 'recoveryCodes', ''), {
          type: BLOB_FILE_TYPES.PLAIN
        })
      } else {
        enqueueSnackbar(translate('resetCodeMFAFail'), {
          variant: 'error'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisableMFA = async () => {
    try {
      const response = await accountMfaDisable().unwrap()

      if (response?.success) {
        enqueueSnackbar(translate('disableMFASuccess'), {
          variant: 'success'
        })
        setOpenDisabledDialog(false)
        getProfile()
      } else {
        enqueueSnackbar(translate('disableMFAFail'), {
          variant: 'error'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (_get(profileData, 'accountInfo.isMfaEnabled'))
    return (
      <>
        <TemplateSection spacing={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyDivider label="Multi-factor authentication" propsTypo={{ variant: 'body1' }} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                  <Typography variant="body2" paragraph>
                    Your MFA is activated.
                  </Typography>
                  <Typography variant="body2">
                    Multi-factor authentication creates strong user verification - it is recommended to maintain
                    high-standards of security for access to resources.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {!_get(profileData, 'organizationForceMfa', false) && (
                    <ButtonLoading
                      propsButton={{
                        variant: 'outlined',
                        color: 'inherit',
                        onClick: () => setOpenDisabledDialog(true)
                      }}
                      propsLoading={{ color: 'inherit' }}
                    >
                      Disable MFA
                    </ButtonLoading>
                  )}
                  <br />
                  <ButtonLoading
                    propsButton={{
                      variant: 'outlined',
                      color: 'inherit',
                      onClick: () => handleResetMFA(),
                      sx: { mt: 2 }
                    }}
                    propsLoading={{ color: 'inherit' }}
                    isLoading={isLoadingResetMFA}
                  >
                    Reset Code
                  </ButtonLoading>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TemplateSection>

        <DialogAdvanced
          dialogProps={{
            maxWidth: 'xs'
          }}
          open={openDisabledDialog}
          onClose={() => setOpenDisabledDialog(false)}
          title="Notification"
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">Do you really want to disable Multi-factor Authentication?</Typography>
              </Grid>
            </Grid>
          }
          actions={
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'text',
                    color: 'inherit',
                    onClick: () => setOpenDisabledDialog(false)
                  }}
                  propsLoading={{ color: 'inherit' }}
                  isLoading={false}
                >
                  Cancel
                </ButtonLoading>
              </Grid>
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    disabled: isLoadingDisableMFA,
                    variant: 'contained',
                    color: 'primary',
                    onClick: () => handleDisableMFA()
                  }}
                  propsLoading={{ color: 'inherit' }}
                  isLoading={isLoadingDisableMFA}
                >
                  Yes
                </ButtonLoading>
              </Grid>
            </Grid>
          }
        />
      </>
    )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TypographyDivider label="Multi-factor authentication" propsTypo={{ variant: 'body1' }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs>
              <Typography variant="body2" paragraph>
                Your MFA hasn't been activated yet.
              </Typography>
              <Typography variant="body2">
                Multi-factor authentication creates strong user verification - it is recommended to maintain
                high-standards of security for access to resources.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: () => navigate(mfaPageURL)
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Setup MFA
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SecurityMFA
