import { KEYS, handleDecryptCMToken } from '@myopswat/common'
import { getLocalStorage } from '@opswat/react-core'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_OCM_API_ENDPOINT,
  prepareHeaders: async headers => {
    const accessToken = await handleDecryptCMToken(
      getLocalStorage(KEYS.OCM_TOKEN_KEY) || '',
      process.env.REACT_APP_OCM_JWT_ENC_KEY || ''
    )

    headers.set('Authorization', `Bearer ${accessToken}`)

    return headers
  }
})

export const fetchRequestBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions)

  return result
}
