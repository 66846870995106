import React, { FC } from 'react'

import _map from 'lodash/map'

import { Grid, Typography, TextGrid, Skeleton, Divider } from '@opswat/react-ui'

const DownloadVersionSkeleton: FC<unknown> = () => {
  const infoArray = [
    {
      label: 'Release date'
    },
    {
      label: 'MD5'
    },
    {
      label: 'SHA1'
    },
    {
      label: 'SHA256'
    }
  ]

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={6}>
        <Typography gutterBottom>Platform</Typography>
        <Skeleton animation="wave" variant="rectangular" width="100%" height="160px" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography gutterBottom>Version</Typography>
        <Skeleton animation="wave" variant="rectangular" width="100%" height="160px" />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {_map(infoArray, (item, idx) => (
          <React.Fragment key={idx.toString()}>
            <TextGrid
              label={<Typography variant="body2">{item.label}:</Typography>}
              value={<Skeleton animation="wave" variant="text" width="60%" height="25px" />}
              boxProps={{
                mb: 0
              }}
            />
          </React.Fragment>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {_map(infoArray, (item, idx) => (
            <React.Fragment key={idx.toString()}>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="text" width="100%" height="110px" />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DownloadVersionSkeleton
