import React, { useEffect } from 'react'

import _get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useAccountMfaSetupMutation, useAccountMfaVerifyTokenMutation } from 'myopswat-web/src/api/mfa'
import { myInformationSecurityPageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'

import MFAPage from 'myopswat-web/src/pages/MFAPage'

export default function MFAContainer() {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [accountMfaSetup, { data: accountMfaSetupData, isLoading: isLoadingSetupMFA, error: accountMfaSetupError }] =
    useAccountMfaSetupMutation()
  const [
    accountMfaVerifyToken,
    { data: accountMfaVerifyTokenData, isLoading: isLoadingVerifyMFAToken, error: accountMfaVerifyTokenError }
  ] = useAccountMfaVerifyTokenMutation()

  const handleSetupMFA = async () => {
    try {
      await accountMfaSetup().unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleVerifyMFAToken = async (data: string) => {
    try {
      await accountMfaVerifyToken(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      _get(accountMfaSetupError, 'errors.0.message') ||
      _get(accountMfaSetupError, 'message') ||
      _get(accountMfaSetupData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('setupMFAFail'), {
        variant: 'error'
      })
    }
  }, [accountMfaSetupData, accountMfaSetupError])

  useEffect(() => {
    if (_get(accountMfaVerifyTokenData, 'success')) {
      window.location.replace(myInformationSecurityPageURL)
    } else if (
      _get(accountMfaVerifyTokenError, 'errors.0.message') ||
      _get(accountMfaVerifyTokenError, 'message') ||
      _get(accountMfaVerifyTokenData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('verifyCodeMFAFail'), {
        variant: 'error'
      })
    }
  }, [accountMfaVerifyTokenData, accountMfaVerifyTokenError])

  return (
    <MFAPage
      setupMFAData={accountMfaSetupData}
      profile={profile}
      isLoadingSetupMFA={isLoadingSetupMFA}
      handleSetupMFA={handleSetupMFA}
      isLoadingVerifyMFAToken={isLoadingVerifyMFAToken}
      handleVerifyMFAToken={handleVerifyMFAToken}
    />
  )
}
