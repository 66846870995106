import { FC, useMemo } from 'react'

import { Link, Skeleton, Typography } from '@opswat/react-ui'

import PhoneSection from '../CompanyInformation/PhoneSection'
import WarningMessage from '../components/WarningMessage'

interface IProps {
  supportLevel: any
  isLoading: boolean
  phoneSupportList: any[]
}

const PhoneSupportSection: FC<IProps> = ({ supportLevel, isLoading, phoneSupportList }) => {
  const hasPhoneSupport = useMemo(() => {
    return supportLevel && supportLevel === 'Silver'
  }, [supportLevel])

  const PhoneSupport = useMemo(
    () =>
      hasPhoneSupport ? (
        <PhoneSection supportLevel={supportLevel} phoneSupportList={phoneSupportList} />
      ) : (
        <WarningMessage
          message={
            <Typography variant="caption" color="textSecondary">
              {'Phone Support is only available for higher '}
              <Link
                variant="caption"
                color="primary"
                target="_blank"
                href="https://www.opswat.com/support#software-support-plans"
              >
                {'support level'}
              </Link>
              {', please reach out to your sales representative or regional support leader to find out more.'}
            </Typography>
          }
        />
      ),
    [supportLevel]
  )

  return isLoading ? <Skeleton animation="wave" variant="rectangular" width="100%" height="365px" /> : PhoneSupport
}

export default PhoneSupportSection
