import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ConfirmDialog } from '@opswat/react-ui'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useOrganizationUpdateMutation } from 'myopswat-web/src/api/organization'

interface IProps {
  organization: any
  isEnable: boolean
}

const ConfirmModal = NiceModal.create(({ organization, isEnable }: IProps) => {
  const { t } = useTranslation()
  const modal = useModal()
  const [updateOrganization, { isLoading }] = useOrganizationUpdateMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = async () => {
    try {
      const res = await updateOrganization({
        id: organization.id,
        requiredMfa: isEnable
      }).unwrap()
      if (res.success) {
        enqueueSnackbar(isEnable ? t('enableMFAOrgSuccess') : t('disableMFAOrgSuccess'), { variant: 'success' })
        modal.resolve()
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <ConfirmDialog
      open={modal.visible}
      onExited={() => modal.remove()}
      onClose={() => modal.hide()}
      onConfirm={handleChange}
      isLoading={isLoading}
      content={
        <>
          Changing this setting will affect all the users in this Organization. <br />
          Do you really want to continue?
        </>
      }
    />
  )
})

export default ConfirmModal
