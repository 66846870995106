import React, { FC } from 'react'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _includes from 'lodash/includes'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Typography,
  Tooltip,
  TypographyLineClamp,
  TypographyNoData
} from '@opswat/react-ui'
import { OEM, UTILITY } from 'myopswat-web/src/constants/product-type'
import { formatDatetime } from '@opswat/react-core'
import { DATE_FORMATS } from '@myopswat/common'
import { useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  productDetailData: any
}

const BoxDownloadedView: FC<IProps> = ({ productDetailData }) => {
  const theme = useTheme()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  return (
    <TableContainer component={Box}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 250 }}>
              <Typography>Product</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}>
              <Typography>Platform</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}>
              <Typography>Version</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}>
              <Typography>Download Time</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 200 }}>
              <Typography>Note</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_map(_get(productDetailData, 'downloads'), (item, idx) => (
            <TableRow
              key={idx.toString()}
              sx={{
                '&:nth-of-type(odd)': {
                  backgroundColor: theme.palette.grey[50]
                },
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 250 }}>
                <Typography variant="body2">
                  {_get(item, 'product.name', '--')}&nbsp;
                  {_includes(_get(item, 'product.tags'), UTILITY) && (
                    <Box
                      component="span"
                      sx={{
                        bgcolor: theme.palette.info.main,
                        borderRadius: '4px',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                      }}
                      ml={2}
                    >
                      <Typography variant="caption">Utility</Typography>
                    </Box>
                  )}
                  &nbsp;
                  {_includes(_get(item, 'product.tags'), OEM) && (
                    <Box
                      component="span"
                      sx={{
                        bgcolor: theme.palette.info.main,
                        borderRadius: '4px',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                      }}
                      ml={2}
                    >
                      <Typography variant="caption">OEM</Typography>
                    </Box>
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}
              >
                <Typography variant="body2">{_get(item, 'os', '--')}</Typography>
              </TableCell>
              <TableCell
                sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}
              >
                <Typography variant="body2">{_get(item, 'version', '--')}</Typography>
              </TableCell>
              <TableCell
                sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150, textAlign: 'center' }}
              >
                {formatDatetime(_get(item, 'createdAt'), DATE_FORMATS.DATE_TIME, _get(profileData, 'timezone')) || '--'}
              </TableCell>
              <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 200 }}>
                <Tooltip
                  title={
                    <Typography variant="caption" component="div" sx={{ mb: 1, color: theme.palette.common.white }}>
                      {_get(item, 'integrations', []).toString().replace(/,/g, ', ')}
                      {_get(item, 'otherIntegrations', []).toString() !== ''
                        ? `, ${_get(item, 'otherIntegrations').toString().replace(/,/g, ', ')}`
                        : ''}
                    </Typography>
                  }
                  arrow
                >
                  <Box>
                    <TypographyLineClamp line={2} variant="body2">
                      {_get(item, 'integrations', []).toString().replace(/,/g, ', ')}
                    </TypographyLineClamp>
                  </Box>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {_size(_get(productDetailData, 'downloads')) === 0 && <TypographyNoData />}
    </TableContainer>
  )
}

export default BoxDownloadedView
