import { FC, useMemo } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { useOrganizationQuery } from 'myopswat-web/src/api/organization'
import { useTypedSelector } from 'myopswat-web/src/store'

import {
  myOrganizationCriticalAlertUsersPageURL,
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL
} from 'myopswat-web/src/routes'
import CritalAlertUserList from './CriticalAlertUsers'
import EventHistory from './EventHistory'
import General from './GeneralInformation'
import Roles from './Roles'
import OrganizationSecurity from './Security'
import UserListView from './Users'

const MyOrganizationPage: FC<unknown> = () => {
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const { data, isFetching, refetch } = useOrganizationQuery(_get(profile, 'currentOrganizationId', ''), {
    skip: _isEmpty(_get(profile, 'currentOrganizationId')),
    refetchOnMountOrArgChange: true
  })

  const usingOrganization = useMemo(() => {
    return data || {}
  }, [data])

  const tabArray = useMemo(() => {
    const tabs = [
      {
        label: 'General Information',
        content: <General organization={usingOrganization} isLoading={isFetching} afterSave={refetch} />,
        tabPath: myOrganizationGeneralInfoPageURL
      },

      {
        label: 'Users',
        content: (
          <UserListView isFetching={isFetching} organization={usingOrganization} refetchOrganization={refetch} />
        ),
        tabPath: myOrganizationUsersPageURL
      },
      {
        label: 'Application Roles',
        content: <Roles />,
        tabPath: myOrganizationRolesPageURL
      },
      {
        label: 'Security',
        content: <OrganizationSecurity isLoading={isFetching} organization={usingOrganization} afterSave={refetch} />,
        tabPath: myOrganizationSecurityPageURL
      },
      {
        label: 'Event History',
        content: <EventHistory />,
        tabPath: myOrganizationEventHistoryPageURL
      },
      {
        label: 'Critical Alerts Users',
        content: <CritalAlertUserList isFetching={isFetching} organization={usingOrganization} />,
        tabPath: myOrganizationCriticalAlertUsersPageURL
      }
    ]

    return tabs
  }, [isFetching, usingOrganization])

  return (
    <>
      <TypographyDivider label="My Organization" />
      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default MyOrganizationPage
