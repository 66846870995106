import { useModal } from '@ebay/nice-modal-react'
import { Skeleton, Switch } from '@opswat/react-ui'
import { ORGANIZATION_ADMIN_ROLE } from 'myopswat-web/src/constants'
import { useMemo } from 'react'
import ConfirmModal from './MFA/ConfirmModal'
interface IProps {
  isLoading: boolean
  organization: any
  afterSave: () => void
}

const OrganizationSecurity = ({ isLoading, organization, afterSave }: IProps) => {
  const mfaConfirmModal = useModal(ConfirmModal)
  const isAdmin = useMemo(() => {
    return organization.roles?.includes(ORGANIZATION_ADMIN_ROLE)
  }, [organization])

  const handleChange = (checked: boolean) => {
    mfaConfirmModal
      .show({
        organization: organization,
        isEnable: checked
      })
      .then(() => {
        afterSave()
        mfaConfirmModal.hide()
      })
  }

  return (
    <Switch
      checked={organization?.requiredMfa}
      disabled={!isAdmin}
      label={
        isLoading ? (
          <Skeleton animation="wave" variant="text" width="300px" />
        ) : (
          'Require Multi-factor Authentication for Users'
        )
      }
      handleChange={handleChange}
    />
  )
}

export default OrganizationSecurity
