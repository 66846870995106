import { PageLoading } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { useEffect } from 'react'

export default function RegisterContainer() {
  // const navigate = useNavigate()

  // const [accountRegister, { data, error, isLoading }] = useAccountRegisterMutation()

  // const handleRegister = async (data: IRegisterInput) => {
  //   try {
  //     await accountRegister(data).unwrap()
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  // useEffect(() => {
  //   if (_get(data, 'success', false)) {
  //     navigate(confirmEmailPageURL)
  //   }
  // }, [data])

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_SSO_WEB_HOST}/register` || '/'
  }, [])

  return (
    // <RegisterPage
    //   isLoading={isLoading}
    //   handleRegister={handleRegister}
    //   errorMessage={_get(data, 'errors.0.message') || _get(error, 'errors.0.message') || _get(error, 'message')}
    // />
    <PageLoading background={imageBg} color="primary" />
  )
}
