import React, { FC } from 'react'

import { Box, Link, Typography } from '@opswat/react-ui'

interface IProps {
  icon: React.ReactNode
  label: React.ReactNode
  link?: string
  fontSize?: string
}

const BoxResource: FC<IProps> = ({ icon, label, link, fontSize = '14px' }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F4F4F5',
        padding: '12px',
        '&:hover': {
          backgroundColor: '#EFF4FF',
          cursor: 'pointer'
        }
      }}
      component={Link}
      href={link || ''}
      target="_blank"
      underline="none"
    >
      <Box
        sx={{
          pr: 1.5,
          py: 1.25,
          borderRight: '1px solid #D2D4D6'
        }}
      >
        <Box sx={{ width: '16px', height: '16px' }}>{icon}</Box>
      </Box>
      <Typography noWrap sx={{ paddingInline: '12px', fontSize, lineHeight: '20px' }}>
        {label}
      </Typography>
    </Box>
  )
}

export default BoxResource
