import { FC } from 'react'

import { Chip, Grid, Rating, TextField, Typography } from '@opswat/react-ui'

interface IProps {
  watch: any
  setValue: any
  trigger: any
}

const CSATForm: FC<IProps> = ({ watch, setValue, trigger }) => {
  return (
    <Grid container rowSpacing={2} sx={{ my: 1 }}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}
      >
        <Typography sx={{ fontSize: '13px', lineHeight: '20px', fontWeight: 500 }}>
          How satisfied were you with OPSWAT Support Services on this case?
        </Typography>
        <Rating
          iconSize={60}
          value={watch('csScore')}
          onChange={(_, value) => {
            setValue('csScore', value)
            trigger()
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}
      >
        <Typography sx={{ fontSize: '13px', lineHeight: '20px', fontWeight: 500 }}>
          How satisfied were you with your OPSWAT Product(s) related to this case?
        </Typography>
        <Rating
          iconSize={60}
          value={watch('productScore')}
          onChange={(_, value) => {
            setValue('productScore', value)
            trigger()
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px', my: 2 }}>
        {[
          ...['Helpful & responsive', 'Knowledgeable', 'Quick resolution', 'Exceeded expectation'].map(positiveTag => ({
            label: positiveTag,
            color: '#E1E9FE',
            hoverColor: '#d2ddfc',
            borderColor: '#1D6BFC'
          })),
          ...['Problem not solved', 'Infrequent updates', 'Uninformed agent', 'Problematic product'].map(
            negativeTag => ({
              label: negativeTag,
              color: '#F8DBDB',
              hoverColor: '#facaca',
              borderColor: '#DE4B49'
            })
          )
        ].map((item: any) => (
          <Chip
            key={item.label}
            label={item.label}
            variant="outlined"
            onClick={() => {
              const currentTags = watch('tags')
              setValue(
                'tags',
                currentTags.includes(item.label)
                  ? currentTags.filter((tag: string) => tag !== item.label)
                  : [...currentTags, item.label]
              )
            }}
            sx={{
              backgroundColor: item.color,
              borderColor: watch('tags').includes(item.label) ? item.borderColor : 'transparent',
              borderWidth: '2px',
              color: '#1B273C',
              borderRadius: '50px',
              height: '24px',
              '& .MuiChip-label': {
                fontSize: '12px',
                fontWeight: 400
              },
              '&&:hover': {
                backgroundColor: item.hoverColor,
                height: '24px'
              }
            }}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={5}
          placeholder="Any other comments you would like to add? (optional)"
          sx={{
            '& .MuiInputBase-root': {
              padding: 0
            },
            '& .MuiInputBase-input': {
              padding: '6px 10px'
            },
            '& textarea': {
              resize: 'vertical',
              overflowX: 'hidden'
            }
          }}
          value={watch('csatComment')}
          onChange={e => setValue('csatComment', e.target.value)}
        />
      </Grid>
    </Grid>
  )
}

export default CSATForm
