import React, { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import {
  Typography,
  Skeleton,
  Grid,
  TemplateSection,
  TextGrid,
  TypographyDivider,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@opswat/react-ui'

interface IProps {
  children?: React.ReactNode
}

const GeneralInfoLoading: FC<IProps> = () => {
  const leftInfoArray = [
    {
      label: 'First Name*'
    },
    {
      label: 'Last Name*'
    },
    {
      label: 'Title*'
    },
    {
      label: 'Company*'
    },
    {
      label: 'Phone Number*'
    },
    {
      label: 'Time Zone*'
    }
  ]

  const rightInfoArray = [
    {
      label: 'Address1*'
    },
    {
      label: 'Address2'
    },
    {
      label: 'City*'
    },
    {
      label: 'State'
    },
    {
      label: 'Country*'
    },
    {
      label: 'Postal Code*'
    }
  ]

  const organizationArray = [
    {
      label: 'Organization 1',
      value: '1'
    },
    {
      label: 'Organization 2',
      value: '2'
    },
    {
      label: 'Organization 3',
      value: '3'
    }
  ]

  return (
    <TemplateSection>
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Email</Typography>}
              value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
            />

            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4} sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 2, sm: 0 } }}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TextGrid
            label={<Typography variant="subtitle2">Note</Typography>}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="100px" />}
            containerProps={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TypographyDivider label="Default Organization" propsTypo={{ variant: 'body1' }} />
        </Grid>

        <Grid item xs={12}>
          <RadioGroup defaultValue="1">
            {_map(organizationArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <FormControlLabel
                  value={_get(item, 'value')}
                  control={<Radio disabled={true} />}
                  label={<Skeleton animation="wave" variant="rectangular" width="200px" />}
                />
              </React.Fragment>
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default GeneralInfoLoading
