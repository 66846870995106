import React, { FC, useState, useEffect } from 'react'

import { Box, Typography, Grid, ButtonLoading, CommonOTP } from '@opswat/react-ui'
import { autoFocusInput } from '@opswat/react-core'

interface IProps {
  isLoadingLogin: boolean
  handleRecoveryCode: (data: string) => void
}

const StepRecoveryCode: FC<IProps> = ({ isLoadingLogin, handleRecoveryCode }) => {
  const [otpString, setOtpString] = useState<string>('')

  const handleSubmit = () => {
    handleRecoveryCode(otpString)
  }

  useEffect(() => {
    autoFocusInput()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        minHeight: '500px',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Multifactor Verification</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Enter your recovery code</Typography>
        </Grid>

        <Grid item xs={12}>
          <CommonOTP
            count={11}
            handleSubmit={handleSubmit}
            getValue={setOtpString}
            propsItem={{ xs: true }}
            propsContainer={{ spacing: 0.5 }}
          />
        </Grid>
      </Grid>

      <Box>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: handleSubmit,
            disabled: isLoadingLogin || !otpString,
            fullWidth: true
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isLoadingLogin}
        >
          Sign In
        </ButtonLoading>
      </Box>
    </Box>
  )
}

export default StepRecoveryCode
