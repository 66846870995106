import { FC } from 'react'

import _map from 'lodash/map'
import _range from 'lodash/range'

import { Box, Grid, Skeleton, Switch, Typography, TypographyDivider } from '@opswat/react-ui'

const PreferencesLoading: FC<unknown> = () => {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={10} lg={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TypographyDivider label="Email Subscriptions" propsTypo={{ variant: 'body1' }} />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                {_map(_range(4), (_, index: number) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TypographyDivider label="Interested topic" propsTypo={{ variant: 'body1' }} />
              <Typography variant="caption" color="textSecondary">
                Interested topic are used to provide relevant content of Product Updates on Home page.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                {_map(_range(6), (item, idx) => (
                  <Grid item xs={12} sm={6} key={idx.toString()}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Grid container>
            <Grid item xs={12}>
              <TypographyDivider
                label={
                  <Box display="flex" alignItems="center">
                    My OPSWAT AI Cyber Adviser&ensp;
                    <Box
                      sx={{
                        backgroundColor: '#3D4A68',
                        color: 'white',
                        borderRadius: '4px',
                        px: 1
                      }}
                    >
                      BETA
                    </Box>
                  </Box>
                }
                propsTypo={{ variant: 'body1', component: 'div' }}
              />
              <Typography variant="caption" color="textSecondary">
                My OPSWAT AI Cyber Adviser helps you learn more about OPSWAT Products and industry knowledge. This
                feature is available in Home &rsaquo; Product Details page.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Switch disabled checked={false} label="--" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PreferencesLoading
