import { FC, useEffect, useRef, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { enqueueSnackbar } from 'notistack'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'

import { AttachPaperclip } from '@opswat/react-icon'
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography
} from '@opswat/react-ui'

import { useDeleteMFTFileMutation, useUploadMFTFileSingleMutation } from 'myopswat-web/src/api/analyzer/fileUpload'
import { ISupportCaseCreateInput } from 'myopswat-web/src/api/support/types'
import {
  MAXIMUM_UPLOAD_FILE_NUMBER_SUBMIT_CASE,
  MAXIMUM_UPLOAD_FILE_SIZE_SUBMIT_CASE
} from 'myopswat-web/src/constants'

import { CaseFileItem } from '../components'
import { FileErrors, FileItem, FileStatus } from '../interface'

interface IProps {
  control: Control<ISupportCaseCreateInput>
  setValue: UseFormSetValue<ISupportCaseCreateInput>
  caseId: string
}

const CaseUploadStep: FC<IProps> = ({ control, setValue, caseId }) => {
  const inputFile: any = useRef(null)
  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  const [fileItems, setFileItems] = useState<FileItem[]>(attachments ?? [])
  const [currentFile, setCurrentFile] = useState<File>()
  const [errors, setErrors] = useState<string>('')

  const [uploadFileSingle] = useUploadMFTFileSingleMutation()
  const [deleteFile] = useDeleteMFTFileMutation()

  const onFilesChange = async (event: any) => {
    const fileList: FileList = event.target.files
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0]
      const errorMessage = handleValidateFile(fileList[0])
      if (errorMessage) {
        setErrors(errorMessage)
        setCurrentFile(undefined)
      } else {
        setErrors('')
        setCurrentFile(file)
      }
    }
  }

  const handleUploadFileResult = async (response: any, displayId: string) => {
    const status = _get(response, ['status', 'status'])
    const fileId = _get(response, ['status', 'file_id'])
    const fileSize = _get(response, ['status', 'file_size'])
    const groupId = _get(response, ['group_id'])
    setFileItems(prev =>
      prev.map((item: FileItem) =>
        item.uuid === displayId
          ? {
              ...item,
              fileId,
              fileSize,
              groupId,
              status: status === 'success' ? FileStatus.SUCCESS : FileStatus.ERROR
            }
          : item
      )
    )
  }

  const handleUploadFileError = (error: any, displayId: string) => {
    setFileItems(prev =>
      prev.map((item: FileItem) => (item.uuid === displayId ? { ...item, status: FileStatus.ERROR } : item))
    )
    if (error.status === 429) {
      // Too Many Requests status code
      enqueueSnackbar('Please give the system a moment then try again.', {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(
        _get(
          error,
          ['data', 'error', 'message'],
          'Submitting your file has failed. Please give the system a moment then try again.'
        ),
        {
          variant: 'error'
        }
      )
    }
  }

  const handleUploadFile = async (file: File) => {
    const displayId = crypto.randomUUID()
    setFileItems((prev: any) => [{ file, uuid: displayId, status: FileStatus.IN_PROGRESS }, ...prev])
    setCurrentFile(undefined)

    const formData = new FormData()
    formData.append('file', file)
    await uploadFileSingle(formData)
      .unwrap()
      .then((response: any) => {
        handleUploadFileResult(response, displayId)
      })
      .catch((error: any) => {
        handleUploadFileError(error, displayId)
      })
  }

  const handleRemoveFileItems = (fileId: string) => {
    setFileItems(prev => prev.filter((item: FileItem) => fileId !== item.fileId))
  }

  const handleRemoveFile = async (fileItem: FileItem) => {
    const fileId = fileItem.fileId
    if (fileId) {
      await deleteFile({ file_id: fileItem.fileId, group_id: fileItem.groupId })
        .unwrap()
        .then(() => {
          handleRemoveFileItems(fileId)
        })
    } else {
      setFileItems(prev => prev.filter((item: FileItem) => fileItem.uuid !== item.uuid))
    }
  }

  const handleValidateFile = (file: File) => {
    if (file.size > MAXIMUM_UPLOAD_FILE_SIZE_SUBMIT_CASE) {
      return FileErrors.MAX_FILE_SIZE
    } else if (fileItems.length === MAXIMUM_UPLOAD_FILE_NUMBER_SUBMIT_CASE) {
      return FileErrors.MAX_FILE_NUM
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (fileItems.length < 5 && errors === FileErrors.MAX_FILE_NUM) {
      setErrors('')
    }
  }, [fileItems.length])

  useEffect(() => {
    setValue('attachments', fileItems)
  }, [fileItems])

  return (
    <Grid container columnSpacing={2} sx={{ mt: '0px' }}>
      <Grid item xs={12} sx={{ position: 'sticky', top: 0, py: '16px', backgroundColor: '#FFFFFF', zIndex: 3 }}>
        <Typography variant="subtitle2" marginBottom={1.5}>
          Case <strong>{`#${caseId}`}</strong> has been created. You can attach files to this case below. You can skip
          this step if not required.
        </Typography>
        <Typography variant="subtitle1" height="20px" fontSize="12px" mb={0.5}>
          Attach Files (optional)
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            mb: 1
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Select File"
            value={_get(currentFile, 'name') ?? ''}
            error={!!errors}
            onClick={() => inputFile?.current?.click()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4px' }}>
                      <AttachPaperclip />
                    </Box>
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& :hover': {
                cursor: 'pointer'
              },
              '& .MuiOutlinedInput-root': { paddingRight: 0 },
              '& .MuiFormHelperText-root': { margin: 0, marginTop: 1 },
              minHeight: '0px'
            }}
          />
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={!currentFile}
            onClick={() => currentFile && handleUploadFile(currentFile)}
          >
            Upload
          </Button>
        </Box>
        <FormHelperText
          sx={{
            color: '#707682',
            fontSize: '11px',
            fontStyle: 'italic',
            lineHeight: '16px',
            fontWeight: 400
          }}
        >
          Maximum number of uploaded files: 5
        </FormHelperText>
        <FormHelperText
          sx={{
            color: '#707682',
            fontSize: '11px',
            fontStyle: 'italic',
            lineHeight: '16px',
            fontWeight: 400
          }}
        >
          Maximum upload file size: 2GB
        </FormHelperText>
        {!!errors && (
          <FormHelperText
            sx={{
              fontSize: '11px',
              fontStyle: 'italic',
              lineHeight: '16px',
              fontWeight: 400,
              color: '#D00300'
            }}
          >
            {errors}
          </FormHelperText>
        )}
        <input
          type="file"
          id="submit-case-file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onFilesChange}
          onClick={(event: any) => {
            event.target.value = null // to allow re-uploads of the same file
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List sx={{ padding: 0 }}>
          {_map(fileItems, (item: FileItem) => (
            <CaseFileItem
              key={item.uuid}
              fileItem={item}
              handleRemoveFile={() => {
                handleRemoveFile(item)
              }}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default CaseUploadStep
