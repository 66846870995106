import React, { FC } from 'react'

import _differenceInDays from 'date-fns/differenceInDays'
import _get from 'lodash/get'
import _parseInt from 'lodash/parseInt'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'

import { formatDatetime } from '@opswat/react-core'
import { Alert, Grid, TextGrid, Typography } from '@opswat/react-ui'
import { useTranslation } from 'react-i18next'
import { DATE_FORMATS } from '@myopswat/common'

interface IProps {
  data: any
}

const getLicenseData = (data: any, key: string) => {
  const value = _get(data, key)
  if (value instanceof String && _isEmpty(value)) return '--'
  return value
}

const BoxMDCloud: FC<IProps> = ({ data }) => {
  const { t: translate } = useTranslation()

  const handleCheckStatus = (date: any) => {
    if (!date) return '--'

    if (date >= 90)
      return (
        <Typography variant="body1" color="success.dark">
          Active
        </Typography>
      )
    if (date < 90 && date >= 30)
      return (
        <Typography variant="body1" color="warning.dark">
          Expiring
        </Typography>
      )
    if (date < 30 && date >= 0)
      return (
        <Typography variant="body1" color="error.dark">
          Expiring
        </Typography>
      )
    if (date < 0)
      return (
        <Typography variant="body1" color="error.dark">
          Expired
        </Typography>
      )

    return '--'
  }

  const handleRenderRequestText = (request: number | string) => {
    if (_parseInt(request.toString()) > 1) return translate('requestsPerDay')
    return translate('requestPerDay')
  }

  const handleRenderHashesText = (request: number | string) => {
    if (_parseInt(request.toString()) > 1) return translate('hashesPerDay')
    return translate('hashPerDay')
  }

  const MDCloudLeftData = [
    {
      label: 'Prevention',
      value: getLicenseData(data, 'accountInfo.mdc.mdcPreventionApi'),
      subValue: handleRenderRequestText(getLicenseData(data, 'accountInfo.mdc.mdcPreventionApi'))
    },
    {
      label: 'Reputation',
      value: getLicenseData(data, 'accountInfo.mdc.mdcReputationApi'),
      subValue: handleRenderRequestText(getLicenseData(data, 'accountInfo.mdc.mdcReputationApi'))
    },
    {
      label: 'Sandbox',
      value: getLicenseData(data, 'accountInfo.mdc.sandboxApiLimit'),
      subValue: handleRenderRequestText(getLicenseData(data, 'accountInfo.mdc.sandboxApiLimit'))
    },
    {
      label: 'Analysis',
      value: getLicenseData(data, 'accountInfo.mdc.mdcAnalysisLimit'),
      subValue: handleRenderRequestText(getLicenseData(data, 'accountInfo.mdc.mdcAnalysisLimit'))
    },
    {
      label: 'Feed',
      value: getLicenseData(data, 'accountInfo.mdc.mdcFeedLimit'),
      subValue: handleRenderHashesText(getLicenseData(data, 'accountInfo.mdc.mdcFeedLimit'))
    },
    {
      label: 'Throttle',
      value: getLicenseData(data, 'accountInfo.mdc.mdCloudThrottle')
    }
  ]

  const MDCloudRightData = [
    {
      label: 'Type',
      value: _get(data, 'accountInfo.mdc.mdCloudLicenseType')
        ? _startCase(_get(data, 'accountInfo.mdc.mdCloudLicenseType'))
        : '--'
    },
    {
      label: 'License Expiration',
      value: formatDatetime(_get(data, 'accountInfo.mdc.mdcExpiration'), DATE_FORMATS.DATE) || '--'
    },
    {
      label: 'Status',
      value: handleCheckStatus(_differenceInDays(new Date(_get(data, 'accountInfo.mdc.mdcExpiration', '')), new Date()))
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={5}>
        {_map(MDCloudLeftData, (item, idx) => (
          <React.Fragment key={idx.toString()}>
            <TextGrid
              label={item?.label}
              value={
                <Typography variant="inherit">
                  {item?.value}&nbsp;
                  {item?.value !== '--' && (
                    <Typography variant="subtitle2" component="span">
                      {item?.subValue}
                    </Typography>
                  )}
                </Typography>
              }
              labelColProps={{ xs: 'auto', sm: 6 }}
            />
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        {_map(MDCloudRightData, (item, idx) => (
          <React.Fragment key={idx.toString()}>
            <TextGrid label={item?.label} value={item?.value} labelColProps={{ xs: 'auto', sm: 6 }} />
          </React.Fragment>
        ))}
      </Grid>

      {_get(data, 'accountInfo.mdcLicenseManageBy') === 'organization' && (
        <Grid item xs={12} sm={6} md={5}>
          <Alert
            variant="outlined"
            severity="info"
            sx={{
              border: 0,
              ml: -2
            }}
          >
            Your MetaDefender Cloud limits are controlled by the organization.
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default BoxMDCloud
