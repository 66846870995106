import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    sysItemCountries: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            sysItemCountries {
              id
              name
              code
              slug
            }
          }
        `
      })
    }),
    sysItemRegions: builder.query<any, number>({
      query: countryId => ({
        document: gql`
        query {
          sysItemRegions(countryId: ${countryId}) {
              id
              name
              countryId
          }
      }
        `
      })
    }),
    sysItemIndustries: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            sysItemIndustries {
              name
              code
            }
          }
        `
      })
    }),
    systemValueList: builder.query<any, string[]>({
      query: keys => ({
        document: gql`
          query ($keys: [String]) {
            systemValueList(keys: $keys) {
              data
              key
            }
          }
        `,
        variables: { keys }
      })
    })
  })
})

export const {
  useSysItemCountriesQuery,
  useLazySysItemCountriesQuery,
  useSysItemRegionsQuery,
  useSysItemIndustriesQuery,
  useLazySysItemRegionsQuery,
  useLazySystemValueListQuery
} = api
