import { Box, Button, Typography } from '@opswat/react-ui'
import { Link } from 'react-router-dom'
import { homePageURL } from 'myopswat-web/src/routes'

const NoPermissionPage = () => {
  return (
    <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Typography variant="h1">Sorry, there is a problem.</Typography>
      <Typography variant="body2">You do not have the permission to view this content.</Typography>
      <Button sx={{ mt: '2rem' }} variant="contained" component={Link} to={`${homePageURL}`} color="primary">
        Go to Homepage
      </Button>
    </Box>
  )
}

export default NoPermissionPage
