import { useMemo, useState } from 'react'

import _get from 'lodash/get'

import {
  Box,
  PageLoading,
  SnackbarAlert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyNoData,
  useTheme
} from '@opswat/react-ui'

import { EnginePackage } from 'myopswat-web/src/api/engine-status/types'
import { useTypedSelector } from 'myopswat-web/src/store'
import PackageView from './package/PackageView'

interface IProps {
  list: EnginePackage[]
  isLoading: boolean
}

const EnginePackagesView = ({ list, isLoading }: IProps) => {
  const theme = useTheme()
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleAfterCopy = () => {
    setIsOpen(true)
  }

  const loading = useMemo(() => {
    return isLoading || !_get(profile, 'email')
  }, [isLoading, profile])

  return (
    <>
      {loading && <PageLoading color="primary" />}
      {list && list.length > 0 ? (
        <TableContainer component={Box}>
          <Table stickyHeader aria-label="engine packages">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 230 }}>
                  <Typography>Package Name</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100 }}>
                  <Typography>Timestamp</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 200 }}>
                  <Typography>Hash</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item, idx) => {
                return (
                  <PackageView
                    key={`package-view-${idx}`}
                    timezone={_get(profile, 'timezone')}
                    item={item}
                    afterCopy={handleAfterCopy}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TypographyNoData />
      )}
      <SnackbarAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        messages={'Copied hash successfully.'}
        severity={'success'}
      />
    </>
  )
}

export default EnginePackagesView
