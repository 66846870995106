import { useForm } from 'react-hook-form'

import _get from 'lodash/get'

import { Grid, TemplateSection } from '@opswat/react-ui'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'

import CasesInformation from './CasesInformation'
import CompanyInformation from './CompanyInformation'
import UserInformation from './UserInformation'

interface IProps {
  data: any
  isFetching: boolean
  systemValueInfo: any[]
  openSubmitCaseDialog: () => void
}

const SupportAvailable = ({ data, isFetching, systemValueInfo, openSubmitCaseDialog }: IProps) => {
  const { control, setValue } = useForm<any>({
    defaultValues: {
      filters: {
        caseType: 'myOpen'
      },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7} md={9.5}>
        <TemplateSection spacing={2.5}>
          <CasesInformation
            isLoading={isFetching}
            supportInfo={data?.supportInfo}
            contactId={data?.contactId}
            casesInfo={_get(data, 'casesInfo') || {}}
            openSubmitCaseDialog={openSubmitCaseDialog}
            control={control}
            setValue={setValue}
          />
          <CompanyInformation control={control} setValue={setValue} casesInfo={_get(data, 'casesInfo') || {}} />
        </TemplateSection>
      </Grid>
      <Grid item xs={12} sm={5} md={2.5}>
        <UserInformation isLoading={isFetching} companyInfo={data} systemValueInfo={systemValueInfo} />
      </Grid>
    </Grid>
  )
}

export default SupportAvailable
