import React from 'react'

import { Box, Button, Divider, Grid, TemplateSection, TextField, Typography } from '@opswat/react-ui'

import _isEmpty from 'lodash/isEmpty'

import { ORGANIZATION_ADMIN_ROLE } from 'myopswat-web/src/constants'
import { useMemo } from 'react'
import TextGridView from '../TextGridView'

interface IProps {
  organization: any
  countries: any[]
  onEdit: () => void
}

const BasicView = ({ organization, countries, onEdit }: IProps) => {
  const countryMap = useMemo(() => {
    if (_isEmpty(countries)) return {}
    return countries.reduce(
      (obj: any, country: { code: string; name: string }) => ((obj[country.code] = country.name), obj),
      {}
    )
  }, [countries])

  const leftInfos = useMemo(() => {
    return [
      {
        label: 'Owner',
        value: organization.owner
      },
      {
        label: 'Website',
        value: organization.website || ''
      }
    ]
  }, [organization])

  const billingAddressInfos = useMemo(() => {
    return [
      {
        label: 'Address 1*',
        value: organization.billingAddress?.streetAddress1 || ''
      },
      {
        label: 'Address 2',
        value: organization.billingAddress?.streetAddress2 || ''
      },
      {
        label: 'City*',
        value: organization.billingAddress?.city || ''
      },
      {
        label: 'Country*',
        value: countryMap[organization.billingAddress?.country] || ''
      },
      ...(organization.billingAddress?.country === 'US'
        ? [
            {
              label: 'State',
              value: organization.billingAddress?.state || ''
            }
          ]
        : []),
      {
        label: 'Postal Code*',
        value: organization.billingAddress?.postalCode || ''
      },
      {
        label: 'Phone Number*',
        value: organization.billingAddress?.phone || ''
      }
    ]
  }, [organization.billingAddress])

  const shippingAddressInfos = useMemo(() => {
    return [
      {
        label: 'Address 1*',
        value: organization.shippingAddress?.streetAddress1 || ''
      },
      {
        label: 'Address 2',
        value: organization.shippingAddress?.streetAddress2 || ''
      },
      {
        label: 'City*',
        value: organization.shippingAddress?.city || ''
      },
      {
        label: 'Country*',
        value: countryMap[organization.shippingAddress?.country] || ''
      },
      ...(organization.shippingAddress?.country === 'US'
        ? [
            {
              label: 'State',
              value: organization.shippingAddress?.state || ''
            }
          ]
        : []),
      {
        label: 'Postal Code*',
        value: organization.shippingAddress?.postalCode || ''
      },
      {
        label: 'Phone Number*',
        value: organization.shippingAddress?.phone || ''
      }
    ]
  }, [organization.shippingAddress])

  const isAdmin = useMemo(() => {
    return organization.roles?.includes(ORGANIZATION_ADMIN_ROLE)
  }, [organization])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              {isAdmin && (
                <Button variant="contained" onClick={onEdit} color="primary">
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TemplateSection>
        <Grid container sx={{ mt: isAdmin ? -4 : 0 }}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView label="Name*" value={organization.name} />
              </Grid>

              {leftInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<TextField disabled fullWidth size="small" value={item.value} />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <Divider />
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" paragraph>
                  Billing Address
                </Typography>
              </Grid>
              {billingAddressInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<TextField disabled value={item.value} fullWidth size="small" variant="outlined" />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4} sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 2, sm: 0 } }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" paragraph>
                  Shipping Address
                </Typography>
              </Grid>
              {shippingAddressInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<TextField disabled value={item.value} fullWidth size="small" variant="outlined" />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <Divider />
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView
                  containerProps={{
                    sx: {
                      alignItems: 'flex-start'
                    }
                  }}
                  label={<Typography>Note</Typography>}
                  value={
                    <TextField disabled fullWidth rows={6} multiline size="small" value={organization?.note || ''} />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TemplateSection>
    </>
  )
}

export default BasicView
