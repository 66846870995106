import { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { Grid, TextGrid } from '@opswat/react-ui'
import React from 'react'

interface IProps {
  data: any
}

const BoxMAccess: FC<IProps> = ({ data }) => {
  const MALeftData = [
    {
      label: 'Number of Devices',
      value: _get(data, 'accountInfo.ma.maLicMaxDevices') || '--'
    },
    {
      label: 'License Expiration',
      value: _get(data, 'accountInfo.ma.maExpirationDate') || '--'
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={5}>
        {_map(MALeftData, (item, idx) => (
          <React.Fragment key={idx.toString()}>
            <TextGrid label={item?.label} value={item?.value} labelColProps={{ xs: 'auto', sm: 6 }} />
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default BoxMAccess
