import { useEffect } from 'react'

import _isEmpty from 'lodash/isEmpty'
import ReportFalseDetectionPage from 'myopswat-web/src/pages/ReportFalseDetectionPage'
import { reportFalseDetectionSubmitPageURL } from 'myopswat-web/src/routes'
import { useNavigate, useParams } from 'react-router-dom'

export default function ReportFalseDetectionContainer() {
  const { tabPath = '' } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (_isEmpty(tabPath)) {
      navigate(reportFalseDetectionSubmitPageURL)
    }
  }, [])
  return <ReportFalseDetectionPage />
}
