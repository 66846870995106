import { useEffect } from 'react'

import { handleClearCookie } from '@myopswat/common'
import { PageLoading } from '@opswat/react-ui'

import { useLazySaml2LogoutQuery } from 'myopswat-web/src/api/sso'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'

export default function LogoutContainer() {
  const [saml2Logout] = useLazySaml2LogoutQuery()

  useEffect(() => {
    try {
      saml2Logout()
        .unwrap()
        .then(async (response: any) => {
          await handleClearCookie()
          window.location.href = response.url
        })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return <PageLoading background={imageBg} color="primary" />
}
