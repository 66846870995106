import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

import { IUserUnsubscribeEmailInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    userUnsubscribe: builder.mutation<any, IUserUnsubscribeEmailInput>({
      query: ({ code }) => ({
        document: gql`
          mutation ($input: UserUnsubscribeEmailInput!) {
            userUnsubscribe(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            code: code
          }
        }
      })
    })
  })
})

export const { useUserUnsubscribeMutation } = api
