import React, { FC } from 'react'

import _get from 'lodash/get'

import {
  Autocomplete,
  Box,
  ButtonLoading,
  Grid,
  TemplateSection,
  TextField,
  Typography,
  TypographyDivider,
  TypographyLink
} from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

interface IInvoiceNumber {
  name: string
  code: string
}

interface IForm {
  os1: string
  os0: string
  hosted_button_id: IInvoiceNumber
}

const PaymentPage: FC<unknown> = () => {
  const invoiceNumberArray = [
    {
      name: 'USD',
      code: '4ZB3E4JNH34T2'
    },
    {
      name: 'GBP',
      code: '3MTPXFGNX3ZCQ'
    },
    {
      name: 'AUD',
      code: 'HBC72BP45SJ9S'
    },
    {
      name: 'EUR',
      code: 'VUNUXG3ZEQX58'
    }
  ]

  const defaultValues = {
    os1: '',
    os0: '',
    hosted_button_id: _get(invoiceNumberArray, '0', {
      name: 'USD',
      code: '4ZB3E4JNH34T2'
    })
  }

  const schema = yup.object().shape({
    os1: yup.string().trim().required('Account Name cannot be blank'),
    os0: yup.string().trim().required('Invoice Number cannot be blank'),
    hosted_button_id: yup.object().shape({
      name: yup.string().trim().required('Invoice Currency cannot be blank'),
      code: yup.string().trim().required('Invoice Currency cannot be blank')
    })
  })

  const {
    control,
    trigger,
    watch,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  return (
    <form id="myForm" action="https://www.paypal.com/cgi-bin/webscr" method="post">
      <input name="cmd" type="hidden" value="_s-xclick" />

      <TemplateSection spacing={2}>
        <Box>
          <TypographyDivider label="Credit Card Payment" />
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography>Please enter your account name and invoice number to continue.</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <Typography variant="subtitle1">Account Name*</Typography>
            <input name="on1" type="hidden" value="Account Name" />

            <Controller
              name="os1"
              control={control}
              render={(cProps: any) => (
                <TextField
                  value={cProps.field.value}
                  onChange={(e: any) => cProps.field.onChange(e.target.value)}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'os1', '')}
                  helperText={_get(errors, 'os1.message', '')}
                  required
                  onBlur={() => {
                    trigger('os1')
                  }}
                  inputProps={{
                    name: 'os1'
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <Typography variant="subtitle1">Invoice Numbers*</Typography>
            <input name="on0" type="hidden" value="Invoice Number" />

            <Controller
              name="os0"
              control={control}
              render={(cProps: any) => (
                <TextField
                  value={cProps.field.value}
                  onChange={(e: any) => cProps.field.onChange(e.target.value)}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'os0', '')}
                  helperText={_get(errors, 'os0.message', '')}
                  required
                  onBlur={() => {
                    trigger('os0')
                  }}
                  inputProps={{
                    name: 'os0'
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <Typography variant="subtitle1">Invoice Currency*</Typography>
            <Controller
              name="hosted_button_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  options={invoiceNumberArray}
                  value={value}
                  getOptionLabel={(option: IInvoiceNumber) => _get(option, 'name', '')}
                  onChange={(event: any, newValue: IInvoiceNumber) => {
                    onChange(newValue)
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      error={!!_get(errors, 'hosted_button_id', '')}
                      helperText={_get(errors, 'hosted_button_id.name.message', '')}
                    />
                  )}
                />
              )}
            />

            <input name="hosted_button_id" type="hidden" value={_get(watch('hosted_button_id'), 'code', '')} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              You will be redirected to PayPal to complete payment. You do not need a PayPal account to complete
              payment. I agree to the My OPSWAT&nbsp;
              <TypographyLink href={`${process.env.REACT_APP_OPSWAT_URL}/legal/privacy-policy`} target="_blank">
                Privacy Policy
              </TypographyLink>
              .
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disabled: !watch('os0') || !watch('os1'),
                fullWidth: true,
                type: 'submit'
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Pay Now using PayPal
            </ButtonLoading>
          </Grid>
        </Grid>
      </TemplateSection>
    </form>
  )
}

export default PaymentPage
