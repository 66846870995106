import { FC } from 'react'

import { Grid, Typography, TypographyLink } from '@opswat/react-ui'

interface IProps {
  caseId: string
}

const CaseSuccessStep: FC<IProps> = ({ caseId }) => {
  return (
    <Grid
      container
      rowSpacing={3}
      sx={{
        mt: '0px',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography textAlign="center" paragraph component="div" variant="body2" maxWidth="400px">
        Our dedicated team is currently reviewing your case, and we will be in touch with you very soon. Click&nbsp;
        <TypographyLink
          color="primary"
          fontWeight="500"
          href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${caseId}`}
        >
          here
        </TypographyLink>
        &nbsp;to check the latest status, attach more files, and comment on the case.
      </Typography>

      <Typography textAlign="center" paragraph component="div" variant="body2" maxWidth="400px">
        In the meantime, I encourage you to explore our&nbsp;
        <TypographyLink color="primary" fontWeight="500" href={process.env.REACT_APP_OPSWAT_DOCS_URL}>
          OPSWAT Knowledge Center
        </TypographyLink>
        , where you can find additional resources that might help you find answers or solutions. If there are any
        specific details or additional concerns, you would like to share with us. Simply update the case, and we will
        ensure that your message reaches the right person on our team.
      </Typography>
    </Grid>
  )
}

export default CaseSuccessStep
