/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-namespace */
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

export declare namespace DocSearcherNS {
  type KeyValue = {
    [key: string]: any
  }
  type ScanListenerCallback = (e: MouseEvent, docList: string[]) => void
  type Result = any
}

class DocSearcher {
  private static instance: DocSearcher
  private cb!: DocSearcherNS.ScanListenerCallback
  private observer!: MutationObserver
  constructor() {}

  public static getInstance(): DocSearcher {
    if (!DocSearcher.instance) DocSearcher.instance = new DocSearcher()
    return DocSearcher.instance
  }

  private mouseUpEvent = _debounce(async (e: MouseEvent) => {
    try {
      const targetEl = e.target as HTMLElement
      const isCheckTargetEl = targetEl.tagName === 'INPUT' || targetEl.tagName === 'TEXTAREA' ? false : true

      if (isCheckTargetEl) {
        const scanText = this.getSelectionText()
        let finalText = ''

        if (scanText.length > 0) {
          const targetText = targetEl.innerText || ''
          if (targetText.includes(scanText)) finalText = targetText
          if (finalText === '') finalText = scanText
        }

        if (finalText.length > 0) {
          this.cb(e, [scanText, finalText])
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, 500)

  private getSelectionText = () => {
    let text = ''
    try {
      if (window.getSelection) {
        text = window.getSelection()!.toString()
      }
    } catch (error) {
      text = ''
      console.error(error)
    }

    return text
  }

  public addScanTextListener = (div: HTMLElement, cb: DocSearcherNS.ScanListenerCallback) => {
    this.cb = cb
    div.addEventListener('mouseup', this.mouseUpEvent)
  }

  public removeScanTextListener = (div: HTMLElement) => {
    div.removeEventListener('mouseup', this.mouseUpEvent)
    if (this.observer) this.observer.disconnect()
  }
}

const DocSearcherSDK = DocSearcher.getInstance()
export default DocSearcherSDK
