import { FC, useCallback } from 'react'

import _get from 'lodash/get'
import _parseInt from 'lodash/parseInt'

import { Grid, TextField, ButtonLoading, Typography, Box, useTheme } from '@opswat/react-ui'
import { DownloadIcon } from '@opswat/react-icon'
import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ILicenseActivateInput } from 'myopswat-web/src/api/product/types'
import { useLazyCheckDisplayNPSQuery } from 'myopswat-web/src/api/survey'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch } from 'myopswat-web/src/store'

interface IProps {
  data: any
  isLoadingLicenseActivate: boolean
  handleLicenseActivate: (data: ILicenseActivateInput) => void
  setOpenDialog: (data: boolean) => void
  step: 'activate' | 'download'
  licenseActivateData: any
}

interface IForm {
  product: string
  key: string
  deployment: string
  description: string
  quantity: string
}

const BoxCommonDialogActivate: FC<IProps> = ({
  data,
  setOpenDialog,
  handleLicenseActivate,
  isLoadingLicenseActivate,
  step,
  licenseActivateData
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [checkDisplayNPS] = useLazyCheckDisplayNPSQuery()

  const allowQuantityInput: (productId: string) => boolean = productId => {
    return [PRODUCT_IDS.MD_DRIVE as string, PRODUCT_IDS.MD_CORE as string].includes(productId)
  }

  const isEmailSecurity: (productId: string) => boolean = productId => {
    return [
      PRODUCT_IDS.EMAIL_SECURITY as string,
      PRODUCT_IDS.EMAIL_SECURITY_BUNDLE as string,
      PRODUCT_IDS.EMAIL_SECURITY_STANDALONE as string
    ].includes(productId)
  }

  const getQuantityDefaultValue: (productId: string) => string = productId => {
    if (isEmailSecurity(productId)) return '-1'
    return '1'
  }

  const currentProductSlug = _get(data, 'productId', '')

  const defaultValues = {
    product: _get(data, 'product', ''),
    key: _get(data, 'key', ''),
    deployment: '',
    description: '',
    quantity: getQuantityDefaultValue(currentProductSlug)
  }

  const schema = yup.object().shape({
    product: yup.string().trim().required('Product cannot be blank'),
    key: yup.string().trim().required('License Key cannot be blank'),
    deployment: yup.string().trim().required('Deployment ID cannot be blank'),
    quantity: yup
      .string()
      .trim()
      .required('Requested Number Of Agents cannot be blank')
      .matches(/^-?[0-9]*$/, 'Requested Number Of Agents should only contain numbers')
  })
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (item: any) => {
    handleLicenseActivate({
      productId: _get(data, 'productId'),
      key: _get(data, 'key'),
      description: _get(item, 'description'),
      deployment: _get(item, 'deployment'),
      quantity: _parseInt(_get(item, 'quantity'), 10)
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleActivate = () => {
    handleCreateFile(watch('deployment'), 'yml', _get(licenseActivateData, 'licenseText'), {
      type: BLOB_FILE_TYPES.YAML
    })
  }

  const handleCheckDisplayNPS = useCallback(async () => {
    await checkDisplayNPS({}).then((response: any) => {
      const showDialog = response?.data ?? false
      if (showDialog) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.NPS_SURVEY]: true,
            [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'response-action',
            [DIALOGS_WEB.NPS_NOTIFICATION]: false
          })
        )
      }
    })
  }, [])

  if (step === 'download')
    return (
      <Box
        sx={{
          display: 'flex',
          height: 'auto',
          minHeight: '400px',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="body2">License file is now available to download.</Typography>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => {
                  setOpenDialog(false)
                }
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disabled: false,
                startIcon: <DownloadIcon color="white" />,
                onClick: () => {
                  handleActivate()
                  setOpenDialog(false)
                  handleCheckDisplayNPS()
                }
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Download
            </ButtonLoading>
          </Grid>
        </Grid>
      </Box>
    )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Product*</Typography>
        <TextField
          {...register('product')}
          fullWidth
          size="small"
          error={!!_get(errors, 'product.message', '')}
          helperText={_get(errors, 'product.message')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
          onBlur={() => {
            trigger('product')
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">License Key*</Typography>

        <TextField
          {...register('key')}
          fullWidth
          size="small"
          error={!!_get(errors, 'key.message', '')}
          helperText={_get(errors, 'deployment.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
          onBlur={() => {
            trigger('key')
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Deployment ID*</Typography>

        <TextField
          {...register('deployment')}
          fullWidth
          size="small"
          error={!!_get(errors, 'deployment.message', '')}
          helperText={_get(errors, 'deployment.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
          onBlur={() => {
            trigger('deployment')
          }}
        />
      </Grid>

      {allowQuantityInput(currentProductSlug) && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">Requested Number Of Agents</Typography>
          {currentProductSlug === PRODUCT_IDS.MD_CORE && (
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.dark
              }}
            >
              <i>Update if this is MetaDefender Core v4.x</i>
            </Typography>
          )}

          <TextField
            {...register('quantity')}
            fullWidth
            size="small"
            error={!!_get(errors, 'quantity.message', '')}
            helperText={_get(errors, 'quantity.message', '')}
            required
            onKeyUp={(e: any) => {
              if (_get(e, 'keyCode') === 13) {
                handleSubmit(onSuccess, onFail)()
              }
            }}
            onBlur={() => {
              trigger('quantity')
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="subtitle1">Description</Typography>

        <TextField
          {...register('description')}
          minRows={4}
          multiline
          fullWidth
          sx={{
            '& textarea': {
              resize: 'vertical'
            }
          }}
          size="small"
          error={!!_get(errors, 'description.message', '')}
          helperText={_get(errors, 'description.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => setOpenDialog(false)
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled: isLoadingLicenseActivate,
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingLicenseActivate}
            >
              Activate
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxCommonDialogActivate
