import { FC, useContext } from 'react'

import _get from 'lodash/get'

import { ButtonLoading, DialogAdvanced, Divider, Grid, Typography } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/feedback-bg.png'
import thankYouImg from 'myopswat-web/src/assets/images/thank-you-feedback.svg'

import { DialogNPSSurveyContext } from './interface'

const DialogNPSSuccess: FC<any> = () => {
  const { surveyData, openSuccessDialog, handleCloseSuccessDialog, handleOpenSurveyDialog } =
    useContext(DialogNPSSurveyContext)

  const surveyTitle = _get(surveyData, 'name', 'Product Feedback')

  return (
    <DialogAdvanced
      background={imageBg}
      open={openSuccessDialog}
      title={surveyTitle}
      content={
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            sx={{
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 2.5
            }}
          >
            <Typography variant="h3">Thank you for your feedback!</Typography>
            <img src={thankYouImg} />
            <Typography variant="body2">
              We leverage your valuable insights to drive OPSWAT products <br />
              improvement and innovation.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Divider />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'text',
                    color: 'inherit',
                    disableElevation: true,
                    onClick: () => handleCloseSuccessDialog(true)
                  }}
                >
                  Done
                </ButtonLoading>
              </Grid>
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    disableElevation: true,
                    onClick: () => {
                      handleCloseSuccessDialog()
                      handleOpenSurveyDialog()
                    }
                  }}
                >
                  Submit Another Response
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogContentProps={{
        sx: {
          paddingBottom: '0px'
        }
      }}
    />
  )
}

export default DialogNPSSuccess
