import React, { FC, useCallback, useEffect, useState, useRef } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _range from 'lodash/range'

import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Typography,
  TypographyLink,
  TypographyLineClamp,
  ListItemButton
} from '@opswat/react-ui'
import { DATE_FORMATS } from '@myopswat/common'

import { homePageURL } from 'myopswat-web/src/routes'
import { useLazyGetRecentReleaseNotificationsQuery } from 'myopswat-web/src/api/notification'
import { formatDatetime } from '@opswat/react-core'
import { useNavigate } from 'react-router-dom'

const NotificationProduct: FC<unknown> = () => {
  const navigate = useNavigate()
  const loadingLatestReleaseArray = _range(5)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [releaseNotiList, setReleaseNotiList] = useState<any[]>([])
  const contentRef = useRef<any>(null)
  const [gridSizes, setGridSizes] = useState<any>({
    product: 4,
    platform: 4,
    version: 2.1,
    releaseDate: 1.9
  })

  const [getLatestReleasesNotifications, { isFetching: isFetchingNotiData }] =
    useLazyGetRecentReleaseNotificationsQuery()

  const handleGetRecentReleaseNotifications = useCallback(() => {
    try {
      getLatestReleasesNotifications({ page: currentPage, pageSize: 5 })
        .unwrap()
        .then(response => {
          setReleaseNotiList(prev => [...prev, ...response.results])
          setTotalCount(response.totalCount)
        })
    } catch (err) {
      console.error(err)
    }
  }, [currentPage])

  const LatestProductReleaseComponent = () => {
    if (isFetchingNotiData && _size(releaseNotiList) === 0)
      return (
        <>
          {_map(loadingLatestReleaseArray, (item, idx) => (
            <React.Fragment key={idx.toString()}>
              <Skeleton animation="wave" variant="text" width="100%" height="70px" />
            </React.Fragment>
          ))}
        </>
      )

    return (
      <Grid container spacing={1} alignItems="center">
        {_map(releaseNotiList, (item, idx: number) => (
          <React.Fragment key={idx.toString()}>
            <Grid item xs={4}>
              <TypographyLink
                onClick={e => {
                  navigate(`${homePageURL}/${_get(item, 'productSlug')}`)
                  e.preventDefault()
                }}
                href={`${homePageURL}/${_get(item, 'productSlug')}`}
                variant="subtitle2"
                underline="hover"
                color="primary"
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              >
                {_get(item, 'productName', '--')}
              </TypographyLink>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} gap={1.5}>
                  {_map(_get(item, 'platforms', []), (__item, idx) => (
                    <React.Fragment key={_get(__item, 'id', idx)}>
                      <TypographyLineClamp tooltipValue={_get(__item, 'name', '--')} line={1} variant="subtitle2">
                        {_get(__item, 'name', '--')}
                      </TypographyLineClamp>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={3} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} gap={1.5}>
                  {_map(_get(item, 'platforms', []), (__item, idx) => (
                    <React.Fragment key={_get(__item, 'id', idx)}>
                      <Typography variant="subtitle2">{_get(__item, 'sem_ver', '--')}</Typography>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={3} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} gap={1.5}>
                  {_map(_get(item, 'platforms', []), (__item, idx) => (
                    <Typography variant="subtitle2" textAlign="left" key={_get(__item, 'id', idx)}>
                      {formatDatetime(_get(__item, 'release_date'), DATE_FORMATS.DATE)}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  useEffect(() => {
    handleGetRecentReleaseNotifications()
  }, [currentPage])

  useEffect(() => {
    const container = contentRef.current

    const checkHeight = () => {
      if (container) {
        const maxHeight = window.innerHeight * 0.4

        if (container.scrollHeight > maxHeight) {
          setGridSizes({
            product: 3.9,
            platform: 3.9,
            version: 2.1,
            releaseDate: 2.1
          })
        }
      }
    }

    checkHeight()
  }, [releaseNotiList])

  return (
    <Box sx={{ marginTop: '-18px' }}>
      {_size(releaseNotiList) > 0 && (
        <Grid container sx={{ py: 1, px: 2 }}>
          <Grid item xs={_get(gridSizes, 'product')}>
            <Typography variant="subtitle1">New Product Releases</Typography>
          </Grid>
          <Grid item xs={_get(gridSizes, 'platform')}>
            <Typography variant="subtitle1">Platform</Typography>
          </Grid>
          <Grid item xs={_get(gridSizes, 'version')}>
            <Typography variant="subtitle1">Latest Version</Typography>
          </Grid>
          <Grid item xs={_get(gridSizes, 'releaseDate')}>
            <Typography variant="subtitle1">Release Date</Typography>
          </Grid>
        </Grid>
      )}

      <Divider />

      {!isFetchingNotiData && _size(releaseNotiList) === 0 ? (
        <Typography variant="subtitle2" color="textSecondary" p={2}>
          No product releases.
        </Typography>
      ) : (
        <Box
          ref={contentRef}
          paddingTop={1}
          px={2}
          sx={{
            position: 'relative',
            maxHeight: '40vh',
            minHeight: 'auto',
            overflow: 'auto'
          }}
        >
          <LatestProductReleaseComponent />
        </Box>
      )}

      {_size(releaseNotiList) < totalCount && (
        <ListItemButton
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #e7e9ee',
            backgroundColor: '#FFFFFF',
            position: 'sticky',
            bottom: 0,
            p: 1,
            gap: 1
          }}
          onClick={() => {
            setCurrentPage(currentPage + 1)
          }}
        >
          <Typography variant="subtitle1" color="#1D6BFC" textTransform="none">
            Show more
          </Typography>
        </ListItemButton>
      )}
    </Box>
  )
}

export default React.memo(NotificationProduct)
