import React, { FC, useState } from 'react'

import _map from 'lodash/map'

import { Typography, Grid, TemplateSection, Box, Divider, Switch, Collapse, TextGrid, useTheme } from '@opswat/react-ui'
import { ChevronDownIcon, ChevronRightIcon } from '@opswat/react-icon'
import { KEYS } from '@myopswat/common'
import { useTranslation } from 'react-i18next'

const CookieSettingsForm: FC<unknown> = () => {
  const theme = useTheme()
  const { t: translate } = useTranslation()

  const [isEnable, setIsEnable] = useState<boolean>(false)
  const [isOpenNecessary, setIsOpenNecessary] = useState<boolean>(false)

  const loginKeyInfo = [
    {
      label: 'Cookie',
      value: KEYS.IDAAS_LOGIN_KEY
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: translate('manageCookieSSO')
    }
  ]

  const payloadKeyInfo = [
    {
      label: 'Cookie',
      value: KEYS.IDAAS_PAYLOAD_KEY
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: translate('manageCookieSSO')
    }
  ]

  const refreshKeyInfo = [
    {
      label: 'Cookie',
      value: KEYS.IDAAS_REFRESH_KEY
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: translate('manageCookieSSO')
    }
  ]

  const sessionKeyInfo = [
    {
      label: 'Cookie',
      value: KEYS.IDAAS_SESSION_KEY
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: translate('manageCookieSSO')
    }
  ]

  const ocmTokenKeyInfo = [
    {
      label: 'Cookie',
      value: KEYS.OCM_TOKEN_KEY
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: 'This cookie is used for My Products configuration'
    }
  ]

  const jsessionIDKeyInfo = [
    {
      label: 'Cookie',
      value: 'JsessionID'
    },
    {
      label: 'Duration',
      value: 'session'
    },
    {
      label: 'Description',
      value: 'This cookie is used for session management'
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TemplateSection>
          <Box>
            <Typography variant="subtitle2">
              We use cookies to help you navigate efficiently and perform certain functions. You will find detailed
              information about all cookies under each consent category below.
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2">
              The cookies that are categorized as "Necessary" are stored on your browser as they are essential for
              enabling the basic functionalities of the site. We also use third-party cookies that help us analyze how
              you use this website. These cookies will only be stored in your browser with your prior consent. You can
              choose to enable or disable some or all of these cookies but disabling some of them may affect your
              browsing experience.
            </Typography>
          </Box>
        </TemplateSection>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex">
          <Box>
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => setIsOpenNecessary(!isOpenNecessary)}
            >
              {isOpenNecessary ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Box>
          </Box>
          <TemplateSection>
            <Box display="flex" justifyContent="space-between" sx={{ minHeight: '30px' }}>
              <Typography>Necessary</Typography>
              <Typography variant="subtitle1" color="success.dark">
                Always Active
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Necessary cookies are required to enable the basic features of this site, such as providing secure
                log-in or adjusting your consent preferences. These cookies do not store any personally identifiable
                data.
              </Typography>
            </Box>

            <Collapse in={isOpenNecessary} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  backgroundColor: theme.palette.info.main,
                  py: 1,
                  px: 2,
                  borderRadius: '4px'
                }}
              >
                <TemplateSection spacing={1}>
                  {_map(loginKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}

                  <Box>
                    <Divider />
                  </Box>

                  {_map(payloadKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}

                  <Box>
                    <Divider />
                  </Box>

                  {_map(refreshKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}

                  <Box>
                    <Divider />
                  </Box>

                  {_map(sessionKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}

                  <Box>
                    <Divider />
                  </Box>

                  {_map(ocmTokenKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}

                  <Box>
                    <Divider />
                  </Box>

                  {_map(jsessionIDKeyInfo, (item, idx) => (
                    <Box key={idx.toString()}>
                      <TextGrid
                        label={<Typography variant="subtitle2">{item.label}</Typography>}
                        value={<Typography variant="subtitle1">{item.value}</Typography>}
                        boxProps={{
                          mb: 0,
                          minHeight: 0
                        }}
                        labelColProps={{
                          xs: 12,
                          md: 3
                        }}
                      />
                    </Box>
                  ))}
                </TemplateSection>
              </Box>
            </Collapse>
          </TemplateSection>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sx={{ mb: 4, ml: 2.25 }}>
        <TemplateSection>
          <Box display="flex" justifyContent="space-between">
            <Typography>Uncategorized</Typography>
            <Switch
              checked={isEnable}
              label=""
              handleChange={() => setIsEnable(!isEnable)}
              sx={{
                mt: -2,
                mr: -3
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Other uncategorized cookies are those that are being analyzed and have not been classified into a category
              as yet.
            </Typography>
          </Box>
        </TemplateSection>
      </Grid>
    </Grid>
  )
}

export default CookieSettingsForm
