import React, { FC } from 'react'

import { Box, Grid, TemplateSection } from '@opswat/react-ui'

import SecurityMFA from './SecurityMFA'
import SecurityPassword from './SecurityPassword'
import SecurityPIN from './SecurityPIN'

const Security: FC<unknown> = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <TemplateSection>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <SecurityPassword />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <TemplateSection>
              <SecurityMFA />
              <SecurityPIN />
            </TemplateSection>
          </Grid>
        </Grid>
      </TemplateSection>
    </Box>
  )
}

export default Security
