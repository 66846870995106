import { FC, useLayoutEffect, useRef, useState } from 'react'

import { Box, Tooltip, Typography } from '@opswat/react-ui'

interface IProps {
  fileName: string
}

const CaseFileName: FC<IProps> = ({ fileName }) => {
  const ref = useRef<any>()
  const [hasEllipsis, setHasEllipsis] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setHasEllipsis(true)
    }
  }, [ref])

  return (
    <Tooltip
      arrow
      placement="top"
      title={hasEllipsis && fileName}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            maxWidth: '400px'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '2px',
          '& .MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '#485161'
          }
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, maxWidth: '100%' }}>
          <Typography
            ref={ref}
            component="span"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {fileName}
          </Typography>
        </Box>
        {hasEllipsis && <Typography component="span">{`.${fileName.split('.').pop()}`}</Typography>}
      </Box>
    </Tooltip>
  )
}

export default CaseFileName
