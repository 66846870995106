import { FC, useContext } from 'react'

import _map from 'lodash/map'

import { Divider, Grid, Typography } from '@opswat/react-ui'

import { DialogNPSSurveyContext } from '../../Dialog/DialogNPSSurvey/interface'
import NPSFieldController from './fieldController'

interface IProps {
  questions: any[]
}

const NPSSurveyForm: FC<IProps> = ({ questions }) => {
  const { handleGetNPSOption } = useContext(DialogNPSSurveyContext)

  return (
    <Grid container>
      <Grid item xs={12} sm={10}>
        <Divider sx={{ position: 'sticky', top: 0 }} />
      </Grid>

      <Grid item xs={12} sm={9}>
        <Grid container rowGap={2.5} sx={{ my: 2 }}>
          {_map(
            [
              {
                id: 'representative',
                title: handleGetNPSOption('identityText'),
                type: 'identity'
              },
              ...questions
            ],
            question => (
              <NPSFieldController question={question} />
            )
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10} sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <NPSFieldController
          question={{
            id: 'allowedContact',
            title: handleGetNPSOption('allowedContactText'),
            type: 'checkbox'
          }}
        />
        <Typography variant="caption" whiteSpace="nowrap" lineHeight="20px">
          {handleGetNPSOption('footerText')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={10} sx={{ mt: 2 }}>
        <Divider />
      </Grid>
    </Grid>
  )
}

export default NPSSurveyForm
