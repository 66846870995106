import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

type MyInformationState = {
  editMode: string
  submitMode: string
  isDirtyField: boolean
  isLoadingSubmit: boolean
}

const initialState: MyInformationState = {
  editMode: '',
  submitMode: '',
  isDirtyField: false,
  isLoadingSubmit: false
}

const myInformation = createSlice({
  name: 'myInformation',
  initialState,
  reducers: {
    saveEditMode: (state, action: PayloadAction<string>) => {
      state.editMode = action.payload
    },
    saveSubmitMode: (state, action: PayloadAction<string>) => {
      state.submitMode = action.payload
    },
    saveIsDirtyField: (state, action: PayloadAction<boolean>) => {
      state.isDirtyField = action.payload
    },
    saveIsLoadingSubmit: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSubmit = action.payload
    }
  }
})

export const { saveEditMode, saveSubmitMode, saveIsDirtyField, saveIsLoadingSubmit } = myInformation.actions

export default myInformation.reducer

export const selectEditMode = (state: RootState) => state.myInformation.editMode
export const selectSubmitMode = (state: RootState) => state.myInformation.submitMode
export const selectIsDirtyField = (state: RootState) => state.myInformation.isDirtyField
export const selectIsLoadingSubmit = (state: RootState) => state.myInformation.isLoadingSubmit
