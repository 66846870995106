import { useEffect } from 'react'

import _get from 'lodash/get'

import { useNavigate } from 'react-router-dom'

import { handleCheckLogin, handleForceLogin, handleGetErrorMessage, handleRedirectPage } from '@myopswat/common'

import { useAccountLoginMutation } from 'myopswat-web/src/api/account'
import { ILoginInput } from 'myopswat-web/src/api/account/types'
import { saveAccount } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import LoginPage from 'myopswat-web/src/pages/LoginPage'
import { homePageURL, verifyOTPPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch } from 'myopswat-web/src/store'

export default function LoginContainer() {
  const isToken = handleCheckLogin()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [accountLogin, { data, isLoading, error: accountLoginError }] = useAccountLoginMutation()

  const handleLogin = async (loginData: ILoginInput) => {
    try {
      await dispatch(
        saveAccount({
          email: _get(loginData, 'email', ''),
          password: _get(loginData, 'password', ''),
          captchaToken: _get(loginData, 'captchaToken', '')
        })
      )

      await accountLogin({
        email: _get(loginData, 'email', ''),
        password: _get(loginData, 'password', ''),
        captchaToken: _get(loginData, 'captchaToken', '')
      })
        .unwrap()
        ?.then(async (response: any) => {
          if (response?.success) {
            if (response?.enableMfa) {
              navigate(verifyOTPPageURL)
            } else {
              await handleForceLogin(
                response,
                process.env.REACT_APP_TOKEN_STORAGE,
                process.env.REACT_APP_OCM_JWT_ENC_KEY
              )
              const redirectURL = handleRedirectPage(process.env.REACT_APP_OPSWAT_OC_URL || '', homePageURL)
              if (redirectURL) {
                navigate(redirectURL)
              } else {
                window.location.replace(process.env.REACT_APP_OPSWAT_OC_URL || '')
              }
            }
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isToken) {
      navigate(homePageURL)
    }
  }, [isToken])

  return (
    <LoginPage
      isLoading={isLoading}
      handleLogin={handleLogin}
      errorMessage={handleGetErrorMessage(data, accountLoginError)}
    />
  )
}
