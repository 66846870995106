import { FC } from 'react'

import { Box, TabAdvanced, TypographyDivider } from '@opswat/react-ui'
import { reportFalseDetectionHistoryPageURL, reportFalseDetectionSubmitPageURL } from 'myopswat-web/src/routes'
import FalseSubmissionHistoryTab from './FalseSubmissionHistoryTab'
import ReportFalseDetectionTab from './ReportFalseDetectionTab'

const ReportFalseDetectionPage: FC<unknown> = () => {
  const tabArray = [
    {
      label: 'Submit',
      content: <ReportFalseDetectionTab></ReportFalseDetectionTab>,
      tabPath: reportFalseDetectionSubmitPageURL
    },
    {
      label: 'Submission History',
      content: <FalseSubmissionHistoryTab></FalseSubmissionHistoryTab>,
      tabPath: reportFalseDetectionHistoryPageURL
    }
  ]

  return (
    <>
      <Box>
        <TypographyDivider label="Report False Detection" />
      </Box>

      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default ReportFalseDetectionPage
