import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    accountMfaSetup: builder.mutation<any, void>({
      query: () => ({
        document: gql`
          mutation accountMfaSetup {
            accountMfaSetup {
              success
              errors
              code
              recoveryCodes
            }
          }
        `
      })
    }),
    accountMfaVerifyToken: builder.mutation<any, string>({
      query: code => ({
        document: gql`
          mutation accountMfaVerifyToken {
            accountMfaVerifyToken(input: { totpCode: "${code}" }) {
              success
              errors
            }
          }
        `
      })
    }),
    accountMfaReset: builder.mutation<any, void>({
      query: () => ({
        document: gql`
          mutation accountMfaReset {
            accountMfaReset {
              success
              errors
              recoveryCodes
            }
          }
        `
      })
    }),
    accountMfaDisable: builder.mutation<any, void>({
      query: () => ({
        document: gql`
          mutation accountMfaDisable {
            accountMfaDisable {
              success
              errors
            }
          }
        `
      })
    })
  })
})

export const {
  useAccountMfaSetupMutation,
  useAccountMfaVerifyTokenMutation,
  useAccountMfaResetMutation,
  useAccountMfaDisableMutation
} = api
