import _get from 'lodash/get'
import _has from 'lodash/has'
import _template from 'lodash/template'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { DIALOG_MESSAGES } from '../constants'

interface IProps {
  errorMap: any
}

const useApiErrorHook = ({ errorMap }: IProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const getErrorMessage = useCallback(
    (errors: any) => {
      const errorCode = _get(errors, '0.code')
      const errorMessage = _get(errors, '0.message')
      const messageData = getMessageData(errorMessage)
      let message = _get(errorMap, errorCode, '')
      if (!message) {
        message = errorMessage
      }
      // Fill message with data
      if (messageData) {
        message = _template(message)(messageData)
      }
      return { errorCode, message, messageData }
    },
    [errorMap]
  )

  const getMessageData = (message: string) => {
    try {
      return JSON.parse(message)
    } catch (error) {
      return null
    }
  }

  const showErrorMessage = useCallback(
    (errors: any) => {
      const { errorCode, message } = getErrorMessage(errors)
      // Ignore show popup message
      if (!_has(DIALOG_MESSAGES, errorCode)) {
        // Show alert
        enqueueSnackbar(message, { variant: 'error' })
      }
    },
    [errorMap]
  )

  return {
    showErrorMessage,
    getErrorMessage
  }
}

export { useApiErrorHook as default }
