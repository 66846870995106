import _get from 'lodash/get'

import { handleForceLogin, handleRedirectPage } from '@myopswat/common'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAccountLoginMutation } from 'myopswat-web/src/api/account'
import { saveAccount, selectLayoutAccount } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import VerifyOTPPage from 'myopswat-web/src/pages/VerifyOTPPage'
import { homePageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

export default function VerifyOTPContainer() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const account = useTypedSelector(selectLayoutAccount)
  const { t: translate } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const [accountLogin, { isLoading: isLoadingLogin }] = useAccountLoginMutation()

  const handleVerifyOTP = async (data: string) => {
    const params = {
      email: _get(account, 'email', ''),
      password: _get(account, 'password', ''),
      captchaToken: _get(account, 'captchaToken', ''),
      totp: data
    }
    await postLogin(params)
  }

  const handleRecoveryCode = async (data: string) => {
    const params = {
      email: account.email,
      password: account.password,
      captchaToken: account.captchaToken,
      recovery: data
    }
    await postLogin(params)
  }

  const postLogin = async (params: any) => {
    try {
      await accountLogin(params)
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            await dispatch(
              saveAccount({
                email: '',
                password: '',
                captchaToken: ''
              })
            )
            await handleForceLogin(response, process.env.REACT_APP_TOKEN_STORAGE, process.env.REACT_APP_OCM_JWT_ENC_KEY)
            const redirectURL = handleRedirectPage(process.env.REACT_APP_OPSWAT_OC_URL || '', homePageURL)
            if (redirectURL) {
              navigate(redirectURL)
            } else {
              window.location.replace(process.env.REACT_APP_OPSWAT_OC_URL || '')
            }
          } else {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('signInFail')), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('signInFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <VerifyOTPPage
      isLoadingLogin={isLoadingLogin}
      handleVerifyOTP={handleVerifyOTP}
      handleRecoveryCode={handleRecoveryCode}
    />
  )
}
