import { useMemo, useState } from 'react'

import _map from 'lodash/map'

import { Box } from '@opswat/react-ui'

import { SINGLE_PHONE_SUPPORT_MAP } from '../contants'
import BoxPhone from './BoxPhone'
import LocationPhones from './LocationPhones'

interface IProps {
  supportLevel: string
  phoneSupportList: any[]
}

const PhoneSection = ({ supportLevel, phoneSupportList }: IProps) => {
  const [location, setLocation] = useState<string>('')

  const handleSelectLocation = (selected: string) => {
    if (selected === location) {
      setLocation('')
    } else {
      setLocation(selected)
    }
  }

  const singlePhone = useMemo(() => {
    return supportLevel in SINGLE_PHONE_SUPPORT_MAP
      ? SINGLE_PHONE_SUPPORT_MAP[supportLevel as keyof typeof SINGLE_PHONE_SUPPORT_MAP]
      : null
  }, [supportLevel])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%'
      }}
    >
      {singlePhone ? (
        <BoxPhone item={singlePhone} />
      ) : (
        _map(phoneSupportList, (item: any, idx) => (
          <LocationPhones key={idx} item={item} open={location === item.location} onSelect={handleSelectLocation} />
        ))
      )}
    </Box>
  )
}

export default PhoneSection
