import React from 'react'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _range from 'lodash/range'
import { TemplateSection, Box, Grid, Skeleton, Typography, TextGrid } from '@opswat/react-ui'

const DetailInfoSkeleton = () => {
  const detailData = [
    {
      label: 'Type',
      value: ''
    },
    {
      label: 'Solutions',
      value: ''
    }
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="200px" />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <TemplateSection spacing={2}>
            <Skeleton animation="wave" variant="text" />
            <Box>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" variant="text" width="85%" />
              <Skeleton animation="wave" variant="text" width="40%" />
            </Box>
            <Box>
              {_map(detailData, (item, idx) => (
                <React.Fragment key={idx.toString()}>
                  <TextGrid
                    label={<Typography variant="body2">{_get(item, 'label', '--')}:</Typography>}
                    value={<Skeleton animation="wave" variant="text" width="200px" />}
                    labelColProps={{ xs: 12, md: 3, lg: 2 }}
                  />
                </React.Fragment>
              ))}
              <TextGrid
                label={<Typography variant="body2">Resources:</Typography>}
                value={<Skeleton animation="wave" variant="text" width="200px" />}
                labelColProps={{ xs: 12, md: 3, lg: 2 }}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Skeleton animation="wave" variant="text" width="100px" height="35px" />
              </Grid>
              <Grid item xs="auto">
                <Skeleton animation="wave" variant="text" width="100px" height="35px" />
              </Grid>
            </Grid>
          </TemplateSection>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {_map(_range(4), (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton animation="wave" variant="text" width="100%" height="150px" />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {_map(_range(4), (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton animation="wave" variant="text" width="100%" height="150px" />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DetailInfoSkeleton
