import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IRoleFiltersInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    roles: builder.query<any, IRoleFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: RoleFiltersInput) {
            roles(filters: $filters) {
              id
              accessLevel
              name
              description
              ssoId
              updatedAt
              createdAt
              creatorId
              creatorFullname
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    applicationRoles: builder.query<any, IRoleFiltersInput>({
      query: () => ({
        document: gql`
          query {
            applicationRoles {
              id
              name
              permissions
              description
              ssoId
              updatedAt
              createdAt
            }
          }
        `
      })
    }),
    applicationRolesWithPermissions: builder.query<any, IRoleFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: RoleFiltersInput) {
            applicationRolesWithPermissions(filters: $filters) {
              roles {
                id
                name
                permissions
                description
                ssoId
                updatedAt
                createdAt
              }
              allPermissions
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    roleCreate: builder.mutation<any, any>({
      query: ({ name, permissionIds }) => ({
        document: gql`
          mutation ($input: RoleInput!) {
            roleCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            name: name,
            permissionIds: permissionIds || []
          }
        }
      })
    }),
    roleDelete: builder.mutation<any, string>({
      query: id => ({
        document: gql`
        mutation {
          roleDelete(id: "${id}") {
              success
              errors
          }
      }
        `
      })
    })
  })
})

export const {
  useRolesQuery,
  useLazyRolesQuery,
  useApplicationRolesQuery,
  useApplicationRolesWithPermissionsQuery,
  useRoleCreateMutation,
  useRoleDeleteMutation
} = api
