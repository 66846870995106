import { Box, Grid, TextField, Typography } from '@opswat/react-ui'
import { OrganizationAddCriticalAlertUserForm } from 'myopswat-web/src/api/organization/types'
import { UseFormReturn } from 'react-hook-form'
interface IProps {
  formRef: UseFormReturn<OrganizationAddCriticalAlertUserForm>
}

const AddUserForm = ({ formRef }: IProps) => {
  const {
    register,
    formState: { errors }
  } = formRef

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">Email</Typography>
          <TextField
            required
            fullWidth
            size="small"
            {...register(`email`)}
            error={!!errors?.email}
            helperText={errors?.email && errors?.email?.message}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddUserForm
