import React, { FC, useMemo } from 'react'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'

import _get from 'lodash/get'

import { FileAnalysisIcon } from '@opswat/react-icon'
import { Box, Button, Divider, Grid, Skeleton, TemplateSection } from '@opswat/react-ui'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'

import WarningMessage from '../components/WarningMessage'
import ButtonFilterCases from './ButtonFilterCases'

interface IProps {
  casesInfo: any
  isLoading: boolean
  supportInfo: any
  contactId: string
  openSubmitCaseDialog: () => void
  control: Control
  setValue: UseFormSetValue<any>
}

const CasesInformation: FC<IProps> = ({
  casesInfo,
  isLoading,
  contactId,
  supportInfo,
  control,
  setValue,
  openSubmitCaseDialog
}) => {
  const caseType = useWatch({ control, name: 'filters.caseType' })

  const defaultPaging = useMemo(
    () => ({
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }),
    []
  )

  const supportInfoNotAvailable = useMemo(() => {
    return !supportInfo?.support_available
  }, [supportInfo])

  const createCaseEnable = useMemo(() => {
    return !supportInfoNotAvailable
  }, [supportInfoNotAvailable])

  const myCaseAvaiable = useMemo(() => {
    return contactId != null
  }, [contactId])

  const hasExtendedCaseView = useMemo(() => {
    return _get(casesInfo, 'org_case_open_count') && _get(casesInfo, 'org_case_closed_count')
  }, [casesInfo])

  const MyCasesInfo = useMemo(
    () =>
      !myCaseAvaiable ? (
        <Grid item xs={12}>
          <WarningMessage message="Your Cases not available." />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <ButtonFilterCases
              isSelected={caseType === 'myOpen'}
              title="My Open Cases"
              count={_get(casesInfo, 'my_case_open_count') || 0}
              onClick={() => {
                setValue('filters.caseType', 'myOpen')
                setValue('pageInfo', defaultPaging)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonFilterCases
              isSelected={caseType === 'myClosed'}
              title="My Closed Cases"
              color="#FDBD0D"
              count={_get(casesInfo, 'my_case_closed_count') || 0}
              onClick={() => {
                setValue('filters.caseType', 'myClosed')
                setValue('pageInfo', defaultPaging)
              }}
            />
          </Grid>
        </>
      ),
    [myCaseAvaiable, caseType, casesInfo]
  )

  const ExtendedCaseInfo = useMemo(() => {
    if (supportInfoNotAvailable) {
      return (
        <Grid item xs={12}>
          <WarningMessage message="Organization Cases not available." />
        </Grid>
      )
    } else if (hasExtendedCaseView) {
      return (
        <>
          <Grid item xs={12} sm={6}>
            <ButtonFilterCases
              isSelected={caseType === 'orgOpen'}
              title="Organization Open Cases"
              count={_get(casesInfo, 'org_case_open_count') || 0}
              onClick={() => {
                setValue('filters.caseType', 'orgOpen')
                setValue('pageInfo', defaultPaging)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonFilterCases
              isSelected={caseType === 'orgClosed'}
              title="Organization Closed Cases"
              color="#FDBD0D"
              count={_get(casesInfo, 'org_case_closed_count') || 0}
              onClick={() => {
                setValue('filters.caseType', 'orgClosed')
                setValue('pageInfo', defaultPaging)
              }}
            />
          </Grid>
        </>
      )
    } else {
      return (
        <Grid item xs={12}>
          <WarningMessage message="You cannot see cases created by other users in your organization." />
        </Grid>
      )
    }
  }, [supportInfoNotAvailable, hasExtendedCaseView, caseType, casesInfo])

  return (
    <div>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={3.5}>
          <Button
            fullWidth
            disableElevation
            color="primary"
            variant="contained"
            startIcon={<FileAnalysisIcon color={createCaseEnable ? 'white' : '#a6a6a6'} size={16} />}
            sx={{
              height: '86px',
              padding: '12px',
              border: '1px solid #FFFFFF',
              outline: `1px solid ${createCaseEnable ? '#7EAAFD' : '#a6a6a6'}`,
              '& .MuiButton-startIcon': {
                m: 0
              }
            }}
            disabled={supportInfoNotAvailable}
            onClick={openSubmitCaseDialog}
          >
            <Divider
              orientation="vertical"
              sx={{
                height: '36px',
                borderColor: createCaseEnable ? '#7EAAFD' : '#a6a6a6',
                borderRightWidth: '1px',
                mx: 1.5
              }}
            />
            <Box sx={{ flexGrow: 1, textAlign: 'left', fontSize: '16px', lineHeight: '20px', fontWeight: 500 }}>
              Submit A Case
            </Box>
          </Button>
        </Grid>

        <Grid item xs={12} md={8.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TemplateSection>
                <Grid container spacing={2}>
                  {isLoading ? (
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rectangular" width="100%" height="65px" />
                    </Grid>
                  ) : (
                    MyCasesInfo
                  )}
                </Grid>
              </TemplateSection>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TemplateSection>
                <Grid container spacing={2}>
                  {isLoading ? (
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rectangular" width="100%" height="65px" />
                    </Grid>
                  ) : (
                    ExtendedCaseInfo
                  )}
                </Grid>
              </TemplateSection>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CasesInformation
