import { FC, useCallback } from 'react'

import { CircleBlockIcon, CircleCheckIcon, RemoveIcon } from '@opswat/react-icon'
import { Box, CircularProgress, IconButton, ListItem, Typography } from '@opswat/react-ui'

import { FileItem, FileStatus } from '../../interface'
import CaseFileName from './fileItemName'

interface IProps {
  fileItem: FileItem
  handleRemoveFile: () => void
}

const CaseFileItem: FC<IProps> = ({ fileItem, handleRemoveFile }) => {
  const { file, status } = fileItem

  const handleFormatFileSize = useCallback((bytes: number, decimals: number) => {
    if (bytes == 0) return '0 B'
    const k = 1024,
      dm = decimals || 2,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }, [])

  const getStatusColor = useCallback((status: FileStatus) => {
    switch (status) {
      case FileStatus.IN_PROGRESS:
        return '#485161'
      case FileStatus.SUCCESS:
        return '#008A00'
      case FileStatus.ERROR:
        return '#D00300'
    }
  }, [])

  const getStatusContent = useCallback((status: FileStatus) => {
    switch (status) {
      case FileStatus.IN_PROGRESS:
        return 'In progress'
      case FileStatus.SUCCESS:
        return 'File uploaded successfully'
      case FileStatus.ERROR:
        return 'Failed to upload file'
    }
  }, [])

  const getStatusIcon = useCallback((status: FileStatus) => {
    switch (status) {
      case FileStatus.IN_PROGRESS:
        return <CircularProgress size="15px" color="inherit" />
      case FileStatus.SUCCESS:
        return <CircleCheckIcon size={18} color="#008A00" />
      case FileStatus.ERROR:
        return <CircleBlockIcon size={16} color="#D00300" />
    }
  }, [])

  return (
    <ListItem
      sx={{
        borderTop: '1px solid #E9ECF2',
        py: '10px',
        px: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4
      }}
    >
      <Box sx={{ maxWidth: '80%' }}>
        <CaseFileName fileName={file.name} />
        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '20px', color: '#8E939B' }}>
          {handleFormatFileSize(file.size, 1)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {getStatusIcon(status)}
          <Typography sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '20px', color: getStatusColor(status) }}>
            {getStatusContent(status)}
          </Typography>
        </Box>
      </Box>
      {status !== FileStatus.IN_PROGRESS && (
        <IconButton onClick={handleRemoveFile}>
          <RemoveIcon size={16} />
        </IconButton>
      )}
    </ListItem>
  )
}

export default CaseFileItem
