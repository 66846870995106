import { fetchAPI } from 'myopswat-web/src/api/base-query'
import { IEditPINInput, IReSetupPINInput, ISetupPINInput } from './types'

export const api = fetchAPI.injectEndpoints({
  endpoints: builder => ({
    getUsersProfile: builder.query<any, void>({
      query: () => 'users/profile'
    }),
    setupPIN: builder.mutation<any, ISetupPINInput>({
      query: payload => ({
        url: 'pin',
        method: 'POST',
        body: payload
      })
    }),
    editPIN: builder.mutation<any, IEditPINInput>({
      query: payload => ({
        url: 'pin',
        method: 'PUT',
        body: payload
      })
    }),
    forgotPIN: builder.mutation<any, void>({
      query: () => ({
        url: 'pin/forgot',
        method: 'POST'
      })
    }),
    confirmPIN: builder.query<any, string>({
      query: pinParams => `pin/forgot/confirm?token=${pinParams}`
    }),
    reSetupPIN: builder.mutation<any, IReSetupPINInput>({
      query: payload => ({
        url: 'pin/forgot/confirm',
        method: 'POST',
        body: payload
      })
    })
  })
})

export const {
  useLazyGetUsersProfileQuery,
  useSetupPINMutation,
  useEditPINMutation,
  useForgotPINMutation,
  useLazyConfirmPINQuery,
  useReSetupPINMutation
} = api
