import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  AddCriticalAlertUserInput,
  IChatSupportAIInput,
  ISubscribeCriticalUserInput,
  ISupportCaseCreateInput,
  ISupportCaseUpdateCsatInput,
  ISupportCaseUpdateInput,
  RemoveCriticalAlertUserInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    organizationSupportInfo: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            organizationSupportInfo(id: "${id}") {
              supportInfo
              casesInfo
              portalUsers
              portalAdmins
              criticalAlertUsers
              supportUsers
              extendedCaseView
              contactId
              currentOrgName
              sfdcOrgName
              orgAccessCaseInfo {
                contactId
                orgCaseAccess
                email
                name
              }
              isEditableCaseInfo
            }
          }
        `
      })
    }),
    updateOrganizationCaseAccess: builder.mutation<any, any>({
      query: ({ orgId, contactId, access }) => ({
        document: gql`
          mutation organizationCaseAccessUpdate($input: UpdateOrganizationCaseAccessInput!) {
            organizationCaseAccessUpdate(input: $input) {
              message
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            orgId: orgId,
            contactId: contactId,
            access: access
          }
        }
      })
    }),
    subscribeCriticalUser: builder.mutation<any, ISubscribeCriticalUserInput>({
      query: ({ orgId, subscribeFlg }) => ({
        document: gql`
          mutation ($input: SubscribeCriticalUserInput!) {
            subscribeCriticalUser(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            orgId: orgId,
            subscribeFlg: subscribeFlg
          }
        }
      })
    }),
    chatSupportAi: builder.mutation<any, IChatSupportAIInput>({
      query: ({ question, history, conversationId }) => ({
        document: gql`
          mutation ($input: ChatSupportAIInput!) {
            chatSupportAi(input: $input) {
              result
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            question: question,
            history: history,
            conversationId: conversationId
          }
        }
      })
    }),
    supportCaseCreate: builder.mutation<any, ISupportCaseCreateInput>({
      query: payload => ({
        document: gql`
          mutation ($input: SupportCaseCreateInput!) {
            supportCaseCreate(input: $input) {
              caseId
              caseNumber
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    addCriticalAlertUser: builder.mutation<any, AddCriticalAlertUserInput>({
      query: input => ({
        document: gql`
          mutation ($input: AddCriticalAlertUserInput!) {
            addCriticalAlertUser(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    removeCriticalAlertUser: builder.mutation<any, RemoveCriticalAlertUserInput>({
      query: input => ({
        document: gql`
          mutation ($input: RemoveCriticalAlertUserInput!) {
            removeCriticalAlertUser(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    supportCaseUpdate: builder.mutation<any, ISupportCaseUpdateInput>({
      query: payload => ({
        document: gql`
          mutation ($input: SupportCaseUpdateInput!) {
            supportCaseUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    organizationCases: builder.query<any, any>({
      query: ({ id, caseType, pageInfo }) => ({
        document: gql`
          query ($pageInfo: PageInfoType) {
            organizationCases(id: "${id}", caseType: "${caseType}", pageInfo: $pageInfo) {
              id
              status
              caseNumber
              subject
              lastModifiedDate
              attributes
              closeDate
              subCategory
              satisfactoryNumber
              contactName
              contactId
              userId
              csScore
              productScore
              csatComment
            }
          }
        `,
        variables: {
          pageInfo
        }
      })
    }),
    supportCaseCsatUpdate: builder.mutation<any, ISupportCaseUpdateCsatInput>({
      query: payload => ({
        document: gql`
          mutation supportCaseCsatUpdate($input: SupportCaseUpdateCsatInput!) {
            supportCaseCsatUpdate(input: $input) {
              message
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    supportPerformSyncData: builder.mutation({
      query: () => ({
        document: gql`
          mutation supportPerformSync {
            supportPerformSync {
              errors
              success
            }
          }
        `
      })
    })
  })
})

export const {
  useLazyOrganizationSupportInfoQuery,
  useUpdateOrganizationCaseAccessMutation,
  useSubscribeCriticalUserMutation,
  useChatSupportAiMutation,
  useSupportCaseCreateMutation,
  useAddCriticalAlertUserMutation,
  useRemoveCriticalAlertUserMutation,
  useSupportCaseUpdateMutation,
  useLazyOrganizationCasesQuery,
  useSupportCaseCsatUpdateMutation,
  useSupportPerformSyncDataMutation
} = api
