import { PageLoading } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { useEffect } from 'react'

export default function ResetPasswordContainer() {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_SSO_WEB_HOST}/resetPassword` || '/'
  }, [])

  return <PageLoading background={imageBg} color="primary" />
}
