import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEn from '../locales/en.json'
import translationVi from '../locales/vi.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    vi: { translation: translationVi }
  },
  lng: 'en', // Default
  fallbackLng: 'en', // Fallback
  interpolation: { escapeValue: false }
})

export default i18n
