import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import _remove from 'lodash/remove'
import _get from 'lodash/get'

import { handleUpdateObjectById } from '@myopswat/common'

import type { RootState } from '../../store'

type DetailState = {
  productRelatedEntries: any[]
  productDetail: any
  productChangeDetail: any
  productUtilityDetail: any
}

const initialState: DetailState = {
  productRelatedEntries: [],
  productDetail: null,
  productChangeDetail: null,
  productUtilityDetail: null
}

const myDetail = createSlice({
  name: 'myDetail',
  initialState,
  reducers: {
    saveProductRelatedEntries: (state, action: PayloadAction<any>) => {
      state.productRelatedEntries = action.payload || []
    },
    addSeenEntries: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { seen: true })
      state.productRelatedEntries = handleUpdateObjectById(state.productRelatedEntries, newData)
    },
    removeSeenEntries: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { seen: false })
      state.productRelatedEntries = handleUpdateObjectById(state.productRelatedEntries, newData)
    },
    ignoreEntries: (state, action: PayloadAction<any>) => {
      state.productRelatedEntries = _remove(
        state.productRelatedEntries,
        (item: any) => item.id !== _get(action.payload, 'id', '')
      )
    },
    saveProductDetail: (state, action: PayloadAction<any>) => {
      state.productDetail = action.payload || []
    },
    saveProductChangeDetail: (state, action: PayloadAction<any>) => {
      state.productChangeDetail = action.payload || []
    },
    saveProductUtilityDetail: (state, action: PayloadAction<any>) => {
      state.productUtilityDetail = action.payload || []
    }
  }
})

export const {
  saveProductRelatedEntries,
  addSeenEntries,
  removeSeenEntries,
  ignoreEntries,
  saveProductDetail,
  saveProductChangeDetail,
  saveProductUtilityDetail
} = myDetail.actions

export default myDetail.reducer

export const selectProductRelatedEntries = (state: RootState) => state.myDetail.productRelatedEntries
export const selectProductDetail = (state: RootState) => state.myDetail.productDetail
export const selectProductChangeDetail = (state: RootState) => state.myDetail.productChangeDetail
export const selectProductUtilityDetail = (state: RootState) => state.myDetail.productUtilityDetail
