import React, { FC, useEffect } from 'react'

import _get from 'lodash/get'

import { Box, Typography, TemplateSection, ButtonLoading, Grid, TypographyLink, Card } from '@opswat/react-ui'

import chplayLogo from 'myopswat-web/src/assets/images/chplay-logo.png'
import appstoreLogo from 'myopswat-web/src/assets/images/appstore-logo.png'

interface IProps {
  children?: React.ReactNode
  setupMFAData: any
  isLoadingSetupMFA: boolean
  handleSetupMFA: () => void
  setStep: (data: 'intro' | 'totp' | 'qr') => void
}

const StepIntro: FC<IProps> = ({ setupMFAData, isLoadingSetupMFA, handleSetupMFA, setStep }) => {
  const chplayLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
  const appstoreLink = 'https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box&itscg=30200'

  const handleSetupTOTP = () => {
    handleSetupMFA()
  }

  useEffect(() => {
    if (_get(setupMFAData, 'success')) {
      setStep('totp')
    }
  }, [setupMFAData])

  return (
    <TemplateSection spacing={2}>
      <Box>
        <Typography variant="h1">Now set up Multi-factor Authentication</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Multi-factor authentication creates strong user verification - thus maintain high-standards of security
                for access to resources.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ p: 4, height: '300px', mr: 3 }}>
                <img
                  src={chplayLogo}
                  alt="ch-play"
                  width="100%"
                  height="auto"
                  style={{ cursor: 'pointer' }}
                  onClick={() => window.open(chplayLink, '_blank')}
                />

                <img
                  src={appstoreLogo}
                  alt="app-store"
                  width="96%"
                  height="auto"
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => window.open(appstoreLink, '_blank')}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              height: 'auto',
              minHeight: '480px',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  1. Download&nbsp;
                  <Typography component="span" variant="body1">
                    Google Authenticator or another Authenticator
                  </Typography>
                  &nbsp; app on your mobile device. See Google and Apple App Stores.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  2. Download the&nbsp;
                  <TypographyLink color="textSecondary">Recovery Code</TypographyLink>, store it in a safe place. This
                  is a backup method allowing you to log in, if the app fails (MFA is deactivated when using Recovery
                  Codes).
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  3. Scan the QR code with your authenticator app or directly input the provided text code into your
                  Authenticator app. After that, please enter the 6-character Authenticator code for verification.
                </Typography>
              </Grid>
            </Grid>

            <Box>
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSetupTOTP,
                  disabled: isLoadingSetupMFA,
                  fullWidth: true
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={isLoadingSetupMFA}
              >
                Continue
              </ButtonLoading>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default StepIntro
