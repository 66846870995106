import React, { FC } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced, Grid, Button } from '@opswat/react-ui'
import { setLocalStorage } from '@opswat/react-core'

import CookieSettingsForm from '../Form/CookieSettingsForm'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS } from '@myopswat/common'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

const DialogCookieSettings: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.COOKIE_SETTINGS]: false
      })
    )
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.COOKIE_SETTINGS, false)}
      onClose={() => handleClose()}
      title="Customize Consent Preferences"
      content={<CookieSettingsForm />}
      actions={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              color="inherit"
              variant="outlined"
              fullWidth
              onClick={() => {
                setLocalStorage('cn', 'necessary')
                handleClose()
              }}
            >
              Save My Preferences
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                setLocalStorage('cn', 'all')
                handleClose()
              }}
            >
              Accept All
            </Button>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogCookieSettings
