import { FC } from 'react'

import _range from 'lodash/range'

import { Box, Chip, Divider, useTheme } from '@opswat/react-ui'

interface IProps {
  steps: number
  activeStep: number
  completedSteps: Set<number>
}

const CustomStepper: FC<IProps> = ({ steps, activeStep, completedSteps }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', transition: 'all 1s ease' }}>
      {_range(1, steps + 1).map((step: number) => {
        const isActive = activeStep === step || completedSteps.has(step)

        return (
          <>
            <Chip
              label={`${step}`}
              color="primary"
              variant="outlined"
              sx={{
                borderRadius: '50%',
                backgroundColor: theme.palette.common.white,
                border: `2px solid ${isActive ? theme.palette.primary.main : '#707682'}`,
                width: '24px',
                height: '24px',
                '& .MuiChip-label': {
                  padding: 0,
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: isActive ? theme.palette.primary.main : '#707682',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            />
            {step !== steps && <Divider sx={{ width: '16px', borderColor: '#707682', borderBottomWidth: '2px' }} />}
          </>
        )
      })}
    </Box>
  )
}

export default CustomStepper
