import React, { useState } from 'react'

import { DownloadIcon, SolidDownIcon, SolidRightIcon } from '@opswat/react-icon'
import { Box, Link, TableCell, TableRow, styled, useTheme } from '@opswat/react-ui'

import { formatDatetime } from '@opswat/react-core'
import { EnginePackage } from 'myopswat-web/src/api/engine-status/types'
import { ENGINE_PACKAGE_DATETIME_FORMAT } from '../../contants'
import EngineView from './EngineView'
import HashView from './HashView'

interface IProps {
  item: EnginePackage
  afterCopy: () => void
  timezone?: string
}

const StyledCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const PackageView = ({ item, afterCopy, timezone }: IProps) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow>
        <StyledCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <SolidDownIcon /> : <SolidRightIcon />}
            {item.displayName}
          </Box>
        </StyledCell>
        <StyledCell>{formatDatetime(item.timestamp, ENGINE_PACKAGE_DATETIME_FORMAT, timezone)}</StyledCell>
        <StyledCell>
          <HashView hash={item.hash} afterCopy={afterCopy} />
        </StyledCell>
        <StyledCell sx={{ textAlign: 'right' }}>
          <Link href={item.link} target="_blank" variant="body2" underline="none">
            <DownloadIcon color={theme.palette.primary.main} />
          </Link>
        </StyledCell>
      </TableRow>
      {item.engines && item.engines.length > 0 && (
        <EngineView open={open} list={item.engines} timezone={timezone} afterCopy={afterCopy} />
      )}
    </React.Fragment>
  )
}

export default PackageView
