import { useEffect } from 'react'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { handleForceLogin } from '@myopswat/common'
import { homePageURL } from 'myopswat-web/src/routes'

export default function SSOSuccessfullyContainer() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    handleForceLogin(
      {
        accessToken: searchParams.get('token'),
        refreshToken: searchParams.get('refresh'),
        csrfToken: searchParams.get('csrf'),
        ocmToken: searchParams.get('ocm')
      },
      process.env.REACT_APP_TOKEN_STORAGE,
      process.env.REACT_APP_OCM_JWT_ENC_KEY
    ).then(() => {
      navigate(homePageURL)
    })
  }, [searchParams])

  return <Outlet />
}
