import { formatDatetime } from '@opswat/react-core'
import {
  Box,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyNoData,
  useTheme
} from '@opswat/react-ui'
import { useMemo } from 'react'
import { EngineStatus } from 'myopswat-web/src/api/engine-status/types'
import DatabaseVersionView from './status/DatabaseVersion'

interface IProps {
  isLoading: boolean
  list: EngineStatus[]
}

const RELEASE_DATE_VIEW_FORMAT = 'MM/dd/yyyy'

const EngineStatusView = ({ isLoading, list }: IProps) => {
  const theme = useTheme()

  const releaseDateHeaders = useMemo(() => {
    if (list && list.length > 0) {
      return list[0].releaseVersions.map(r => formatDatetime(r.releaseDate, RELEASE_DATE_VIEW_FORMAT))
    }
    return []
  }, [list])

  return (
    <>
      {isLoading && <PageLoading color="primary" />}
      {list && list.length > 0 ? (
        <TableContainer component={Box}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100 }}>
                  <Typography>Engine</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100 }}>
                  <Typography>Version</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100 }}>
                  <Typography>Package Type</Typography>
                </TableCell>
                <TableCell
                  colSpan={releaseDateHeaders.length}
                  sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 350 }}
                >
                  <Typography>Release Date</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {releaseDateHeaders.map((header, index) => {
                  return (
                    <TableCell
                      key={`release-date-header-${index}`}
                      sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                    >
                      <Typography>{header}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item, idx) => (
                <TableRow key={`engine-status-${idx}`}>
                  <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>{item.displayName}</TableCell>
                  <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
                    {item.latestEngineVersion}
                  </TableCell>
                  <TableCell sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>{item.packageType}</TableCell>
                  {item.releaseVersions.map((releaseVersion, idx2) => {
                    return (
                      <TableCell
                        key={`engine-status-release-version-${idx}-${idx2}`}
                        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                      >
                        <DatabaseVersionView list={releaseVersion.databaseVersions} />
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TypographyNoData />
      )}
    </>
  )
}

export default EngineStatusView
