import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import MyInformationPage from 'myopswat-web/src/pages/MyInformationPage'
import { useAppDispatch } from 'myopswat-web/src/store'
import {
  saveEditMode,
  saveSubmitMode
} from 'myopswat-web/src/containers/MyInformationContainer/myInformationContainerSlice'

const MyInformationContainer = () => {
  const { tabPath = '' } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tabPath) {
      dispatch(saveEditMode(''))
      dispatch(saveSubmitMode(''))
    }
  }, [tabPath])

  return <MyInformationPage />
}

export default MyInformationContainer
