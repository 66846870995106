import { FC, useEffect, useMemo, useState } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Box, Divider, Grid, TableLoading, TemplateSection, Typography, TypographyNoData } from '@opswat/react-ui'

import { useTypedSelector } from 'myopswat-web/src/store'
import { homePageURL } from 'myopswat-web/src/routes'
import { Link } from 'react-router-dom'

import BoxMAccess from './BoxMAccess'
import BoxMDCloud from './BoxMDCloud'
import { selectOrganizations } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useLazyBasicProductsQuery } from 'myopswat-web/src/api/product'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'

interface ProductNameType {
  id: string
  name: string
}

const BoxCloudBasedTable: FC<unknown> = () => {
  const [isLoading, setIsLoading] = useState(true)

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}
  const organizations = useTypedSelector(selectOrganizations)

  const [getBasicProducts, { data: productNameData }] = useLazyBasicProductsQuery()

  useEffect(() => {
    getBasicProducts({
      q: '',
      tags: []
    })
  }, [isLoading])

  const maItem = productNameData?.find((item: ProductNameType) => item?.id === PRODUCT_IDS.MD_IT_ACCESS)
  const mdcItem = productNameData?.find((item: ProductNameType) => item?.id === PRODUCT_IDS.MD_CLOUD)

  const isDisplayMdc = useMemo(() => {
    const accountInfo = _get(profileData, 'accountInfo')
    if (!accountInfo) return false

    if (
      (_get(accountInfo, 'mdcLicenseManageBy') === 'organization' && !_isEmpty(_get(accountInfo, 'mdc'))) ||
      _get(accountInfo, 'mdcLicenseManageBy') === 'user'
    ) {
      return true
    }
    return false
  }, [profileData])

  const currentOrganizationName = useMemo(() => {
    if (_isEmpty(organizations)) return ''

    const org: any = _find(organizations, (item: any) => item?.id === _get(profileData, 'currentOrganizationId'))
    if (!org) return ''

    return org.name
  }, [organizations, profileData])

  useEffect(() => {
    if (profileData) {
      setIsLoading(false)
    }
  }, [profileData])

  return (
    <Box sx={{ minHeight: '85vh' }}>
      {isLoading && <TableLoading />}
      {!isLoading && !profileData && <TypographyNoData>You have not got any Cloud Services Licenses.</TypographyNoData>}
      {!isLoading && profileData && (
        <TemplateSection>
          <Grid container>
            <Grid item xs={12} sm={6} md={5}>
              <Typography>Product</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Typography>API Key</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Divider />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={5}>
              <Typography
                variant="body2"
                color="primary"
                component={Link}
                to={`${homePageURL}/metadefender-cloud`}
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }
                }}
              >
                {mdcItem?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              {isDisplayMdc ? (
                <Typography variant="body2">{_get(profileData, 'accountInfo.mdc.mdcApiKey', '--')}</Typography>
              ) : (
                <Typography variant="body2">Your license is managed by {currentOrganizationName}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {isDisplayMdc && <BoxMDCloud data={profileData} />}

          <Grid container>
            <Grid item xs={12} sm={6} md={5}>
              <Typography
                variant="body2"
                color="primary"
                component={Link}
                to={`${homePageURL}/metadefender-it-access`}
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }
                }}
              >
                {maItem?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Typography variant="body2">{_get(profileData, 'accountInfo.ma.maApikey', '--')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <BoxMAccess data={profileData} />
        </TemplateSection>
      )}
    </Box>
  )
}

export default BoxCloudBasedTable
