import React, { FC } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced } from '@opswat/react-ui'

import imageBg from 'myopswat-web/src/assets/images/about-product-bg-new.png'
import AboutProductForm from '../Form/AboutProductForm'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS } from '@myopswat/common'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  orgName: string
}

const DialogAboutProduct: FC<IProps> = ({ orgName }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.ABOUT_PRODUCT, false)}
      onClose={() => {
        dispatch(
          toggleDialogs({
            [DIALOGS.ABOUT_PRODUCT]: false
          })
        )
      }}
      disableBackdropClick={false}
      background={imageBg}
      content={<AboutProductForm orgName={orgName} />}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            overflow: 'hidden',
            backgroundPosition: '60% 50%',
            backgroundSize: 'cover',
            backgroundColor: 'transparent',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${imageBg})`,
            height: 'auto',
            maxWidth: '820px'
          }
        },
        slotProps: {
          backdrop: {
            sx: {
              backgroundColor: 'rgba(255, 255, 255, 0.75) !important'
            }
          }
        }
      }}
    />
  )
}

export default DialogAboutProduct
