import React, { FC } from 'react'

import _range from 'lodash/range'
import _map from 'lodash/map'

import { Typography, Grid, Divider, TextFieldArea, Box, useTheme } from '@opswat/react-ui'

interface IProps {
  feedback: string
  setFeedback: (data: string) => void
  recommendProductScore: number | null
  setRecommendProductScore: (data: number) => void
}

const FeedbackForm: FC<IProps> = ({ feedback, setFeedback, recommendProductScore, setRecommendProductScore }) => {
  const theme = useTheme()

  return (
    <Grid container>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Thank you for using My OPSWAT. We are always looking for ways to improve your experience. Please take
                  a moment to tell us about your experience.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  How likely would you be to recommend My OPSWAT product?
                </Typography>
                <Grid container spacing={0.5}>
                  {_map(_range(11), (_, idx: number) => (
                    <Grid item xs="auto" key={idx.toString()}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          border: `1px solid ${theme.palette.divider}`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          '&: hover': {
                            cursor: 'pointer',
                            backgroundColor: recommendProductScore === idx ? theme.palette.primary.light : '#F4F4F5'
                          },
                          backgroundColor: recommendProductScore === idx ? theme.palette.primary.main : 'inherit'
                        }}
                        onClick={() => setRecommendProductScore(idx)}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: recommendProductScore === idx ? 'white' : 'inherit'
                          }}
                        >
                          {idx}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Tell us more
                </Typography>
                <TextFieldArea
                  value={feedback}
                  placeholder="Share your experience with us. What went well? What could have gone better?"
                  onChange={(e: any) => {
                    if (e) {
                      setFeedback(e.target.value)
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FeedbackForm
