export interface FileItem {
  file: File
  uuid: string
  groupId: string
  fileId: string
  fileSize: number
  status: FileStatus
}

export enum FileStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum FileErrors {
  MAX_FILE_SIZE = 'File exceeded maximum upload file size. Please try again.',
  MAX_FILE_NUM = 'Maximum number of attachments reached. You can either remove uploaded files below or zip multiple files into an archive.'
}
