import React, { FC, useCallback, useEffect } from 'react'

import _get from 'lodash/get'
import _parseInt from 'lodash/parseInt'
import _map from 'lodash/map'

import { Grid, TextField, ButtonLoading, Typography, Box, Autocomplete, useTheme } from '@opswat/react-ui'
import { DownloadIcon } from '@opswat/react-icon'
import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import { ILicenseActivateInput } from 'myopswat-web/src/api/product/types'
import { useAppDispatch } from 'myopswat-web/src/store'
import { useLazyCheckDisplayNPSQuery } from 'myopswat-web/src/api/survey'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'

interface IProps {
  data: any
  handleLicenseActivate: (data: ILicenseActivateInput) => void
  step: 'activate' | 'download'
  licenseActivateData: any
  isSubmit: boolean
  handleClose: () => void
  setIsSubmit: (data: boolean) => void
}

const LicenseActivateForm: FC<IProps> = ({
  data,
  handleLicenseActivate,
  step,
  licenseActivateData,
  isSubmit,
  handleClose,
  setIsSubmit
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [checkDisplayNPS] = useLazyCheckDisplayNPSQuery()

  const productsData = _map(data, item => ({
    name: `${_get(item, 'name', '--')}${
      item.id === 'b2e5642c-a822-417b-819c-f5c54383f56f' ? ' for OPSWAT Central Management V7' : ''
    }`,
    id: _get(item, 'id', ''),
    slug: _get(item, 'slug')
  }))

  const defaultValues = {
    product: {
      name: '',
      id: '',
      slug: ''
    },
    key: '',
    deployment: '',
    description: '',
    quantity: ''
  }

  const schema = yup.object().shape({
    product: yup.object().shape({
      name: yup.string().trim().required('Product cannot be blank'),
      id: yup.string().trim().required('Product cannot be blank')
    }),
    key: yup.string().trim().required('License Key cannot be blank'),
    deployment: yup.string().trim().required('Deployment ID cannot be blank'),
    quantity: yup
      .string()
      .trim()
      .required('Requested Number Of Agents cannot be blank')
      .matches(/^-?[0-9]*$/, 'Requested Number Of Agents should only contain numbers')
  })
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (item: any) => {
    handleLicenseActivate({
      productId: _get(item, 'product.id', ''),
      key: _get(item, 'key'),
      description: _get(item, 'description'),
      deployment: _get(item, 'deployment'),
      quantity: _parseInt(_get(item, 'quantity'), 10)
    })
    setIsSubmit(false)
  }

  const onFail = (e: any) => {
    console.error(e)
    setIsSubmit(false)
  }

  const handleActivate = async () => {
    await handleCreateFile(watch('deployment'), 'yml', _get(licenseActivateData, 'licenseText'), {
      type: BLOB_FILE_TYPES.YAML
    })

    await handleClose()
  }

  const allowQuantityInput: (productId: string) => boolean = productId => {
    return [PRODUCT_IDS.MD_DRIVE as string, PRODUCT_IDS.MD_CORE as string].includes(productId)
  }

  const isEmailSecurity: (productId: string) => boolean = productId => {
    return [
      PRODUCT_IDS.EMAIL_SECURITY as string,
      PRODUCT_IDS.EMAIL_SECURITY_BUNDLE as string,
      PRODUCT_IDS.EMAIL_SECURITY_STANDALONE as string
    ].includes(productId)
  }

  const getQuantityDefaultValue: (productId: string) => string = productId => {
    if (isEmailSecurity(productId)) return '-1'
    return '1'
  }

  const handleCheckDisplayNPS = useCallback(async () => {
    await checkDisplayNPS({}).then((response: any) => {
      const showDialog = response?.data ?? false
      if (showDialog) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.NPS_SURVEY]: true,
            [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'response-action',
            [DIALOGS_WEB.NPS_NOTIFICATION]: false
          })
        )
      }
    })
  }, [])

  useEffect(() => {
    const productId = _get(watch('product'), 'id')
    if (productId) {
      const checkQuantity = getQuantityDefaultValue(productId)
      setValue('quantity', checkQuantity)
    }
  }, [watch('product')])

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSuccess, onFail)()
    }
  }, [isSubmit])

  if (step === 'download')
    return (
      <Box
        sx={{
          display: 'flex',
          height: 'auto',
          minHeight: '400px',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="body2">License file is now available to download.</Typography>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disabled: false,
                startIcon: <DownloadIcon color="white" />,
                onClick: () => {
                  handleActivate()
                  handleCheckDisplayNPS()
                }
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Download
            </ButtonLoading>
          </Grid>
        </Grid>
      </Box>
    )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Product*</Typography>
        <Controller
          name="product"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              options={productsData}
              value={value}
              getOptionLabel={(option: any) => option.name}
              onChange={(event: any, newValue: any) => {
                onChange(newValue)
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  error={!!_get(errors, 'product', '')}
                  helperText={_get(errors, 'product.name.message')}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">License Key*</Typography>

        <TextField
          {...register('key')}
          fullWidth
          size="small"
          error={!!_get(errors, 'key.message', '')}
          helperText={_get(errors, 'key.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Deployment ID*</Typography>

        <TextField
          {...register('deployment')}
          fullWidth
          size="small"
          error={!!_get(errors, 'deployment.message', '')}
          helperText={_get(errors, 'deployment.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
        />
      </Grid>

      {allowQuantityInput(_get(watch('product'), 'id')) && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">Requested Number Of Agents</Typography>
          {_get(watch('product'), 'id') === PRODUCT_IDS.MD_CORE && (
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.dark
              }}
            >
              <i>Update if this is MetaDefender Core v4.x</i>
            </Typography>
          )}

          <TextField
            {...register('quantity')}
            fullWidth
            size="small"
            error={!!_get(errors, 'quantity.message', '')}
            helperText={_get(errors, 'quantity.message', '')}
            required
            onKeyUp={(e: any) => {
              if (_get(e, 'keyCode') === 13) {
                handleSubmit(onSuccess, onFail)()
              }
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="subtitle1">Description</Typography>

        <TextField
          {...register('description')}
          minRows={4}
          multiline
          fullWidth
          sx={{
            '& textarea': {
              resize: 'vertical'
            }
          }}
          size="small"
          error={!!_get(errors, 'description.message', '')}
          helperText={_get(errors, 'description.message', '')}
          required
          onKeyUp={(e: any) => {
            if (_get(e, 'keyCode') === 13) {
              handleSubmit(onSuccess, onFail)()
            }
          }}
        />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  )
}

export default LicenseActivateForm
