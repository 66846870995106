import _get from 'lodash/get'
import _isInteger from 'lodash/isInteger'

import { FC, useContext, useEffect } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import { ButtonLoading, DialogAdvanced, Grid } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/feedback-bg.png'

import NPSSurveyForm from '../../Form/NPSSurveyForm'
import { DialogNPSSurveyContext, NPS_MAX_LENGTH, NPS_OPTIONS } from './interface'

const DialogNPSSurvey: FC<unknown> = () => {
  const {
    control,
    surveyData,
    openSurveyDialog,
    handleSubmit,
    handleSubmitNPSSurvey,
    isLoadingSubmitSurvey,
    handleRejectNPSSurvey,
    handleReset,
    isCustomer,
    handleGetNPSOption
  } = useContext(DialogNPSSurveyContext)

  const { isValid } = useFormState({
    control
  })

  const surveyTitle = _get(surveyData, 'name', 'Product Feedback')
  const surveyQuestions = _get(surveyData, 'questions', []).map((question: any) =>
    ['text'].includes(question.type)
      ? {
          ...question,
          maxLength: NPS_MAX_LENGTH
        }
      : question
  )
  const scoreQuestion = surveyQuestions.find((item: any) => ['score'].includes(item.type))
  const scoreValue = useWatch({
    control,
    name: scoreQuestion?.id
  })

  function checkNPSValidation(questions: any[]) {
    let newQuestions = [...questions]
    if (scoreQuestion) {
      if (isCustomer) {
        newQuestions = newQuestions.map((item: any) =>
          item.type === 'dropdown'
            ? {
                ...item,
                data: [
                  {
                    label: 'All My Licensed Products',
                    value: NPS_OPTIONS.ALL_LICENSED_PRODUCTS,
                    groupName: item.data.length > 0 ? _get(item, ['data', '0', 'groupName']) : ''
                  },
                  ...item.data
                ]
              }
            : item
        )
      }
      const requireComment = _isInteger(scoreValue) && Number(scoreValue) < 7
      newQuestions = newQuestions.map((question: any) =>
        ['text'].includes(question.type)
          ? {
              ...question,
              required: requireComment,
              placeholder: _get(
                question,
                ['placeHolder', requireComment ? 'detractor' : 'default'],
                handleGetNPSOption(`placeHolder.${requireComment ? 'detractor' : 'default'}`)
              )
            }
          : question
      )
    }
    return newQuestions
  }

  useEffect(() => {
    handleReset()
  }, [openSurveyDialog])

  return (
    <DialogAdvanced
      open={openSurveyDialog}
      title={surveyTitle}
      background={imageBg}
      content={<NPSSurveyForm questions={checkNPSValidation(surveyQuestions)} />}
      actions={
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'text',
                    color: 'inherit',
                    disabled: isLoadingSubmitSurvey,
                    onClick: () => handleRejectNPSSurvey('reject')
                  }}
                >
                  {handleGetNPSOption('cancelBtn')}
                </ButtonLoading>
              </Grid>
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    disabled: !isValid || isLoadingSubmitSurvey,
                    onClick: handleSubmit(handleSubmitNPSSurvey)
                  }}
                  propsLoading={{ color: 'inherit' }}
                  isLoading={isLoadingSubmitSurvey}
                >
                  {handleGetNPSOption('submitBtn')}
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
      dialogTitleProps={{
        sx: {
          padding: '16px 30px'
        }
      }}
      dialogContentProps={{
        sx: {
          paddingBottom: '0px',
          paddingX: '30px',
          // hide scrollbars
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
      dialogActionsProps={{
        sx: {
          paddingX: '20px',
          paddingY: '0px',
          marginY: '16px'
        }
      }}
    />
  )
}

export default DialogNPSSurvey
