import React from 'react'

import _get from 'lodash/get'

import { Typography } from '@opswat/react-ui'
import { fullName } from '@opswat/react-core'

import { useTypedSelector } from 'myopswat-web/src/store'

const SOCSurveyForm = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  return (
    <>
      <Typography variant="body2" paragraph>
        Hi <b>{fullName(_get(profileData, 'firstName'), _get(profileData, 'lastName'))}</b>,
      </Typography>

      <Typography variant="body2">
        Win an iPad by taking our 5-question survey! It will to help us better understand your Security Operation Center
        (SOC) needs so we can improve our product offerings.
      </Typography>
    </>
  )
}

export default SOCSurveyForm
