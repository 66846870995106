import React, { FC, useState } from 'react'

import _get from 'lodash/get'
import _find from 'lodash/find'

import {
  TemplateFormLogin,
  TemplateSection,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  TypographyLink,
  Typography
} from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'

import { useNavigate } from 'react-router-dom'

import { useOrganizationsQuery } from 'myopswat-web/src/api/organization'

import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { myInformationSecurityPageURL } from 'myopswat-web/src/routes'

import StepIntro from './StepIntro'
import StepTOTP from './StepTOTP'
import StepQR from './StepQR'

interface IProps {
  setupMFAData: any
  profile: any
  isLoadingSetupMFA: boolean
  handleSetupMFA: () => void
  isLoadingVerifyMFAToken: boolean
  handleVerifyMFAToken: (data: string) => void
}

const MFAPage: FC<IProps> = ({
  setupMFAData,
  profile,
  isLoadingSetupMFA,
  handleSetupMFA,
  isLoadingVerifyMFAToken,
  handleVerifyMFAToken
}) => {
  const navigate = useNavigate()
  const { data: organizationsData } = useOrganizationsQuery()

  const organizationName =
    _get(
      _find(organizationsData, (item: any) => item?.id === profile?.currentOrganizationId),
      'name'
    ) || '--'

  const steps = ['1', '2', '3']
  const [step, setStep] = useState<'intro' | 'totp' | 'qr'>('intro')

  return (
    <>
      <TemplateFormLogin background={imageBg}>
        <TemplateSection spacing={4}>
          <Box>
            <OpswatIcon />
            {_get(profile, 'organizationForceMfa', false) && !_get(profile, 'accountInfo.isMfaEnabled', false) ? (
              <Typography variant="body2" color="error" sx={{ pt: 2, mb: -4 }}>
                Your default organization&nbsp;
                <Typography component="span" color="error">
                  {organizationName}
                </Typography>
                &nbsp;requires Multi-factor Authentication for logging in.
              </Typography>
            ) : (
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <TypographyLink onClick={() => navigate(myInformationSecurityPageURL)}>
                  Back to My Information Page
                </TypographyLink>
              </Box>
            )}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={true}>
              {step === 'intro' && (
                <StepIntro
                  setupMFAData={setupMFAData}
                  isLoadingSetupMFA={isLoadingSetupMFA}
                  handleSetupMFA={handleSetupMFA}
                  setStep={setStep}
                />
              )}
              {step === 'totp' && <StepTOTP setStep={setStep} setupMFAData={setupMFAData} />}
              {step === 'qr' && (
                <StepQR
                  setupMFAData={setupMFAData}
                  profile={profile}
                  isLoadingVerifyMFAToken={isLoadingVerifyMFAToken}
                  handleVerifyMFAToken={handleVerifyMFAToken}
                />
              )}
            </Grid>
            <Grid
              item
              xs="auto"
              sx={{
                display: { xs: 'none', sm: 'flex' },
                direction: 'column',
                alignItems: 'center',
                paddingBottom: step !== 'totp' ? '250px' : '200px'
              }}
            >
              <Box sx={{ maxWidth: 400 }}>
                <Stepper
                  activeStep={(step === 'qr' && 2) || (step === 'totp' && 1) || (step === 'intro' && 0) || 0}
                  orientation="vertical"
                  sx={{
                    '& .MuiStepConnector-line': {
                      minHeight: step !== 'totp' ? '45px' : '85px'
                    }
                  }}
                >
                  {steps.map(step => (
                    <Step key={step.toString()}>
                      <StepLabel />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
          </Grid>
        </TemplateSection>
      </TemplateFormLogin>
    </>
  )
}

export default MFAPage
