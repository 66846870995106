/** ----------------- PATHNAME -------------------- */
export const personalInformationPath = 'personal-information'
export const preferencesPath = 'preferences'
export const securityPath = 'security'

/** ----------------- ROUTE -------------------- */
export const myInformationPageURL = '/my-information'
export const myInformationFlexPageURL = '/my-information/:tabPath'
export const myInformationSecurityPageURL = `${myInformationPageURL}/${securityPath}`
export const myInformationPreferencesPageURL = `${myInformationPageURL}/${preferencesPath}`
export const myInformationPersonalInfoPageURL = `${myInformationPageURL}/${personalInformationPath}`
