import { FC, useMemo } from 'react'

import { Link, Outlet } from 'react-router-dom'

import { ExternalLinkIcon } from '@opswat/react-icon'
import { LeftMenu, PageLoading, TemplateDashboard } from '@opswat/react-ui'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  isSuspending?: boolean
}

const PublicLayoutPage: FC<IProps> = ({ isSuspending }) => {
  const arrayData = [
    {
      label: 'OPSWAT Academy',
      propListItem: {
        selected: false,
        component: Link,
        to: process.env.REACT_APP_ACADEMY_URL,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    },
    {
      label: 'Request Support',
      propListItem: {
        selected: false,
        component: Link,
        to: `${process.env.REACT_APP_OPSWAT_GO_URL}/login?sc=0LE4X000000k9dd`,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    },
    {
      label: 'Product Documentation',
      propListItem: {
        selected: false,
        component: Link,
        to: process.env.REACT_APP_OPSWAT_DOCS_URL,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    },
    {
      label: 'OPSWAT Channel',
      propListItem: {
        selected: false,
        component: Link,
        to: process.env.REACT_APP_OPSWAT_ALLBOUND_URL,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    },
    {
      label: 'Contact OPSWAT',
      propListItem: {
        selected: false,
        component: Link,
        to: `${process.env.REACT_APP_OPSWAT_URL}/contact/`,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    },
    {
      label: 'All Systems Operational',
      propListItem: {
        selected: false,
        component: Link,
        to: `${process.env.REACT_APP_OPSWAT_TRUST_URL}/?utm_source=embed`,
        target: '_blank'
      },
      rightIcon: <ExternalLinkIcon />
    }
  ]

  const leftContent = useMemo(() => {
    if (isSuspending) {
      return <LeftMenu menuList={arrayData} content={<PageLoading background="white" color="primary" />} />
    }
    return <LeftMenu menuList={arrayData} content={<Outlet />} />
  }, [isSuspending])

  return <TemplateDashboard left={<LeftMenu menuList={arrayData} content={leftContent} />} />
}

export default PublicLayoutPage
