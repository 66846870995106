export enum ENGINE_STATUS_TAB {
  LINUX = 'Linux',
  WINDOWS = 'Windows',
  PACKAGES = 'Packages'
}

export const ENGINE_PACKAGE_DATETIME_FORMAT = 'MM/dd/yyyy'

export enum ENGINE_ERROR_CODES {
  ENGINE_PACKAGE_PERMISSION = 'engine_package_permission'
}
