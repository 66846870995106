import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _intersection from 'lodash/intersection'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  useEntryIgnoreMutation,
  useEntrySeenMutation,
  useEntryUnseenMutation,
  useLazyProductRelatedEntriesQuery
} from 'myopswat-web/src/api/entry'
import {
  useDownloadedProductAddMutation,
  useIcapIntegrationsQuery,
  useLazyLicensedProductsQuery,
  useLazyProductQuery,
  useProductInterestedAddMutation,
  useProductInterestedRemoveMutation
} from 'myopswat-web/src/api/product'
import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import {
  CLOUDBASE,
  EVALUABLE,
  OEM,
  ONPREMISE,
  ONPREMISE_OEM_TAGS,
  UNDOWNLOADABLE,
  UTILITY
} from 'myopswat-web/src/constants/product-type'
import {
  addSeenEntries,
  ignoreEntries,
  removeSeenEntries,
  saveProductChangeDetail,
  saveProductDetail,
  saveProductRelatedEntries,
  saveProductUtilityDetail,
  selectProductChangeDetail,
  selectProductRelatedEntries
} from 'myopswat-web/src/containers/MyDetailContainer/myDetailContainerSlice'
import MyDetailPage from 'myopswat-web/src/pages/MyDetailPage'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

const MyDetailContainer = () => {
  const dispatch = useAppDispatch()
  const { productSlug = '' } = useParams()
  const { t } = useTranslation()

  const productRelatedEntries = useTypedSelector(selectProductRelatedEntries)
  const productChangeDetail = useTypedSelector(selectProductChangeDetail)

  const { enqueueSnackbar } = useSnackbar()

  const [saveItem, setSaveItem] = useState<any>()

  const [getProduct, { data: productDetailData, isLoading: isFetchingProductDetail, error: productDetailError }] =
    useLazyProductQuery()

  const [downloadedProductAdd] = useDownloadedProductAddMutation()

  const [getLicensedProducts, { data: licensedProductsData, isFetching: isFetchingLicensedProducts }] =
    useLazyLicensedProductsQuery()

  const [getProductRelatedEntries, { data: productRelatedEntriesData, isFetching: isFetchingProductRelatedEntries }] =
    useLazyProductRelatedEntriesQuery()

  const [entrySeen, { data: entrySeenData, error: entrySeenError }] = useEntrySeenMutation()
  const [entryUnseen, { data: entryUnseenData, error: entryUnseenError }] = useEntryUnseenMutation()
  const [entryIgnore, { data: entryIgnoreData, error: entryIgnoreError }] = useEntryIgnoreMutation()

  const { data: icapIntegrationsData } = useIcapIntegrationsQuery(undefined, {
    skip: _get(productDetailData, 'id') !== PRODUCT_IDS.MD_ICAP
  })

  const [productInterestedAdd, { data: productInterestedAddData, error: productInterestedAddError }] =
    useProductInterestedAddMutation()

  const [productInterestedRemove, { data: productInterestedRemoveData, error: productInterestedRemoveError }] =
    useProductInterestedRemoveMutation()

  const handleDownloadedProductAdd = async (data: IDownloadedProductInput) => {
    try {
      await downloadedProductAdd(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntrySeen = async (data: any) => {
    try {
      setSaveItem(data)
      await entrySeen(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntryUnseen = async (data: any) => {
    try {
      setSaveItem(data)
      await entryUnseen(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEntryIgnore = async (data: any) => {
    try {
      setSaveItem(data)
      await entryIgnore(_get(data, 'id')).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleProductInterestedAdd = async (data: string) => {
    try {
      await productInterestedAdd(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleProductInterestedRemove = async (data: string) => {
    try {
      await productInterestedRemove(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const handleReturnTag = (data: any) => {
    const tags: string[] = []
    if (_includes(data, ONPREMISE)) {
      tags.push(ONPREMISE)
    } else if (_includes(data, OEM)) {
      tags.push(OEM)
    } else if (_includes(data, CLOUDBASE)) {
      tags.push(CLOUDBASE)
    } else {
      tags.push(UTILITY)
    }
    const extendedTags = [EVALUABLE, UNDOWNLOADABLE]
    extendedTags.forEach((tag: string) => {
      if (_includes(data, tag)) {
        tags.push(tag)
      }
    })
    return tags
  }

  useEffect(() => {
    if (productSlug) {
      getProduct(productSlug)
    }
  }, [productSlug])

  useEffect(() => {
    const hasErrorMessage =
      _get(productDetailError, 'errors.0.message') ||
      _get(productDetailError, 'message') ||
      _get(productDetailData, 'errors.0.message')
    const hasExpiredErrorCode =
      _get(productDetailError, 'errors.0.code') === 'jwt_signature_expired' ||
      _get(productDetailData, 'errors.0.code') === 'jwt_signature_expired'

    if (productDetailData) {
      const tagType = handleReturnTag(_get(productDetailData, 'tags'))
      const productId = _get(productDetailData, 'id', '')

      dispatch(saveProductDetail(productDetailData))
      dispatch(saveProductChangeDetail(productDetailData))
      dispatch(saveProductUtilityDetail(_get(productDetailData, 'utilities', [])))

      getProductRelatedEntries(productId)

      if (_intersection(tagType, ONPREMISE_OEM_TAGS).length > 0) {
        getLicensedProducts({
          q: '',
          licenseType: '',
          status: 'all',
          productId: productId
        })
      }
    } else if (hasErrorMessage && !hasExpiredErrorCode) {
      enqueueSnackbar(_get(productDetailError, 'errors.0.message', ''), {
        variant: 'error',
        autoHideDuration: null
      })
    }
  }, [productDetailData, productDetailError])

  useEffect(() => {
    if (productRelatedEntriesData) {
      dispatch(saveProductRelatedEntries(productRelatedEntriesData))
    }
  }, [isFetchingProductRelatedEntries])

  useEffect(() => {
    if (_get(entrySeenData, 'success')) {
      dispatch(addSeenEntries(saveItem))
    } else if (
      _get(entrySeenError, 'errors.0.message') ||
      _get(entrySeenError, 'message') ||
      _get(entrySeenData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('makeReadFail'), {
        variant: 'error'
      })
    }
  }, [entrySeenData, entrySeenError])

  useEffect(() => {
    if (_get(entryUnseenData, 'success')) {
      dispatch(removeSeenEntries(saveItem))
    } else if (
      _get(entryUnseenError, 'errors.0.message') ||
      _get(entryUnseenError, 'message') ||
      _get(entryUnseenData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('unmakeReadFail'), {
        variant: 'error'
      })
    }
  }, [entryUnseenData, entryUnseenError])

  useEffect(() => {
    if (_get(entryIgnoreData, 'success')) {
      enqueueSnackbar(t('removeBlogSuccess'), {
        variant: 'success'
      })

      dispatch(ignoreEntries(saveItem))
    } else if (
      _get(entryIgnoreError, 'errors.0.message') ||
      _get(entryIgnoreError, 'message') ||
      _get(entryIgnoreData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('removeBlogFail'), {
        variant: 'error'
      })
    }
  }, [entryIgnoreData, entryIgnoreError])

  useEffect(() => {
    if (_get(productInterestedAddData, 'success')) {
      enqueueSnackbar(t('makeFavoriteSuccess'), {
        variant: 'success'
      })

      getProduct(productSlug)
    } else if (
      _get(productInterestedAddError, 'errors.0.message') ||
      _get(productInterestedAddError, 'message') ||
      _get(productInterestedAddData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('makeFavoriteFail'), {
        variant: 'error'
      })
    }
  }, [productInterestedAddData, productInterestedAddError])

  useEffect(() => {
    if (_get(productInterestedRemoveData, 'success')) {
      enqueueSnackbar(t('unmakeFavoriteSuccess'), {
        variant: 'success'
      })

      getProduct(productSlug)
    } else if (
      _get(productInterestedRemoveError, 'errors.0.message') ||
      _get(productInterestedRemoveError, 'message') ||
      _get(productInterestedRemoveData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('unmakeFavoriteFail'), {
        variant: 'error'
      })
    }
  }, [productInterestedRemoveData, productInterestedRemoveError])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {_get(productChangeDetail, `name`, 'Product Detail')}
        </title>
      </Helmet>
      <MyDetailPage
        productDetailData={productChangeDetail}
        isFetchingProductDetail={isFetchingProductDetail}
        handleDownloadedProductAdd={handleDownloadedProductAdd}
        productRelatedEntriesData={productRelatedEntries}
        isFetchingProductRelatedEntries={isFetchingProductRelatedEntries}
        handleEntrySeen={handleEntrySeen}
        handleEntryUnseen={handleEntryUnseen}
        handleEntryIgnore={handleEntryIgnore}
        licensedProductsData={licensedProductsData}
        isFetchingLicensedProducts={isFetchingLicensedProducts}
        icapIntegrationsData={icapIntegrationsData}
        handleProductInterestedAdd={handleProductInterestedAdd}
        handleProductInterestedRemove={handleProductInterestedRemove}
        handleReturnTag={handleReturnTag}
      />
    </>
  )
}

export default MyDetailContainer
