import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import _get from 'lodash/get'

import { OpswatIcon } from '@opswat/react-icon'
import { Box, Button, ButtonLoading, PageBackground, Typography, TypographyLink, useTheme } from '@opswat/react-ui'

import { useLocation, useNavigate } from 'react-router-dom'

import { useUserUnsubscribeMutation } from 'myopswat-web/src/api/unsubscribed'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { homePageURL } from 'myopswat-web/src/routes'

const UnsubscribedPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramsCode = searchParams.get('code') || ''

  const [userUnsubscribe, { isLoading, data }] = useUserUnsubscribeMutation()

  const handleUnsubscribe = async () => {
    await userUnsubscribe({
      code: paramsCode
    })
  }

  useEffect(() => {
    handleUnsubscribe()
  }, [])

  const handleRenderUI = (children: React.ReactNode) => {
    return (
      <PageBackground background={imageBg}>
        <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
          <title itemProp="name" lang="en">
            {'Unsubscribe'}
          </title>
        </Helmet>
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3
          }}
        >
          <Box
            sx={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.background.paper,
              p: 4,
              [theme.breakpoints.up('md')]: {
                width: '450px',
                height: 'auto',
                minHeight: '500px'
              }
            }}
          >
            <OpswatIcon />

            {children}

            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Protecting the
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                World's Critical Infrastructure
              </Typography>
            </Box>
          </Box>
        </Box>
      </PageBackground>
    )
  }

  if (data && !_get(data, 'success')) {
    return handleRenderUI(
      <Box>
        <Typography textAlign="center" paragraph>
          We're sorry, but we encountered an issue processing your unsubscribe request. Please contact&nbsp;
          <TypographyLink
            href={`${process.env.REACT_APP_OPSWAT_URL}/support/contact-support`}
            target="_blank"
            color="primary"
            underline="none"
            fontWeight={500}
          >
            OPSWAT team
          </TypographyLink>
          &nbsp;for further support.
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(homePageURL)
            }}
          >
            Go to My OPSWAT
          </Button>
        </Box>
      </Box>
    )
  }

  return handleRenderUI(
    <Box>
      <Typography textAlign="center" paragraph>
        Thank you for your time with us. If you have any feedback or wish to re-subscribe in the future, we'll be here
        to welcome you back.
      </Typography>

      <Box display="flex" justifyContent="center">
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              navigate(homePageURL)
            }
          }}
          isLoading={isLoading}
        >
          Go to My OPSWAT
        </ButtonLoading>
      </Box>
    </Box>
  )
}

export default UnsubscribedPage
