import { FC } from 'react'

import { UseFormSetValue } from 'react-hook-form'

import { ISupportCaseCreateInput } from 'myopswat-web/src/api/support/types'
import SubmitCaseForm from 'myopswat-web/src/components/Form/SubmitCaseForm'

interface IProps {
  control: any
  setValue: UseFormSetValue<ISupportCaseCreateInput>
  originalValues: any
  setOriginalValues: (value: any) => void
  productList: any[]
}

const CaseCreateStep: FC<IProps> = props => {
  return <SubmitCaseForm {...props} />
}

export default CaseCreateStep
