import React, { FC } from 'react'

import { Skeleton, Box, Grid, Card } from '@opswat/react-ui'

const BlogSkeleton: FC<unknown> = () => {
  return (
    <Card variant="outlined">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="150px" />
        </Grid>
        <Grid item xs={6}>
          <Box p={1}>
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" width="85%" />
            <Skeleton animation="wave" variant="text" width="100%" />
            <Skeleton animation="wave" variant="text" width="40%" />
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default BlogSkeleton
