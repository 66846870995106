import { FC, useEffect, useMemo, useState } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import { Controller, UseFormSetValue, useFormState, useWatch } from 'react-hook-form'

import { Autocomplete, Grid, MenuItem, TextField, TextFieldArea, Typography } from '@opswat/react-ui'

import { ISupportCaseCreateInput } from 'myopswat-web/src/api/support/types'
import { PRODUCTS_OS_MAP } from 'myopswat-web/src/pages/SupportServicesPage/contants'

interface IProps {
  control: any
  setValue: UseFormSetValue<ISupportCaseCreateInput>
  originalValues: any
  setOriginalValues: (value: any) => void
  productList: any[]
}

const SubmitCaseForm: FC<IProps> = ({ control, setValue, originalValues, setOriginalValues, productList }) => {
  const { errors }: any = useFormState({
    control
  })

  const [productOptions, setProductOptions] = useState<any[]>([])

  useEffect(() => {
    setProductOptions([...productList].sort((a: any, b: any) => a.name.localeCompare(b.name)))
  }, [productList])

  const severityArray = useMemo(() => {
    return [
      {
        code: 'Severity 1 (Production server or other mission critical system(s) are down and no workaround is immediately available.)',
        name: 'Severity 1 (Production server or other mission critical system(s) are down and no workaround is immediately available.)'
      },
      {
        code: 'Severity 2 (Major functionality is severely impaired.)',
        name: 'Severity 2 (Major functionality is severely impaired.)'
      },
      {
        code: 'Severity 3 (Partial, non-critical loss of functionality of the software.)',
        name: 'Severity 3 (Partial, non-critical loss of functionality of the software.)'
      },
      {
        code: 'Severity 4 (General usage question or issue with minor business impact.)',
        name: 'Severity 4 (General usage question or issue with minor business impact.)'
      }
    ]
  }, [])

  const productName = useWatch({
    control,
    name: 'productName'
  })

  const osNameArray = useMemo(() => {
    if (productName in PRODUCTS_OS_MAP) {
      return PRODUCTS_OS_MAP[productName as keyof typeof PRODUCTS_OS_MAP]
    }
    return []
  }, [productName])

  useEffect(() => {
    if (!originalValues) {
      setValue('osName', '', { shouldDirty: true })
      setValue('osVersion', '', { shouldDirty: true })
    } else {
      setOriginalValues(undefined)
    }
  }, [productName])

  return (
    <Grid container rowSpacing={3} columnSpacing={8} sx={{ pt: '24px' }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          Product Name<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Controller
          name="productName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              options={_map(productOptions, item => _get(item, 'code')) || []}
              value={value}
              getOptionLabel={(option: any) =>
                _get(
                  _find(productOptions, item => _get(item, 'code') === option),
                  'name'
                ) ?? ''
              }
              onChange={(_: any, newValue: any) => {
                onChange(newValue)
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  multiline
                  size="small"
                  variant="outlined"
                  error={!!_get(errors, 'productName', '')}
                  helperText={_get(errors, 'productName.message', '')}
                  placeholder="Select Product"
                  sx={{ minHeight: '0px' }}
                />
              )}
              renderOption={(props, option, state) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  selected={state.selected}
                  sx={{
                    whiteSpace: 'normal',
                    '&.Mui-selected': { backgroundColor: '#1D6BFC !important', color: '#FFFFFF !important' }
                  }}
                >
                  {_get(
                    _find(productOptions, item => _get(item, 'code') === option),
                    'name'
                  ) ?? ''}
                </MenuItem>
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}></Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          Product Version
        </Typography>

        <Controller
          name="versionNumber"
          control={control}
          render={({ field }: any) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              error={!!_get(errors, 'versionNumber', '')}
              helperText={_get(errors, 'versionNumber.message', '')}
              sx={{ minHeight: '0px' }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          License Key<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Controller
          name="licenseKey"
          control={control}
          render={({ field }: any) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              error={!!_get(errors, 'licenseKey', '')}
              helperText={_get(errors, 'licenseKey.message', '')}
              sx={{ minHeight: '0px' }}
            />
          )}
        />
      </Grid>
      {!_isEmpty(osNameArray) && (
        <>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" height={'20px'} mb={1}>
              OS Name
            </Typography>

            <Controller
              name="osName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={osNameArray}
                  value={value}
                  onChange={(event: any, newValue: any) => {
                    onChange(newValue)
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      error={!!_get(errors, 'osName', '')}
                      helperText={_get(errors, 'osName.message', '')}
                      placeholder="Select OS"
                      sx={{ minHeight: '0px' }}
                    />
                  )}
                  renderOption={(props, option, state) => (
                    <MenuItem
                      {...props}
                      key={option}
                      value={option}
                      selected={state.selected}
                      sx={{
                        whiteSpace: 'normal',
                        '&.Mui-selected': { backgroundColor: '#1D6BFC !important', color: '#FFFFFF !important' }
                      }}
                    >
                      {option}
                    </MenuItem>
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" height={'20px'} mb={1}>
              OS Version
            </Typography>

            <Controller
              name="osVersion"
              control={control}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'osVersion', '')}
                  helperText={_get(errors, 'osVersion.message', '')}
                  sx={{ minHeight: '0px' }}
                />
              )}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          Severity<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Controller
          name="severity"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              options={_map(severityArray, item => _get(item, 'code')) || []}
              value={value}
              getOptionLabel={(option: any) =>
                _get(
                  _find(severityArray, item => _get(item, 'code') === option),
                  'name'
                ) ?? ''
              }
              onChange={(event: any, newValue: any) => {
                onChange(newValue)
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  multiline
                  size="small"
                  variant="outlined"
                  error={!!_get(errors, 'severity', '')}
                  helperText={_get(errors, 'severity.message', '')}
                  placeholder="Select Severity"
                  sx={{ minHeight: '0px' }}
                />
              )}
              renderOption={(props, option, state) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  selected={state.selected}
                  sx={{
                    whiteSpace: 'normal',
                    '&.Mui-selected': { backgroundColor: '#1D6BFC !important', color: '#FFFFFF !important' }
                  }}
                >
                  {option}
                </MenuItem>
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          Subject<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Controller
          name="subject"
          control={control}
          render={({ field }: any) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              error={!!_get(errors, 'subject', '')}
              helperText={_get(errors, 'subject.message', '')}
              sx={{ minHeight: '0px' }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1" height={'20px'} mb={1}>
          Description<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Controller
          name="description"
          control={control}
          render={(cProps: any) => (
            <TextFieldArea
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              minRows={4}
              maxRows={8}
              error={!!_get(errors, 'description', '')}
              helperText={_get(errors, 'description.message', '')}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sx={{ paddingY: '8px !important' }}>
        <Typography
          sx={{
            fontSize: '11px',
            fontStyle: 'italic',
            lineHeight: '16px',
            color: '#707682'
          }}
        >
          You have the option to attach files in the next step
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SubmitCaseForm
