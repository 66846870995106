import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Autocomplete, FormDialog, Grid, TextField } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import { useOrganizationChangeUsersRolesMutation } from 'myopswat-web/src/api/organization'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { UPDATE_USER_ERROR_MESSAGES } from '../constants'

export interface UserRole {
  id: string
  name: string
  ssoId: string
}

interface IProps {
  organization: any
  user: any
  roleMap: any
  roles: UserRole[]
}

const ChangeRoleModal = NiceModal.create(({ organization, user, roleMap, roles }: IProps) => {
  const modal = useModal()
  const [selectedRole, setSelectedRole] = useState<string>()
  const [organizationChangeUserRoles, { isLoading }] = useOrganizationChangeUsersRolesMutation()
  const [error, setError] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: UPDATE_USER_ERROR_MESSAGES })

  const handleChangeUserRoles = () => {
    if (_isEmpty(selectedRole)) {
      setError({
        type: 'error',
        message: 'Role cannot be blank'
      })
    } else {
      try {
        // Compare with current user's roles
        if (!_isEqual([selectedRole], user.roles)) {
          const ssoRoles: string[] = roles.filter(role => role.id === selectedRole).map(role => role.ssoId)
          organizationChangeUserRoles({
            organizationId: organization.id,
            ssoId: organization.ssoId,
            users: [
              {
                id: user.userId,
                email: user.email,
                ssoId: user.userSsoId,
                roles: [selectedRole],
                ssoRoles: ssoRoles
              }
            ]
          })
            .unwrap()
            .then(async (res: any) => {
              if (res.success) {
                enqueueSnackbar("Change user's role successfully!", {
                  variant: 'success'
                })
                modal.resolve()
                modal.hide()
              } else {
                showErrorMessage(res.errors)
              }
            })
        } else {
          modal.hide()
        }
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  }

  useEffect(() => {
    if (user.roles) {
      setSelectedRole(user.roles[0])
    }
  }, [user])

  return (
    <FormDialog
      isLoading={isLoading}
      title={`Change Roles for ${user.email}`}
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      onConfirm={handleChangeUserRoles}
      confirmBtnLabel="Submit"
      maxWidth="xs"
      minHeight={200}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Role
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              value={selectedRole!}
              options={Object.keys(roleMap)}
              getOptionLabel={(option: any) => {
                return _get(roleMap, `${option}.name`, '')
              }}
              onChange={(_event: any, newValue: any) => {
                setSelectedRole(newValue)
                setError(null)
              }}
              isOptionEqualToValue={(option, value) => {
                return option == value
              }}
              renderInput={(params: any) => (
                <TextField {...params} size="small" variant="outlined" error={error} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
      }
    />
  )
})

export default ChangeRoleModal
