import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'

import { Box, IconButton, Rating, TableAdvanced, TypographyLineClamp, TypographyLink } from '@opswat/react-ui'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { EditIcon } from '@opswat/react-icon'

import { useLazyOrganizationCasesQuery } from 'myopswat-web/src/api/support'
import DialogCSAT from 'myopswat-web/src/components/Dialog/DialogCSAT'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT } from 'myopswat-web/src/constants'
import { useTypedSelector } from 'myopswat-web/src/store'

import CasesNoData from './CasesNoData'

interface IProps {
  control: Control
  setValue: UseFormSetValue<any>
  casesInfo: any
}

const CasesTable: FC<IProps> = ({ control, setValue, casesInfo }) => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const userId = useMemo(() => _get(profileData, 'id') ?? '', [profileData])

  const currentOrganizationId = useMemo(() => _get(profileData, 'currentOrganizationId') ?? '', [profileData])

  const pageInfo = useWatch({ control, name: 'pageInfo' })
  const caseType = useWatch({ control, name: 'filters.caseType' })

  const [getSupportCases, { data, isFetching }] = useLazyOrganizationCasesQuery()

  const [openCSATDialog, setOpenCSATDialog] = useState<boolean>(false)
  const [selectedCase, setSelectedCase] = useState<any>({})

  const getCaseNumberColumn = useCallback(
    (data: any) => (
      <TypographyLink
        color="primary"
        fontWeight="400"
        underline="hover"
        href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${data.id}`}
      >
        {_isEmpty(data.caseNumber) ? '--' : data.caseNumber}
      </TypographyLink>
    ),
    []
  )

  const getProductColumn = useCallback(
    (data: any) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.subCategory}>
        {_isEmpty(data.subCategory) ? '--' : data.subCategory}
      </TypographyLineClamp>
    ),
    []
  )

  const getSubjectColumn = useCallback(
    (data: any) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.subject}>
        {_isEmpty(data.subject) ? '--' : data.subject}
      </TypographyLineClamp>
    ),
    []
  )

  const getCloseDateColumn = useCallback((data: any) => {
    const dateValue = data.closeDate ? formatDatetime(data.closeDate, DATE_FORMATS.DATE) : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={dateValue}>
        {dateValue}
      </TypographyLineClamp>
    )
  }, [])

  const getUpdateDateColumn = useCallback((data: any) => {
    const dateValue = data.lastModifiedDate ? formatDatetime(data.lastModifiedDate, DATE_FORMATS.DATE) : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={dateValue}>
        {dateValue}
      </TypographyLineClamp>
    )
  }, [])

  const getMyRatingColumn = useCallback(
    (data: any) => (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '23px' }}>
        <Rating readOnly precision={0.5} defaultValue={data.satisfactoryNumber} />
        <IconButton
          onClick={() => {
            setSelectedCase(data)
            setOpenCSATDialog(true)
          }}
        >
          <EditIcon size={16} color="#8E939B" />
        </IconButton>
      </Box>
    ),
    [userId]
  )

  const getOrgRatingColumn = useCallback(
    (data: any) => (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '23px' }}>
        <Rating readOnly precision={0.5} defaultValue={data.satisfactoryNumber} />
        {userId === data.userId && (
          <IconButton
            onClick={() => {
              setSelectedCase(data)
              setOpenCSATDialog(true)
            }}
          >
            <EditIcon size={16} color="#8E939B" />
          </IconButton>
        )}
      </Box>
    ),
    [userId]
  )

  const getStatusColumn = useCallback(
    (data: any) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.status}>
        {_isEmpty(data.status) ? '--' : data.status}
      </TypographyLineClamp>
    ),
    []
  )

  const getContactNameColumn = useCallback(
    (data: any) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.contactName}>
        {_isEmpty(data.contactName) ? '--' : data.contactName}
      </TypographyLineClamp>
    ),
    []
  )

  const getColumnStyles = useCallback((width: string) => {
    return { minWidth: width, width: width, px: '8px', py: '10px', sx: { px: '8px', py: '0px', height: '40.06px' } }
  }, [])

  const myOpenColumns = useMemo(() => {
    return [
      {
        header: 'Case No.',
        body: getCaseNumberColumn,
        style: getColumnStyles('5vw')
      },
      {
        header: 'Product',
        body: getProductColumn,
        style: getColumnStyles('10vw')
      },
      {
        header: 'Subject',
        body: getSubjectColumn,
        style: getColumnStyles('13vw')
      },
      {
        header: 'Last Updated',
        body: getUpdateDateColumn,
        style: getColumnStyles('8vw')
      },
      {
        header: 'Status',
        body: getStatusColumn,
        style: getColumnStyles('auto')
      }
    ]
  }, [getColumnStyles])

  const orgOpenColumns = useMemo(() => {
    return [
      {
        header: 'Case No.',
        body: getCaseNumberColumn,
        style: getColumnStyles('5vw')
      },
      {
        header: 'Contact Name',
        body: getContactNameColumn,
        style: getColumnStyles('6vw')
      },
      {
        header: 'Product',
        body: getProductColumn,
        style: getColumnStyles('8vw')
      },
      {
        header: 'Subject',
        body: getSubjectColumn,
        style: getColumnStyles('12vw')
      },
      {
        header: 'Last Updated',
        body: getUpdateDateColumn,
        style: getColumnStyles('6vw')
      },
      {
        header: 'Status',
        body: getStatusColumn,
        style: getColumnStyles('auto')
      }
    ]
  }, [getColumnStyles])

  const myClosedColumns = useMemo(() => {
    return [
      {
        header: 'Case No.',
        body: getCaseNumberColumn,
        style: getColumnStyles('5vw')
      },
      {
        header: 'Product',
        body: getProductColumn,
        style: getColumnStyles('10vw')
      },
      {
        header: 'Subject',
        body: getSubjectColumn,
        style: getColumnStyles('13vw')
      },
      {
        header: 'Closed On',
        body: getCloseDateColumn,
        style: getColumnStyles('6vw')
      },
      {
        header: 'Overall Satisfaction',
        body: getMyRatingColumn,
        style: getColumnStyles('auto')
      }
    ]
  }, [getColumnStyles])

  const orgClosedColumns = useMemo(() => {
    return [
      {
        header: 'Case No.',
        body: getCaseNumberColumn,
        style: getColumnStyles('5vw')
      },
      {
        header: 'Contact Name',
        body: getContactNameColumn,
        style: getColumnStyles('6vw')
      },
      {
        header: 'Product',
        body: getProductColumn,
        style: getColumnStyles('8vw')
      },
      {
        header: 'Subject',
        body: getSubjectColumn,
        style: getColumnStyles('12vw')
      },
      {
        header: 'Closed On',
        body: getCloseDateColumn,
        style: getColumnStyles('6vw')
      },
      {
        header: 'Overall Satisfaction',
        body: getOrgRatingColumn,
        style: getColumnStyles('auto')
      }
    ]
  }, [getColumnStyles])

  const handlePagination = useCallback(
    (pageNumber: number, pageSize: number) => {
      if (pageSize !== pageInfo.pageSize) {
        pageNumber = PAGE_DEFAULT
      }
      setValue('pageInfo', { page: pageNumber, pageSize })
    },
    [pageInfo]
  )

  const handleGetTotal = useCallback(() => {
    let key
    switch (caseType) {
      case 'myOpen':
        key = 'my_case_open_count'
        break
      case 'myClosed':
        key = 'my_case_closed_count'
        break
      case 'orgOpen':
        key = 'org_case_open_count'
        break
      case 'orgClosed':
        key = 'org_case_closed_count'
        break
    }
    return _get(casesInfo, `${key}`) || 0
  }, [casesInfo, caseType])

  const handleGetColumns = useCallback(() => {
    switch (caseType) {
      case 'myOpen':
        return myOpenColumns
      case 'myClosed':
        return myClosedColumns
      case 'orgOpen':
        return orgOpenColumns
      case 'orgClosed':
        return orgClosedColumns
      default:
        return []
    }
  }, [caseType])

  const handleSearchOnSubmit = useCallback(() => {
    getSupportCases({
      id: currentOrganizationId ?? '',
      caseType,
      pageInfo
    })
  }, [currentOrganizationId, caseType, pageInfo])

  useEffect(() => {
    getSupportCases({
      id: currentOrganizationId ?? '',
      caseType,
      pageInfo
    })
  }, [currentOrganizationId])

  useEffect(() => {
    getSupportCases({
      id: currentOrganizationId ?? '',
      caseType,
      pageInfo
    })
  }, [pageInfo])

  return (
    <>
      <TableAdvanced
        isPagination
        disableBackground
        columns={handleGetColumns()}
        isLoading={isFetching}
        data={data}
        total={handleGetTotal()}
        page={pageInfo.page}
        pageSize={pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePagination}
        noDataText={<CasesNoData columns={handleGetColumns()} />}
        noDataTextProps={{ sx: { my: 0 } }}
      />
      <DialogCSAT
        caseInfo={{ ...selectedCase, orgId: currentOrganizationId }}
        openDialog={openCSATDialog}
        handleCloseDialog={() => setOpenCSATDialog(false)}
        handleSearchOnSubmit={handleSearchOnSubmit}
      />
    </>
  )
}

export default CasesTable
