import { CheckDoneIcon, ChevronDownIcon, CreateNewGroupIcon } from '@opswat/react-icon'
import { Box, Grid, MenuItem, Select, Skeleton, Typography, useTheme } from '@opswat/react-ui'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { useLazyOrganizationsQuery } from 'myopswat-web/src/api/organization'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { DialogNPSSurveyContext, NPS_OPTIONS } from '../../../Dialog/DialogNPSSurvey/interface'

interface IProps {
  question: any
}

const NPSIdentity: FC<IProps> = ({ question }) => {
  const theme = useTheme()
  const { control, setValue } = useContext(DialogNPSSurveyContext)

  const [options, setOptions] = useState<{ name: string; id: string }[]>([])
  const [getOrganizations, { data, isFetching }] = useLazyOrganizationsQuery()

  const questionId = _get(question, 'id', '')
  const questionTitle = _get(question, 'title', 'I am completing the form as a representative of')

  useEffect(() => {
    getOrganizations()
  }, [])

  useEffect(() => {
    if (data) {
      const newOptions = [...data, { name: 'Myself', id: NPS_OPTIONS.MYSELF }]
      if (data.length === 1) {
        setOptions(newOptions)
        setValue('representative', data[0].id)
      } else if (data.length > 1) {
        setOptions([{ name: 'All Organizations', id: NPS_OPTIONS.ALL_ORGANIZATIONS }, ...newOptions])
        setValue('representative', NPS_OPTIONS.ALL_ORGANIZATIONS)
      } else {
        // no orgs
        setOptions(newOptions)
        setValue('representative', 'YOURSELF')
      }
    }
  }, [data])

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {isFetching ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" />
      ) : (
        <>
          <CreateNewGroupIcon color="#707682" />
          <Typography variant="subtitle2" component="label" color="#707682" marginLeft={1}>
            {questionTitle}
          </Typography>
          {options.length > 1 ? (
            <Box sx={{ maxWidth: 0 }}>
              <Controller
                name={questionId}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    disableUnderline
                    size="small"
                    variant="standard"
                    value={value}
                    onChange={(e: any) => onChange(e.target.value)}
                    IconComponent={() => (
                      <ChevronDownIcon
                        style={{
                          pointerEvents: 'none',
                          position: 'absolute',
                          right: 4,
                          width: 16,
                          height: 16
                        }}
                      />
                    )}
                    MenuProps={{
                      sx: {
                        '&& .Mui-selected': {
                          backgroundColor: '#FFFFFF'
                        },
                        '&& .Mui-selected .selectedIcon': {
                          display: 'flex'
                        }
                      }
                    }}
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '5px',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#000000',
                        '&:focus': {
                          backgroundColor: 'transparent'
                        }
                      }
                    }}
                  >
                    {_map(options, item => (
                      <MenuItem
                        value={item.id}
                        sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between', gap: 1 }}
                      >
                        {item.name}
                        <Box className="selectedIcon" sx={{ display: 'none' }}>
                          <CheckDoneIcon color={theme.palette.primary.main} />
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          ) : (
            <Typography component="label" variant="subtitle1" marginLeft={0.5}>
              {'Myself'}
            </Typography>
          )}
        </>
      )}
    </Grid>
  )
}

export default NPSIdentity
