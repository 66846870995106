import { REGEXS } from '@myopswat/common'
import * as yup from 'yup'

const addressSchema = yup.object().shape({
  streetAddress1: yup
    .string()
    .trim()
    .required('Address 1 cannot be blank')
    .max(256, 'Address 1 is limited to 256 characters'),
  streetAddress2: yup.string().trim().nullable().max(256, 'Address 2 is limited to 256 characters'),
  city: yup.string().trim().required('City cannot be blank').max(256, 'City is limited to 256 characters'),
  country: yup.string().trim().required('Country cannot be blank').max(50, 'Country is limited to 50 characters'),
  state: yup.string().trim().nullable().max(128, 'State is limited to 128 characters'),
  postalCode: yup
    .string()
    .trim()
    .required('Postal Code cannot be blank')
    .max(20, 'Postal Code is limited to 20 characters')
    .matches(REGEXS.SPECIAL_CHARACTERS_REGEX, 'Postal Code should not contain special characters'),
  phone: yup
    .string()
    .trim()
    .required('Phone cannot be blank')
    .matches(
      REGEXS.PHONE_NUMBER_REGEX,
      'Please enter the phone number correctly in the format. For example: +14325551212'
    )
    .max(128, 'Phone is limited to 128 characters')
})

export const basicSchema = yup.object().shape({
  owner: yup
    .string()
    .trim()
    .matches(REGEXS.SPECIAL_CHARACTERS_REGEX, 'Owner should not contain special characters')
    .nullable()
    .max(255, 'Owner is limited to 255 characters'),
  website: yup.string().trim().nullable().max(255, 'Website is limited to 255 characters'),
  industries: yup.array().of(yup.number()),
  note: yup
    .string()
    .trim()
    .test('no-html', 'Please do not enter HTML formatted text.', value => {
      if (value) {
        return !REGEXS.HTML_REGEX.test(value)
      }
      return true
    })
    .nullable()
    .max(1000, 'Note is limited to 1000 characters'),
  // region: yup.string().trim().nullable().max(255, 'Region is limited to 255 characters'),
  // subRegion: yup.string().trim().nullable().max(255, 'Sub region is limited to 255 characters'),
  // channelPartner: yup.string().trim().nullable().max(255, 'Channel Partner is limited to 255 characters'),
  // currency: yup.string().trim().nullable().max(255, 'Currency is limited to 255 characters'),
  // customerSince: yup.date().default(null).nullable(),
  // supportLevel: yup.string().trim().nullable().max(255, 'Support level is limited to 255 characters'),
  billingAddress: addressSchema,
  shippingAddress: addressSchema
})
