import React, { FC, useEffect, useState } from 'react'

import _capitalize from 'lodash/capitalize'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IProps {
  children?: React.ReactNode
  setPurchasedFilter: (data: any) => void
}

const BoxPurchasedFilter: FC<IProps> = ({ setPurchasedFilter }) => {
  const { t: translate } = useTranslation()
  const searchParams = new URLSearchParams(location.search)

  const statusArray = ['active', 'expired']

  const [keyword, setKeyword] = useState<string>()
  const [status, setStatus] = useState()

  const { handleSubmit, control, watch, reset, setValue } = useForm<any>()

  const handleReturnStatus = (status: string[] | undefined) => {
    if (_get(status, 'active') && !_get(status, 'expired')) return 'active'
    if (!_get(status, 'active') && _get(status, 'expired')) return 'expired'
    return 'all'
  }

  const onSuccess = (data: any) => {
    setStatus(data)
    setPurchasedFilter({
      q: keyword,
      licenseType: 'PURCHASED',
      status: handleReturnStatus(data)
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        setPurchasedFilter({
          q: keyword,
          licenseType: 'PURCHASED',
          status: handleReturnStatus(status)
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  useEffect(() => {
    if (watch('all') === true) {
      setValue('active', false)
      setValue('expired', false)
    }
  }, [watch('all')])

  useEffect(() => {
    const q = searchParams.get('q')
    if (q != null) {
      setKeyword(q)
    }
  }, [searchParams])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterProductName') || ''}
          onChange={(e: any) => {
            if (_get(e, 'target')) {
              setKeyword(e.target.value)
            }
          }}
          onClearText={() => setKeyword('')}
        />
      </Grid>
      <Grid item xs="auto">
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Status</Typography>
                </Grid>
                {_map(statusArray, (item: any, idx: any) => (
                  <Grid item xs={12} key={idx.toString()}>
                    <Controller
                      name={`${item}`}
                      control={control}
                      render={(cProps: any) => (
                        <CheckboxWithLabel
                          label={<Typography variant="subtitle2">{_capitalize(item) || '--'}</Typography>}
                          checkboxProps={{
                            checked: !!cProps.field.value,
                            onChange: (e: any) => {
                              cProps.field.onChange(e.target.checked)
                              setValue('all', false)
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          }
          onResetFilter={() => {
            setValue('all', true)
          }}
          onResetChange={() => reset()}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BoxPurchasedFilter
