import { apiRestful } from 'myopswat-web/src/api/base-query'

export const api = apiRestful.injectEndpoints({
  endpoints: builder => ({
    saml2Login: builder.query<any, void>({
      query() {
        return {
          url: 'saml2/login/',
          method: 'GET',
          responseHandler: async response => response,
          cache: 'no-cache'
        }
      }
    }),
    saml2Logout: builder.query<any, void>({
      query() {
        return {
          url: 'saml2/logout/',
          method: 'GET',
          responseHandler: async response => response,
          cache: 'no-cache'
        }
      }
    })
  })
})

export const { useSaml2LoginQuery, useLazySaml2LoginQuery, useSaml2LogoutQuery, useLazySaml2LogoutQuery } = api
