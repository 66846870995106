/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _format from 'date-fns/format'

import {
  Typography,
  Box,
  IconButton,
  useTheme,
  BoxImageVideo,
  TypographyLineClamp,
  Grid,
  Tooltip,
  ListItem
} from '@opswat/react-ui'
import { EyeIcon, CloseIcon } from '@opswat/react-icon'
import { Link } from 'react-router-dom'

import defaultImage from 'myopswat-web/src/assets/images/default-bg.png'
import { formatDatetime } from '@opswat/react-core'
import { DATE_FORMATS } from '@myopswat/common'

interface IProps {
  propsData: any
  propsComponent: any
}

const BoxBlog: FC<IProps> = ({ propsData, propsComponent }) => {
  const theme = useTheme()
  const isSeen = _get(propsData, 'seen', false)

  const handleSeen = (data: any) => {
    if (!data) return null

    if (isSeen) {
      const unread = _get(propsComponent, 'handleEntryUnseen', (data: any) => {})
      return unread(data)
    } else {
      const read = _get(propsComponent, 'handleEntrySeen', (data: any) => {})
      return read(data)
    }
  }

  const handleRemove = (data: any) => {
    if (!data) return null

    const remove = _get(propsComponent, 'handleEntryIgnore', (data: any) => {})
    return remove(data)
  }

  return (
    <ListItem
      disablePadding
      component={Link}
      to={_get(propsData, 'url')}
      target="_blank"
      onClick={() => {
        if (!isSeen) {
          handleSeen(propsData)
        }
      }}
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.info.main}`,
        transition: 'transform 0.75s',
        '&:hover': {
          backgroundColor: theme.palette.info.light
        }
      }}
    >
      {_get(propsData, 'seoImage') ? (
        <Grid container>
          <Grid item xs={6}>
            <BoxImageVideo
              src={_get(propsData, 'seoImage') || defaultImage}
              blurImage={_get(propsData, 'seoImage') || defaultImage}
              backgroundColor="white"
              ratio={12 / 9}
              propsRoot={{ sx: { height: '100%' } }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={isSeen ? 'Read' : 'Unread'} arrow>
                <Box display="flex" alignItems="center">
                  {isSeen ? (
                    <EyeIcon color={theme.palette.info.main} />
                  ) : (
                    <EyeIcon color={theme.palette.text.primary} />
                  )}
                </Box>
              </Tooltip>

              <Tooltip title="Remove blog from list" arrow>
                <IconButton
                  size="small"
                  onClick={(e: any) => {
                    handleRemove(propsData)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Box px={2} py={1}>
              <Typography variant="caption">
                {formatDatetime(_get(propsData, 'postDate'), DATE_FORMATS.DATE)}
              </Typography>
              <Box>
                <TypographyLineClamp
                  line={3}
                  variant="h3"
                  sx={{
                    minHeight: '68px'
                  }}
                >
                  {_get(propsData, 'title', '--')}
                </TypographyLineClamp>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs sx={{ minHeight: '50px' }}>
                <Box
                  px={1}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}
                >
                  {_map(_get(propsData, 'productTags'), (item: any, idx: any) => (
                    <Box
                      sx={{
                        bgcolor: 'inherit',
                        border: `1px solid ${theme.palette.info.main}`,
                        borderRadius: '4px',
                        paddingLeft: '4px',
                        paddingRight: '4px'
                      }}
                      key={idx.toString()}
                      mr={0.5}
                      mt={0.5}
                    >
                      <Typography variant="caption">{item}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box display="flex" alignItems="center">
                  <Tooltip title={isSeen ? 'Read' : 'Unread'} arrow>
                    <Box display="flex" alignItems="center">
                      {isSeen ? (
                        <EyeIcon color={theme.palette.info.main} />
                      ) : (
                        <EyeIcon color={theme.palette.text.primary} />
                      )}
                    </Box>
                  </Tooltip>

                  <Tooltip title="Remove blog from list" arrow>
                    <IconButton
                      size="small"
                      onClick={(e: any) => {
                        handleRemove(propsData)
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>

            <Box px={2} py={1}>
              <Box>
                <Typography variant="caption">
                  {formatDatetime(_get(propsData, 'postDate'), DATE_FORMATS.DATE)}
                </Typography>
              </Box>
              <Box>
                <TypographyLineClamp
                  line={2}
                  variant="h3"
                  sx={{
                    minHeight: '48px'
                  }}
                >
                  {_get(propsData, 'title', '--')}
                </TypographyLineClamp>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </ListItem>
  )
}

export default BoxBlog
