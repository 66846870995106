import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IMultipleNotificationsDismissInput, INotificationDismissInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    notificationMessages: builder.query<any, any>({
      query: pageInfo => ({
        document: gql`
          query notificationMessages($pageInfo: PageInfoType) {
            notificationMessages(pageInfo: $pageInfo) {
              totalCount
              results {
                id
                content
                type
                dismissed
                createdAt
                read
              }
            }
          }
        `,
        variables: {
          pageInfo
        }
      })
    }),
    getRecentReleaseNotifications: builder.query<any, any>({
      query: pageInfo => ({
        document: gql`
          query releaseNotifications($pageInfo: PageInfoType) {
            releaseNotifications(pageInfo: $pageInfo) {
              totalCount
              results {
                id
                productName
                productSlug
                platforms
                releaseDate
              }
            }
          }
        `,
        variables: {
          pageInfo
        }
      })
    }),
    checkAllNotifications: builder.query<any, void>({
      query: () => ({
        document: gql`
          query checkAllNotifications {
            checkAllNotifications {
              messages
              releases
            }
          }
        `
      })
    }),
    hasNewNotification: builder.query<any, void>({
      query: () => ({
        document: gql`
          query hasNewNotification {
            hasNewNotification {
              newNotification
            }
          }
        `
      })
    }),
    notificationDismiss: builder.mutation<any, INotificationDismissInput>({
      query: ({ notificationId }) => ({
        document: gql`
          mutation notificationDismiss($input: NotificationDismissInput!) {
            notificationDismiss(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            notificationId
          }
        }
      })
    }),
    multipleNotificationsDismiss: builder.mutation<any, IMultipleNotificationsDismissInput>({
      query: ({ notificationIds }) => ({
        document: gql`
          mutation multipleNotificationsDismiss($input: MultipleNotificationsDismissInput!) {
            multipleNotificationsDismiss(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            notificationIds
          }
        }
      })
    }),
    multipleNotificationsRead: builder.mutation<any, IMultipleNotificationsDismissInput>({
      query: ({ notificationIds }) => ({
        document: gql`
          mutation multipleNotificationsRead($input: MultipleNotificationsReadInput!) {
            multipleNotificationsRead(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            notificationIds
          }
        }
      })
    })
  })
})

export const {
  useLazyNotificationMessagesQuery,
  useLazyGetRecentReleaseNotificationsQuery,
  useLazyHasNewNotificationQuery,
  useLazyCheckAllNotificationsQuery,
  useNotificationDismissMutation,
  useMultipleNotificationsDismissMutation,
  useMultipleNotificationsReadMutation
} = api
