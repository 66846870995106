import React, { FC } from 'react'

import _map from 'lodash/map'
import _size from 'lodash/size'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Typography,
  TableLoading,
  TypographyNoData
} from '@opswat/react-ui'

import BoxRolesCell from './BoxRolesCell'

interface IProps {
  isFetchingRoles: boolean
  rolesData: any
  handleRoleDelete: (data: string) => void
}

const BoxRolesTable: FC<IProps> = ({ rolesData, isFetchingRoles, handleRoleDelete }) => {
  const theme = useTheme()

  return (
    <Box sx={{ minHeight: '85vh' }}>
      {isFetchingRoles && <TableLoading />}
      {!isFetchingRoles && _size(rolesData) === 0 && <TypographyNoData />}
      {!isFetchingRoles && _size(rolesData) > 0 && (
        <TableContainer component={Box}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 200, width: 200 }}
                >
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 300,
                    width: 300
                  }}
                >
                  <Typography>Description</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 180,
                    width: 180
                  }}
                >
                  <Typography>Created by</Typography>
                </TableCell>

                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 700,
                    width: 700
                  }}
                >
                  <Typography>Permissions</Typography>
                </TableCell>

                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 150 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_map(rolesData, (item, idx) => (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    },
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.grey[50]
                    }
                  }}
                  key={idx.toString()}
                >
                  <TableCell component="th" scope="row" colSpan={5} sx={{ p: 0.25 }}>
                    <BoxRolesCell data={item} handleRoleDelete={handleRoleDelete} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default BoxRolesTable
