import React, { FC } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { Link } from 'react-router-dom'

import {
  Table,
  TableCell,
  TableHead,
  TypographyLink,
  Typography,
  Button,
  Box,
  Tooltip,
  useTheme
} from '@opswat/react-ui'
import { formatDatetime } from '@opswat/react-core'
import { OEM, UTILITY } from 'myopswat-web/src/constants/product-type'

import { homePageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'
import { DATE_FORMATS } from '@myopswat/common'

interface IProps {
  data: any
  setSelectItem: (data: any) => void
  setIsOpenView: (data: boolean) => void
  setIsOpenPVersion: (data: boolean) => void
}

const BoxDownloadedCell: FC<IProps> = ({ data, setSelectItem, setIsOpenPVersion, setIsOpenView }) => {
  const theme = useTheme()
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const handleRenderNumberOfDownload = (number: number) => {
    if (!number) return '--'
    return `${number}`
  }

  return (
    <Box>
      <Table component="div">
        <TableHead component="div">
          <TableCell component="div" sx={{ p: 0.25, width: 400 }}>
            <Typography
              variant="body2"
              color="primary"
              component={Link}
              to={`${homePageURL}/${_get(data, 'slug', '')}`}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
            >
              {_get(data, 'name')}
            </Typography>
            &nbsp;
            {_includes(_get(data, 'tags'), UTILITY) && (
              <Box
                component="span"
                sx={{
                  bgcolor: theme.palette.info.main,
                  borderRadius: '4px',
                  paddingLeft: '8px',
                  paddingRight: '8px'
                }}
                ml={2}
              >
                <Typography variant="caption">Utility</Typography>
              </Box>
            )}
            &nbsp;
            {_includes(_get(data, 'tags'), OEM) && (
              <Box
                component="span"
                sx={{
                  bgcolor: theme.palette.info.main,
                  borderRadius: '4px',
                  paddingLeft: '8px',
                  paddingRight: '8px'
                }}
                ml={2}
              >
                <Typography variant="caption">OEM</Typography>
              </Box>
            )}
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, width: 150, textAlign: 'center' }}>
            {!_get(data, 'downloadedCount', 0) ? (
              <Typography variant="body2">{handleRenderNumberOfDownload(_get(data, 'downloadedCount', 0))}</Typography>
            ) : (
              <Tooltip title="Click to see Download History" arrow placement="top">
                <Box component="span">
                  <TypographyLink
                    variant="body2"
                    color="primary"
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => {
                      setSelectItem(data)
                      setIsOpenView(true)
                    }}
                  >
                    {handleRenderNumberOfDownload(_get(data, 'downloadedCount', 0))}
                  </TypographyLink>
                </Box>
              </Tooltip>
            )}
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, width: 180, textAlign: 'center' }}>
            <Typography variant="body2">
              {formatDatetime(_get(data, 'latestDownloadedAt'), DATE_FORMATS.DATE_TIME, _get(profile, 'timezone')) ||
                '--'}
            </Typography>
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, minWidth: 150, textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectItem(data)
                setIsOpenPVersion(true)
              }}
            >
              Download
            </Button>
          </TableCell>
        </TableHead>
      </Table>
    </Box>
  )
}

export default BoxDownloadedCell
