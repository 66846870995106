import React, { FC, useMemo } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _capitalize from 'lodash/capitalize'

import { Box, useTheme, TypographyLineClamp, Typography } from '@opswat/react-ui'

import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import {
  saveProductChangeDetail,
  selectProductChangeDetail,
  selectProductDetail
} from 'myopswat-web/src/containers/MyDetailContainer/myDetailContainerSlice'
import { UNDOWNLOADABLE, OEM } from 'myopswat-web/src/constants/product-type'

interface IProps {
  propsData: any
}

const BoxUtilityProduct: FC<IProps> = ({ propsData }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const productChangeDetail = useTypedSelector(selectProductChangeDetail)
  const productDetail = useTypedSelector(selectProductDetail)

  const productTags = useMemo(() => {
    const tags = _get(propsData, 'tags', []).filter((tag: any) => tag !== UNDOWNLOADABLE)

    const displayTags = _map(tags, (tag: any) => {
      if (tag === OEM) return 'OEM'

      return _capitalize(tag)
    })

    return displayTags
  }, [propsData])

  return (
    <Box
      sx={{
        boxShadow: '0px 2px 8px rgba(61, 74, 104, 0.16)',
        height: '100%',
        transition: 'transform 0.75s',
        '&:hover': {
          backgroundColor: theme.palette.info.light
        },
        background:
          _get(propsData, 'slug') === _get(productChangeDetail, 'slug')
            ? theme.palette.info.light
            : theme.palette.common.white
      }}
      onClick={() => {
        if (_get(propsData, 'slug') === _get(productChangeDetail, 'slug')) {
          dispatch(saveProductChangeDetail(productDetail))
        } else {
          dispatch(saveProductChangeDetail(propsData))
        }
      }}
    >
      <Box
        p={1}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Box
          px={1}
          mb={1}
          sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {_map(_get(propsData, 'type'), (item: any, idx: any) => (
            <Box
              sx={{
                bgcolor: 'inherit',
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: '4px',
                paddingLeft: '4px',
                paddingRight: '4px'
              }}
              key={idx.toString()}
              mr={0.5}
              mt={0.5}
            >
              <Typography variant="caption">{_capitalize(item)}</Typography>
            </Box>
          ))}

          {_map(productTags, (item: any, idx: any) => (
            <Box
              sx={{
                bgcolor: 'inherit',
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: '4px',
                paddingLeft: '4px',
                paddingRight: '4px'
              }}
              key={idx.toString()}
              mr={0.5}
              mt={0.5}
            >
              <Typography variant="caption">{item}</Typography>
            </Box>
          ))}
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '48px' }}>
          <TypographyLineClamp line={2} variant="h3" textAlign="center">
            {_get(propsData, 'name', '...')}
          </TypographyLineClamp>
        </Box>
        <Box display="flex" justifyContent="center">
          <TypographyLineClamp line={2} variant="body2" textAlign="center" sx={{ minHeight: '40px' }}>
            {_get(propsData, 'description', '')
              ? _get(propsData, 'description', '')
                  .replace(/(<([^>]+)>)/gi, '')
                  .replace(/Overview/g, '')
              : ''}
          </TypographyLineClamp>
        </Box>
      </Box>
    </Box>
  )
}

export default BoxUtilityProduct
