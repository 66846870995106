import { Grid, Link, TemplateSection, Typography } from '@opswat/react-ui'
import { useMemo } from 'react'
import ResourcesSection from './CompanyInformation/ResourcesSection'
import WarningMessage from './components/WarningMessage'
interface IProps {
  data: any
}
const SupportNotAvailable = ({ data }: IProps) => {
  const warningMessage = useMemo(() => {
    let message = 'You are not configured to access support functions.'
    if (data?.currentOrgName) {
      if (data?.sfdcOrgName) {
        message = `You are not configured to access support functions for ${data.currentOrgName}. Your support entitlement is under ${data.sfdcOrgName}.`
      } else {
        message = `You are not configured to access support functions for ${data.currentOrgName}.`
      }
    }
    return message
  }, [data])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7} md={9}>
        <TemplateSection spacing={6}>
          <Grid container spacing={2}>
            <ResourcesSection />
            <Grid item xs={12} sm={6} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <WarningMessage message={warningMessage} />
                  <Typography variant="body2" color="textSecondary" marginLeft={3}>
                    {'Go to '}
                    <Link
                      variant="body2"
                      color="primary"
                      target="_blank"
                      href={process.env.REACT_APP_OPSWAT_GO_PORTAL_URL}
                    >
                      {'Support Portal'}
                    </Link>
                    {' for more details.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TemplateSection>
      </Grid>
      <Grid item xs={12} sm={5} md={3}></Grid>
    </Grid>
  )
}

export default SupportNotAvailable
