import { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { CheckDisabledIcon, CheckedDisabledIcon } from '@opswat/react-icon'
import {
  Box,
  CheckboxWithLabel,
  Grid,
  Table,
  TableCell,
  TableHead,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

interface IProps {
  data: any
  handleRoleDelete: (data: string) => void
}

const BoxRolesCell: FC<IProps> = ({ data }) => {
  const handlePermissionsData = () => {
    const allPermissions = _get(data, 'allPermissions', [])
    const permissions = _get(data, 'permissions', [])
    return _map(allPermissions, (item: any) => ({
      ...item,
      checked: permissions.find((p: any) => p.name === item.name)
    }))
  }
  return (
    <Box>
      <Table component="div">
        <TableHead component="div">
          <TableCell component="div" sx={{ p: 0.25, py: 2, width: 200 }}>
            <Typography variant="body2">{_get(data, 'name', '--')}</Typography>
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, py: 2, width: 300 }}>
            <TypographyLineClamp line={2} variant="body2">
              {_get(data, 'description', '--')}
            </TypographyLineClamp>
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, py: 2, width: 180 }}>
            <Typography variant="body2">{_get(data, 'creatorFullname', '')}</Typography>
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, py: 2, width: 700 }}>
            <Grid container spacing={2}>
              {_map(handlePermissionsData(), (item, idx) => (
                <Grid item xs={4} key={idx.toString()}>
                  <CheckboxWithLabel
                    label={<Typography variant="body2">{item.name}</Typography>}
                    checkboxProps={{
                      disabled: true,
                      checked: item.checked,
                      icon: <CheckDisabledIcon />,
                      checkedIcon: <CheckedDisabledIcon />
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </TableCell>

          <TableCell component="div" sx={{ p: 0.25, py: 2, minWidth: 150, textAlign: 'right' }}></TableCell>
        </TableHead>
      </Table>
    </Box>
  )
}

export default BoxRolesCell
