import { Button, Checkbox, DropdownFilter, FormControlLabel, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { ChangeEvent, FC, useEffect, useMemo } from 'react'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'

import { OrganizationUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { STATUS_OPTIONS } from './constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  roles: any[]
  query: OrganizationUserFilterInput
  onSearch: (searchData: OrganizationUserFilterInput) => void
  isAdmin?: boolean
  onInviteUser: () => void
}

const UserFilter: FC<IProps> = ({ roles, query, onSearch, isAdmin, onInviteUser }) => {
  const { t: translate } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields }
  } = useForm<OrganizationUserFilterInput>({
    mode: 'onSubmit',
    defaultValues: query
  })

  const onSuccess = (data: OrganizationUserFilterInput) => {
    onSearch(data)
  }

  const selectedStatus = useWatch({
    control: control,
    name: 'status'
  })

  const selectedRoles = useWatch({
    control: control,
    name: 'roles'
  })

  const q = useWatch({
    control: control,
    name: 'q'
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields])

  const handleSelectStatus = (status: any, checked: boolean) => {
    let newStatus
    if (checked) {
      newStatus = [...selectedStatus, status]
    } else {
      newStatus = selectedStatus.filter(s => s !== status)
    }
    return newStatus
  }

  const handleSelectRole = (role: any, checked: boolean) => {
    let newRoles
    if (checked) {
      newRoles = [...selectedRoles, role]
    } else {
      newRoles = selectedRoles.filter(s => s !== role)
    }
    return newRoles
  }

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          ...getValues(),
          q: q
        }
        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterUserName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs="auto">
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <DropdownFilter
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <Grid container>
                  <Grid item xs={6}>
                    <Controller
                      name={'status'}
                      control={control}
                      render={({ field }) => (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">Status</Typography>
                          </Grid>
                          {STATUS_OPTIONS.map((option, index) => {
                            return (
                              <Grid item xs={12} key={index.toString()}>
                                <FormControlLabel
                                  key={`user-filter-status-${index}`}
                                  control={
                                    <Checkbox
                                      checked={selectedStatus?.includes(option.value as never)}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        field.onChange(handleSelectStatus(option.value, event.target.checked))
                                      }
                                    />
                                  }
                                  label={<Typography variant="subtitle2">{option.label}</Typography>}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={'roles'}
                      control={control}
                      render={({ field }) => (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">Roles</Typography>
                          </Grid>
                          {roles.map((option: any, index: number) => {
                            return (
                              <Grid item xs={12} key={index.toString()}>
                                <FormControlLabel
                                  key={`user-filter-role-${index}`}
                                  control={
                                    <Checkbox
                                      checked={selectedRoles?.includes(option.id as never)}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        field.onChange(handleSelectRole(option.id, event.target.checked))
                                      }
                                    />
                                  }
                                  label={<Typography variant="subtitle2">{option.name}</Typography>}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              }
              onResetFilter={() => {
                reset({
                  q: '',
                  status: [],
                  roles: [],
                  excludeUserIds: []
                })
              }}
              onResetChange={() => reset()}
              onApply={() => {
                handleSubmit(onSuccess)()
              }}
            />
          </Grid>

          {isAdmin && (
            <Grid item xs="auto">
              <Button onClick={onInviteUser} variant="contained" color="primary">
                Invite User
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserFilter
