import {
  myInformationPageURL,
  myInformationPersonalInfoPageURL,
  myInformationPreferencesPageURL,
  myInformationSecurityPageURL
} from './myInformationRoutes'

import {
  licensedProductsCloudBasedPageURL,
  licensedProductsEvaluatedPageURL,
  licensedProductsPageURL,
  licensedProductsPurchasedPageURL
} from './myLicensesRoutes'

import {
  myOrganizationCriticalAlertUsersPageURL,
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationGroupPageURL,
  myOrganizationPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL
} from './myOrganizationRoutes'

import { homePageURL } from './homeRoutes'

import {
  activePageURL,
  changePasswordPageURL,
  confirmEmailPageURL,
  loginPageURL,
  organizationAcceptInvitationURL,
  registerPageURL,
  resendEmailPageURL,
  resetPasswordPageURL,
  verifyOTPPageURL
} from './ssoRoutes'

import { mfaPageURL } from './mfaRoutes'

import { productDownloadsPageURL } from './productDownloadableRoutes'

import {
  engineStatusLinuxPageURL,
  engineStatusPackagePageURL,
  engineStatusWindowsPageURL,
  noPermissionPageUrl
} from './privateRoutes'

import { downloadableProductPageURL, oldPaymentPageUrl, paymentPageURL } from './publicRoutes'
import { reportFalseDetectionHistoryPageURL, reportFalseDetectionSubmitPageURL } from './reportFalseDetectionRoutes'
import { supportServicesPageURL } from './supportServicesRoutes'
import { channelPartnerPageURL } from './channelPartnerRoutes'
import { testIframePageURL } from './testRoutes'

/** Old page mapping */
export const oldUrlsMap = {
  '/portal/products': homePageURL,
  '/portal/oem': homePageURL,
  '/user-management/roles': myOrganizationRolesPageURL,
  '/user-management/users': myOrganizationUsersPageURL,
  '/user-profile/personal-information': myInformationPersonalInfoPageURL,
  '/user-profile/product-subscription': myInformationPreferencesPageURL,
  '/inventory/metadefender-kiosk-l1001/instances':
    '/product-management/inventory/metadefender-kiosk/l-series/instances',
  '/inventory/metadefender-kiosk-l1001/groups': '/product-management/inventory/metadefender-kiosk/l-series/groups',
  '/home/opswat-neuralyzer': '/home/metadefender-ot-security',
  '/home/opswat-client': '/home/metadefender-endpoint',
  '/home/endpoint-clients': '/home/metadefender-endpoint-solution',
  '/home/metaaccess': '/home/metadefender-it-ot-access',
  '/home/metadefender-it-ot-access': '/home/metadefender-it-access',
  '/home/metaaccess-nac': '/home/nac',
  '/home/secure-ot-access': '/home/metadefender-ot-access-on-premise',
  '/home/nac': '/home/metadefender-nac',
  '/home/ot-drive-tool-kit': '/home/metadefender-drive-tool-kit',
  '/home/opswat-filescan-sandbox': '/home/metadefender-sandbox',
  '/home/opswat-endpoint-security-sdk': '/home/metadefender-endpoint-security-sdk',
  '/home/opswat-netwall': '/home/metadefender-netwall',
  '/home/netwall-tp': '/home/transfer-guard',
  '/home/netwall-tp-blue': '/home/transfer-guard-blue',
  '/home/netwall-tp-red': '/home/transfer-guard-red',
  '/home/vulnerability-definitions-for-central-management': '/home/vulnerability-definitions',
  '/home/opswat-otfuse': '/home/otfuse',
  '/home/metadefender-industrial-firewall-ips': '/home/otfuse'
}

/** ----------------- TITLE -------------------- */
export const PageTitle = {
  [loginPageURL]: 'Login',
  [registerPageURL]: 'Register',
  [resetPasswordPageURL]: 'Reset Password',
  [changePasswordPageURL]: 'Change Password',
  [confirmEmailPageURL]: 'Confirm Email',
  [resendEmailPageURL]: 'Resend Email',
  [verifyOTPPageURL]: 'Verify OTP',
  [activePageURL]: 'Active',
  [organizationAcceptInvitationURL]: 'Accepted Invite',
  [mfaPageURL]: 'MFA',
  [homePageURL]: 'Home',
  [myInformationPageURL]: 'My Information',
  [licensedProductsPageURL]: 'Licensed Products',
  [engineStatusWindowsPageURL]: 'Anti Malware Engine Status (Windows)',
  [engineStatusLinuxPageURL]: 'Anti Malware Engine Status (Linux)',
  [engineStatusPackagePageURL]: 'Anti Malware Engine Status (Packages)',
  [myOrganizationPageURL]: 'My Organization',
  [noPermissionPageUrl]: 'No Permission',
  [paymentPageURL]: 'Payment',
  [oldPaymentPageUrl]: 'Payment',
  [productDownloadsPageURL]: 'Product Downloads',
  [downloadableProductPageURL]: 'Downloadable Product',
  [myInformationSecurityPageURL]: 'Security (My Information)',
  [myInformationPreferencesPageURL]: 'Preferences (My Information)',
  [myInformationPersonalInfoPageURL]: 'Personal Information (My Information)',
  [licensedProductsEvaluatedPageURL]: 'Evaluation (My Licenses)',
  [licensedProductsPurchasedPageURL]: 'Purchased (My Licenses)',
  [licensedProductsCloudBasedPageURL]: 'Cloud Services (My Licenses)',
  [myOrganizationGeneralInfoPageURL]: 'General Information (My Organization)',
  [myOrganizationGroupPageURL]: 'Groups (My Organization)',
  [myOrganizationUsersPageURL]: 'Users (My Organization)',
  [myOrganizationRolesPageURL]: 'Application Roles (My Organization)',
  [myOrganizationSecurityPageURL]: 'Security (My Organization)',
  [myOrganizationEventHistoryPageURL]: 'Event History (My Organization)',
  [myOrganizationCriticalAlertUsersPageURL]: 'Critical Alert Users (My Organization)',
  [reportFalseDetectionSubmitPageURL]: 'Report False Detection Submission',
  [reportFalseDetectionHistoryPageURL]: 'Report False Detection History',
  [supportServicesPageURL]: 'Support',
  [channelPartnerPageURL]: 'Channel Partner',
  [testIframePageURL]: 'Test Iframe'
}
