import { TemplateSection } from '@opswat/react-ui'
import _get from 'lodash/get'
import { FC, useCallback, useEffect, useState } from 'react'

import { useLazyLicensedProductsQuery, useLicenseNoteAddMutation } from 'myopswat-web/src/api/product'
import { ILicenseNoteAddInput, ILicenseReportFiltersInput } from 'myopswat-web/src/api/product/types'

import { useTypedSelector } from 'myopswat-web/src/store'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import BoxPurchasedFilter from './BoxPurchasedFilter'
import BoxPurchasedTable from './BoxPurchasedTable'

interface IProps {
  setIsActivate: (data: boolean) => void
  setItemActivate: (data: any) => void
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
}

const BoxPurchased: FC<IProps> = ({ setIsActivate, setItemActivate, setCsvFilter }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [purchasedFilter, setPurchasedFilter] = useState<any>()
  const [purchasedProductsData, setPurchasedProductsData] = useState<any>({})
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const [licenseNoteAdd, { data: licenseNoteAddData, isLoading: isLoadingLicenseNoteAdd, error: licenseNoteAddError }] =
    useLicenseNoteAddMutation()
  const [getLicensedProducts, { isFetching: isFetchingPurchasedProducts }] = useLazyLicensedProductsQuery()

  const fetchData = useCallback(async () => {
    try {
      const data = await getLicensedProducts(purchasedFilter).unwrap()
      setPurchasedProductsData(data)
    } catch (error) {
      enqueueSnackbar(_get(error, 'errors.0.message'), { variant: 'error' })
    }
  }, [getLicensedProducts, purchasedFilter])

  const handleLicenseNoteAdd = useCallback(async (data: ILicenseNoteAddInput) => {
    try {
      await licenseNoteAdd({
        licenseKey: _get(data, 'licenseKey', ''),
        productId: _get(data, 'productId', ''),
        content: _get(data, 'content', ''),
        organizationId: _get(profileData, 'currentOrganizationId') || ''
      }).unwrap()
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (_get(licenseNoteAddData, 'success')) {
      enqueueSnackbar(t('updateLicensesNoteSuccess'), {
        variant: 'success'
      })

      fetchData()
    } else if (
      _get(licenseNoteAddError, 'errors.0.message') ||
      _get(licenseNoteAddError, 'message') ||
      _get(licenseNoteAddData, 'errors.0.message')
    ) {
      enqueueSnackbar(t('updateLicensesNoteFail'), {
        variant: 'error'
      })
    }
  }, [licenseNoteAddData, licenseNoteAddError])

  useEffect(() => {
    setPurchasedFilter({
      q: '',
      licenseType: 'PURCHASED',
      status: 'all'
    })
  }, [])

  useEffect(() => {
    if (purchasedFilter) {
      fetchData()
    }
  }, [purchasedFilter, fetchData])

  return (
    <TemplateSection spacing={2}>
      <BoxPurchasedFilter setPurchasedFilter={setPurchasedFilter} />
      <BoxPurchasedTable
        purchasedProductsData={purchasedProductsData}
        isFetchingPurchasedProducts={isFetchingPurchasedProducts}
        isLoadingLicenseNoteAdd={isLoadingLicenseNoteAdd}
        handleLicenseNoteAdd={handleLicenseNoteAdd}
        isSuccessLicenseNoteAdd={_get(licenseNoteAddData, 'success') || false}
        setIsActivate={setIsActivate}
        setItemActivate={setItemActivate}
        setCsvFilter={setCsvFilter}
      />
    </TemplateSection>
  )
}

export default BoxPurchased
