import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IBaseSurveyInput, ISubmitSurveyInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    submitSurvey: builder.mutation<any, ISubmitSurveyInput>({
      query: input => ({
        document: gql`
          mutation profileUpdate($input: SurveyResponseInput!) {
            surveyResponse(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    rejectSurvey: builder.mutation<any, IBaseSurveyInput>({
      query: ({ surveyId, action }) => ({
        document: gql`
          mutation profileUpdate($input: SurveyResponseInput!) {
            surveyResponse(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            surveyId,
            action
          }
        }
      })
    }),
    checkDisplayNPS: builder.query<any, any>({
      query: () => ({
        document: gql`
          query {
            checkNpsSurveyDisplay
          }
        `
      })
    })
  })
})

export const { useRejectSurveyMutation, useSubmitSurveyMutation, useLazyCheckDisplayNPSQuery } = api
