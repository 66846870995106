import React, { FC } from 'react'

import { CssBaseline, ThemeProvider } from '@opswat/react-ui'

import useThemeOpswatV3 from '../theme/useThemeOpswatV3'

interface IProps {
  children: React.ReactNode
}

const CustomThemeProvider: FC<IProps> = ({ children }) => {
  const opswatTheme = useThemeOpswatV3({ isDarkMode: false })

  return (
    <ThemeProvider theme={opswatTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default CustomThemeProvider
