import { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

import { handleCheckLogin, handleForceLogout, KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'
import { PageLoading } from '@opswat/react-ui'

import { useLazyProfileQuery } from 'myopswat-web/src/api/account'
import {
  CHAT_BOT_CONFIG_URL_V2,
  CHAT_BOT_CONFIG_V2,
  CHAT_BOT_HEADER_URL_CUSTOM,
  CHAT_BOT_INJECT_URL_V2
} from 'myopswat-web/src/constants'
import LayoutPage from 'myopswat-web/src/pages/LayoutPage'
import { iframeLicensedProductsPageURL, mfaPageURL } from 'myopswat-web/src/routes'

import './chatbot.css'

const LayoutContainer: FC<unknown> = () => {
  const { t: translate } = useTranslation()
  const location = useLocation()
  const isToken = handleCheckLogin()

  const { enqueueSnackbar } = useSnackbar()

  const [isRender, setIsRender] = useState<boolean>(false)

  const [getProfile] = useLazyProfileQuery()

  const handleCheckNotLayout = () => {
    if (_includes(location.pathname, mfaPageURL) || _includes(location.pathname, iframeLicensedProductsPageURL))
      return true
    return false
  }

  const loadChatbot = (profile: any) => {
    // botpress script
    const injectScript = document.createElement('script')
    injectScript.src = CHAT_BOT_INJECT_URL_V2
    injectScript.async = true
    document.body.appendChild(injectScript)

    injectScript.onload = () => {
      fetch(CHAT_BOT_CONFIG_URL_V2)
        .then(response => response.json())
        .then(async data => {
          window.botpress.init({
            ...CHAT_BOT_CONFIG_V2,
            theme: data
          })

          // update user data
          const userData = {
            firstname: profile.firstName,
            lastname: profile.lastName,
            email: profile.email,
            contactID: profile.sfdcContactId || '',
            accountID: profile.currentOrganizationAccountId || ''
          }
          await window.botpress.updateUser({
            data: userData
          })

          if (!getLocalStorage(KEYS.BOTPRESS_WEBCHAT)) {
            // clear conversation
            await window.botpress.sendEvent({ type: 'createConversation' })
          }
        })
        .catch(e => {
          window.botpress.init(CHAT_BOT_CONFIG_V2)
          console.error(e)
        })
    }
  }

  useEffect(() => {
    if (getCookie(KEYS.TOKEN_KEY) || getLocalStorage(KEYS.TOKEN_KEY)) {
      getProfile()
        ?.unwrap()
        .then(response => {
          setIsRender(true)

          if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('getProfileFail')), {
              variant: 'error'
            })
          } else {
            if (response.chatbotEnable) {
              // Init Chatbot with user information
              loadChatbot(response)

              // Set user_id to Matomo
              window._paq.push(['setUserId', response.id])
            }
          }
        })
        .catch(error => {
          console.error(error)
          setIsRender(true)
        })
    } else {
      handleForceLogout()
    }
  }, [isToken])

  useEffect(() => {
    // in case the chatbot does not load in time for the script to execute
    const interval = setInterval(() => {
      const chatbots = document.getElementsByClassName('bpOpen')
      if (chatbots.length > 0) {
        const elements = document.getElementsByName('webchat')
        if (elements.length > 0) {
          const iframeChat = elements[0] as HTMLIFrameElement
          const documentChat = iframeChat.contentWindow?.document
          const headerAvartar = documentChat?.getElementsByClassName('bpHeaderContentAvatarImage')[0]
          if (headerAvartar?.getAttribute('src') !== CHAT_BOT_HEADER_URL_CUSTOM) {
            headerAvartar?.setAttribute('src', CHAT_BOT_HEADER_URL_CUSTOM)
          }
        }
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  if (isRender && handleCheckNotLayout()) return <Outlet />
  if (isRender)
    return (
      <>
        <LayoutPage />
      </>
    )
  return <PageLoading background="white" color="primary" />
}

export default LayoutContainer
