import { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _last from 'lodash/last'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { CloseIcon, ExternalLinkIcon } from '@opswat/react-icon'
import {
  Box,
  DialogAdvanced,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableLoading,
  TableRow,
  Typography,
  useTheme
} from '@opswat/react-ui'

import {
  useDownloadedProductAddMutation,
  useLazyIcapIntegrationsQuery,
  useLazyProductQuery
} from 'myopswat-web/src/api/product'
import {
  IDownloadedProductInput,
  ILicenseNoteAddInput,
  ILicenseReportFiltersInput
} from 'myopswat-web/src/api/product/types'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import BoxCommonDialogHistory from '../../LicensedProductsPage/CommonProduct/BoxCommonDialogHistory'
import BoxCommonDialogNote from '../../LicensedProductsPage/CommonProduct/BoxCommonDialogNote'
import BoxPurchasedCell from './BoxPurchasedCell'

interface IProps {
  purchasedProductsData: any
  isFetchingPurchasedProducts: boolean
  isLoadingLicenseNoteAdd: boolean
  handleLicenseNoteAdd: (data: ILicenseNoteAddInput) => void
  isSuccessLicenseNoteAdd: boolean
  setIsActivate: (data: boolean) => void
  setItemActivate: (data: any) => void
  setCsvFilter: (data: ILicenseReportFiltersInput | undefined) => void
}

const BoxPurchasedTable: FC<IProps> = ({
  purchasedProductsData,
  isFetchingPurchasedProducts,
  isLoadingLicenseNoteAdd,
  handleLicenseNoteAdd,
  isSuccessLicenseNoteAdd,
  setIsActivate,
  setItemActivate,
  setCsvFilter
}) => {
  const theme = useTheme()

  const [item, setItem] = useState<any>()
  const [itemLicenses, setItemLicenses] = useState<any>()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [itemDialog, setItemDialog] = useState<any>()
  const [typeDialog, setTypeDialog] = useState<'note' | 'history'>('note')
  const [getIcapIntegrations, { data: icapIntegrationsData }] = useLazyIcapIntegrationsQuery()
  const [getProduct, { data: productDetailData, isFetching: isFetchingProductDetail }] = useLazyProductQuery()
  const [downloadedProductAdd] = useDownloadedProductAddMutation()
  const [isOpenPVersion, setIsOpenPVersion] = useState<boolean>(false)

  useEffect(() => {
    if (isSuccessLicenseNoteAdd) {
      setOpenDialog(false)
    }
  }, [isSuccessLicenseNoteAdd])

  useEffect(() => {
    if (_get(item, 'slug', '')) {
      getProduct(_get(item, 'slug', ''))
      if (_get(item, 'id') === PRODUCT_IDS.MD_ICAP) {
        getIcapIntegrations()
      }
    }
  }, [item])

  const handleDownloadedProductAdd = async (data: IDownloadedProductInput, isICAP?: boolean) => {
    try {
      await downloadedProductAdd(data).unwrap()
      if (isICAP) {
        getIcapIntegrations()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box sx={{ minHeight: '85vh' }}>
      {isFetchingPurchasedProducts && <TableLoading />}
      {!isFetchingPurchasedProducts && _size(purchasedProductsData) === 0 && (
        <Typography
          textAlign="center"
          variant="subtitle2"
          color="textSecondary"
          sx={{
            my: 2,
            color: '#8E939B',
            lineHeight: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          You need to be part of an authorized organization to access Purchased Licenses.
          <br />
          For additional details, please reach out to us via
          <br />
          <Link
            variant="body2"
            target="_blank"
            href="https://www.opswat.com/support/contact-support"
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.25 }}
          >
            OPSWAT Support
            <ExternalLinkIcon color="#1D6BFC" />
          </Link>
        </Typography>
      )}
      {!isFetchingPurchasedProducts && _size(purchasedProductsData) > 0 && (
        <TableContainer component={Box}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 400, width: 400 }}
                >
                  <Typography>Product</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: { xs: 200, xl: 350 },
                    width: { xs: 200, xl: 350 }
                  }}
                >
                  <Typography>License Key</Typography>
                </TableCell>
                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100, width: 100 }}
                >
                  <Typography align="center">Status</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: { xs: 100, xl: 120 },
                    width: { xs: 100, xl: 120 }
                  }}
                >
                  <Typography align="center">License Start</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: { xs: 100, xl: 200 },
                    width: { xs: 100, xl: 200 }
                  }}
                >
                  <Typography align="center">License Expiration</Typography>
                </TableCell>
                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 100, width: 100 }}
                >
                  <Typography align="center">Deployed / Purchased</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0.25,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: { xs: 100, xl: 150 },
                    width: { xs: 100, xl: 150 }
                  }}
                >
                  <Typography>Note</Typography>
                </TableCell>
                <TableCell
                  sx={{ p: 0.25, borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 40 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_map(purchasedProductsData, (item, idx) => (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    },
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.grey[50]
                    }
                  }}
                  key={idx.toString()}
                >
                  <TableCell component="th" scope="row" colSpan={8} sx={{ p: 0.25 }}>
                    <BoxPurchasedCell
                      data={item}
                      setOpenDialog={setOpenDialog}
                      setItemDialog={setItemDialog}
                      setTypeDialog={setTypeDialog}
                      setItem={setItem}
                      setItemLicenses={setItemLicenses}
                      setIsActivate={setIsActivate}
                      setItemActivate={setItemActivate}
                      setCsvFilter={setCsvFilter}
                      setIsOpenPVersion={setIsOpenPVersion}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {item && (
        <DialogDownloadVersion
          icapIntegrationsData={icapIntegrationsData}
          productDetailData={productDetailData}
          isOpenPVersion={isOpenPVersion}
          setIsOpenPVersion={setIsOpenPVersion}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          isFetchingProductDetail={isFetchingProductDetail}
        />
      )}

      <DialogAdvanced
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        iconClose={<CloseIcon />}
        title={typeDialog === 'note' ? 'Note' : `License History - ${_get(item, 'name', '--')}`}
        content={
          <>
            {typeDialog === 'note' ? (
              <BoxCommonDialogNote
                productId={_get(item, 'id')}
                licenseKey={_get(itemLicenses, 'activation_key')}
                content={_get(_last(_get(itemLicenses, 'notes')), 'content') || ''}
                isLoadingLicenseNoteAdd={isLoadingLicenseNoteAdd}
                handleLicenseNoteAdd={handleLicenseNoteAdd}
                setOpenDialog={setOpenDialog}
              />
            ) : (
              <BoxCommonDialogHistory
                data={itemDialog}
                type="purchased"
                productId={_get(item, 'id')}
                productSlug={_get(item, 'slug')}
                product={_get(item, 'name')}
                isLoadingLicenseNoteAdd={isLoadingLicenseNoteAdd}
                handleLicenseNoteAdd={handleLicenseNoteAdd}
                setIsActivate={setIsActivate}
                setItemActivate={setItemActivate}
                setCsvFilter={setCsvFilter}
              />
            )}
          </>
        }
        dialogProps={{
          maxWidth: typeDialog === 'history' ? 'lg' : 'xs'
        }}
      />
    </Box>
  )
}

export default BoxPurchasedTable
