import { FC, useCallback, useMemo, useState } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'

import {
  Autocomplete,
  Box,
  Button,
  ButtonLoading,
  CheckboxWithLabel,
  Grid,
  IconButton,
  InputAdornment,
  TemplateSection,
  TextField,
  TextFieldArea,
  Typography
} from '@opswat/react-ui'

import { ErrorCode, useDropzone } from 'react-dropzone'

import { AttachPaperclip, CloseIcon } from '@opswat/react-icon'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { FalseSubmissionFileOrigins } from 'myopswat-web/src/api/analyzer/falseSubmission/types'
import { useUploadFileMutation } from 'myopswat-web/src/api/analyzer/fileUpload'
import { useFalseDetectionProductsQuery } from 'myopswat-web/src/api/product'
import { MAXIMUM_UPLOAD_FILE_SIZE, MAXIMUM_UPLOAD_IMAGE_SIZE } from 'myopswat-web/src/constants'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import BoxReportType from './BoxReportType'
import { useFalseDetectionAntivirusEnginesQuery } from '../../api/analyzer/falseSubmission'
import { useLazyCheckDisplayNPSQuery } from 'myopswat-web/src/api/survey'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'

interface IForm {
  productIds: string[]
  file: File | string | null
  note: string
  category: string
  fileOrigin: string | undefined
  fileOriginDetail: string
  antivirusEngine: string[]
  detectionName: string
  purpose: string
  userConsent: boolean
  image?: File | null
}

const DEFAULT_FALSE_DETECTION_CATEGORY = 'false_positive'

const ReportFalseDetectionTab: FC<unknown> = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  
  const [fileUpload] = useUploadFileMutation()

  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [isImageSelected, setIsImageSelected] = useState(false)

  const { data: scanProductData } = useFalseDetectionProductsQuery()
  const [checkDisplayNPS] = useLazyCheckDisplayNPSQuery()

  const { data: scanAntivirusEngineData } = useFalseDetectionAntivirusEnginesQuery()

  const defaultValues = {
    productIds: [],
    file: null,
    note: '',
    category: DEFAULT_FALSE_DETECTION_CATEGORY,
    fileOrigin: '',
    antivirusEngine: [],
    detectionName: '',
    purpose: '',
    userConsent: false,
    image: null
  }

  const schema = yup.object().shape({
    file: yup.string().trim().required('Please input a File to analyze'),
    productIds: yup
      .array()
      .of(yup.string())
      .min(1, 'Please select at least one product')
      .required('Please select at least one product'),
    note: yup.string().required('This field cannot be blank'),
    category: yup.string().required(),
    fileOrigin: yup.string().required('File origin cannot be blank'),
    antivirusEngine: yup.array().of(yup.string()).required('Antivirus engine cannot be blank'),
    detectionName: yup.string().required('AV detection/Threat name cannot be blank'),
    purpose: yup.string().required('This field cannot be blank'),
    userConsent: yup.bool().oneOf([true], 'This field must be checked')
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const watchedFields = watch([
    'productIds',
    'file',
    'note',
    'fileOrigin',
    'antivirusEngine',
    'detectionName',
    'purpose',
    'userConsent'
  ])

  const isSubmitDisabled = useMemo(() => {
    return Object.values(watchedFields).some(value => {
      return !value || (Array.isArray(value) && value.length === 0)
    })
  }, [watchedFields])

  const onFileDrop = (files: File[], fileRejections: any[]) => {
    let isBreak = false
    fileRejections.forEach(file => {
      file.errors.forEach((err: any) => {
        if (err.code === ErrorCode.FileTooLarge) {
          enqueueSnackbar('The uploaded file size must be less than 2 GB.', {
            variant: 'error'
          })
        }
        isBreak = true
      })
    })
    if (!isBreak) {
      setValue('file', _get(files, '0'))
    }
  }

  const onFileDropAccepted = () => {
    setIsFileSelected(true)
  }

  const { getRootProps: getFileRootProps, getInputProps: getFileInputProps } = useDropzone({
    onDrop: onFileDrop,
    onDropAccepted: onFileDropAccepted,
    maxSize: MAXIMUM_UPLOAD_FILE_SIZE,
    multiple: false
  })

  const onImageDrop = (files: File[], fileRejections: any[]) => {
    let isBreak = false
    fileRejections.forEach(file => {
      file.errors.forEach((err: any) => {
        if (err.code === ErrorCode.FileTooLarge) {
          enqueueSnackbar('The uploaded file size must be less than 10 MB.', {
            variant: 'error'
          })
        }
        isBreak = true
      })
    })
    if (!isBreak) {
      setValue('image', _get(files, '0'))
    }
  }

  const onImageDropAccepted = () => {
    setIsImageSelected(true)
  }

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/heic': ['.heic'],
      'image/heif': ['.heif'],
      'image/tiff': ['.tiff'],
      'image/gif': ['.gif']
    },
    onDrop: onImageDrop,
    onDropAccepted: onImageDropAccepted,
    maxSize: MAXIMUM_UPLOAD_IMAGE_SIZE,
    multiple: false
  })

  const handleClear = () => {
    setValue('category', DEFAULT_FALSE_DETECTION_CATEGORY)
    reset(defaultValues)
    setIsFileSelected(false)
    setIsImageSelected(false)
  }

  const handleCheckDisplayNPS = useCallback(async () => {
    await checkDisplayNPS({}).then((response: any) => {
      const showDialog = response?.data ?? false
      if (showDialog) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.NPS_SURVEY]: true,
            [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'response-action',
            [DIALOGS_WEB.NPS_NOTIFICATION]: false
          })
        )
      }
    })
  }, [])

  const onSuccess = async (data: any) => {
    const payload: any = {
      note: data.note,
      productIds: data.productIds,
      category: data.category,
      fileOrigin: data.fileOrigin,
      antivirusEngine: data.antivirusEngine,
      detectionName: data.detectionName,
      purpose: data.purpose,
      userConsent: data.userConsent
    }
    if (data.fileOrigin === 'INTERNET_DOWNLOAD' && data.fileOriginDetail) {
      payload['fileOriginDetail'] = data.fileOriginDetail
    }

    const file: any = watch('file')
    const image: any = watch('image')

    try {
      const formData: any = new FormData()
      if (file instanceof File) {
        formData.append('file', file)
      }
      if (image instanceof File) {
        formData.append('image', image)
      }
      formData.append('productIds', JSON.stringify(data.productIds))
      const fields = ['note', 'category', 'fileOrigin', 'antivirusEngine', 'detectionName', 'purpose', 'userConsent']
      fields.forEach((item: string) => {
        formData.append(item, data[item])
      })
      if (data.fileOrigin === 'INTERNET_DOWNLOAD' && data.fileOriginDetail) {
        formData.append('fileOriginDetail', data.fileOriginDetail)
      }
      setIsLoading(true)
      fileUpload(formData)
        .unwrap()
        .then(() => {
          setIsUploadSuccess(true)
          handleCheckDisplayNPS()
        })
        .catch(error => {
          if (error.status === 429) {
            // Too Many Requests status code
            enqueueSnackbar('Please give the system a moment then try again.', {
              variant: 'error'
            })
          } else {
            enqueueSnackbar(_get(error, 'data.errors.0.message', translate('submitFileFail')), {
              variant: 'error'
            })
          }
        })
        .finally(() => setIsLoading(false))
    } catch (error) {
      enqueueSnackbar(translate('submitFileFail'), { variant: 'error' })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  if (isUploadSuccess)
    return (
      <>
        <TemplateSection>
          <Box>
            <Typography>Thank you for your submission!</Typography>
          </Box>

          <Box>
            <Typography>
              We will evaluate the file and send you an email with the tracking ID of this submission.
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsUploadSuccess(false)
                handleClear()
              }}
            >
              Make another submission
            </Button>
          </Box>
        </TemplateSection>
      </>
    )

  return (
    <TemplateSection>
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Submission file
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={6.5}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Select a file"
            fullWidth
            value={_get(watch('file'), 'path')}
            disabled={true}
            InputProps={{
              startAdornment: isFileSelected && (
                <Box sx={{ ml: '-14px', pl: '14px', pr: '16px', backgroundColor: '#E8F0FF' }}>
                  <IconButton
                    sx={{
                      borderRadius: 0,
                      mb: '1px',
                      px: '1px',
                      backgroundColor: '#E8F0FF',
                      '&:hover': {
                        backgroundColor: '#E8F0FF'
                      }
                    }}
                    onClick={(e: any) => {
                      setValue('file', null)
                      setIsFileSelected(false)
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton {...getFileRootProps({ className: 'dropzone' })} sx={{ px: '20px' }}>
                    <AttachPaperclip size={16} />
                    <input {...getFileInputProps()} />
                  </IconButton>
                </InputAdornment>
              ),
              sx: { paddingRight: '0 !important' }
            }}
            inputProps={{
              sx: {
                '&:disabled': {
                  WebkitTextFillColor: 'black',
                  fontWeight: 'regular',
                  backgroundColor: 'white'
                }
              }
            }}
            sx={{ minHeight: 45 }}
            error={!!_get(errors, 'file', '')}
            helperText={_get(errors, 'file.message', '')}
            {...register('file')}
          />
          <Typography color="textSecondary" variant="body2">
            Maximum file size: 2 GB
          </Typography>
          <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 1.5 }}>
            If the submitted file is an archive file, please specify the issues for each child file in the{' '}
            <Typography display="inline">'Why do you think this is a false detection?'</Typography> field.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Submission type
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} onClick={() => setValue('category', 'false_positive')}>
              <BoxReportType
                text={
                  <>
                    <Typography color="inherit">Clean file (false positive)</Typography>
                    <Typography variant="subtitle1" color="inherit" sx={{ mt: 0.3, fontWeight: 'regular' }}>
                      Falsely detected as infected
                    </Typography>
                  </>
                }
                isSelected={watch('category') === 'false_positive'}
              />
            </Grid>

            <Grid item xs={12} sm={6} onClick={() => setValue('category', 'false_negative')}>
              <BoxReportType
                text={
                  <>
                    <Typography color="inherit">Infected File (false negative)</Typography>
                    <Typography variant="subtitle1" color="inherit" sx={{ mt: 0.3, fontWeight: 'regular' }}>
                      Falsely detected as clean
                    </Typography>
                  </>
                }
                isSelected={watch('category') === 'false_negative'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            In which product did the false detection occur?
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="productIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    options={_map(scanProductData, item => _get(item, 'id')) || []}
                    value={value}
                    multiple
                    disableCloseOnSelect={true}
                    getOptionLabel={(option: any) =>
                      _get(
                        _find(scanProductData, item => _get(item, 'id') === option),
                        'name'
                      ) || ''
                    }
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select products"
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'productIds', '')}
                        helperText={_get(errors, 'productIds.message', '')}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Which antivirus engine triggered the false detection?
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="antivirusEngine"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={_map(scanAntivirusEngineData, item => _get(item, 'engineName')) || []}
                    value={value}
                    multiple
                    disableCloseOnSelect={true}
                    getOptionLabel={option => option}
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    onBlur={(event: any) => {
                      if (!event.target.value || event.target.value == '') {
                        return
                      }
                      onChange([...value, event.target.value])
                    }}
                    clearOnBlur={true}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select antivirus engines or Input"
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'antivirusEngine', '')}
                        helperText={_get(errors, 'antivirusEngine.message', '')}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            AV detection/Threat name
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Controller
            name="detectionName"
            control={control}
            render={(cProps: any) => (
              <TextField
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                error={!!_get(errors, 'detectionName', '')}
                helperText={_get(errors, 'detectionName.message', '')}
                required
                size="small"
                fullWidth
                placeholder="E.g. Trojan/Win32.Downloader"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Image of Detection/Report Alert
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Attach an image"
            fullWidth
            value={_get(watch('image'), 'path')}
            disabled={true}
            InputProps={{
              startAdornment: isImageSelected && (
                <Box sx={{ ml: '-14px', pl: '14px', pr: '16px', backgroundColor: 'white' }}>
                  <IconButton
                    sx={{
                      borderRadius: 0,
                      mb: '1px',
                      px: '1px'
                    }}
                    onClick={(e: any) => {
                      setValue('image', null)
                      setIsImageSelected(false)
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton {...getImageRootProps({ className: 'dropzone' })} sx={{ px: '20px' }}>
                    <AttachPaperclip size={16} />
                    <input {...getImageInputProps()} />
                  </IconButton>
                </InputAdornment>
              ),
              sx: { backgroundColor: 'white !important', paddingRight: '0 !important' }
            }}
            inputProps={{
              sx: {
                '&:disabled': {
                  WebkitTextFillColor: 'black',
                  fontWeight: 'regular',
                  backgroundColor: 'white'
                }
              }
            }}
            sx={{ minHeight: 45 }}
            error={!!_get(errors, 'image', '')}
            helperText={_get(errors, 'image.message', '')}
            {...register('image')}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            What is the origin of the file?
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="fileOrigin"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    options={_map(FalseSubmissionFileOrigins, item => _get(item, 'value')) || []}
                    value={value}
                    getOptionLabel={(option: any) =>
                      _get(
                        _find(FalseSubmissionFileOrigins, item => _get(item, 'value') === option),
                        'name'
                      ) || ''
                    }
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select source"
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'fileOrigin', '')}
                        helperText={_get(errors, 'fileOrigin.message', '')}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {watch('fileOrigin') === 'INTERNET_DOWNLOAD' && (
          <Grid item xs={12} sm={10} md={6.5} sx={{ paddingTop: '0 !important' }}>
            <Controller
              name="fileOriginDetail"
              control={control}
              render={(cProps: any) => (
                <TextField
                  value={cProps.field.value}
                  onChange={(e: any) => cProps.field.onChange(e.target.value)}
                  error={!!_get(errors, 'fileOriginDetail', '')}
                  helperText={_get(errors, 'fileOriginDetail.message', '')}
                  required
                  size="small"
                  fullWidth
                  placeholder="Provide a link/source name (if available)"
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            What is the purpose of the submitted file?
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Controller
            name="purpose"
            control={control}
            render={(cProps: any) => (
              <TextFieldArea
                placeholder="E.g. Design program, image viewer, informational document, document editor installer ..."
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                error={!!_get(errors, 'purpose', '')}
                helperText={_get(errors, 'purpose.message', '')}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Why do you think this is a false detection?
            <Typography display="inline" color="red">
              *
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Controller
            name="note"
            control={control}
            render={(cProps: any) => (
              <TextFieldArea
                placeholder="Please let us know why the file was falsely detected as infected or clean."
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                error={!!_get(errors, 'note', '')}
                helperText={_get(errors, 'note.message', '')}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Controller
            name="userConsent"
            control={control}
            render={(cProps: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2">
                    I agree to share the files with AV vendors for further analysis.
                    <Typography display="inline" color="red">
                      *
                    </Typography>
                  </Typography>
                }
                checkboxProps={{
                  checked: !!cProps.field.value,
                  onChange: (e: any) => {
                    cProps.field.onChange(e.target.checked)
                  },
                  error: !!_get(errors, 'userConsent', ''),
                  helperText: _get(errors, 'userConsent.message', '')
                }}
              />
            )}
          />
          {_get(errors, 'userConsent.message', '') && (
            <Typography sx={{ paddingTop: 0.5 }} variant="caption" color="#CB0704">
              {_get(errors, 'userConsent.message', '')}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={10} md={6.5}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  fullWidth: true,
                  onClick: () => handleClear(),
                  disabled: isLoading
                }}
                propsLoading={{ color: 'inherit' }}
              >
                Clear
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: isLoading || isSubmitDisabled,
                  fullWidth: true,
                  onClick: () => {
                    handleSubmit(onSuccess, onFail)()
                  },
                  sx: { textTransform: 'none' }
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={isLoading}
              >
                Submit for Analysis
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default ReportFalseDetectionTab
