import { ChevronRightIcon } from '@opswat/react-icon'
import { Box, Button, Collapse, Typography } from '@opswat/react-ui'

import _map from 'lodash/map'

import BoxPhone from './BoxPhone'

interface IProps {
  item: any
  open?: boolean
  onSelect: (location: string) => void
}

const LocationPhones = ({ item, open = false, onSelect }: IProps) => {
  const handleSelect = () => {
    onSelect(item.location)
  }

  return (
    <>
      <Button
        variant="text"
        sx={{
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '4px 12px 4px 4px',
          backgroundColor: '#E9EAEB',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#d7d8d9'
          }
        }}
        onClick={handleSelect}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ ml: 3, textAlign: 'left' }}>{item.location}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: `rotate(${open ? 90 : 0}deg)`,
            transition: 'all 0.2s ease-in-out'
          }}
        >
          <ChevronRightIcon />
        </Box>
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }}>
          {_map(item.children, (child, idx) => (
            <BoxPhone key={idx.toString()} item={child} />
          ))}
        </Box>
      </Collapse>
    </>
  )
}

export default LocationPhones
