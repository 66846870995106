import React, { FC } from 'react'

import { Box, Divider, Grid, Skeleton, TemplateSection, Typography } from '@opswat/react-ui'

import { useMemo } from 'react'
import TextGridView from '../TextGridView'

const BasicLoading: FC<unknown> = () => {
  const leftInfos = useMemo(() => {
    return [
      {
        label: 'Owner'
      },
      {
        label: 'Website'
      }
    ]
  }, [])

  const billingAddressInfos = useMemo(() => {
    return [
      {
        label: 'Address 1*'
      },
      {
        label: 'Address 2'
      },
      {
        label: 'City*'
      },
      {
        label: 'Country*'
      },

      {
        label: 'Postal Code*'
      },
      {
        label: 'Phone Number*'
      }
    ]
  }, [])

  const shippingAddressInfos = useMemo(() => {
    return [
      {
        label: 'Address 1*'
      },
      {
        label: 'Address 2'
      },
      {
        label: 'City*'
      },
      {
        label: 'Country*'
      },

      {
        label: 'Postal Code*'
      },
      {
        label: 'Phone Number*'
      }
    ]
  }, [])

  return (
    <>
      <TemplateSection>
        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView
                  label="Name*"
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </Grid>

              {leftInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <Divider />
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" paragraph>
                  Billing Address
                </Typography>
              </Grid>
              {billingAddressInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4} sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 2, sm: 0 } }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" paragraph>
                  Shipping Address
                </Typography>
              </Grid>
              {shippingAddressInfos.map((item, idx) => (
                <Grid item xs={12} key={idx.toString()}>
                  <TextGridView
                    label={item.label}
                    value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container>
            <Grid item xs={12} md={10} lg={8}>
              <Divider />
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <TextGridView
                  containerProps={{
                    sx: {
                      alignItems: 'flex-start'
                    }
                  }}
                  label={<Typography>Note</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="100px" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TemplateSection>
    </>
  )
}

export default BasicLoading
