import { Typography, styled } from '@opswat/react-ui'

export const HighLightEmail = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 500
}))

export const ApiErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontStyle: 'italic'
}))
