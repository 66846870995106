import { CustomTab, TypographyDivider } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useTypedSelector } from 'myopswat-web/src/store'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyEnginePackagesQuery, useLazyEnginesStatusQuery } from 'myopswat-web/src/api/engine-status'
import {
  engineStatusLinuxPageURL,
  engineStatusPackagePageURL,
  engineStatusWindowsPageURL,
  noPermissionPageUrl
} from 'myopswat-web/src/routes'
import { ENGINE_ERROR_CODES, ENGINE_STATUS_TAB } from './contants'
import EnginePackagesView from './views/EnginePackages'
import EngineStatusView from './views/EngineStatus'

const URL_MAP_TAB: Record<string, string> = {
  [engineStatusWindowsPageURL]: ENGINE_STATUS_TAB.WINDOWS,
  [engineStatusLinuxPageURL]: ENGINE_STATUS_TAB.LINUX,
  [engineStatusPackagePageURL]: ENGINE_STATUS_TAB.PACKAGES
}

const TAB_MAP_URL: Record<string, string> = {
  [ENGINE_STATUS_TAB.WINDOWS]: engineStatusWindowsPageURL,
  [ENGINE_STATUS_TAB.LINUX]: engineStatusLinuxPageURL,
  [ENGINE_STATUS_TAB.PACKAGES]: engineStatusPackagePageURL
}

const EngineStatusPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showErrorMessage } = useApiErrorHook({ errorMap: {} })
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const [engineStatusList, setEngineStatusList] = useState([])
  const [enginePackagesList, setEnginePackagesList] = useState([])

  const [selectedTab, setSelectedTab] = useState<string>('')
  const [isShowPackages, setIsShowPackages] = useState<boolean>(false)

  const [getEnginesStatus, { isFetching: isFetchingEngineStatus }] = useLazyEnginesStatusQuery()
  const [getEnginePackages, { isFetching: isFetchingEnginePackages }] = useLazyEnginePackagesQuery()

  const onChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
    navigate(TAB_MAP_URL[selectedTab], { replace: true })
  }

  const isLoading = useMemo(() => {
    if (selectedTab === ENGINE_STATUS_TAB.PACKAGES) {
      return isFetchingEnginePackages
    }
    return isFetchingEngineStatus
  }, [isFetchingEnginePackages, isFetchingEngineStatus, selectedTab])

  const fetchData = useCallback(async () => {
    try {
      if (selectedTab === ENGINE_STATUS_TAB.PACKAGES) {
        setEnginePackagesList([])
        const enginePackages = await getEnginePackages().unwrap()
        setEnginePackagesList(enginePackages)
      } else {
        setEngineStatusList([])
        const enginesStatus = await getEnginesStatus(selectedTab).unwrap()
        setEngineStatusList(enginesStatus)
      }
    } catch (error) {
      const errorCode = _get(error, 'errors.0.code')
      if (!_isEmpty(errorCode) && errorCode == ENGINE_ERROR_CODES.ENGINE_PACKAGE_PERMISSION) {
        // Redirect to engine status window if user access to engine packages page without permission
        navigate(noPermissionPageUrl, { replace: true })
      }
      const errors = _get(error, 'errors')
      if (!_isEmpty(errors)) {
        showErrorMessage(errors)
      }
    }
  }, [getEnginePackages, getEnginesStatus, selectedTab])

  const checkPermission = useCallback(async (pathname: string) => {
    const isShowPackages = _get(profile, 'accountInfo.showEnginePackage', false)
    const tab = URL_MAP_TAB[pathname]
    if (!isShowPackages && tab === ENGINE_STATUS_TAB.PACKAGES) {
      // Redirect to engine status window if user access to engine packages page without permission
      navigate(noPermissionPageUrl, { replace: true })
      return
    }
    setSelectedTab(tab)
    setIsShowPackages(isShowPackages)
  }, [])

  useEffect(() => {
    checkPermission(location.pathname)
  }, [])

  useEffect(() => {
    if (selectedTab) {
      fetchData()
    }
  }, [selectedTab])

  return (
    <>
      <TypographyDivider label="Engine Status" />
      {selectedTab != '' && (
        <CustomTab
          defaultTab={selectedTab}
          tabs={[
            {
              label: 'Window Engine Status',
              value: ENGINE_STATUS_TAB.WINDOWS,
              content: <EngineStatusView isLoading={isLoading} list={engineStatusList} />
            },
            {
              label: 'Linux Engine Status',
              value: ENGINE_STATUS_TAB.LINUX,
              content: <EngineStatusView isLoading={isLoading} list={engineStatusList} />
            },
            ...(isShowPackages
              ? [
                  {
                    label: 'Packages',
                    value: ENGINE_STATUS_TAB.PACKAGES,
                    content: <EnginePackagesView isLoading={isLoading} list={enginePackagesList} />
                  }
                ]
              : [])
          ]}
          onSelectedTab={onChangeTab}
          contentProps={{ pt: 3 }}
          tabProps={{
            sx: {
              textTransform: 'capitalize'
            }
          }}
        />
      )}
    </>
  )
}

export default EngineStatusPage
