import { createApi } from '@reduxjs/toolkit/query/react'

import { fetchRequestBaseQuery } from './fetchAPI'
import { fetchRequestFileAPIQuery } from './filesAPI'
import { graphqlRequestBaseQuery } from './graphql'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'

/** GraphQL */
const graphqlService = graphqlRequestBaseQuery({
  url: process.env.REACT_APP_API_ENDPOINT || ''
})

export const apiGraphql = createApi({
  baseQuery: graphqlService,
  endpoints: () => ({})
})

/** Fetch */
export const fetchAPI = createApi({
  reducerPath: 'fetchAPI',
  baseQuery: fetchRequestBaseQuery,
  endpoints: () => ({})
})

/** Fetch */
export const fetchFilesAPI = createApi({
  reducerPath: 'fetchFilesAPI',
  baseQuery: fetchRequestFileAPIQuery,
  endpoints: () => ({})
})

/** REST API */
const baseRestQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST || '',
  prepareHeaders: async headers => {
    const accessToken =
      process.env.REACT_APP_TOKEN_STORAGE === KEYS.COOKIE ? getCookie(KEYS.TOKEN_KEY) : getLocalStorage(KEYS.TOKEN_KEY)
    headers.set('Authorization', `Bearer ${accessToken}`)

    return headers
  }
})

export const apiRestful = createApi({
  reducerPath: 'apiRestful',
  baseQuery: baseRestQuery,
  endpoints: () => ({})
})
