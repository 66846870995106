import React, { useEffect, useMemo } from 'react'

import _range from 'lodash/range'
import _map from 'lodash/map'
import _get from 'lodash/get'

import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { useLocation } from 'react-router-dom'
import { DIALOGS } from '@myopswat/common'
import { Box, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import ProductSkeleton from 'myopswat-web/src/components/Skeleton/ProductSkeleton'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'

export default function DialogPage() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const loadingArray = _range(15)

  const searchParams = new URLSearchParams(location.search)
  const nameParams = searchParams.get('name') || ''

  const dialogName = useMemo(() => {
    if (nameParams === 'ACTIVATE_LICENSE') return DIALOGS.ACTIVE_LICENSES
    return ''
  }, [nameParams])

  useEffect(() => {
    if (nameParams && !_get(dialogType, DIALOGS.SESSION_TIMEOUT)) {
      if (nameParams === 'FEEDBACK') {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.NPS_SURVEY]: true,
            [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'left-menu',
            [DIALOGS_WEB.NPS_NOTIFICATION]: false
          })
        )
      } else {
        dispatch(
          toggleDialogs({
            [dialogName]: true
          })
        )
      }
    }
  }, [nameParams])

  return (
    <TemplateSection>
      <Box>
        <TypographyDivider label="--" />
      </Box>

      <Grid container spacing={2} sx={{ px: 1 }}>
        {_map(loadingArray, (item: any, idx: any) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={idx.toString()}>
            <ProductSkeleton />
          </Grid>
        ))}
      </Grid>
    </TemplateSection>
  )
}
