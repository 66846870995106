import React, { FC } from 'react'

import { Skeleton, Grid } from '@opswat/react-ui'

const NotificationSkeleton: FC<unknown> = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Skeleton animation="wave" variant="text" width="30%" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton animation="wave" variant="text" width="100%" height="40px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton animation="wave" variant="text" width="30%" />
      </Grid>
    </Grid>
  )
}

export default NotificationSkeleton
