import React, { FC } from 'react'

import { Button, Typography, TypographyLineClamp } from '@opswat/react-ui'

interface IProps {
  title: string
  count: number
  isSelected: boolean
  color?: string
  onClick: () => void
}

const ButtonFilterCases: FC<IProps> = ({ title, count, isSelected, color, onClick }) => {
  return (
    <Button
      fullWidth
      disableElevation
      variant={isSelected ? 'contained' : 'text'}
      sx={{
        height: '86px',
        padding: '10px',
        backgroundColor: isSelected ? '#1D6BFC' : '#F4F4F5',
        border: isSelected ? '1px solid #FFFFFF' : 'none',
        outline: isSelected ? '1px solid #7EAAFD' : 'none',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          color: isSelected ? '#FFFFFF' : color ?? '#1D6BFC',
          fontSize: '32px',
          lineHeight: '36px',
          fontWeight: 400
        }}
      >
        {count}
      </Typography>
      <TypographyLineClamp
        line={1}
        tooltipValue={title ?? ''}
        sx={{
          color: isSelected ? '#FFFFFF' : '#1B273C',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          textAlign: 'left'
        }}
      >
        {title}
      </TypographyLineClamp>
    </Button>
  )
}

export default ButtonFilterCases
